import React, { useState } from 'react';

import NewsList from '../../../components/news/news';
import EditNews from '../../../components/news/edit-new';
import AddNews from '../../../components/news/add-new';

export default function Noticias() {

  const [state, setState] = useState(0)
  const [newsToEdit, setNewsToEdit] = useState()

  return (
    <>
      {state === 0 && <NewsList setState={setState} setNewsToEdit={setNewsToEdit} />}
      {state === 1 && <AddNews setState={setState} />}
      {state === 2 && <EditNews noticia={newsToEdit} setState={setState} />}
    </>
  )
}