import React, { useEffect, useState } from 'react';

import List from './list'
import NewSchedule from './new'
import EditSchedule from './edit'
import NewReservedTime from '../reservedTime/new'
import RequestSchedule from './request'

export default function Schedule() {

  const [state, setState] = useState(0)
  const [schedule, setSchedule] = useState(0)
  const [requestSchedule, setRequestSchedule] = useState()

  return (
    <>
      {state === 0 && (
        <List setState={setState} setSchedule={setSchedule} />
      )}
      {state === 1 && (
        <NewSchedule setState={setState} setRequestSchedule={setRequestSchedule} />
      )}
      {state === 2 && (
        <EditSchedule setState={setState} data={schedule} setSchedule={setSchedule} />
      )}
      {state === 3 && (
        <NewReservedTime setState={setState} setSchedule={setSchedule} />
      )}
      {state === 4 && (
        <RequestSchedule setState={setState} schedule={requestSchedule} />
      )}
    </>
  )
}