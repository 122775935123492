import DateFnsUtils from "@date-io/date-fns";
import LuxonUtils from "@date-io/luxon"
import {
    Box,
    Button,
    CircularProgress,
    Container,
    createMuiTheme,
    Grid,
    makeStyles,
    Modal,
    MuiThemeProvider,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { color } from "../../../components/common/colors";
import { getLogSchedule } from "../../../providers/log";
import { getPermissions } from "../../../providers/permissions";
import { endOfTheDay, formatDateApi, formatDateUser, formatHourUser, startOfTheDay, todayDate, todayHour } from "../../../utils/formatDate"
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background,
        height: "100%",
        padding: theme.spacing(3),
    },
    card: {
        background: color.white,
    },
    tableContainer: {
        backgroundColor: color.white,
    },
    submitBtn: {
        background: color.red,
        color: color.white,
        "&:hover": {
            backgroundColor: "#a35655",
        },
    },
    listText: {
        fontSize: 14,
    },
    option: {
        backgroundColor: "white",
    },
    paper: {
        marginTop: 10,
    },
    diolog: {
        background: color.white,
    },
    dialogRoot: {
        background: "rgba(0.0.0, 0.3)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        height: "90%",
    },
}));

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: color.red,
        },
    },
});

export default function ListLog({ props }) {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [search, setSearch] = useState();
    const [logToShow, setLogToShow] = useState([]);
    const [orderAlf, setOrderAlf] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [dataInit, setDataInit] = useState(formatDateApi(startOfTheDay(2022, 1, 11)));
    const [dataFim, setDataFim] = useState(formatDateApi(endOfTheDay(todayDate)));
    const [place, setPlace] = useState('');
    const [action, setAction] = useState('');
    const [oab, setOab] = useState('');
    const [loading, setLoading] = useState(true);
    const [modalScheduleVisible, setModalScheduleVisible] = useState(false)
    const [searchApi, setSearchApi] = useState("")
    const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
    const [schedule, setSchedule] = useState({
        schedule: {
            // status: "",
            data: "2022-12-26",
            horaInicio: "",
            user: { nome: "" },
            servico: { nome: "", },
            procedimento: { nome: "" },
            profissional: {
                nome: "",
                unidade: {
                    nome: "",
                    endereco: "",
                    numero: "",
                    cidade: "",
                    bairro: "",
                    estado: "",
                    cep: "",
                    telefone: "",
                }
            }
        }
    })
    const filterPlace = ['CMS', 'APP']
    const filterStatus = ['CREATED', 'CANCELED', 'NOT_APPEAR', 'CONFIRMED', 'FINISHED', 'EDITED']

    const userData = useSelector(({ user }) => user);

    useEffect(() => {
        const fetchPermissions = async () => {
            const { id } = userData;
            const permissions = await getPermissions(id);
            setNewPermission(permissions.permissions.logSchedule);
        };

        fetchPermissions();
    }, []);

    function changeName(status) {
        switch (status) {
            case 'confirmed':
                return 'confirmado';
            case 'await':
                return 'Aguardando';
            case 'not_appear':
                return 'Não Comp.';
            case 'finished':
                return 'Concluído';
            case 'reject':
                return 'Cancelado';
            case 'CANCELED':
                return 'Cancelado';
            case 'FINISHED':
                return 'Finalizado';
            case 'CONFIRMED':
                return 'Confirmado';
            case 'NOT_APPEAR':
                return 'Não Compareceu';
            case 'CREATED':
                return 'Criado';
            case 'EDITED':
                return 'Editado';
            default:
                break;
        }
    }
    useEffect(() => {
        const req = async () => {
            const data = await getLogSchedule({
                busca: searchApi,
                filtros: {
                    'de': (dataInit),
                    'ate': (dataFim),
                    'place': place,
                    'actions': action,
                    'oab': oab,
                },
                'pagina': page,
                'entradasPorPagina': rowsPerPage,
            });
            setLogToShow(data);
        }
        req()
        setLoading(false);
    }, [rowsPerPage, page, searchApi]);

    const chamadaApi = async () => {
        setLoading(true);
        setPage(0)
        const data = await getLogSchedule({
            busca: searchApi,
            filtros: {
                'de': (dataInit),
                'ate': (dataFim),
                'place': place,
                'actions': action,
                'oab': oab,
            },
            'pagina': page,
            'entradasPorPagina': rowsPerPage,
        });
        setLogToShow(data);
        setLoading(false);
        setOpenModal(false);
    }
    const handleFilter = async () => {
        setPage(0)
        setSearchApi(search)
    };

    const OrderByName = () => {
        if (orderAlf === 0) {
            logToShow.sort((a, b) => {
                return a.user.nome > b.user.nome
                    ? 1
                    : b.user.nome > a.user.nome
                        ? -1
                        : 0;
            });
            setOrderAlf(1);
        } else {
            logToShow.sort((a, b) => {
                return a.user.nome > b.user.nome
                    ? -1
                    : b.user.nome > a.user.nome
                        ? 1
                        : 0;
            });
            setOrderAlf(0);
        }
    };

    const CloseOrder = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleChangeFilter = (event) => {
        setSearch(event.target.value);
    };

    const handleChangeOab = (event) => {
        setOab(event.target.value)
    }

    const handleChangePage = async (event, newPage) => {
        setLoading(true);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        let teste = event.target.value;
        setRowsPerPage(teste);
        setPage(0);
    };

    const handleOpenModal = () => {
        setDataInit(DateTime.fromISO(todayHour).minus({days: 30}));
        setDataFim(endOfTheDay(todayHour));
        setOpenModal(true);
        setPage(0);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const Show = logToShow.map(x => {
        return {
            id: x.id,
            user: x.userScheduled,
            actions: x.action,
            createdAt: x.createdAt,
            place: x.place,
            schedule: x.schedule,
            maker: {
                ...x.userAdmScheduleMaker,
                ...x.userScheduleMaker
            }
        }
    })
    return (
        newPermission.viz ? (
            <>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box my={2} display="flex" justifyContent="flex-end">
                            <Grid container spacing={3}>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nome do Agendador ou OAB"
                                        name="filter"
                                        variant="outlined"
                                        value={search}
                                        onChange={handleChangeFilter}
                                        style={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleFilter()}
                                        style={{ padding: "15px 40px" }}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="button"
                                        variant="contained"
                                        onClick={handleOpenModal}
                                        style={{ padding: "15px 40px" }}
                                    >
                                        Filtrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                <Paper className={classes.paper}>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                        {loading ? (
                            <Grid
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "auto",
                                    padding: 100,
                                }}
                            >
                                <CircularProgress color={"primary"} size={80} />
                            </Grid>
                        ) : (
                            <TableContainer className={classes.tableContainer}>
                                <Table className={classes.table} size={"small"}>
                                    <TableHead className={classes.headerTb}>
                                        <TableRow>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"OAB"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Nome do Agendador"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Local do Agendamento"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Ação"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Data"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Hora"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Dados Agendamento"}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {Show.map((row) => {
                                        return (
                                            <TableBody>
                                                <TableRow hover key={row.id} style={{ verticalAlign: 'text-top' }}>
                                                    <TableCell style={{ minWidth: 150 }}>{row.user.oab}</TableCell>
                                                    <TableCell>{row.maker.nome}</TableCell>
                                                    <TableCell>{row.place}</TableCell>
                                                    <TableCell>{changeName(row.actions)}</TableCell>
                                                    <TableCell>{formatDateUser(row.createdAt)}</TableCell>
                                                    <TableCell>{formatHourUser(row.createdAt)}</TableCell>
                                                    <TableCell>
                                                        <Button
                                                            size='small'
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={() => {
                                                                setSchedule(row)
                                                                setModalScheduleVisible(true)
                                                            }}
                                                            disabled={!newPermission.det}
                                                        >
                                                            Ver Agendamento
                                                        </Button>
                                                    </TableCell>

                                                </TableRow>
                                            </TableBody>
                                        )
                                    })}
                                </Table>
                            </TableContainer>
                        )}
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12} style={{}}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    labelRowsPerPage={"Linhas por página"}
                                    component="div"
                                    count={Show.length}
                                    nextIconButtonProps={{
                                        disabled: Show.length < rowsPerPage || loading,
                                    }}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </MuiThemeProvider>
                </Paper>
                <Box className={classes.footer} />

                {/* <Popover
                id={"orderBy"}
                open={open}
                anchorEl={anchorEl}
                onClose={CloseOrder}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Grid style={{ padding: 10, backgroundColor: "#A93533" }}>
                    <Grid style={{ marginBottom: 10 }}>
                        <Button
                            color="white"
                            size="large"
                            type="submit"
                            variant="contained"
                            style={{ width: 130, color: "#A93533" }}
                            onClick={() => OrderByName()}
                        >
                            Nome
                        </Button>
                    </Grid>
                    <Grid style={{ marginBottom: 10 }}>
                        <Button
                            color="white"
                            size="large"
                            type="submit"
                            variant="contained"
                            style={{ width: 130, color: "#A93533" }}
                        // onClick={() => OrderByServ()}
                        >
                            Serviço
                        </Button>
                    </Grid>
                    <Grid>
                        <Button
                            color="white"
                            size="large"
                            type="submit"
                            variant="contained"
                            style={{ width: 130, color: "#A93533" }}
                        // onClick={() => OrderByProf()}
                        >
                            Profissional/ Recurso
                        </Button>
                    </Grid>
                </Grid>
            </Popover> */}

                {/* MODAL DADOS AGENDAMENTO */}

                <Dialog
                    open={modalScheduleVisible}
                    onClose={() => {
                        setModalScheduleVisible(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {
                            backgroundColor: '#fff',
                        },
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Agendamento"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Situação atual: {changeName(schedule.schedule.status)}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Serviço: {schedule.schedule.servico.nome}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Procedimento: {schedule.schedule.procedimento.nome}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Profissional: {schedule.schedule.profissional.nome}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Usuario: {schedule.schedule.user.nome}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Data: {formatDateUser(schedule.schedule.data)}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Hora: {schedule.schedule.horaInicio}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Unidade: {schedule.schedule.profissional.unidade.nome}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Endereço: {schedule.schedule.profissional.unidade.endereco +
                                ', ' +
                                schedule.schedule.profissional.unidade.numero +
                                ', ' +
                                schedule.schedule.profissional.unidade.cidade +
                                ', ' +
                                schedule.schedule.profissional.unidade.bairro +
                                ', ' +
                                schedule.schedule.profissional.unidade.estado +
                                ', ' +
                                schedule.schedule.profissional.unidade.cep}
                        </DialogContentText>
                        <DialogContentText id="alert-dialog-description">
                            Telefone: {schedule.schedule.profissional.unidade.telefone}
                        </DialogContentText>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setModalScheduleVisible(false)
                            }}
                        >
                            OK
                        </Button>

                    </DialogActions>
                </Dialog>

                {/* MODAL FILTER */}

                <Modal
                    className={classes.modal}
                    open={openModal}
                    onClose={handleCloseModal}
                    keepMounted
                >
                    <Container
                        maxWidth="md"
                        spacing={3}
                        style={{
                            backgroundColor: "white",
                            height: "100%",
                            overflow: "scroll",
                            padding: 30,
                            margin: "0% 10%",
                            borderRadius: 4,
                            border: "1px solid #000000",
                            top: 100,
                        }}
                    >
                        <Grid
                            container
                            spacing={6}
                            style={{
                                padding: 10,
                            }}
                        >
                            <Grid item md={12} xs={12}>
                                <Typography
                                    variant="h3"
                                    display="block"
                                    style={{ marginTop: 10 }}
                                >
                                    Selecionar Filtros
                                </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid xs={6} style={{ padding: 5 }}>
                                        <Typography
                                            variant="h5"
                                            display="block"
                                            style={{ marginTop: 20, marginBottom: 10 }}
                                        >
                                            OAB
                                        </Typography>
                                        <MuiThemeProvider theme={defaultMaterialTheme}>
                                            <TextField
                                                fullWidth
                                                name="filter"
                                                variant="outlined"
                                                value={oab}
                                                onChange={handleChangeOab}
                                                style={{ backgroundColor: 'white' }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid xs={6} style={{ padding: 5 }}>
                                                <Typography
                                                    variant="h5"
                                                    display="block"
                                                    style={{ marginTop: 20 }}
                                                >
                                                    Datas entre:
                                                </Typography>
                                                <MuiThemeProvider theme={defaultMaterialTheme}>
                                                    <MuiPickersUtilsProvider utils={LuxonUtils} 
                                                    // locale={pt}
                                                    >
                                                        <DatePicker
                                                            error={Boolean(dataFim < dataInit)}
                                                            helperText={(dataFim < dataInit) ? 'Data inicial deve ser menor que a data final' : ''}
                                                            fullWidth
                                                            margin="normal"
                                                            id="dataInicio"
                                                            format="dd/MM/yyyy"
                                                            autoOk
                                                            value={dataInit}
                                                            onChange={(newValue) => {
                                                                setDataInit(formatDateApi(startOfTheDay(newValue)));
                                                            }}
                                                            maxDate={todayHour}
                                                            inputVariant={"outlined"}
                                                            variant="inline"
                                                            style={{ marginTop: 10 }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid xs={6} style={{ padding: 5 }}>
                                                <Typography
                                                    variant="h5"
                                                    display="block"
                                                    style={{ marginTop: 20 }}
                                                >
                                                    E:
                                                </Typography>
                                                <MuiThemeProvider theme={defaultMaterialTheme}>
                                                    <MuiPickersUtilsProvider utils={LuxonUtils} 
                                                    // locale={pt}
                                                    >
                                                        <DatePicker
                                                            error={Boolean(dataFim < dataInit)}
                                                            helperText={(dataFim < dataInit) ? 'Data inicial deve ser menor que a data final' : ''}
                                                            fullWidth
                                                            margin="normal"
                                                            id="dataInicio"
                                                            format="dd/MM/yyyy"
                                                            autoOk
                                                            value={dataFim}
                                                            onChange={(newValue) => {
                                                                setDataFim(formatDateApi(endOfTheDay(newValue)));
                                                            }}
                                                            maxDate={todayHour}
                                                            inputVariant={"outlined"}
                                                            variant="inline"
                                                            style={{ marginTop: 10 }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs={6} style={{ padding: 5 }}>
                                        <Typography
                                            variant="h5"
                                            display="block"
                                            style={{ marginTop: 20 }}
                                        >
                                            Ação
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            DisableClearable
                                            CloseOnSelect
                                            id="checkboxes-tags-demos"
                                            classes={{
                                                popper: classes.option,
                                            }}
                                            value={action}
                                            options={filterStatus}
                                            getOptionLabel={(option) => changeName(option)}
                                            onChange={(e, value) => {
                                                setAction(value);
                                            }}
                                            style={{ backgroundColor: "white" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    style={{ marginTop: 10 }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={6} style={{ padding: 5 }}>
                                        <Typography
                                            variant="h5"
                                            display="block"
                                            style={{ marginTop: 20 }}
                                        >
                                            Local
                                        </Typography>
                                        <Autocomplete
                                            disablePortal
                                            DisableClearable
                                            CloseOnSelect
                                            id="checkboxes-tags-demos"
                                            classes={{
                                                popper: classes.option,
                                            }}
                                            value={place}
                                            options={filterPlace}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, value) => {
                                                setPlace(value);
                                            }}
                                            style={{ backgroundColor: "white" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    style={{ marginTop: 10 }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    marginTop: 30,
                                    textAlign: "center",
                                    placeContent: "center",
                                }}
                            >
                                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        onClick={chamadaApi}
                                        style={{ padding: "15px 30px", width: 150 }}
                                    >
                                        Aplicar
                                    </Button>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        onClick={() => {
                                            setDataInit(startOfTheDay(2022, 1, 11));
                                            handleCloseModal()
                                        }}
                                        style={{ padding: "15px 30px", width: 150 }}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Modal>
            </>
        ) : (
            <>
                Sem permissão
            </>
        )
    );
}
