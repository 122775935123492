import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import Avatar from '@material-ui/core/Avatar';
import partnersImg from '../../../../assets/partners.png'
import associatesImg from '../../../../assets/associates.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAction: {
    boxShadow: '0px 0px 0px 2px #979797',
    width: 200,
    height: 200,
    marginRight: 100,
  }
}));

export default function CreditPoint({ setState, credit, setTypeCredit }) {
  const classes = useStyles()

  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.Card}>
        <CardContent>
          <Grid md={12}>
            <Typography style={{ marginBottom: 40 }}>
              <h1>
                Creditar Pontos
              </h1>
            </Typography>
          </Grid>
          <Grid spacing={6}>
            <Button className={classes.btnAction}
              onClick={() => {
                setTypeCredit("USER")
                setState(3)
              }}
            >
              <Avatar src={associatesImg} variant="square"
                style={{ position: "relative", top: -5, left: 55, width: '50%', height: '50%' }}
              />
              <h3 style={{ position: "relative", top: 70, right: 45 }}>Associados</h3>
            </Button>
            <Button className={classes.btnAction}
              onClick={() => {
                setTypeCredit("PARTNER")
                setState(4)
              }}
            >
              <Avatar src={partnersImg} variant="square"
                style={{ position: "relative", top: -5, left: 45, width: '50%', height: '50%' }}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ position: "relative", top: 70, right: 42 }}>Parceiros</h3>
                <h5 style={{ position: "relative", top: 70, right: 42 }}>(Unidades)</h5>
              </div>
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}