import React, { useState } from 'react';
import { Avatar, TextField, Button, makeStyles, Box, Container, Card, CardContent, Grid, CircularProgress } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { capitalize } from 'lodash'
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale'
import { refuse, accept } from '../../../../providers/profileEdit'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  cancelBtn: {
    backgroundColor: '#f48fb1',
    color: color.white,
    '&:hover': {
      backgroundColor: '#f6a5c0',
    },
  },
  avatar: {
    width: '120px',
    height: '120px',
  },
}));

export default function UserProfileEdit({ userActivate, setState }) {
  const classes = useStyles()
  const profile = userActivate.profile[0]
  const [loading, setLoading] = useState(false)

  const refuseEdit = async () => {
    setLoading(true)
    try {
      await refuse(profile)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      setState(0)
    }
  }

  const acceptEdit = async () => {
    setLoading(true)
    try {
      await accept(profile)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      setState(0)
    }
  }

  const getSexoText = sexo => {
    if (!sexo || sexo === 'nao_informado') {
      return "Não informado"
    } else {
      return capitalize(sexo)
    }
  }

  return (
    <Container maxWidth="md" disableGutters>

      {profile.img && <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          src={profile.img}
        />
      </Box>}
      <Card className={classes.card}>
        <CardContent >
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="nome"
                variant="outlined"
                value={profile.nome}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="CPF"
                name="cpf"
                variant="outlined"
                value={profile.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Data de Nascimento"
                name="nascimento"
                variant="outlined"
                value={format(new Date(`${profile.nascimento}T03:00:00`), "dd/MM/yyyy", { locale: ptBR })}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Sexo"
                name="sexo"
                variant="outlined"
                value={getSexoText(profile.sexo)}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Celular"
                name="celular"
                variant="outlined"
                value={profile.celular.replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Cidade"
                name="cidade"
                variant="outlined"
                value={profile.cidade}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Bairro"
                name="bairro"
                variant="outlined"
                value={profile.bairro}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Endereço"
                name="endereco"
                variant="outlined"
                value={profile.endereco}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Número"
                name="numero"
                variant="outlined"
                value={profile.numero}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Complemento"
                name="complemento"
                variant="outlined"
                value={profile.complemento}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid container justify='center' alignItems="center" spacing={3}>
            {loading ? <CircularProgress />
              : <><Grid item md={4} xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  size="large"
                  variant="contained"
                  onClick={() => acceptEdit()}
                >
                  Aceitar Solicitação
                          </Button>
              </Grid>
                <Grid item md={4} xs={12}>
                  <Button color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => refuseEdit()}
                  >
                    Cancelar Solicitação
                          </Button>
                </Grid></>}

          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}