import {
  Box,
  Button,
  CircularProgress, Container,
  createMuiTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, makeStyles,
  Modal,
  MuiThemeProvider, Paper,
  Popover, Table,
  TableBody, TableCell,
  TableContainer,
  TableFooter, TableHead,
  TablePagination, TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Delete as DeleteIcon, SettingsOutlined, LocalPrintshop, SettingsEthernet, SignalCellularConnectedNoInternet1Bar,
} from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { color } from '../../../../components/common/colors';
import { getAll, searchUsers, userCities } from '../../../../providers/user';
import { showOnGrid } from '../../../../providers/userBlock';
import {
  Situacao,
  Status,
  Tipo,
} from '../../../../utils/blockUser'
import { DataBloqueio, DataLiberacao, } from '../../../../utils/blockUser'
import { format, add, getTime, endOfDay, startOfDay } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import jsPDF from "jspdf";
import "jspdf-autotable";
import TestIntegrityLevel from 'es-abstract/2015/TestIntegrityLevel';
import { subscribeToUserUpdates, unsubscribeToUserUpdates } from '../../../../sockets/Socket';
import { unblockUserByAdm } from '../../../../providers/userBlock';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%',
  },
  headerPage: {
    marginBottom: 30
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '95%'
  },
  option: {
    backgroundColor: 'white',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UserToShow({ setState, setUserToBlock, setSelectedUserToBlock, setUserMsg }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('')
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [list, setList] = useState([])


  const [loading, setLoading] = useState(true);
  const [modalUnblockVisible, setModalUnblockVisible] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [unblock, setUnblock] = useState([]);
  const [block, setBlock] = useState([]);
  const [userToUnlock, setUserToUnlock] = useState();
  const [status, setStatus] = useState([]);
  const [blockedFor, setBlockedFor] = useState([]);
  const [unBlockedFor, setUnBlockedFor] = useState([]);
  const [statusPicked, setStatusPicked] = useState([]);
  const [statusChoice, setStatusChoise] = useState([]);
  const [dateBlockedInitial, setDateBLockedInitial] = useState(add(new Date(), { days: -30 }));
  const [dateBlockedFinal, setDateBLockedFinal] = useState(new Date());
  const [A1, setA1] = useState(true);
  const [reason, setReason] = useState('');


  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.bloqueio);
    };

    fetchPermissions();
  }, []);

  
  useEffect(() => {
    async function fetchData() {
      //const temp = await userCities()
      const temp = await showOnGrid({
        'filtros': A1 ? "state" : {
          'statusSearch': statusPicked,
          'blockedFor': block,
          'unblockedFor': unblock,
          'dateInitial': dateBlockedInitial,
          'dateFinal': dateBlockedFinal,
        },
        'pagina': page,
        'entradasPorPagina': rowsPerPage,
        'orderBy': orderAlf,
        'texto': search
      })


      // const listWithFilter = data.map((user) => {
      //   const filtro = `${user.nome};${user.oab}`
      //   return { ...user, filter: filtro }
      // })
      if (orderAlf === 0) {
        setUsersToShow(temp)
      }
      setUsersToShow(temp)
      setLoading(false)
      setStatus(temp.map((e) => e.isBlocked))
      setStatusChoise(["BLOCKED", "UNLOCKED"])
      // setStatusChoise(Array.from(new Set((temp.map((e) => e.isBlocked).map(JSON.stringify)))).map(JSON.parse))
      setBlockedFor(Array.from(new Set((temp.map((e) => e.administratorBlocker).map(JSON.stringify)))).map(JSON.parse))
      const unblocked = temp.filter((e) => !!e.administratorUnblocker)
      const mapedUnblocked = unblocked.map((e) => e.administratorUnblocker)
      const filteredUnblocked = mapedUnblocked.map(JSON.stringify)
      const tempUnblocked = new Set(filteredUnblocked)
      const unblockedFinal = Array.from(tempUnblocked).map(JSON.parse)
      setUnBlockedFor(unblockedFinal)
    }
    fetchData();
  }, [page, rowsPerPage, orderAlf, search])

  function refresh() {
    async function fetchData() {
      const temp = await showOnGrid({
        'filtros': "state",
        'pagina': page,
        'entradasPorPagina': rowsPerPage,
        'orderBy': orderAlf
      })
      setUsersToShow(temp)

    } fetchData()
  }


  function handleUserUpdated(user) {
    const temp = userToShow.map(u => {
      if (u.id == user.id) return user;
      return u;
    })
    setUsersToShow(temp)
  }

  useEffect(() => {
    subscribeToUserUpdates((user) => {
      handleUserUpdated(user);
    });

    return () => {
      unsubscribeToUserUpdates();
    }
  }, []);


  const find = async () => {
    setPage(0)
    const data = await showOnGrid({
      filtros: {
        'statusSearch': statusPicked,
        'blockedFor': block,
        'unblockedFor': unblock,
        'dateInitial': dateBlockedInitial,
        'dateFinal': dateBlockedFinal,
      },
      'texto': search,
      'pagina': page,
      'entradasPorPagina': rowsPerPage
    })
    setUsersToShow(data)
  }


  const handleEditProfile = (user, message) => {
    setSelectedUserToBlock(user);
    setUserToBlock([user]);
    setUserMsg([message])
    setState(3);
  }
  // const OrderByName = () => {
  //   if (orderAlf === 0) {
  //     userToShow.map((e) => e.user.sort((a, b) => {
  //       return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
  //     }));
  //     setOrderAlf(1)
  //   } else {
  //     userToShow.map((e) => e.user.sort((a, b) => {
  //       return (a.nome > b.nome) ? -1 : ((b.nome > a.nome) ? 1 : 0);
  //     }));
  //     setOrderAlf(0)
  //   }
  // }

  // const OrderByOab = () => {
  //   if (orderAlf === 0) {
  //     userToShow.map((e) => e.user.sort((a, b) => {
  //       return (a.oab > b.oab) ? 1 : ((b.oab > a.oab) ? -1 : 0);
  //     }));
  //     setOrderAlf(1)
  //   } else {
  //     userToShow.map((e) => e.user.sort((a, b) => {
  //       return (a.oab > b.oab) ? -1 : ((b.oab > a.oab) ? 1 : 0);
  //     }));
  //     setOrderAlf(0)
  //   }
  // }
  // //aqui ordenar pela situação.
  // const OrderByType = () => {
  //   if (orderAlf === 0) {
  //     userToShow.sort((a, b) => {
  //       return (a.isBlocked > b.isBLocked) ? 1 : ((b.isBlocked > a.isBlocked) ? -1 : 0);
  //     });
  //     setOrderAlf(1)
  //   } else {
  //     userToShow.sort((a, b) => {
  //       return (a.isBlocked > b.isBLocked) ? -1 : ((b.isBLocked > a.isBlocked) ? 1 : 0);
  //     });
  //     setOrderAlf(0)
  //   }
  // }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const filteredByItem = async () => {
    setState(0)
    setA1(false)
    try {
      const data = await showOnGrid({
        texto: search,
        filtros: {
          'statusSearch': statusPicked,
          'blockedFor': block,
          'unblockedFor': unblock,
          'dateInitial': dateBlockedInitial,
          'dateFinal': dateBlockedFinal,
        },
        'pagina': page,
        'entradasPorPagina': rowsPerPage
      })
      setUsersToShow(data)
      setOpenModal(false);
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };


  const handleChangeRowsPerPage = (event) => {
    const temp = (event.target.value)
    setRowsPerPage(temp)
  };

  const getType = type => {
    if (type === 'TITULAR') {
      return 'Titular'
    } else {
      return 'Dependente'
    }
  }
  const getStatus = Bloqueio => {
    if (Bloqueio === 'BLOCKED') {
      return 'Bloqueado'
    } else {
      return 'Desbloqueado'
    }
  }


  const handleOpenModal = () => {
    setOpenModal(true);
    //setUsersToShow(list)
    setPage(0)
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  // codigo para impressão. arrumar depois.
  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Status",
      "Nome",
      "Tipo",
      "CPF",
      "OAB",
      "Situação",
    ];
    const body = [];
    //aqui
    userToShow.forEach((row, index) => {
      if (index < 1100) {
        body.push([
          index + 1,
          row.status,
          row.nome,
          getType(row.userType),
          getStatus(row.isBlocked),
          row.oab,
          row.situacao,
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const formatTimestamp = (val) => {
    try {
      if (!val) return ''
      return format(new Date(val), 'dd/MM/yyyy')
    } catch (error) {
      return ''
    }
  }

  const handleSubmitUnblock = useCallback(
    async () => {
      setLoading(false)
      try {
        if (!userToUnlock || loading) return
        await unblockUserByAdm(userToUnlock, reason)
        refresh()
      } catch (error) {
        alert('Erro ao desbloquear usuário')
      } finally {
        setUserToUnlock(undefined)
        setModalUnblockVisible(false)
        setLoading(false)
        handleChangePage(null, 0)
      }
    },
    [userToUnlock, loading],
  )
  if (!newPermission.viz) {
    return (
      <>
        Sem permissão para visualizar
      </>
    );
  }
  return (
    <Grid item md={12} xs={12}>
      <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
        <Grid container spacing={3}>
          <Grid item md={3} xs={12} >
            <TextField
              fullWidth
              label="Nome ou Oab"
              name="filter"
              variant="outlined"
              value={search}
              onChange={handleChangeFilter}
              style={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item md={2} xs={12} style={{ marginRight: "-1%" }}>
            <Button
              color='primary'
              type="submit"
              size="large"
              variant="contained"
              onClick={() => find()}
              style={{ padding: '15px 40px' }}
            >
              Buscar
            </Button>
          </Grid>
          <Grid item md={2} xs={12} >
            <Button
              color='primary'
              size="large"
              type="button"
              variant="contained"
              onClick={OpenOrder}
              style={{ padding: '15px 40px' }}
            >
              Ordenar
            </Button>
          </Grid>
          <Grid item md={2} xs={12} >
            <Button
              color='primary'
              size="large"
              type="button"
              variant="contained"
              onClick={handleOpenModal}
              style={{ padding: '15px 40px' }}
            >
              Filtrar
            </Button>
          </Grid>
          <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
            {newPermission.det && (
              <Button variant='contained' className={classes.btnAdd} onClick={() => setState(1)}
                style={{ padding: '16px 35px' }}
              >
                Bloquear Associado
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <MuiThemeProvider theme={defaultMaterialTheme}>
          {loading
            ? <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', padding: 100 }}>
              <CircularProgress color={'primary'} size={80} />
            </Grid>
            :
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table}>
                <TableHead className={classes.headerTb}>
                  <TableRow>
                    <TableCell key={'oab'} className={classes.celTableHeader}>
                      {"OAB"}
                    </TableCell>
                    <TableCell key={'nome'} className={classes.celTableHeader}>
                      {"Nome"}
                    </TableCell>
                    <TableCell key={'dataBloqueio'} className={classes.celTableHeader}>
                      {"Data bloqueio"}
                    </TableCell>
                    <TableCell key={'dataLiberacao'} className={classes.celTableHeader}>
                      {"Data liberação"}
                    </TableCell>
                    <TableCell key={'adm bloqueio'} className={classes.celTableHeader}>
                      {"Bloqueado por"}
                    </TableCell>
                    <TableCell key={'adm desbloqueio'} className={classes.celTableHeader}>
                      {"Desbloqueado por"}
                    </TableCell>
                    <TableCell key={'getStatus'} className={classes.celTableHeader}>
                      {"Situação"}
                    </TableCell>
                    <TableCell key={'actions'} className={classes.celTableHeader}>
                      {"Ações"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userToShow.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => console.log(event)}
                        key={row}
                      >
                        <TableCell>{row.user.oab} </TableCell>
                        <TableCell style={{ cursor: 'pointer' }} onClick={() => {
                          handleEditProfile(row.user, row);
                        }} >{row.user.nome.toUpperCase()}</TableCell>
                        <TableCell>{formatTimestamp(row?.dateBlocked)}</TableCell>
                        <TableCell>{formatTimestamp(row?.dateUnblocked)}</TableCell>
                        {/* adm - 2 opções usuario adm ou sistema. */}
                        <TableCell>{row?.administratorBlocker?.nome || 'Sistema'}</TableCell>
                        {/* getStatus é bloqueado / desbloqueado */}
                        <TableCell>{row?.administratorUnblocker?.nome || (row?.isBlocked === "UNLOCKED" ? 'Sistema' : '')}</TableCell>
                        <TableCell>{getStatus(row?.isBlocked)}</TableCell>
                        <TableCell>
                          {row?.isBlocked === 'BLOCKED' && (
                            newPermission.edit && (
                              <Button
                                size='small'
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => {
                                  setReason('')
                                  setUserToUnlock(row)
                                  setModalUnblockVisible(true)
                                }}
                              >
                                Desbloquear
                              </Button>
                            )
                          )}
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>}
          <Grid container spacing={3} >
            <Grid item md={6} xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: 'center'
              }} >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<LocalPrintshop />}
                onClick={() => createPDF()}
              >
                Imprimir
              </Button>
            </Grid>
            <Grid item md={6} xs={12} style={{}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={'Linhas por página'}
                component="div"
                count={-1}
                rowsPerPage={rowsPerPage}
                nextIconButtonProps={{ disabled: userToShow.length < rowsPerPage }}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </MuiThemeProvider>
      </Paper>
      <Box className={classes.footer} />
      <Popover
        id={'orderBy'}
        open={open}
        anchorEl={anchorEl}
        onClose={CloseOrder}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
          <Grid style={{ marginBottom: 10 }}>
            <Button
              color='white'
              size="large"
              type="submit"
              variant="contained"
              style={{ width: 130, color: '#A93533' }}
              onClick={() => setOrderAlf("name")}
            >
              Nome
            </Button>
          </Grid>
          <Grid style={{ marginBottom: 10 }}>
            <Button
              color='white'
              size="large"
              type="submit"
              variant="contained"
              style={{ width: 130, color: '#A93533' }}
              onClick={() => setOrderAlf("oab")}
            >
              OAB
            </Button>
          </Grid>
          <Grid style={{ marginBottom: 10 }}>
            <Button
              color='white'
              size="large"
              type="submit"
              variant="contained"
              style={{ width: 130, color: '#A93533' }}
              onClick={() => setOrderAlf('lockedDate')}
            >
              Data Bloqueio
            </Button>
          </Grid>
          <Grid style={{ marginBottom: 10 }}>
            <Button
              color='white'
              size="large"
              type="submit"
              variant="contained"
              style={{ width: 130, color: '#A93533' }}
              onClick={() => setOrderAlf('unlockedDate')}
            >
              Data Desbloqueio
            </Button>
          </Grid>

        </Grid>
      </Popover>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        keepMounted
      >
        <Container maxWidth='md' spacing={3}
          style={{
            backgroundColor: 'white',
            height: '100%',
            overflow: 'scroll',
            padding: 30,
            margin: '0% 10%',
            borderRadius: 4,
            border: '1px solid #000000',
            top: 100
          }}
        >
          <Grid container spacing={6}
            style={{
              padding: 10,
            }}
          >
            <Grid item md={12} xs={12}>
              <Typography variant="h3" display="block" style={{ marginTop: 10 }}>
                Selecionar Filtros
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container spacing={3}>
                <Grid md={6} xs={12} style={{ paddingRight: 12 }} >
                  <Typography variant="h5" display="block" >
                    Status
                  </Typography>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="checkboxes-tags-demo"
                    disableCloseOnSelect
                    classes={{
                      popper: classes.option
                    }}
                    value={statusPicked}
                    options={statusChoice}
                    getOptionLabel={(option) => getStatus(option)}
                    onChange={(e, value) => {
                      setStatusPicked(value)
                    }}
                    style={{ backgroundColor: 'white' }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid xs={12} >
                  <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                    Bloqueado por
                  </Typography>
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="checkboxes-tags-demo"
                    disableCloseOnSelect
                    classes={{
                      popper: classes.option
                    }}
                    value={block}
                    options={blockedFor}
                    getOptionLabel={(options) => options.nome}
                    onChange={(e, value) => {
                      setBlock(value)
                    }}
                    style={{ backgroundColor: 'white' }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                    )}
                  />
                </Grid>
                <Grid xs={6} style={{ padding: 5 }}>
                  <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                    Datas entre:
                  </Typography>
                  <MuiThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <DatePicker
                        error={Boolean(dateBlockedFinal < dateBlockedInitial)}
                        helperText={(dateBlockedFinal < dateBlockedInitial) ? "Data inicial deve ser menor que a Data final " : ""}
                        fullWidth
                        margin="normal"
                        id="bloqueioInicio"
                        format="dd/MM/yyyy"
                        autoOk
                        value={dateBlockedInitial}
                        onChange={(newValue) => {
                          setDateBLockedInitial(startOfDay(newValue))
                        }}
                        inputVariant={'outlined'}
                        variant='inline'
                        maxDate={new Date()}
                      />
                      {/* aqui */}
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                </Grid>
                <Grid xs={6} style={{ padding: 5 }}>
                  <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                    e:
                  </Typography>
                  <MuiThemeProvider theme={defaultMaterialTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                      <DatePicker
                        error={Boolean(dateBlockedFinal < dateBlockedInitial)}
                        helperText={(dateBlockedFinal < dateBlockedInitial) ? "Data inicial deve ser menor que a Data final " : ""}
                        fullWidth
                        margin="normal"
                        id="bloqueioFim"
                        format="dd/MM/yyyy"
                        autoOk
                        value={dateBlockedFinal}
                        onChange={(newValue) => {
                          setDateBLockedFinal(endOfDay(newValue))
                        }}
                        inputVariant={'outlined'}
                        variant='inline'
                        maxDate={new Date()}
                      />
                    </MuiPickersUtilsProvider>
                  </MuiThemeProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={3}>
                <Grid xs={12} >
                  <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                    Desbloqueado por
                  </Typography>
                  <Autocomplete
                    multiple

                    id="checkboxes-tags-demo"
                    disableCloseOnSelect
                    classes={{
                      popper: classes.option
                    }}
                    options={unBlockedFor}
                    value={unblock}
                    getOptionLabel={(option) => option.nome}
                    onChange={(e, value) => {
                      setUnblock(value)
                    }}
                    style={{ backgroundColor: 'white' }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                    )}
                  />
                </Grid>

              </Grid>
            </Grid>
            <Grid container spacing={3}
              style={{ marginTop: 20, textAlign: "center", placeContent: 'center' }}
            >
              <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                <Button
                  color='primary'
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={filteredByItem}
                  style={{ padding: '15px 30px', width: 150 }}
                >
                  Aplicar
                </Button>
              </Grid>
              <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                <Button
                  color='primary'
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={handleCloseModal}
                  style={{ padding: '15px 30px', width: 150 }}
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Modal>

      <Dialog
        open={modalUnblockVisible}
        onClose={() => {
          setModalUnblockVisible(false)
          setUserToUnlock(undefined)
          setState(0)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: '#fff',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Deseja desbloquear o usuário?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            O usuário {userToUnlock?.user.nome} será desbloqueado.
          </DialogContentText>
        <TextField
          fullWidth
          label="Motivo"
          name="motivo"
          multiline
          inputProps={{classes: { input: classes.textArea }, maxLength: 230,  }}
          onChange={ (event, values) => {
              setReason(event.target.value);
              setUserToUnlock({...userToUnlock, reason})
            }
          }
          helperText={'Minimo de 10 caracteres'}
          value={reason}
          variant="outlined"
        />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {

              setModalUnblockVisible(false)
              setUserToUnlock(undefined)
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if(reason.length <11){
              } else {
                handleSubmitUnblock()
              }
            }}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid >
  )
}