import React, { useState } from 'react';
import { TextField, Button, Typography, makeStyles, Container, Card, CardContent, Grid, Divider, CircularProgress, CardHeader, IconButton } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import InputCpf from '../../../../components/common/inputCpf'
import { setActivateStatus } from '../../../../providers/user'
import {
  ArrowBackRounded as ArrowBackRoundedIcon
} from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
}));

export default function ActivateUser({ user, setState }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const cancel = async () => {
    try {
      setLoading(true)
      await setActivateStatus(user)
      setLoading(false)
    } catch (e) { }
    finally {
      setState(0)
    }
  }

  const acept = async () => {
    try {
      setLoading(true)
      user.activated = 'true'
      await setActivateStatus(user)
      setLoading(false)
    } catch (e) { }
    finally {
      setState(0)
    }
  }

  const resend = async () => {
    try {
      setLoading(true)
      user.activated = 'resend'
      await setActivateStatus(user)
      setLoading(false)
    } catch (e) { }
    finally {
      setState(0)
    }
  }

  return (
    <Container maxWidth="md" disableGutters>

      <Card className={classes.card}>
        <IconButton onClick={() => setState(0)} ><ArrowBackRoundedIcon /></IconButton>
        <CardContent >
          <Grid container spacing={3} alignItems="center" justify="center">
            <Grid item md={12}>
              <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                Informações
                        </Typography>
              <Divider style={{ marginBottom: 10 }} />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                style={{ color: 'black' }}
                fullWidth
                label="CPF"
                name="cpf"
                variant="outlined"
                value={user.cpf}
                InputProps={{
                  inputComponent: InputCpf,
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nome"
                name="nome"
                value={user.nome}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Mãe"
                name="mae"
                value={user.nomeMae}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Pai"
                name="pai"
                value={user.nomePai}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12} >
              <TextField
                fullWidth
                label="Celular"
                name="celular"
                value={user.celular}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Naturalidade"
                name="naturalidade"
                value={user.naturalidade}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Sexo"
                name="sexo"
                value={user.sexo}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Estado Civil"
                name="estadoCivil"
                value={user.estadoCivil}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Data de Nascimento"
                name="nascimento"
                value={user.nascimento}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="OAB"
                name="oab"
                value={user.oab}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                Documento
                        </Typography>
              <Divider style={{ marginBottom: 10 }} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h5" >Parte da frente</Typography>
              <img src={user.docFront} style={{ width: '100%', borderRadius: 3 }} />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h5" >Parte de trás</Typography>
              <img src={user.docBack} style={{ width: '100%', borderRadius: 3 }} />
            </Grid>
            {loading ? <CircularProgress /> : <>{user.activated === 'false' && <Grid item md={4} xs={12} >
              <Button
                fullWidth
                color='secondary'
                size="large"
                type="submit"
                variant="contained"
                onClick={resend}
              >
                Solicitar Reenvio
                        </Button>
            </Grid>}
              <Grid item md={4} xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={cancel}
                >
                  Cancelar Solicitação
                        </Button>
              </Grid>
              <Grid item md={4} xs={12} >
                <Button
                  fullWidth
                  color='primary'
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={acept}
                >
                  Aceitar Solicitação
                        </Button>
              </Grid></>}
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}