import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Divider
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Rating } from '@material-ui/lab';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
  item: {
    color: '#979797',
    fontWeight: 'bold',
    letterSpacing: 1.5
  }
}));

const labels = {
  0.5: '',
  1: '',
  1.5: '',
  2: '',
  2.5: '',
  3: '',
  3.5: '',
  4: '',
  4.5: '',
  5: '',
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{labels[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function DataAvaliationSchedule({ setState, avaliation }) {
  const classes = useStyles()
  const goBack = () => {
    setState(0)
  }
  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.Card}>
        <CardContent>
          <Grid spacing={3}>
            <Grid md={12}>
              <Typography style={{ marginBottom: 30 }} variant={'h1'}>
                Avaliação #
              </Typography>
            </Grid>
            <Grid md={12}>
              <Typography variant={'h5'}>
                Dados do Agendamento
              </Typography>
              <Divider style={{ marginBottom: 20 }} />
            </Grid>
            <Grid md={12} style={{ marginBottom: 30, display: 'flex' }}>
              {/* <div style={{ flex: 1 }}>
                <Typography className={classes.item}>id</Typography>
                {avaliation.agendamento}
              </div> */}
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Serviço</Typography>
                {avaliation.servico.nome}
              </div>
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Procedimento</Typography>
                {avaliation.procedimento.nome}
              </div>
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Profissional</Typography>
                {avaliation.profissional.nome}
              </div>
            </Grid>
            <Grid md={12} style={{ marginBottom: 30, display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Data</Typography>
                {format(new Date(avaliation.data), "dd 'de' MMMM 'de' yyyy", { locale: pt })}
              </div>
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Procedimento</Typography>
                {avaliation.horaInicio}
              </div>
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Local</Typography>
                {avaliation.profissional.unidade.nome}
              </div>
              <div style={{ flex: 1 }}>
                <Typography className={classes.item}>Agendado para</Typography>
                {avaliation.user.nome}
              </div>
            </Grid>

            <Grid md={12}>
              <Typography variant={'h5'}>
                Dados da Avaliação
              </Typography>
              <Divider style={{ marginBottom: 20 }} />
            </Grid>

            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Nota</Typography>
              <div>
                <Rating
                  defaultValue={avaliation.avaliacaoNota}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </div>
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Comentário</Typography>
              {avaliation.avaliacaoComentario != null && avaliation.avaliacaoComentario != '' ? avaliation.avaliacaoComentario : 'NaD.'}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Data</Typography>
              {/* {format(new Date(avaliation.data), 'dd/MM/yyyy')} */}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Hora</Typography>
              {/* {format(new Date(avaliation.data), 'HH:mm')} */}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Usuário</Typography>
              {avaliation.user.nome}
            </Grid>
            <Grid container style={{ marginBottom: 20 }} alignItems='flex-end' justify='flex-end'>
              <Grid item md={3}>
                <Button
                  fullWidth
                  color='primary'
                  size="large"
                  variant="contained"
                  onClick={goBack}
                >
                  Voltar
                        </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}