import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { createCategory, findAllCategorys } from '../../../../providers/partner'
import {
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons'
import { useSelector } from 'react-redux';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  btns: {
    marginTop: 15,
  },
  btns2: {
    marginTop: 15,
    marginLeft: 7
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
}));

export default function NewCategory({ setState }) {
  const classes = useStyles()
  const [categoryList, setCategoryList] = useState([])

  const user = useSelector(({ user }) => user);

  useEffect(async () => {
    const data = await findAllCategorys()
    setCategoryList(data)
  }, [])

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          name: '',
          description: '',
          priority: '',
          imgCard: null,
          imgCapa: null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
          priority: Yup.string()
            .required('Campo obrigatório'),
          imgCard: Yup.mixed().required('Escolha uma imagem'),
          imgCapa: Yup.mixed().required('Escolha uma imagem'),
        })}
        onSubmit={async (values, actions) => {
          try {
            const body = new FormData()
            body.append('file', values.imgCapa)
            body.append('folder', UploadFolder.MISC)
            const dataCapa = await apiUpload(body);
            values.imgCapa = dataCapa.location
            const body2 = new FormData()
            body2.append('file', values.imgCard)
            body2.append('folder', UploadFolder.MISC)
            const dataCard = await apiUpload(body2);
            values.imgCard = dataCard.location
            await createCategory(values)
            await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Parceiros", subMenu: "Parceiros" });
          } catch (e) {
            console.log(e)
          } finally {
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Nome"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.priority && errors.priority)}
                      helperText={touched.priority && errors.priority}
                      label="Prioridade"
                      name="priority"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priority}
                      variant="outlined"
                      type='number'
                      inputProps={{
                        max: 9999,
                        min: 1
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label="Descrição"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns2}>
                    {<Grid item xs={12}>
                      <input
                        accept="image/*"
                        className={classes.inputFile}
                        name='imgCard'
                        id="image-card"
                        type="file"
                        onChange={event => setFieldValue('imgCard', event.target.files[0])}
                      />
                      <label htmlFor="image-card">
                        <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                          IMAGEM DE CARD
                                </Button>
                      </label>
                      {Boolean(touched.imgCard && errors.imgCard) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.imgCard && errors.imgCard}
                      </Typography>}
                    </Grid>}
                    {values.imgCard && <Grid item md={8} xs={12}>
                      <img src={URL.createObjectURL(values.imgCard)} style={{ maxHeight: 630, maxWidth: 630, borderRadius: 4, objectFit: 'contain' }} />
                    </Grid>}
                  </Grid>

                  <Grid container spacing={1} justify='center' className={classes.btns2}>
                    {<Grid item xs={12}>
                      <input
                        accept="image/*"
                        className={classes.inputFile}
                        name='imgCapa'
                        id="image-capa"
                        type="file"
                        onChange={event => setFieldValue('imgCapa', event.target.files[0])}
                      />
                      <label htmlFor="image-capa">
                        <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                          IMAGEM DE CAPA
                                </Button>
                      </label>
                      {Boolean(touched.imgCapa && errors.imgCapa) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.imgCapa && errors.imgCapa}
                      </Typography>}
                    </Grid>}
                    {values.imgCapa && <Grid item md={8} xs={12}>
                      <img src={URL.createObjectURL(values.imgCapa)} style={{ maxHeight: 630, maxWidth: 630, borderRadius: 4, objectFit: 'contain' }} />
                    </Grid>}
                  </Grid>

                </Grid>
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      size="large"
                      color='secondary'
                      variant="contained"
                      onClick={() => setState(0)}
                    >
                      Voltar
                        </Button>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      color='primary'
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Cadastrar Categoria'}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}