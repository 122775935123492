import { Error } from "@material-ui/icons";
import axios from "axios";
import localStorage from "redux-persist/es/storage";

export const getCepInfos = async (cep) => {
  if (!cep) throw new Error();

  const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

  return data;
};
