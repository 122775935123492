export const ADD_USERS = "ADD_USERS";
export const RMV_USERS = "RMV_USERS";

export const addUser = (payload) => ({
  type: ADD_USERS,
  payload: payload
});

export const rmvUser = () => ({
  type: RMV_USERS
})