export const color = {
    blue: '#354F5C',
    red: '#A93533',
    redError: '#FF0000' ,
    black: '#313131',
    white: '#FFFFFF',
    gray: '#979797',
    background: '#F4F6F8',
}

export const blueLight = '#A1B2CC'
