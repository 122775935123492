import React, { useState, useEffect } from 'react';
import { ErrorMessage, Formik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Collapse,
  IconButton,
  FormControlLabel,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Alert, Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { getAllCategory, newServico } from '../../../../providers/schedules';
import * as Yup from 'yup';
import {
  CloudUpload as CloudUploadIcon
} from '@material-ui/icons';
import { alterationLog } from '../../../../providers/log';
import { useSelector } from 'react-redux';
import { UploadFolder, apiUpload } from '../../../../providers/upload';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(1)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    // width: '100%', 
    marginBottom: 20
  },
  option: {
    backgroundColor: 'white'
  },
  inputFile: {
    display: 'none'
  }
}));


export default function NewService(props) {

  const classes = useStyles()
  const [categorias, setCategorias] = useState([])
  const [created, serCreated] = useState(false)

  const user = useSelector(({ user }) => user);

  useEffect(async () => {
    const data = await getAllCategory()
    setCategorias(data)
  }, [])
  return (
    <>
      <Collapse in={created} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={'success'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                serCreated(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Serviço criado com sucesso
        </Alert>
      </Collapse>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" >
          <Box display="flex" my={1} justifyContent="flex-end">
            <Button
              onClick={() => {
                props.setTab(1)
                props.setStateCategory(1)
              }}
              color='primary'
              size="large"
              type="submit"
              variant="contained"
            >
              Cadastrar nova categoria
            </Button>
          </Box>
          <Formik
            initialValues={{
              nome: '',
              idCategoria: '',
              disponivelDependente: false,
              disponivel: false,
              img: null
            }}
            validationSchema={Yup.object().shape({

              nome: Yup.string()
                .required('Campo obrigatório'),
              idCategoria: Yup.string()
                .required('Campo obrigatório'),
              img: Yup.mixed().required('Escolha uma imagem')

            })}
            onSubmit={async (values) => {
              try {
                const body = new FormData()
                body.append('file', values.img)
                body.append('folder', UploadFolder.MISC)
                const data = await apiUpload(body);
                delete values.img
                values.urlImage = data.location
                const result = await newServico(values)
              await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Agendamentos", subMenu: "Serviço", });

                if (result) {
                  console.error("Error Upload Slide Image")
                  props.setState(0)
                  serCreated(true)
                }
              } catch (error) {
                console.log(error);
              }
            }}

          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <Card style={{ backgroundColor: 'white', paddingTop: 30 }}>

                <CardContent >

                  < Grid container spacing={3} justify={'center'} >
                    <Grid item md={8} xs={12}>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="start"
                          name="disponivel"
                          control={<Checkbox
                            checked={values.disponivel}
                            name="disponivel"
                            color="primary"
                            onChange={handleChange}
                          />}
                          label="Disponível?"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="start"
                          name="disponivelDependente"
                          control={<Checkbox
                            checked={values.disponivelDependente}
                            name="disponivelDependente"
                            color="primary"
                            onChange={handleChange}
                          />}
                          label="Disponível para dependentes?"
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={8} xs={12} >
                      <Typography variant={'h4'}>Novo serviço</Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        name="nome"
                        label="Nome do serviço"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Autocomplete
                        fullWidth
                        id="select-local"
                        classes={{
                          popper: classes.option
                        }}
                        color={errors.idCategoria ? 'error' : 'primary'}
                        options={categorias.map((option) => option)}
                        getOptionLabel={option => option.nome}
                        onChange={(e, value) => {
                          setFieldValue('idCategoria', value ? value.id : '')
                        }}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} onBlur={handleBlur} label="Categoria" variant="outlined" />
                        )}
                      />
                      <ErrorMessage name='idCategoria' render={msg => (<Typography style={{ fontSize: 14 }} color={'error'}>{msg}</Typography>)} />
                    </Grid>

                    {<Grid item md={8} xs={12}>
                      <input
                        accept="image/*"
                        className={classes.inputFile}
                        name='img'
                        id="img-input"
                        type="file"
                        onChange={event => setFieldValue('img', event.target.files[0])}
                      />
                      <label htmlFor="img-input">
                        <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                          Upload
                                </Button>
                      </label>
                      {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.img && errors.img}
                      </Typography>}
                    </Grid>}
                    {values.img && <Grid item md={8} xs={12}>
                      <img src={URL.createObjectURL(values.img)} style={{ width: '100%', borderRadius: 4, objectFit: 'contain' }} />
                      {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.img && errors.img}
                      </Typography>}
                    </Grid>}

                    <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>
                      <Button
                        color='primary'
                        onClick={() => props.setState(0)}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Voltar
                    </Button>
                      <Button
                        onClick={() => {
                          handleSubmit()
                        }}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Cadastrar
                      </Button>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}