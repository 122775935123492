import React, { useState } from 'react';

import EditUser from './edit-user';
import ListUser from './list-user';
import AddUser from './new-user';

export default function Users(props) {

  const [state, setState] = useState(0)
  const [userToEdit, setUserToEdit] = useState()

  return (
    <>
      {state === 0 && <ListUser setState={setState} setUserToEdit={setUserToEdit} />}
      {state === 1 && <AddUser setState={setState} />}
      {state === 2 && <EditUser user={userToEdit} setState={setState} />} 
    </>
  )
}