export const ADD_ADM_EDIT = "ADD_ADM_EDIT";
export const RMV_ADM_EDIT = "RMV_ADM_EDIT";

export const addColaboradorEdit = (payload) => ({
  type: ADD_ADM_EDIT,
  payload: payload
});

export const rmvColaboradorEdit = () => ({
  type: RMV_ADM_EDIT
})