import React, { useState } from 'react';

import Payment from './payment';
import GetPayment from './get-payment'
import PaymentSolicitation from './payment-solicitation'
import DetachedPayment from './detached-payment'
import IdentifiedPayment from './identified-payment'
import QrCode from './qr-code'

export default function Payments(props) {

  const [state, setState] = useState(0)
  const [typePayment, setTypePayment] = useState()
  const [dataPayment, setDataPayment] = useState()


  return (
    <>
      {state === 0 && <Payment setState={setState} />}
      {state === 1 && <GetPayment setState={setState} setTypePayment={setTypePayment} />}
      {state === 2 && <PaymentSolicitation setState={setState} />}
      {state === 3 && <DetachedPayment setState={setState} setTypePayment={setTypePayment} setDataPayment={setDataPayment} />}
      {state === 4 && <IdentifiedPayment setState={setState} setTypePayment={setTypePayment} setDataPayment={setDataPayment} />}
      {state === 5 && <QrCode setState={setState} typePayment={typePayment} dataPayment={dataPayment} />}


    </>
  )
}