import React, { useEffect, useState } from 'react';
import { TextField, Button, makeStyles, Container, Card, CardContent, Box, Grid, Typography, Divider, CircularProgress, MenuItem, Avatar, MuiThemeProvider, createMuiTheme, } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import InputCpf from '../../../../components/common/inputCpf';
import InputCel from '../../../../components/common/inputCelular';
import { cpf } from 'cpf-cnpj-validator';
import {
  Tipo,
  Status,
  EstadoCivil,
  Sexo,
  SituacaoOab,
  SituacaoFinanceira,
  Situacao,
} from "../../../../utils/dataUser";
import ReactLoading from "react-loading";
import Backdrop from "@material-ui/core/Backdrop";
import InputCep from "../../../../components/common/inputCep";
import InputOab from "../../../../components/common/inputOab";
import { getCepInfos } from "../../../../utils/getCepInfos";
import { newUser } from "../../../../providers/user";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import pt from "date-fns/locale/pt-BR";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Yup from "yup";
import { AddBox as AddBoxIcon } from "@material-ui/icons";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import RemoveCircleOutlineOutlinedIcon from "@material-ui/icons/RemoveCircleOutlineOutlined";
import { IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { startOfDay } from "date-fns/esm";
import { alterationLog } from '../../../../providers/log';
import { useSelector } from 'react-redux';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: "100%",
    padding: theme.spacing(3),
  },
  card: {
    background: color.white,
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  btns: {
    marginTop: 15,
  },
  textArea: {
    minHeight: 100,
  },
  inputFile: {
    display: "none",
  },
  Avatar: {
    cursor: "pointer",
    width: 200,
    height: 200,
    margin: "8px auto",
    boxShadow: "0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red,
    },
  },
});

export default function NewUser({ setState, validade }) {
  const [open, setOpen] = useState(false);
  const [logradouro, setLogradouro] = useState(true);
  const [date, onChangeDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [docArray, setDocArray] = useState([
    // {
    //   nome: 'doc1',
    //   imagem: {
    //     nome: 'img1'
    //   },
    // },
    // {
    //   nome: 'doc2',
    //   imagem: {
    //     nome: 'img2'
    //   },
    // },
    // {
    //   nome: 'doc3',
    //   imagem: {
    //     nome: 'img3'
    //   },
    // },
    // {
    //   nome: 'doc4',
    //   imagem: {
    //     nome: 'img4'
    //   },
    // },
  ]);

  const userData = useSelector(({ user }) => user);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const classes = useStyles();
  const goBack = () => {
    setState(0);
  };

  const unmaskCpf = (value) => {
    return value.replace(/\./g, "").replace("-", "");
  };

  return (
    <>
      <Container maxWidth="md" disableGutters>
        <Formik
          //enableReinitialize={true}
          initialValues={{
            img: null,
            nome: "",
            nascimento: new Date(),
            estadoCivil: "",
            nomeMae: "",
            status: "AGUARDANDO",
            userType: "TITULAR",
            oab: "",
            cpf: "",
            sexo: "",
            naturalidade: "",
            celular: "",
            email: "",
            cep: "",
            estado: "",
            cidade: "",
            bairro: "",
            endereco: "",
            numero: "",
            complemento: "",
            situacao: "Inativo",
            situacaoOab: "",
            validade: validade.date,
            situacaoFinanceira: "",
            observacoes: "",
            documentos: docArray,
            userImported: true
          }}
          validationSchema={Yup.object().shape({
            img: Yup.mixed()
              .required('Selecione uma imagem de perfil'),
            nome: Yup.string()
              .required('Campo obrigatório'),
            nascimento: Yup.date()
              .required('Campo obrigatório'),
            estadoCivil: Yup.string()
              .required('Campo obrigatório'),
            nomeMae: Yup.string()
              .required('Campo obrigatório'),
            status: Yup.string()
              .required('Campo obrigatório'),
            userType: Yup.string()
              .required('Campo obrigatório'),
            oab: Yup.string()
              .required('Campo obrigatório'),
            cpf: Yup.string()
              .required('Campo obrigatório')
              .test('cpf-valid', 'CPF inválido', value => cpf.isValid(value)),
            sexo: Yup.string()
              .required('Campo obrigatório'),
            naturalidade: Yup.string()
              .required('Campo obrigatório'),
            celular: Yup.string()
              .required('Campo obrigatório'),
            email: Yup.string()
              .required('Campo obrigatório'),
            cep: Yup.string()
              .required('Campo obrigatório'),
            estado: Yup.string()
              .required('Campo obrigatório'),
            cidade: Yup.string()
              .required('Campo obrigatório'),
            endereco: Yup.string()
              .required('Campo obrigatório'),
            numero: Yup.string()
              .required('Campo obrigatório'),
            situacao: Yup.string()
              .required('Campo obrigatório'),
            situacaoOab: Yup.string()
              .required('Campo obrigatório'),
            validade: Yup.date()
              .required('Campo obrigatório'),
            situacaoFinanceira: Yup.string()
              .required('Campo obrigatório'),
          })}
          onSubmit={async (values, actions) => {
            try {
              values.cpf = unmaskCpf(values.cpf);

              
              const body = new FormData()
              body.append('file', values.img)
              body.append('folder', UploadFolder.MISC)
              const data = await apiUpload(body);
              delete values.img;
              values.imgProfile = data.location;

              const arrayUris = [];

              if (values.documentos) {
                for (const doc of values.documentos) {
                  const body = new FormData()
                  body.append('file', doc)
                  body.append('folder', UploadFolder.MISC)
                  const data1 = await apiUpload(body);
                  arrayUris.push(data1.location);
                }
                values.documentos = arrayUris;
              }

              await newUser(values, userData);
              await alterationLog({ action: 'CREATE', idUser: userData.id, before: '', after: {...values, documentos: values.documentos.length}, menu: "Associados", subMenu: "Associados", });

              setState(0);
            } catch (e) {
              console.error("Error Upload News Image")
              console.error(e)
              alert("ERRO! CPF já cadastrado.")
              // setState(0)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h5"
                        display="block"
                        style={{ marginTop: 30 }}
                      >
                        Dados Pessoais
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <input
                            accept="image/*"
                            className={classes.inputFile}
                            name="img"
                            id="img-input"
                            type="file"
                            onChange={(event) =>
                              setFieldValue("img", event.target.files[0])
                            }
                          />
                          <label htmlFor="img-input">
                            <Avatar
                              className={classes.Avatar}
                              src={
                                values.img
                                  ? URL.createObjectURL(values.img)
                                  : ""
                              }
                            />
                          </label>
                        </Grid>
                        <Grid item md={12} xs={12} style={{ marginBottom: -5 }}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            label="Nome"
                            name="nome"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nome.toUpperCase()}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          style={{ padding: "0 12px 5px" }}
                        >
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={pt}
                            >
                              <DatePicker
                                fullWidth
                                margin="normal"
                                id="nascimento"
                                label="Data de Nascimento"
                                format="dd/MM/yyyy"
                                value={selectedDate}
                                onChange={(newValue) => {
                                  setFieldValue('nascimento', startOfDay(newValue));
                                  setSelectedDate(startOfDay(newValue));
                                }}
                                inputVariant={"outlined"}
                                variant="inline"
                                disableFuture
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(
                              touched.estadoCivil && errors.estadoCivil
                            )}
                            helperText={
                              touched.estadoCivil && errors.estadoCivil
                            }
                            label="Estado Civil"
                            name="estadoCivil"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.estadoCivil}
                            variant="outlined"
                            select
                          >
                            {EstadoCivil.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.nomeMae && errors.nomeMae)}
                            helperText={touched.nomeMae && errors.nomeMae}
                            label="Nome da Mãe"
                            name="nomeMae"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nomeMae}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container alignContent={"center"} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.status && errors.status)}
                            helperText={touched.status && errors.status}
                            label="Status"
                            name="status"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.status}
                            variant="outlined"
                            select
                            inputProps={{
                              readOnly: true,
                            }}
                          >
                            {Status.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.userType && errors.userType)}
                            helperText={touched.userType && errors.userType}
                            label="Tipo de Usuário"
                            name="userType"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.userType}
                            variant="outlined"
                            select
                          >
                            {Tipo.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.oab && errors.oab)}
                            helperText={touched.oab && errors.oab}
                            label="OAB"
                            name="oab"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.oab}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputOab,
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.cpf && errors.cpf)}
                            helperText={touched.cpf && errors.cpf}
                            label="CPF"
                            name="cpf"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cpf}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputCpf,
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.sexo && errors.sexo)}
                            helperText={touched.sexo && errors.sexo}
                            label="Sexo"
                            name="sexo"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sexo}
                            variant="outlined"
                            select
                          >
                            {Sexo.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(
                              touched.naturalidade && errors.naturalidade
                            )}
                            helperText={
                              touched.naturalidade && errors.naturalidade
                            }
                            label="Naturalidade"
                            name="naturalidade"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.naturalidade}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h5"
                        display="block"
                        style={{ marginTop: 30 }}
                      >
                        Dados de Contato
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.celular && errors.celular)}
                        helperText={touched.celular && errors.celular}
                        label="Celular"
                        name="celular"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.celular}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCel,
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="E-mail"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h5"
                        display="block"
                        style={{ marginTop: 30 }}
                      >
                        Dados de Endereço
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.cep && errors.cep)}
                        helperText={touched.cep && errors.cep}
                        label="CEP"
                        name="cep"
                        onBlur={handleBlur}
                        onChange={async (event, value) => {
                          setFieldValue("cep", event.target.value);
                          if (event.target.value.length === 9) {
                            setOpen(true);
                            const dados = await getCepInfos(event.target.value);
                            if (dados.erro) {
                              setFieldError("cep", "CEP não encontrado");
                            } else {
                              setFieldValue("estado", dados.uf);
                              setFieldValue("bairro", dados.bairro);
                              setFieldValue("cidade", dados.localidade);
                              if (dados.logradouro) {
                                setFieldValue("endereco", dados.logradouro);
                                setLogradouro(true);
                              } else {
                                setLogradouro(false);
                              }
                            }
                            setOpen(false);
                          }
                        }}
                        value={values.cep}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCep,
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.estado && errors.estado)}
                        helperText={touched.estado && errors.estado}
                        label="Estado"
                        name="estado"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.estado}
                        variant="outlined"
                        // InputProps={{
                        //   readOnly: true,
                        // }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.cidade && errors.cidade)}
                        helperText={touched.cidade && errors.cidade}
                        label="Cidade"
                        name="cidade"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cidade}
                        variant="outlined"
                        // InputProps={{
                        //   readOnly: true,
                        // }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.bairro && errors.bairro)}
                        helperText={touched.bairro && errors.bairro}
                        label="Bairro"
                        name="bairro"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bairro}
                        variant="outlined"
                        // inputProps={{
                        //   readOnly: logradouro
                        // }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.endereco && errors.endereco)}
                        helperText={touched.endereco && errors.endereco}
                        label="Logradouro"
                        name="endereco"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.endereco}
                        variant="outlined"
                        // inputProps={{
                        //   readOnly: logradouro
                        // }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.numero && errors.numero)}
                        helperText={touched.numero && errors.numero}
                        label="Número"
                        type="number"
                        name="numero"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.numero}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          touched.complemento && errors.complemento
                        )}
                        helperText={touched.complemento && errors.complemento}
                        label="Complemento"
                        name="complemento"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.complemento}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h5"
                        display="block"
                        style={{ marginTop: 30 }}
                      >
                        Dados Admnistrativos
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          touched.situacaoOab && errors.situacaoOab
                        )}
                        helperText={touched.situacaoOab && errors.situacaoOab}
                        label="Situação Cadastral OAB"
                        name="situacaoOab"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setFieldValue("situacaoOab", event.target.value);
                          if (
                            event.target.value === "REGULAR" &&
                            values.situacaoFinanceira === "ADIMPLENTE"
                          ) {
                            setFieldValue("situacao", "Ativo");
                          } else {
                            setFieldValue("situacao", "Inativo");
                          }
                        }}
                        value={values.situacaoOab}
                        variant="outlined"
                        select
                        disabled={values.userType !== "TITULAR" ? true : false}
                      >
                        {SituacaoOab.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          touched.situacaoFinanceira &&
                            errors.situacaoFinanceira
                        )}
                        helperText={
                          touched.situacaoFinanceira &&
                          errors.situacaoFinanceira
                        }
                        label="Situação Financeira OAB"
                        name="situacaoFinanceira"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setFieldValue(
                            "situacaoFinanceira",
                            event.target.value
                          );
                          if (
                            event.target.value === "ADIMPLENTE" &&
                            values.situacaoOab === "REGULAR"
                          ) {
                            setFieldValue("situacao", "Ativo");
                          } else {
                            setFieldValue("situacao", "Inativo");
                          }
                        }}
                        value={values.situacaoFinanceira}
                        variant="outlined"
                        select
                        disabled={values.userType !== "TITULAR" ? true : false}
                      >
                        {SituacaoFinanceira.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      style={{
                        marginTop: -15,
                      }}
                    >
                      <MuiThemeProvider theme={defaultMaterialTheme}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={pt}
                        >
                          <DatePicker
                            fullWidth
                            autoOk
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="validade"
                            label="Validade do Cartão"
                            onChange={(newValue) => {
                              onChangeDate(newValue);
                              setFieldValue("validade", newValue);
                            }}
                            value={values.validade}
                            inputVariant={"outlined"}
                            variant="inline"
                            disablePast
                            minDateMessage={null}
                          />
                        </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.situacao && errors.situacao)}
                        helperText={touched.situacao && errors.situacao}
                        label="Situação"
                        name="situacao"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.situacao}
                        variant="outlined"
                      >
                        {Situacao.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Typography
                        variant="h5"
                        display="block"
                        style={{ marginTop: 30 }}
                      >
                        Informações Adicionais
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(
                          touched.observacoes && errors.observacoes
                        )}
                        helperText={touched.observacoes && errors.observacoes}
                        label="Observações"
                        name="observacoes"
                        value={values.observacoes}
                        multiline
                        InputProps={{ classes: { input: classes.textArea } }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FieldArray
                        name="documentos"
                        render={(arrayHelpers) => (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: 30,
                              }}
                            >
                              <input
                                accept="image/*"
                                className={classes.inputFile}
                                name="docimg"
                                id="doc-img-input"
                                type="file"
                                onChange={(event) => {
                                  const temp = docArray;
                                  temp.push(event.target.files[0]);
                                  setDocArray(temp);

                                  arrayHelpers.push(event.target.files[0]);

                                  setFieldValue("documentos", docArray);
                                }}
                              />
                              <Typography variant="h5" display="block">
                                Documentos
                              </Typography>
                              <label htmlFor="doc-img-input">
                                <AddBoxIcon />
                              </label>
                            </div>
                            <Divider style={{ marginBottom: 10 }} />

                            <Grid container xs={12} spacing={1}>
                              {values.documentos.map((doc, index) => (
                                <Grid item md={4} xs={12}>
                                  <OutlinedInput
                                    fullWidth
                                    name="doc"
                                    value={doc.name}
                                    disabled
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                    style={{
                                      backgroundColor: "white",
                                      fontWeight: "bold",
                                    }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            window.open(
                                              URL.createObjectURL(doc),
                                              "_blank"
                                            );
                                          }}
                                          edge="end"
                                        >
                                          <VisibilityOutlinedIcon />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            const temp = docArray;
                                            temp.splice(index, 1);
                                            setDocArray(temp);
                                            arrayHelpers.remove(index);
                                            setFieldValue(
                                              "documentos",
                                              docArray
                                            );
                                          }}
                                          edge="end"
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>

                    {/* <Grid item xs={12}>
                      <Grid container xs={12} direction='row' justify='flex-start' alignItems='center'>
                        <DocComponent docName={'doc1'} />
                      </Grid>
                    </Grid> */}

                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      className={classes.btns}
                    >
                      <Grid item md={4} xs={12}>
                        <Button
                          fullWidth
                          color="primary"
                          disabled={isSubmitting}
                          size="large"
                          variant="contained"
                          onClick={goBack}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Button
                          fullWidth
                          color="primary"
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {isSubmitting ? (
                            <CircularProgress
                              color="inherit"
                              circle={{ color: "#fff" }}
                              size={25}
                            />
                          ) : (
                            "Salvar"
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>

      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={"spinningBubbles"} color={color.red} />
      </Backdrop>
    </>
  );
}
