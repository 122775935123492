import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Typography,
  Grid,
  TextField,
  Popover,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Rating } from '@material-ui/lab';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { format } from 'date-fns';
import {
  findAvaliated
} from '../../../../providers/schedules';
import { LocalPrintshop } from '@material-ui/icons';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getPermissions } from '../../../../providers/permissions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  celTableHeaderActions: {
    width: 96,
    backgroundColor: color.white,
  },
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
  ellipseName: {
    width: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

const labels = {
  0.5: '',
  1: '',
  1.5: '',
  2: '',
  2.5: '',
  3: '',
  3.5: '',
  4: '',
  4.5: '',
  5: '',
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{labels[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function AvaliationSchedule({ setState, SetAvaliationToCheck }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleAvaliations);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const data = await findAvaliated()
    if (orderAlf === 0) {
      data.sort((a, b) => {
        return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
      });
    }
    setList(data)
    setUsersToShow(data)
  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(list.filter(p => p.user.nome.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(list)
      setPage(0)
    }
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.user.nome > b.user.nome) ? 1 : ((b.user.nome > a.user.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.user.nome > b.user.nome) ? -1 : ((b.user.nome > a.user.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OrderByNota = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.avaliacaoNota > b.avaliacaoNota) ? 1 : ((b.avaliacaoNota > a.avaliacaoNota) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.avaliacaoNota > b.avaliacaoNota) ? -1 : ((b.avaliacaoNota > a.avaliacaoNota) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheck = (avaliation) => {
    // if (newPermission.edit) {
      SetAvaliationToCheck(avaliation)
      setState(1)
    // }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Usuário",
      "Nota",
      "Comentário",
      "Data",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.user.nome,
          `${<Rating
            defaultValue={row.avaliacaoNota}
            precision={0.5}
            readOnly
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
          />}`,
          row.avaliacaoComentario,
          row.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4'),
          format(new Date(row.data), 'dd/MM/yyyy')
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={4} xs={12} ></Grid>
          </Grid>
        </Box>

        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table}>
                <TableHead className={classes.headerTb}>
                  <TableRow>
                    <TableCell key={'usuario'} className={classes.celTableHeader}>
                      {"Usuário"}
                    </TableCell>
                    <TableCell key={'nota'} align='center' className={classes.celTableHeader}>
                      {"Nota"}
                    </TableCell>
                    <TableCell key={'comentario'} className={classes.celTableHeader}>
                      {"Comentário"}
                    </TableCell>
                    <TableCell key={'data'} className={classes.celTableHeader}>
                      {"Data"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          onClick={() => handleCheck(row)}
                          key={row.user.id + new Date(row.createdAt)}
                        >
                          <TableCell style={{ width: 100 }}>
                            <p className={classes.ellipseName}>
                              {row.user.nome}
                            </p>
                          </TableCell>
                          <TableCell align='center' borderColor="transparent">
                            <div>
                              <Rating
                                defaultValue={row.avaliacaoNota}
                                precision={0.5}
                                readOnly
                                emptyIcon={<StarBorderIcon fontSize="inherit" />}
                              />
                            </div>
                          </TableCell>
                          <TableCell style={{ width: 200 }} >
                            {row.avaliacaoComentario != null && row.avaliacaoComentario != '' ? 'Sim' : 'Não'}
                          </TableCell>
                          <TableCell >{format(new Date(row.data), 'dd/MM/yyyy')}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: 'center'
                }} >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => createPDF()}
                  startIcon={<LocalPrintshop />}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={'Linhas por página'}
                  component="div"
                  count={userToShow.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider >
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByNota()}
              >
                Nota
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}