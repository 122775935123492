import api from './api'

//CRUD - SCHEDULE
export const newSchedule = async (body) => {
  const { data } = await api.post('schedule/user/', body)
  return data
}

export const getAllSchedules = async (params) => {
  const { data } = await api.get('adm/schedule', {
    params,
  })
  return data
}

export const findSchedulesBasedOnProperties = async (body) => {
  const { data } = await api.post('adm/schedule-based-on-properties', body)
  return data
}

export const getAllSchedulesPagination = async (body) => {
  const { data } = await api.post('adm/schedule', body)
  return data
}

export async function updateScheduleEdit(schedule, userData) {
  const { data } = await api.post(`adm/schedule/${schedule.id}`, { ...schedule, userData })
  return data
}
export async function rejectSchedule(id, message, userData) {
  const { data } = await api.post(`adm/schedule/reject/${id}`, { message, userData })
  return data
}
export async function finishSchedule(id, beenPresent) {
  const { data } = await api.post(`adm/schedule/finish/${id}`, beenPresent)
  return data
}
export async function confirmSchedule(id, userData) {
  const { data } = await api.post(`adm/schedule/confirm/${id}`, {userData})
  return data
}

export async function updateSchedule(schedule) {
  const { data } = await api.post(`schedule/user/${schedule.id}`, { ...schedule })
  return data
}
export async function deleteSchedule(id, message) {
  const { data } = await api.post(`schedule/delete/user/${id}`, { message })
  return data
}


// export async function deleteSchedule(id) {
//   const { data } = await api.delete(`schedule/user/${id}`)
//   return data
// }
export async function getSchedule(id) {
  const { data } = await api.get(`schedule/user/${id}`)
  return data
}

export const findAvaliated = async () => {
  const { data } = await api.get(`/schedule/schedule-avaliated`)
  return data
}

//CRUD -CATEGORIA
export async function newCategory(body) {
  const { data } = await api.post('categoria-servico', body)
  return data
}
export async function getAllCategory() {
  const { data } = await api.get('categoria-servico')
  return data
}
export async function updateCategory(categoria) {
  const { data } = await api.post(`categoria-servico/${categoria.id}`, { ...categoria })
  return data
}
export async function deleteCategory(id) {
  const { data } = await api.delete(`categoria-servico/${id}`)
  return data
}
export async function getCategory(id) {
  const { data } = await api.get(`categoria-servico/${id}`)
  return data
}

//CRUD -UNIDADE
export async function newUnidade(body) {
  const { data } = await api.post('unidade', body)
  return data
}
export async function getAllUnidades() {
  const { data } = await api.get('unidade')
  return data
}

export async function getCepInformation(cep) {
  const { data } = await api.get('unidade/cep/' + cep)
  return data
}

export async function getAllUnidadesToSchedule() {
  const { data } = await api.get('unidade-to-schedule')
  return data
}

export async function updateUnidade(unidade) {
  const { data } = await api.post(`unidade/${unidade.id}`, { ...unidade })
  return data
}
export async function deleteUnidade(id) {
  const { data } = await api.delete(`unidade/${id}`)
  return data
}
export async function getUnidade(id) {
  const { data } = await api.get(`unidade/${id}`)
  return data
}

//CRUD -SERVICO
export async function newServico(body) {
  const { data } = await api.post('servico', body)
  return data
}
export async function getAllServicos() {
  const { data } = await api.get('servico')
  return data
}
export async function updateServico(servico) {
  const { data } = await api.post(`servico/${servico.id}`, { ...servico })
  return data
}
export async function deleteServico(id) {
  const { data } = await api.delete(`servico/${id}`)
  return data
}
export async function getServicoByCategory(id) {
  const { data } = await api.get(`servico/category/${id}`)
  return data
}


//CRUD -PROCEDIMENTO
export async function newProcedimento(body) {
  const { data } = await api.post('procedimento', body)
  return data
}
export async function getAllProcedimentos() {
  const { data } = await api.get('procedimento',)
  return data
}
export async function updateProcedimento(procedimento) {
  const { data } = await api.post(`procedimento/${procedimento.id}`, { ...procedimento })
  return data
}
export async function deleteProcedimento(id) {
  const { data } = await api.delete(`procedimento/${id}`)
  return data
}
export async function getProcedimentoByServico(id) {
  const { data } = await api.get(`procedimento/servico/${id}`)
  return data
}

//CRUD - PROFISSIONAL
export async function newProfissional(profissional) {
  const { data } = await api.post('profissional', { profissional })
  return data
}
export async function getAllProfissional() {
  const { data } = await api.get('profissional')
  return data
}
export async function updateProfissional(profissional) {
  const { data } = await api.post(`profissional/${profissional.id}`, { ...profissional })
  return data
}
export async function deleteProfissional(id) {
  const { data } = await api.delete(`profissional/${id}`)
  return data
}
export async function getProfissionalByServico(id) {
  const { data } = await api.get(`profissional/servico/${id}`)
  return data
}
export async function getProfissionalByUnidade(id) {
  const { data } = await api.get(`profissional/unidade/${id}`)
  return data
}

// INDISPONIBILIDADES

export async function getAllIndisponible() {
  const { data } = await api.get('indisponible')
  return data
}

export async function newIndisponible(indisponible) {
  const { data } = await api.post('indisponible', { indisponible })
  return data
}

export async function updateIndisponible(indisponible) {
  const { data } = await api.post(`indisponible/${indisponible.id}`, { ...indisponible })
  return data
}

export async function deleteIndisponible(id) {
  const { data } = await api.delete(`indisponible/${id}`)
  return data
}

// AGENDA EXTRA OU HORAS EXTRAS

export async function getAllExtraHour() {
  const { data } = await api.get('extra-hour')
  return data
}

export async function newExtraHour(extra) {
  const { data } = await api.post('extra-hour', { extraHour: extra })
  return data
}

export async function updateExtraHour(extra) {
  const { data } = await api.post(`extra-hour/${extra.id}`, { extraHour: extra })
  return data
}

export async function deleteExtraHour(id) {
  const { data } = await api.delete(`extra-hour/${id}`)
  return data
}

export async function checkExtraUsed(professionals){
  const { data } = await api.post(`extra-hour-used`, professionals)
  return data
}

// Historico de agendamento em horas extras

export async function getHistoricUser(idUser, filter) {
  const { data } = await api.post(`/schedule/historic/${idUser}`, filter);
  return data
}

export async function getHistoricFilterUser(idUser) {
  const { data } = await api.get(`/schedule/historic/${idUser}`);
  return data
}
