import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Icon,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {
  FiberNew as FiberNewIcon,
  Event as EventIcon,
  PeopleAlt as PeopleAltIcon,
  PeopleAltOutlined as PeopleAltOutlinedIcon,
  AttachMoney,
  Business,
  Email,
  SyncAlt,
} from "@material-ui/icons";
import NavItem from "./NavItem";
import { color } from "../../../common/colors";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useDispatch } from "react-redux";
import { rmvUser, addUser } from "../../../../redux/actions/userAction";
import { getById } from "../../../../providers/userAdm";
import { disconnectSocket } from "../../../../sockets/Socket";

const items = [
  {
    href: "/app/news",
    icon: FiberNewIcon,
    title: "Notícias",
  },
  {
    href: "/app/schedules",
    icon: EventIcon,
    title: "Agendamentos",
  },
  {
    href: "/app/users",
    icon: PeopleAltIcon,
    title: "Associados",
  },
  {
    href: "/app/colaboradores",
    icon: PeopleAltOutlinedIcon,
    title: "Colaboradores",
  },
  {
    href: "/app/caassh",
    icon: AttachMoneyIcon,
    title: "Caassh",
  },
  {
    href: "/app/parceiros",
    icon: Business,
    title: "Parceiros",
  },
  {
    href: "/app/mensagens",
    icon: Email,
    title: "Mensagens",
  },
  {
    href: "/app/logs",
    icon: Email,
    title: "Log de Alterações",
  },
];

const items2 = [
  {
    href: "/app/users-partners",
    icon: SyncAlt,
    title: "Pagamentos",
  },
];

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  button: {
    color: theme.palette.black,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "flex-start",
    letterSpacing: 0,
    padding: "10px 8px",
    textTransform: "none",
    width: "100%",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const checkPermisson = (item) => {
    switch (item.title) {
      case "Notícias":
        return user.permissions.some((i) => i === "news");
      case "Agendamentos":
        return user.permissions.some((i) => i === "schedule");
      case "Associados":
        return user.permissions.some((i) => i === "user");
      case "Colaboradores":
        return user.permissions.some((i) => i === "auth");
      case "Caassh":
        return user.permissions.some((i) => i === "credit");
      case "Parceiros":
        return user.permissions.some((i) => i === "partner");
      case "Mensagens":
        return user.permissions.some((i) => i === "message");
      case "Log de Alterações":
        return user.permissions.some((i) => i === "log");
      default:
        return false;
    }
  };

  const content = (
    <Box
      style={{
        background: color.white,
      }}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {user.type !== "PARTNER" ? (
        <Box p={2}>
          <List>
            {items.map((item) => {
              if (!checkPermisson(item)) return;
              return (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              );
            })}
          </List>
        </Box>
      ) : (
        <Box p={2}>
          <List>
            {items2.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </List>
        </Box>
      )}

      <Box p={2}>
        <Button
          className={classes.button}
          onClick={() => {
            dispatch(rmvUser());
            localStorage.clear();
            sessionStorage.clear();
          }}
        >
          <ExitToAppIcon className={classes.icon} size="20" />
          <Typography className={classes.title}>Sair</Typography>
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false,
};

export default NavBar;
