import React from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { createTag } from '../../../../providers/partner';
import { Formik } from 'formik';
import { alterationLog } from '../../../../providers/log';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  btns: {
    marginTop: 15,
  },
  btns2: {
    marginTop: 15,
    marginLeft: 7
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
}));

export default function NewTag({ setState }) {
  const classes = useStyles()
  const user = useSelector(({ user }) => user);

  return (
    <Grid item md={12} xs={12}>
      <Formik
        initialValues={{
          name: '',
          description: '',
          priority: '',
          imgCard: null,
          imgCapa: null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Campo obrigatório'),
        })}
        onSubmit={async (values, actions) => {
          try {
            await createTag(values)
            await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values,  menu: "Parceiros", subMenu: "Parceiros" });
          } catch (e) {
            console.log(e)
          } finally {
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      label="ID"
                      name="id"
                      disabled
                      onBlur={handleBlur}
                      value={'O ID será gerado automaticamente...'}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Nome"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      size="large"
                      color='secondary'
                      variant="contained"
                      onClick={() => setState(0)}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      color='primary'
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Cadastrar Tag'}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Grid>
  )
}