import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  makeStyles,
  Grid,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { color } from '../../common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getConfig, updateConfig } from '../../../providers/configSlides'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../providers/permissions';
import { alterationLog } from '../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  card: {
    background: color.white,
    marginBottom: 60
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  }
}));

export default function Config() {
  const classes = useStyles()
  const [config, setConfig] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [newPermission, setNewPermission] = useState({ edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.newsConfig);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const [data] = await getConfig()
    setConfig(data)
  }, [])

  return (
    (newPermission.edit ? (
      <Grid container spacing={3}>
        <Grid item md={6} xs={12} >
          <Card className={classes.card}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  qntSlides: config.qntSlides || 0,
                  qntNews: config.qntNews || 0,
                  timeSlides: config.timeSlides || 0
                }}
                validationSchema={Yup.object().shape({
                  qntSlides: Yup.number()
                    .required('Campo obrigatório'),
                  qntNews: Yup.number()
                    .required('Campo obrigatório'),
                  timeSlides: Yup.number()
                    .required('Campo obrigatório'),
                })}
                onSubmit={async (values, actions) => {
                  try {
                    values.id = config.id
                    await updateConfig(values)
                    await alterationLog({ 
                      action: 'UPDATE', 
                      idUser: userData.id, 
                      before: {
                        qntSlides: config.qntSlides,
                        qntNews: config.qntNews,
                        timeSlides: config.timeSlides,
                      }, 
                      after: {
                        qntSlides: values.qntSlides,
                        qntNews: values.qntNews,
                        timeSlides: values.timeSlides,
                      }, 
                      menu: "Notícias", 
                      subMenu: "Config" 
                    });

                    setIsEdit(false)
                  } catch (e) {
                    console.log(e)
                  }
                }}>
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>

                    <Grid container spacing={3} direction='column'>
                      <Grid item md={6} xs={12} >
                        <TextField
                          fullWidth
                          type='number'
                          error={Boolean(touched.qntSlides && errors.qntSlides)}
                          helperText={errors.qntSlides || 'Quantidade a ser exibida'}
                          label="Slides"
                          name="qntSlides"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.qntSlides}
                          variant="outlined"
                          disabled={!isEdit}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} >
                        <TextField
                          fullWidth
                          type='number'
                          error={Boolean(touched.qntNews && errors.qntNews)}
                          helperText={errors.qntNews || 'Quantidade a ser exibida'}
                          label="Notícias"
                          name="qntNews"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.qntNews}
                          variant="outlined"
                          disabled={!isEdit}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} >
                        <TextField
                          fullWidth
                          type='number'
                          error={Boolean(touched.timeSlides && errors.timeSlides)}
                          helperText={errors.timeSlides || 'Em segundos'}
                          label="Intervalo"
                          name="timeSlides"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.timeSlides}
                          variant="outlined"
                          disabled={!isEdit}
                        />
                      </Grid>
                      <Grid item xs={12} >
                        {isEdit &&
                          <Button
                            fullWidth
                            color='primary'
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}
                          </Button>
                        }
                        {!isEdit &&
                          <Button
                            fullWidth
                            color='primary'
                            size="large"
                            variant="contained"
                            onClick={() => setIsEdit(true)}
                          >
                            Editar informações
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    ) : (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}>
        Você não tem permissão para acessar essa página
      </div>
    ))
  )
}