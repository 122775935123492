import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Container,
  Card,
  Grid,
  CardContent,
  TextField,
  Button,
  CircularProgress
} from '@material-ui/core';
import { color } from '../../../components/common/colors';
import { Formik } from 'formik';
import * as Yup from 'yup';
import inputConversion from '../../../components/common/inputConversion';
import { getConfig, updateConfig } from '../../../providers/configConversion';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  currencyField: {
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));



export default function ConversaoOab(props) {

  const classes = useStyles()
  const [config, setConfig] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.caashConfig);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const [data] = await getConfig()
    setConfig(data)
  }, [])



  return (
    newPermission.viz ? (
      <Container maxWidth="md" disableGutters>
        <Grid item md={6} xs={12} >
          <Card className={classes.card}>
            <CardContent>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  conversao: config.conversao
                }}
                validationSchema={Yup.object().shape({
                  conversao: Yup.string()
                    .required('Campo obrigatório'),
                })}
                onSubmit={async (values, actions) => {
                  try {
                    values.id = config.id
                    values.conversao = parseFloat(values.conversao.replace(',', '.'))
                    await updateConfig(values)
                    setIsEdit(false)
                  } catch (e) {
                    console.log(e)
                  }
                }}>
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3} direction='column'>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.conversao && errors.conversao)}
                          helperText={touched.conversao && errors.conversao}
                          label="Conversão"
                          name="conversao"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.conversao}
                          variant="outlined"
                          InputProps={{
                            inputComponent: inputConversion,
                          }}
                          disabled={!isEdit}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>

                      {newPermission.edit && (
                        <Grid item xs={12} >
                          {isEdit &&
                            <Button
                              fullWidth
                              color='primary'
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}
                            </Button>
                          }
                          {!isEdit &&
                            <Button
                              fullWidth
                              color='primary'
                              size="large"
                              variant="contained"
                              onClick={() => setIsEdit(true)}
                            >
                              Editar informações
                          </Button>
                          }
                        </Grid>
                      )}

                    </Grid>
                  </form>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>


      </Container>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}