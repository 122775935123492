import DateFnsUtils from '@date-io/date-fns';
import {
  Box, Button,
  CircularProgress,
  Collapse,
  Container, createMuiTheme, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Grid, IconButton, makeStyles,
  Modal, MuiThemeProvider, Paper,
  Popover, Table,
  TableBody, TableCell,
  TableContainer,
  TableFooter, TableHead,
  TablePagination, TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Delete,
  Edit as EditIcon,
  Check as CheckIcon,
  LocalPrintshop,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { add, differenceInMinutes, endOfDay, format, getTime, startOfDay } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { color } from '../../../../components/common/colors';
import { getPermissions } from '../../../../providers/permissions';
import {
  confirmSchedule,
  deleteSchedule,
  finishSchedule,
  getAllProcedimentos,
  getAllProfissional,
  getAllSchedulesPagination,
  getAllServicos,
  getAllUnidades,
  rejectSchedule
} from '../../../../providers/schedules';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
  },
  tableContainer: {
    backgroundColor: color.white,
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  listText: {
    fontSize: 14
  },
  option: {
    backgroundColor: 'white'
  },
  paper: {
    marginTop: 10
  },
  diolog: {
    background: color.white,
  },
  dialogRoot: {
    background: 'rgba(0.0.0, 0.3)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '90%'
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

const Status = [
  { label: 'Aguardando', value: 'await' },
  { label: 'Confirmado', value: 'confirmed' },
  { label: 'Não Comp.', value: 'not_appear' },
  { label: 'Finalizado', value: 'finished' },
  { label: 'Cancelado', value: 'reject' },
]

export default function ListSchedule(props) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [list, setList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')
  const [disabled, setDisabled] = useState(false)
  const [openDialogFinish, setOpenDialogFinish] = useState(false);
  const [openDialogReject, setOpenDialogReject] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [textRejectReason, setTextRejectReason] = useState('')
  const [textDeleteReason, setTextDeleteReason] = useState('')
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [allServico, setAllServico] = useState([])
  const [allProc, setAllProc] = useState([])
  const [allProfi, setAllProfi] = useState([])
  const [allUnity, setAllUnity] = useState([])
  const [dataInit, setDataInit] = useState(new Date(2022, 1, 11));
  const [dataFim, setDataFim] = useState(add(new Date(), { days: 30 }))
  //const [dataFim, setDataFim] = useState(new Date());
  const [status, setStatus] = useState([])
  const [servico, setServico] = useState([])
  const [procedimento, setProcedimento] = useState([])
  const [profissional, setProfissional] = useState([])
  const [unidade, setUnidade] = useState([])
  const [loading, setLoading] = useState(true);

  const [newPermission, setNewPermission] = useState({ viz: true, det: true, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.schedule);
    };

    fetchPermissions();
  }, []);

  const handlePrint = async () => {
    try {
      const maxLength = 500;

      setLoading(true);
      const data = await getAllSchedulesPagination({
        'texto': search,
        'filtros': {
          'status': status.map(u => u.value),
          'servico': servico.map(u => u.id),
          'procedimento': procedimento.map(u => u.id),
          'profissional': profissional.map(u => u.id),
          'unidade': unidade.map(u => u.id),
          'agendamento': {
            'de': format(new Date(dataInit), 'yyyy-MM-dd'),
            'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
          },
        },
        'timeOffset': new Date().getTimezoneOffset(),
      });

      let shouldOnlySave = false;

      if (data.length > maxLength) {
        shouldOnlySave = true;
      }

      createPDF(data, shouldOnlySave);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

  }

  const createPDF = (dataArray, shouldOnlySave) => {
    const doc = new jsPDF();

    const head = [
      '',
      'Nome do Usuário',
      'Status',
      'Serviço',
      'Horário de Inícioo',
      'Data',
      'Profissional/Recurso',
      'Local'
    ];
    const body = [];

    dataArray.forEach((row, index) => {
      body.push([
        index + 1,
        row.user.nome,
        statusName(row.status),
        row.servico.nome,
        row.horaInicio,
        format(new Date(row.data + 'T04:00:00'), "dd/MM/yyyy"),
        row.profissional.nome,
        row.profissional.unidade.nome,
      ])
    });

    doc.autoTable({
      theme: 'striped',
      headStyles: { fillColor: '#A93533', textColor: '#FFFFFF', halign: 'center' },
      head: [head],
      body,
    })

    if (shouldOnlySave) {
      doc.save(`${format(new Date(), 'dd-MM-yyyy_hh-mm-ss')}`)
    } else {
      doc.autoPrint();
      doc.output('dataurlnewwindow');
    }
  }

  const handleClickOpenDialogFinish = (id) => {
    setOpenDialogFinish(id);
  };

  const handleCloseDialogiFinish = () => {
    setOpenDialogFinish(false);
  }
  const handleClickOpenDiologReject = (id) => {
    setOpenDialogReject(id);
  };

  const handleCloseDialogReject = () => {
    setOpenDialogReject(false);
  }
  const handleChangeTextRejectReason = (event) => {
    setTextRejectReason(event.target.value)
  }

  const handleClickOpenDialogDelete = (id) => {
    setOpenDialogDelete(id);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  }

  const handleChangeTextDeleteReason = (event) => {
    setTextDeleteReason(event.target.value)
  }

  function dialogAction(row) {
    return (
      <>
        <Dialog open={openDialogFinish === row.id} onClose={handleCloseDialogiFinish} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ fontSize: 28 }} className={classes.diolog} id="form-dialog-title">Confirmar presença do usuário</DialogTitle>
          <DialogContent className={classes.diolog} >
            <DialogContentText>
              O usuário compareceu no agendamento?
            </DialogContentText>

          </DialogContent>
          <DialogActions className={classes.diolog} >
            <Button onClick={() => handleNotAppear(row.id, false)} color="primary">
              Não
            </Button>
            <Button onClick={() => handleAppear(row.id, true)} color="primary">
              Sim
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialogReject === row.id} onClose={handleCloseDialogReject} aria-labelledby="form-dialog-title">
          <DialogTitle disableTypography style={{ fontWeight: 'bold' }} className={classes.diolog} id="form-dialog-title">Cancelar agendamento</DialogTitle>
          <DialogContent className={classes.diolog} >
            <DialogContentText>
              Escreva o motivo do cancelamento.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="textRejectReason"
              value={textRejectReason}
              onChange={handleChangeTextRejectReason}
              label="Mensagem para o usuário"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions className={classes.diolog} >
            <Button onClick={handleCloseDialogReject} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleReject(row.id)} color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openDialogDelete === row.id} onClose={handleCloseDialogDelete} aria-labelledby="form-dialog-title">
          <DialogTitle disableTypography style={{ fontWeight: 'bold' }} className={classes.diolog} id="form-dialog-title">Cancelar agendamento</DialogTitle>
          <DialogContent className={classes.diolog} >
            <DialogContentText>
              Escreva o motivo do cancelamento.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="textDeleteReason"
              value={textDeleteReason}
              onChange={handleChangeTextDeleteReason}
              label="Mensagem para o usuário"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions className={classes.diolog} >
            <Button onClick={handleCloseDialogDelete} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => handleDelete(row.id)}
              color="primary">
              Enviar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
  function switchActions(row) {

    const isDisabled = () => {
      let horaFim = row.horaFim.split(':')[0] <= 9 ? '0' + row.horaFim : row.horaFim

      let diff = differenceInMinutes(new Date(row.data + 'T' + horaFim), new Date())
      return diff > 0
    }

    const handleDelete = async id => {
      await deleteSchedule(id)
      setList(list.filter(item => item.id != id))
      setUsersToShow(list.filter(item => item.id !== id))
    }

    switch (row.status) {
      case 'confirmed':
        return (
          <>
            <IconButton size={'small'} onClick={() => handleClickOpenDialogFinish(row.id)}>
              <CheckIcon />
            </IconButton>
            {newPermission.det && (
              <IconButton size={'small'} onClick={() => handleEdit(row)} disabled={disabled}>
                <EditIcon />
              </IconButton>
            )}
            {/* {newPermission.edit && (
                <IconButton size={'small'} onClick={() => handleClickOpenDialogDelete(row.id)}>
                  <Delete />
                </IconButton>
              )} */}
            {dialogAction(row)}
          </>
        )
      case 'reject':
        return (<>
          {newPermission.det && (
            <IconButton size={'small'} onClick={() => handleEdit(row)} disabled={disabled}>
              <EditIcon />
            </IconButton>
          )}
          {/* {newPermission.edit && (
              <IconButton size={'small'} onClick={() => handleDelete(row.id)}>
                <Delete />
              </IconButton>
            )} */}
        </>)
      case 'await':
        return (
          <>
            <IconButton size={'small'} onClick={() => handleAccept(row.id)} disabled={disabled}><CheckIcon /></IconButton>
            {newPermission.det && (
              <IconButton size={'small'} onClick={() => handleEdit(row)} disabled={disabled}><EditIcon /></IconButton>
            )}
            {/* {newPermission.edit && (
                <IconButton size={'small'} onClick={() => handleClickOpenDialogDelete(row.id)}> <Delete /> </IconButton>
              )} */}
            {dialogAction(row)}
          </>
        )
      case 'not_appear':
        return (<>
          {newPermission.det && (
            <IconButton size={'small'} onClick={() => handleEdit(row)} disabled={disabled}>
              <EditIcon />
            </IconButton>
          )}
          {/* {newPermission.edit && (
              <IconButton size={'small'} onClick={() => handleDelete(row.id)}>
                <Delete />
              </IconButton>
            )} */}
        </>)

      case 'finished':
        return (<>
          {newPermission.det && (
            <IconButton size={'small'} onClick={() => handleEdit(row)} disabled={disabled}>
              <EditIcon />
            </IconButton>
          )}
          {/* {newPermission.edit && (
            <IconButton size={'small'} onClick={() => handleDelete(row.id)}>
              <Delete />
            </IconButton>
          )} */}
        </>)
      default:
        break;
    }
  }

  function statusName(status) {
    switch (status) {
      case 'confirmed':
        return 'Confirmado'
      case 'await':
        return 'Aguardando'
      case 'not_appear':
        return 'Não Comp.'
      case 'finished':
        return 'Finalizado'
      case 'reject':
        return 'Cancelado'
      default:
        break;
    }
  }

  useEffect(async () => {
    let data = await getAllSchedulesPagination({
      'filtros': {
        'agendamento': {
          'de': format(new Date(dataInit), 'yyyy-MM-dd'),
          'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
        },
      },
      'pagina': page,
      'entradasPorPagina': rowsPerPage
    })
    const servico = await getAllServicos()
    const procedimento = await getAllProcedimentos()
    const profissional = await getAllProfissional()
    const unity = await getAllUnidades()
    const listWithFilter = data.map((user) => {
      const filtro = `${user.user.nome};${user.user.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')};${user.user.oab}`
      return { ...user, filter: filtro }
    })
    // listWithFilter.sort((a, b) => {
    //   return (a.servico.nome > b.servico.nome) ? 1 : ((b.servico.nome > a.servico.nome) ? -1 : 0);
    // });
    setAllServico(servico)
    setAllProc(procedimento)
    setAllProfi(profissional)
    setAllUnity(unity)
    setList(listWithFilter)
    setUsersToShow(listWithFilter)
    setLoading(false);
  }, [])



  const handleFilter = async () => {
    setLoading(true);
    const data = await getAllSchedulesPagination({
      'texto': search,
      'filtros': {
        'status': status.map(u => u.value),
        'servico': servico.map(u => u.id),
        'procedimento': procedimento.map(u => u.id),
        'profissional': profissional.map(u => u.id),
        'unidade': unidade.map(u => u.id),
        'agendamento': {
          'de': format(new Date(dataInit), 'yyyy-MM-dd'),
          'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
        },
      },
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': 0,
      'entradasPorPagina': rowsPerPage
    })
    setLoading(false);
    setUsersToShow(data)
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.user.nome > b.user.nome) ? 1 : ((b.user.nome > a.user.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.user.nome > b.user.nome) ? -1 : ((b.user.nome > a.user.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    // if (!search) {
    //   setList(list)
    //   setUsersToShow(list)
    // } else {
    //   setUsersToShow(userToShow)
    // }
  }

  const OrderByStatus = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.status > b.status) ? -1 : ((b.status > a.status) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    // if (!search) {
    //   setList(list)
    //   setUsersToShow(list)
    // } else {
    //   setUsersToShow(userToShow)
    // }
  }

  const OrderByServ = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.servico.nome > b.servico.nome) ? 1 : ((b.servico.nome > a.servico.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.servico.nome > b.servico.nome) ? -1 : ((b.servico.nome > a.servico.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    // if (!search) {
    //   setList(list)
    //   setUsersToShow(list)
    // } else {
    //   setUsersToShow(userToShow)
    // }
  }

  const OrderByProf = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.profissional.nome > b.profissional.nome) ? 1 : ((b.profissional.nome > a.profissional.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.profissional.nome > b.profissional.nome) ? -1 : ((b.profissional.nome > a.profissional.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    // if (!search) {
    //   setList(list)
    //   setUsersToShow(list)
    // } else {
    //   setUsersToShow(userToShow)
    // }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true);
    const data = await getAllSchedulesPagination({
      'texto': search,
      'filtros': {
        'status': status.map(u => u.value),
        'servico': servico.map(u => u.id),
        'procedimento': procedimento.map(u => u.id),
        'profissional': profissional.map(u => u.id),
        'unidade': unidade.map(u => u.id),
        'agendamento': {
          'de': format(new Date(dataInit), 'yyyy-MM-dd'),
          'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
        },
      },
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': 0,
      'entradasPorPagina': parseInt(event.target.value, 10)
    })
    setLoading(false);
    setUsersToShow(data)
  };
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    const data = await getAllSchedulesPagination({
      'texto': search,
      'filtros': {
        'status': status.map(u => u.value),
        'servico': servico.map(u => u.id),
        'procedimento': procedimento.map(u => u.id),
        'profissional': profissional.map(u => u.id),
        'unidade': unidade.map(u => u.id),
        'agendamento': {
          'de': format(new Date(dataInit), 'yyyy-MM-dd'),
          'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
        },
      },
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': newPage,
      'entradasPorPagina': rowsPerPage
    })
    setLoading(false);
    setUsersToShow(data)
  };
  const handleEdit = (data) => {
    props.setSchedule(data)
    props.setState(2)
  }

  async function handleAccept(id) {
    try {
      setDisabled(true)
      const result = await confirmSchedule(id, userData)

      if (result) {
        setList(list.map(item => {
          if (item.id == id) {
            item.status = 'confirmed'
            return item
          }
          return item
        }))
        setLoading(true);
        const data = await getAllSchedulesPagination({
          'texto': search,
          'filtros': {
            'status': status.map(u => u.value),
            'servico': servico.map(u => u.id),
            'procedimento': procedimento.map(u => u.id),
            'profissional': profissional.map(u => u.id),
            'unidade': unidade.map(u => u.id),
            'agendamento': {
              'de': format(new Date(dataInit), 'yyyy-MM-dd'),
              'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
            },
          },
          'timeOffset': new Date().getTimezoneOffset(),
          'pagina': 0,
          'entradasPorPagina': rowsPerPage
        })
        setLoading(false);
        setUsersToShow(data)

        setMessage('Agendamento confirmado')
        setSeverity('success')
        setDisabled(false)
      }


    } catch (error) {
      setMessage('Error ao aceitar agendamento')
      setSeverity('error')
      setDisabled(false)
    }

  }

  const handleDelete = async id => {
    setOpenDialogDelete(true)
    await deleteSchedule(id, textDeleteReason)
    setLoading(true);
    const data = await getAllSchedulesPagination({
      'texto': search,
      'filtros': {
        'status': status.map(u => u.value),
        'servico': servico.map(u => u.id),
        'procedimento': procedimento.map(u => u.id),
        'profissional': profissional.map(u => u.id),
        'unidade': unidade.map(u => u.id),
        'agendamento': {
          'de': format(new Date(dataInit), 'yyyy-MM-dd'),
          'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
        },
      },
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': 0,
      'entradasPorPagina': rowsPerPage
    })
    setLoading(false);
    setUsersToShow(data)
    setUsersToShow(list.filter(item => item.id !== id))
  }


  async function handleReject(id) {
    try {
      setDisabled(true)
      if (textRejectReason.length < 1) {
        setMessage('Campo de mensagem vazio')
        setSeverity('error')
        return
      }

      const result = await rejectSchedule(id, textRejectReason, userData)

      if (result) {
        setTextRejectReason('')
        setList(list.map(item => {
          if (item.id == id) {
            item.status = 'reject'
            return item
          }
          return item
        }))

        setLoading(true);
        const data = await getAllSchedulesPagination({
          'texto': search,
          'filtros': {
            'status': status.map(u => u.value),
            'servico': servico.map(u => u.id),
            'procedimento': procedimento.map(u => u.id),
            'profissional': profissional.map(u => u.id),
            'unidade': unidade.map(u => u.id),
            'agendamento': {
              'de': format(new Date(dataInit), 'yyyy-MM-dd'),
              'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
            },
          },
          'timeOffset': new Date().getTimezoneOffset(),
          'pagina': 0,
          'entradasPorPagina': rowsPerPage
        })
        setLoading(false);
        setUsersToShow(data)

        setMessage('Agendamento rejeitado')
        setSeverity('success')
        setDisabled(false)
        setOpenDialogReject(true)
      }

    } catch (error) {

      setList(list.map(item => {
        if (item.id == id) {
          item.status = 'reject'
          return item
        }
        return item
      }))

      setMessage('Error ao rejeitar agendamento')
      setSeverity('error')
      setDisabled(false)
      setOpenDialogReject(false)
    }
  }

  async function handleAppear(id, beenPresent) {
    try {
      setDisabled(true)
      const result = await finishSchedule(id, { beenPresent, userData })

      if (result) {

        setList(list.map(item => {
          if (item.id == id) {
            item.status = 'finished'
            return item
          }
          return item
        }))

        setLoading(true);
        const data = await getAllSchedulesPagination({
          'texto': search,
          'filtros': {
            'status': status.map(u => u.value),
            'servico': servico.map(u => u.id),
            'procedimento': procedimento.map(u => u.id),
            'profissional': profissional.map(u => u.id),
            'unidade': unidade.map(u => u.id),
            'agendamento': {
              'de': format(new Date(dataInit), 'yyyy-MM-dd'),
              'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
            },
          },
          'timeOffset': new Date().getTimezoneOffset(),
          'pagina': 0,
          'entradasPorPagina': rowsPerPage
        })
        setLoading(false);
        setUsersToShow(data)

        setMessage('Agendamento finalizado')
        setSeverity('success')
        setDisabled(false)
        setOpenDialogFinish(true)
      }

    } catch (error) {

      setList(list.map(item => {
        if (item.id == id) {
          item.status = 'finished'
          return item
        }
        return item
      }))

      setMessage('Error ao finalizar agendamento')
      setSeverity('error')
      setDisabled(false)
      setOpenDialogFinish(true)
    }
  }

  async function handleNotAppear(id, beenPresent) {
    try {
      setDisabled(true)

      const result = await finishSchedule(id, { beenPresent, userData })

      if (result) {

        setList(list.map(item => {
          if (item.id == id) {
            item.status = 'not_appear'
            return item
          }
          return item
        }))

        setLoading(true);
        const data = await getAllSchedulesPagination({
          'texto': search,
          'filtros': {
            'status': status.map(u => u.value),
            'servico': servico.map(u => u.id),
            'procedimento': procedimento.map(u => u.id),
            'profissional': profissional.map(u => u.id),
            'unidade': unidade.map(u => u.id),
            'agendamento': {
              'de': format(new Date(dataInit), 'yyyy-MM-dd'),
              'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
            },
          },
          'timeOffset': new Date().getTimezoneOffset(),
          'pagina': 0,
          'entradasPorPagina': rowsPerPage
        })
        setLoading(false);
        setUsersToShow(data)

        setMessage('Agendamento finalizado')
        setSeverity('success')
        setDisabled(false)
        setOpenDialogFinish(true)
      }

    } catch (error) {

      setList(list.map(item => {
        if (item.id == id) {
          item.status = 'not_appear'
          return item
        }
        return item
      }))

      setMessage('Error ao finalizar agendamento')
      setSeverity('error')
      setDisabled(false)
      setOpenDialogFinish(true)
    }
  }

  const handleOpenModal = () => {
    setDataInit(new Date())
    setOpenModal(true);
    setUsersToShow(list)
    setPage(0)
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const filterByItem = async () => {
    setLoading(true);
    const data = await getAllSchedulesPagination({
      'texto': search,
      'filtros': {
        'status': status.map(u => u.value),
        'servico': servico.map(u => u.id),
        'procedimento': procedimento.map(u => u.id),
        'profissional': profissional.map(u => u.id),
        'unidade': unidade.map(u => u.id),
        'agendamento': {
          'de': format(new Date(dataInit), 'yyyy-MM-dd'),
          'ate': format(new Date(dataFim), 'yyyy-MM-dd'),
        },
      },
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': page,
      'entradasPorPagina': rowsPerPage
    })
    setLoading(false);
    setUsersToShow(data)
    handleCloseModal(true)
  }

  return (
    newPermission.viz ? (
      <>
        <Grid container justify='space-between' spacing={2} >

          <Grid item md={12} xs={12}>

            <Box my={2} display="flex" justifyContent="flex-end">
              <Grid container spacing={3}>
                <Grid item md={3} xs={12} >
                  <TextField
                    fullWidth
                    label="Nome ou OAB"
                    name="filter"
                    variant="outlined"
                    value={search}
                    onChange={handleChangeFilter}
                    style={{ backgroundColor: 'white' }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    color='primary'
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleFilter()}
                    style={{ padding: '15px 40px' }}

                  >
                    Buscar
                  </Button>
                </Grid>

                <Grid item md={2} xs={12} >
                  <Button
                    color='primary'
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={OpenOrder}
                    style={{ padding: '15px 40px' }}
                  >
                    Ordenar
                  </Button>
                </Grid>
                <Grid item md={2} xs={12} >
                  <Button
                    color='primary'
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={handleOpenModal}
                    style={{ padding: '15px 40px' }}
                  >
                    Filtrar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => props.setState(1)}
                    className={classes.submitBtn}
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{ padding: '16px 35px' }}
                  >
                    Novo Agendamento
                  </Button>
                </Grid>
              </Grid>
            </Box>

          </Grid>
        </Grid>

        <Collapse in={message} style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          zIndex: 99999
        }}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Collapse>
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            {loading
              ? <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', padding: 100 }}>
                <CircularProgress color={'primary'} size={80} />
              </Grid>
              :
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} size={'small'}>
                  <TableHead className={classes.headerTb}>
                    <TableRow>

                      <TableCell key={'nome'} className={classes.celTableHeader}>
                        {"Nome do Usuário"}
                      </TableCell>
                      <TableCell key={'status'} className={classes.celTableHeader}>
                        {"Status"}
                      </TableCell>
                      <TableCell key={'category'} className={classes.celTableHeader}>
                        {"Serviço"}
                      </TableCell>
                      <TableCell key={'horaInicio'} className={classes.celTableHeader}>
                        {"Horário de início"}
                      </TableCell>
                      <TableCell key={'data'} className={classes.celTableHeader}>
                        {"Data"}
                      </TableCell>
                      <TableCell key={'profissional'} className={classes.celTableHeader}>
                        {"Profissional/Recurso"}
                      </TableCell>
                      <TableCell key={'unidade'} className={classes.celTableHeader}>
                        {"Local"}
                      </TableCell>

                      <TableCell style={{ minWidth: 150 }} key={'actions'} className={classes.celTableHeader} align="right" >
                        {"Ações"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userToShow.map((row, index) => {
                      const data = format(new Date(row.data + 'T04:00:00'), "dd/MM/yyyy")
                      return (
                        <TableRow
                          hover
                          key={row.id}
                        >
                          <TableCell style={{ minWidth: 150 }}>{row.user.nome}</TableCell>
                          <TableCell>{statusName(row.status)}</TableCell>
                          <TableCell>{row.servico.nome}</TableCell>
                          <TableCell>{row.horaInicio}</TableCell>
                          <TableCell>{data}</TableCell>
                          <TableCell>{row.profissional.nome}</TableCell>
                          <TableCell>{row.profissional.unidade.nome}</TableCell>
                          <TableCell style={{ minWidth: 150 }} align="right">
                            {switchActions(row)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: 'center'
                }} >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => handlePrint()}
                  startIcon={<LocalPrintshop />}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={'Linhas por página'}
                  component="div"
                  count={-1}
                  nextIconButtonProps={{ disabled: userToShow.length < rowsPerPage || loading }}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider >
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => statusName(OrderByStatus())}
              >
                Status
              </Button>
            </Grid>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByServ()}
              >
                Serviço
              </Button>
            </Grid>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByProf()}
              >
                Profissional/
                Recurso
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          keepMounted
        >
          <Container maxWidth='md' spacing={3}
            style={{
              backgroundColor: 'white',
              height: '100%',
              overflow: 'scroll',
              padding: 30,
              margin: '0% 10%',
              borderRadius: 4,
              border: '1px solid #000000',
              top: 100
            }}
          >
            <Grid container spacing={6}
              style={{
                padding: 10,
              }}
            >
              <Grid item md={12} xs={12}>
                <Typography variant="h3" display="block" style={{ marginTop: 10 }}>
                  Selecionar Filtros
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Status
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={status}
                      options={Status}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setStatus(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Serviço
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={servico}
                      options={allServico}
                      getOptionLabel={(option) => option.nome}
                      onChange={(e, value) => {
                        setServico(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Procedimento
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={procedimento}
                      options={allProc}
                      getOptionLabel={(option) => option.nome}
                      onChange={(e, value) => {
                        setProcedimento(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Profissional/Recurso
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={profissional}
                      options={allProfi}
                      getOptionLabel={(option) => option.nome}
                      onChange={(e, value) => {
                        setProfissional(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Unidade
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={unidade}
                      options={allUnity}
                      getOptionLabel={(option) => option.nome}
                      onChange={(e, value) => {
                        setUnidade(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={3} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Agendados entre:
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="dataInicio"
                          format="dd/MM/yyyy"
                          autoOk
                          value={dataInit}
                          onChange={(newValue) => {
                            setDataInit(startOfDay(newValue));
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                          style={{ marginTop: 10 }}
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid xs={3} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      e:
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="dataFim"
                          format="dd/MM/yyyy"
                          autoOk
                          value={dataFim}
                          onChange={(newValue) => {
                            setDataFim(endOfDay(newValue))
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                          style={{ marginTop: 10 }}
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}
                style={{ marginTop: 30, textAlign: "center", placeContent: 'center' }}
              >
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={filterByItem}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Aplicar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={handleCloseModal}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Modal>
      </>
    ) : (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
      }}>
        Você não possui permissão para visualizar essa página
      </div>
    )
  )
}