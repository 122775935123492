import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Grid,
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon,
  LocalPrintshop
} from '@material-ui/icons';
import { color } from '../../../../components/common/colors';
import { findAllTags, deleteTag } from '../../../../providers/partner';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  celTableHeaderActions: {
    width: 96,
    backgroundColor: color.white,
  }
}));

export default function ListTag({ setState, setTagToEdit }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tagList, setTagList] = useState([])
  const [tagsToShow, setTagsToShow] = useState([])
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerTag);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const data = await findAllTags();
      data.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
      setTagList(data);
      setTagsToShow(data);
    }

    fetchData();
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (category) => {
    setTagToEdit(category)
    setState(2)
  }

  const handleDelete = async id => {
    const body = {
      id: id,
    }
    try {
      await deleteTag(body)
      setTagList(tagList.filter(item => item.id !== id))
      setTagsToShow(tagsToShow.filter(item => item.id !== id))
    } catch (e) {
      console.log(e)
    }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "ID",
      "Nome",
    ];
    const body = [];

    tagsToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.id,
          row.name,
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, tagsToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
          <Grid>
            <Button
              variant='contained'
              className={classes.btnAdd}
              onClick={() => setState(1)}
              style={{ padding: '15px 40px' }}
            >
              Adicionar Tag
            </Button>
          </Grid>

        </Box>
        <Paper className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.headerTb}>
                <TableRow>
                  <TableCell key={'id'} className={classes.celTableHeader}>
                    {"ID"}
                  </TableCell>
                  <TableCell key={'nome'} className={classes.celTableHeader}>
                    {"Nome"}
                  </TableCell>
                  <TableCell key={'actions'} align="right" className={classes.celTableHeaderActions}>
                    {"Ações"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tagsToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => console.log(event)}
                        key={row.id}
                      >
                        <TableCell >{row.id}</TableCell>
                        <TableCell >{row.name}</TableCell>
                        <TableCell padding='none' align="right">
                          {newPermission.det && (
                            <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                          )}
                          {/* {newPermission.edit && (
                            <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={3} >
            <Grid item md={6} xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: 'center'
              }} >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => createPDF()}
                startIcon={<LocalPrintshop />}
              >
                Imprimir
              </Button>
            </Grid>
            <Grid item md={6} xs={12} style={{}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={'Linhas por página'}
                component="div"
                count={tagsToShow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box className={classes.footer} />
      </Grid>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}