import React, { useState } from 'react';

import Avaliacao from './avaliacao';
import DataAvaliacao from './check-avaliacao'

export default function Avaliation(props) {

  const [state, setState] = useState(0)
  const [avaliationToCheck, SetAvaliationToCheck] = useState()


  return (
    <>
      {state === 0 && <Avaliacao setState={setState} SetAvaliationToCheck={SetAvaliationToCheck} />}
      {state === 1 && <DataAvaliacao setState={setState} avaliation={avaliationToCheck} />}
    </>
  )
}