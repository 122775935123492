import api from './api'


export const getAll = async () => {
  const { data } = await api.get('user')
  return data
}
export async function getUserById(id) {
  const { data } = await api.get(`user/${id}`)
  return data
}
export async function searchUsers(body) {
  const { data } = await api.post(`user/search/users`, body)
  return data
}

export const userCities = async () => {
  const { data } = await api.get('/user/search/cities')
  return data
}

export const newUser = async (user, userData, alterationLogData) => {
  const { data } = await api.post('user', { user, userData, alterationLogData })
  return data
}

export const editUser = async (user) => {
  const { data } = await api.post(`user/${user.id}`, { user })
  return data
}

export const deleteUser = async (id) => {
  const { data } = await api.delete(`user/${id}`)
  return data
}

export async function getUserByCpf(cpf) {
  const { data } = await api.get(`adm/user/cpf/${cpf}`)
  return data
}

export const setActivateStatus = async user => {
  const { data } = await api.post(`user/activate-status`, { user })
  return data
}

export const setRefusedStatus = async (user, message) => {
  const { data } = await api.post(`user/refused-status`, { user, message })
  return data
}

export const getImages = async id => {
  const { data } = await api.get(`user/images/${id}`)
  return data;
}

// SCHEDULE

export const findToSchedule = async value => {
  const { data } = await api.post(`schedule/users/find-to-schedule`, { value })
  return data
}


//bloqueio de usuario.
export const findAllUserBlocked = async () => {
  const { data } = await api.get(`/user-block/all`);
  return data;
}

export const blockUser = async () => {
  const { data } = await api.post(`/user-block`);
  return data;
}

export const sendMenssages = async () => {
  const { data } = await api.post(`/user-block/sendMenssages`)
}

export const validateSession = async (token) => {
  const res = await api.post('/user/validate', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
