import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons'
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { create } from '../../../../providers/partner'
import { findAllCategorys } from '../../../../providers/partner'
import { findAllTags } from '../../../../providers/partner';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'
import { alterationLog } from '../../../../providers/log';
import { useSelector } from 'react-redux';
import { UploadFolder, apiUpload } from '../../../../providers/upload';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  btns: {
    marginTop: 15
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  option: {
    backgroundColor: 'white'
  }
}));

export default function NewParceiro({ setState }) {
  const classes = useStyles()
  const [categoryList, setCategoryList] = useState([])
  const [tagList, setTagList] = useState([]);
  const [categoriesValues, setCategoriesValues] = useState([])
  const [tagsValues, setTagsValues] = useState([]);

  const user = useSelector(({ user }) => user);

  useEffect(() => {
    async function fetchData() {
      let data = await findAllCategorys();
      setCategoryList(data);
      data = await findAllTags();
      setTagList(data);
    }

    fetchData();
  }, [])

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          visible: true,
          name: '',
          categories: [],
          tags: [],
          description: '',
          note: '',
          logo: '',
          priority: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
          note: Yup.string()
            .required('Campo obrigatório'),
          logo: Yup.mixed()
            .required('Escolha uma logo para o parceiro'),
          categories: Yup.array()
            .test('categories', 'Selecione ao menos uma categoria', value => !!value.length),
          priority: Yup.string()
            .required('Campo obrigatório'),
        })}
        onSubmit={async (values, actions) => {
          try {
            const body = new FormData()
            body.append('file', values.logo)
            body.append('folder', UploadFolder.PARTNER_LOGO)
            const data = await apiUpload(body);
            values.logo = data.location
            await create(values)
            await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values,  menu: "Parceiros", subMenu: "Parceiros" });
          } catch (e) {
            console.log(e)
          } finally {
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      value="start"
                      name="visible"
                      control={<Checkbox
                        checked={values.visible}
                        name="visible"
                        color="primary"
                        onChange={handleChange}
                      />}
                      label="Visível"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.priority && errors.priority)}
                      helperText={touched.priority && errors.priority}
                      label="Prioridade"
                      name="priority"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priority}
                      variant="outlined"
                      type='number'
                      inputProps={{
                        max: 9999,
                        min: 1
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Nome"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      fullWidth
                      options={categoryList}
                      value={categoriesValues}
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      onChange={(e, value) => {
                        setCategoriesValues(value)
                        setFieldValue('categories', value)
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params}
                          variant="outlined"
                          label="Categorias"
                          error={Boolean(touched.categories && errors.categories)}
                          helperText={touched.categories && errors.categories}
                          name="categories"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      fullWidth
                      options={tagList}
                      value={tagsValues}
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      onChange={(e, value) => {
                        setTagsValues(value)
                        setFieldValue('tags', value)
                      }}
                      getOptionLabel={(option) => option.name}
                      renderOption={(option, { selected }) => (
                        <React.Fragment >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params}
                          variant="outlined"
                          label="Tags"
                          error={Boolean(touched.tags && errors.tags)}
                          helperText={touched.tags && errors.tags}
                          name="tags"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label="Descrição"
                      name="description"
                      multiline
                      InputProps={{ classes: { input: classes.textArea } }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                      label="Observação"
                      name="note"
                      multiline
                      InputProps={{ classes: { input: classes.textArea } }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  {<Grid item xs={12}>
                    <input
                      accept="image/*"
                      className={classes.inputFile}
                      name='logo'
                      id="logo-input"
                      type="file"
                      onChange={event => setFieldValue('logo', event.target.files[0])}
                    />
                    <label htmlFor="logo-input">
                      <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                        Upload
                                </Button>
                    </label>
                    {Boolean(touched.logo && errors.logo) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                      *{touched.logo && errors.logo}
                    </Typography>}
                  </Grid>}
                  {values.logo && <Grid item md={8} xs={12}>
                    <img src={URL.createObjectURL(values.logo)} style={{ maxHeight: 630, maxWidth: 630, borderRadius: 4, objectFit: 'contain' }} />
                  </Grid>}
                </Grid>
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      size="large"
                      color='secondary'
                      variant="contained"
                      onClick={() => setState(0)}
                    >
                      Voltar
                        </Button>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      color='primary'
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Cadastrar Parceiro'}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}