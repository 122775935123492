import React, { useState } from 'react';

import EditGroup from './edit-group';
import ListGroup from './list-group';
import AddGroup from './new-group';

export default function Groups(props) {

  const [state, setState] = useState(0)
  const [groupToEdit, setGroupToEdit] = useState()

  return (
    <>
      {state === 0 && <ListGroup setState={setState} setGroupToEdit={setGroupToEdit} />}
      {state === 1 && <AddGroup setState={setState} />}
      {state === 2 && <EditGroup group={groupToEdit} setState={setState} />} 
    </>
  )
}