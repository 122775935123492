import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  MenuItem,
  createMuiTheme,
  MuiThemeProvider,
  Checkbox,
  FormControlLabel,
  Avatar,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Formik } from 'formik';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'
import {
  getAllServicos,
  getAllUnidades,
  newProfissional,
  getProcedimentoByServico
} from '../../../../providers/schedules';
import { useSelector } from 'react-redux';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
    maxWidth: '50vw'
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    marginTop: 30
  },
  listText: {
    fontSize: 16
  },
  option: {
    backgroundColor: 'white',
  },
  inputFile: {
    display: 'none'
  },
  Avatar: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});



export default function NewProfissional({ setState }) {
  const classes = useStyles()

  const categoria = [
    {
      value: 'profissional',
      label: 'Profissional'
    },
    {
      value: 'recurso',
      label: 'Recurso'
    }
  ]
  const [locais, setLocais] = useState([])
  const [servicos, setServicos] = useState([])
  const [procedimentoServicoList, setProcedimentoServicoList] = useState([])
  const [procedimentoServicoValues, setProcedimentoServicoValues] = useState([])
  const [hourLunchInit, onChangeHourLunchInit] = useState(new Date().setHours('00', '00'));
  const [hourLunchFinal, onChangeHourLunchFinal] = useState(new Date().setHours('00', '00'));
  const [result, setResult] = useState(false)

  const user = useSelector(({ user }) => user);

  const setHourFieldValue = value => {
    if (value) {
      return null
    } else {
      return { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) }
    }
  }

  useEffect(async () => {
    const data = await getAllUnidades()
    let servicos = await getAllServicos()
    // const dataProcedimento = await getAllProcedimentos()
    // setProcedimentoList(dataProcedimento)
    setLocais(data)
    setServicos(servicos)
  }, [])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              img: null,
              idUnidade: '',
              idServico: '',
              nome: '',
              tipo: 'profissional',
              horaAlmocoInicio: '',
              horaAlmocoFim: '',
              segunda: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              terca: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              quarta: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              quinta: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              sexta: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              sabado: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              domingo: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              disponible: true,
              future: '',
              antecedency: '',
              procedimento: [],
            }}
            validationSchema={Yup.object().shape({
              nome: Yup.string()
                .required('Campo obrigatório'),
              tipo: Yup.string()
                .required('Campo obrigatório'),
              idUnidade: Yup.string()
                .required('Campo obrigatório'),
              idServico: Yup.string()
                .required('Campo obrigatório'),
              procedimento: Yup.array()
                .test('categories', 'Selecione ao menos uma categoria', values => !!values.length),
              future: Yup.string()
                .required('Campo obrigatório'),
              antecedency: Yup.string()
                .required('Campo obrigatório').
                max(4, 'Antecedência de visualização de horários deve conter no maximo 3 digitos '),
              horaAlmocoInicio: Yup.string()
                .required('Campo obrigatório'),
              horaAlmocoFim: Yup.string()
                .required('Campo obrigatório'),
              img: Yup.mixed().required('Escolha uma imagem')
            })}

            onSubmit={async (values) => {
              try {
                const horarios = [
                  values.segunda ? {
                    horaIni: [new Date(values.segunda.horaInit).getHours(), new Date(values.segunda.horaInit).getMinutes()],
                    horaFim: [new Date(values.segunda.horaFim).getHours(), new Date(values.segunda.horaFim).getMinutes()]
                  } : null,
                  values.terca ? {
                    horaIni: [new Date(values.terca.horaInit).getHours(), new Date(values.terca.horaInit).getMinutes()],
                    horaFim: [new Date(values.terca.horaFim).getHours(), new Date(values.terca.horaFim).getMinutes()]
                  } : null,
                  values.quarta ? {
                    horaIni: [new Date(values.quarta.horaInit).getHours(), new Date(values.quarta.horaInit).getMinutes()],
                    horaFim: [new Date(values.quarta.horaFim).getHours(), new Date(values.quarta.horaFim).getMinutes()]
                  } : null,
                  values.quinta ? {
                    horaIni: [new Date(values.quinta.horaInit).getHours(), new Date(values.quinta.horaInit).getMinutes()],
                    horaFim: [new Date(values.quinta.horaFim).getHours(), new Date(values.quinta.horaFim).getMinutes()]
                  } : null,
                  values.sexta ? {
                    horaIni: [new Date(values.sexta.horaInit).getHours(), new Date(values.sexta.horaInit).getMinutes()],
                    horaFim: [new Date(values.sexta.horaFim).getHours(), new Date(values.sexta.horaFim).getMinutes()]
                  } : null,
                  values.sabado ? {
                    horaIni: [new Date(values.sabado.horaInit).getHours(), new Date(values.sabado.horaInit).getMinutes()],
                    horaFim: [new Date(values.sabado.horaFim).getHours(), new Date(values.sabado.horaFim).getMinutes()]
                  } : null,
                  values.domingo ? {
                    horaIni: [new Date(values.domingo.horaInit).getHours(), new Date(values.domingo.horaInit).getMinutes()],
                    horaFim: [new Date(values.domingo.horaFim).getHours(), new Date(values.domingo.horaFim).getMinutes()]
                  } : null
                ]

                const verify = horarios.some(value => {
                  if (value === null) return false
                  const ini = value.horaIni.every(i => i === 0)
                  const fim = value.horaFim.every(i => i === 0)
                  return ini || fim
                })

                setResult(verify)
                if (verify) return
                const body = new FormData()
                body.append('file', values.img)
                body.append('folder', UploadFolder.MISC)
                const data = await apiUpload(body);
                delete values.img
                values.imgProfile = data.location

                if (values.segunda) {
                  values.segunda = {
                    horaInit: `${('0' + values.segunda.horaInit.getHours()).slice(-2).replace(' ', '')}: ${('0' + values.segunda.horaInit.getMinutes()).slice(-2).replace(' ', '')}`,
                    horaFim: `${('0' + values.segunda.horaFim.getHours()).slice(-2).replace(' ', '')}:${('0' + values.segunda.horaFim.getMinutes()).slice(-2).replace(' ', '')}`
                  }
                }
                if (values.terca) {
                  values.terca = {
                    horaInit: `${('0' + values.terca.horaInit.getHours()).slice(-2)}:${('0' + values.terca.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.terca.horaFim.getHours()).slice(-2)}:${('0' + values.terca.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.quarta) {
                  values.quarta = {
                    horaInit: `${('0' + values.quarta.horaInit.getHours()).slice(-2)}:${('0' + values.quarta.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.quarta.horaFim.getHours()).slice(-2)}:${('0' + values.quarta.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.quinta) {
                  values.quinta = {
                    horaInit: `${('0' + values.quinta.horaInit.getHours()).slice(-2)}:${('0' + values.quinta.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.quinta.horaFim.getHours()).slice(-2)}:${('0' + values.quinta.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.sexta) {
                  values.sexta = {
                    horaInit: `${('0' + values.sexta.horaInit.getHours()).slice(-2)}:${('0' + values.sexta.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.sexta.horaFim.getHours()).slice(-2)}:${('0' + values.sexta.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.sabado) {
                  values.sabado = {
                    horaInit: `${('0' + values.sabado.horaInit.getHours()).slice(-2)}:${('0' + values.sabado.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.sabado.horaFim.getHours()).slice(-2)}:${('0' + values.sabado.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.domingo) {
                  values.domingo = {
                    horaInit: `${('0' + values.domingo.horaInit.getHours()).slice(-2)}:${('0' + values.domingo.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.domingo.horaFim.getHours()).slice(-2)}:${('0' + values.domingo.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                const result = await newProfissional(values)
                await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Agendamentos", subMenu: "Profissional", });
                setState(0)
              } catch (error) {
                console.log('ERROR_SCHEDULE_NEW');
                console.log(error);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (

              <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
                <CardContent>
                  <Grid container alignContent={'center'} spacing={3}>

                    <Grid item md={12}>
                      <FormControlLabel
                        value="start"
                        name="disponible"
                        control={<Checkbox
                          checked={values.disponible}
                          name="disponible"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Disponível"
                        labelPlacement="start"
                      />
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Grid item xs={12} style={{ marginBottom: 42 }}>
                        <input
                          accept="image/*"
                          className={classes.inputFile}
                          name='img'
                          id="img-input"
                          type="file"
                          onChange={(event) => setFieldValue('img', event.target.files[0])}
                        />
                        <label htmlFor="img-input">

                          <Avatar
                            className={classes.Avatar}
                            src={values.img ? URL.createObjectURL(values.img) : ''}
                          />
                        </label>
                        {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                          *{touched.img && errors.img}
                        </Typography>}
                      </Grid>
                      <Grid container alignContent={'center'} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Typography
                            style={{ marginBottom: -10 }}
                            paragraph variant={'h4'}>
                            Cadastrar novo profissional/recurso
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            label="Nome"
                            name="nome"
                            id='text-nome'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nome}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            id="standard-select-category"
                            error={Boolean(touched.tipo && errors.tipo)}
                            helperText={touched.tipo && errors.tipo}
                            select
                            onBlur={handleBlur}
                            value={values.tipo}
                            onChange={(e, value) => {
                              setFieldValue('tipo', e.target.value)
                            }}
                            variant="outlined"
                          >
                            {categoria.map((option) => (
                              <MenuItem

                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <Autocomplete
                            fullWidth
                            id="select-local"
                            color={touched.idUnidade && errors.idUnidade ? 'error' : 'primary'}
                            options={locais.map((option) => option)}
                            getOptionLabel={option => option.nome}
                            onChange={(e, value) => {
                              setFieldValue('idUnidade', value ? value.id : '')
                            }}
                            onBlur={handleBlur}
                            classes={{
                              popper: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField {...params}
                                onBlur={handleBlur}
                                label="Unidade"
                                error={Boolean(touched.idUnidade && errors.idUnidade)}
                                helperText={touched.idUnidade && errors.idUnidade}
                                variant="outlined" />
                            )}

                          />
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <Autocomplete
                            fullWidth
                            id="select-local"
                            color={touched.idServico && errors.idServico ? 'error' : 'primary'}
                            options={servicos.map((option) => option)}
                            getOptionLabel={option => option.nome}
                            onChange={async (e, value) => {
                              if (value && value.id) {
                                const dataServico = await getProcedimentoByServico(value.id)
                                setProcedimentoServicoList(dataServico)
                                setFieldValue('idServico', value ? value.id : '')
                              } else {
                                setFieldValue('idServico', '')
                              }
                            }}
                            onBlur={handleBlur}
                            classes={{
                              popper: classes.option
                            }}
                            renderInput={(params) => (
                              <TextField {...params}
                                onBlur={handleBlur}
                                label="Serviço"
                                variant="outlined"
                                error={Boolean(touched.idServico && errors.idServico)}
                                helperText={touched.idServico && errors.idServico}
                              />
                            )}

                          />
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography paragraph variant={'h4'}>Procedimento</Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <Autocomplete
                            multiple
                            fullWidth
                            options={procedimentoServicoList}
                            value={procedimentoServicoValues}
                            disableCloseOnSelect
                            classes={{
                              popper: classes.option
                            }}
                            onChange={(e, value) => {
                              // if (value.length <= 3) {
                              setProcedimentoServicoValues(value)
                              setFieldValue('procedimento', value)
                              // }
                            }}
                            getOptionLabel={(option) => option.nome}
                            renderOption={(option, { selected }) => (
                              <React.Fragment >
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                {option.nome}
                              </React.Fragment>
                            )}
                            renderInput={(params) => (
                              <TextField {...params}
                                variant="outlined"
                                label="Procedimentos"
                                error={Boolean(touched.procedimento && errors.procedimento)}
                                helperText={touched.procedimento && errors.procedimento}
                                nome="procedimento"
                              />
                            )}
                          />
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography paragraph variant={'h4'}>Horário de funcionamento</Typography>
                        </Grid>

                        <Grid style={{ padding: 12 }}>
                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Segunda-Feira</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.segunda}
                                  color="primary"
                                  onChange={(event) => setFieldValue('segunda', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.segunda && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.segunda.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('segunda.horaInit', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.segunda.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('segunda.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}

                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Terça-Feira</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.terca}
                                  color="primary"
                                  onChange={(event) => setFieldValue('terca', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.terca && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.terca.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('terca.horaInit', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.terca.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('terca.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}

                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Quarta-Feira</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.quarta}
                                  color="primary"
                                  onChange={(event) => setFieldValue('quarta', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.quarta && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.quarta.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('quarta.horaInit', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.quarta.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('quarta.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}

                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Quinta-Feira</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.quinta}
                                  color="primary"
                                  onChange={(event) => setFieldValue('quinta', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.quinta && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.quinta.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('quinta.horaInit', date)

                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.quinta.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('quinta.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}

                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Sexta-Feira</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.sexta}
                                  color="primary"
                                  onChange={(event) => setFieldValue('sexta', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.sexta && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.sexta.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('sexta.horaInit', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.sexta.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('sexta.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}

                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Sábado</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.sabado}
                                  color="primary"
                                  onChange={(event) => setFieldValue('sabado', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.sabado && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.sabado.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('sabado.horaInit', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.sabado.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('sabado.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}

                          <Grid container alignContent={'center'} spacing={0}>
                            <Grid item md={6} xs={12} >
                              <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Domingo</Typography>
                            </Grid>

                            <Grid item md={6}>
                              <FormControlLabel
                                style={{ marginBottom: 0, marginTop: 10 }}
                                value="start"
                                name="visible"
                                control={<Checkbox
                                  checked={!values.domingo}
                                  color="primary"
                                  onChange={(event) => setFieldValue('domingo', setHourFieldValue(event.target.checked))}
                                />}
                                label="Fechado"
                                labelPlacement="start"
                              />
                            </Grid>
                          </Grid>

                          {values.domingo && <Grid container spacing={3} >

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    autoOk
                                    fullWidth
                                    margin="normal"
                                    id="time-picker"
                                    label="Início"
                                    hideTabs
                                    ampm={false}
                                    value={values.domingo.horaInit}
                                    onChange={(date) => {
                                      setFieldValue('domingo.horaInit', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />

                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>

                            <Grid item md={6} xs={12}>
                              <MuiThemeProvider theme={defaultMaterialTheme}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                  <TimePicker
                                    fullWidth
                                    autoOk
                                    margin="normal"
                                    id="horaFim"
                                    label="Fim"
                                    hideTabs
                                    ampm={false}
                                    value={values.domingo.horaFim}
                                    onChange={(date) => {
                                      setFieldValue('domingo.horaFim', date)
                                    }}
                                    variant='inline'
                                    inputVariant={'outlined'}
                                  />
                                </MuiPickersUtilsProvider>
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>}
                          {!!result && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                            Escolha horários válidos!
                          </Typography>}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container alignContent={'center'} spacing={3}>

                        <Grid item md={12} xs={12} >
                          <Typography variant={'h4'}>Horário de almoço</Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                id="time-picker-almoco-init"
                                label="Início"
                                hideTabs
                                error={Boolean(touched.horaAlmocoInicio && errors.horaAlmocoInicio)}
                                helperText={touched.horaAlmocoInicio && errors.horaAlmocoInicio}
                                ampm={false}
                                value={hourLunchInit}
                                onChange={(date) => {
                                  const data = new Date(date)
                                  onChangeHourLunchInit(date)
                                  setFieldValue('horaAlmocoInicio', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                id="time-picker-almoco-final"
                                label="Fim"
                                hideTabs
                                error={Boolean(touched.horaAlmocoFim && errors.horaAlmocoFim)}
                                helperText={touched.horaAlmocoFim && errors.horaAlmocoFim}
                                ampm={false}
                                value={hourLunchFinal}
                                onChange={(date) => {
                                  const data = new Date(date)
                                  onChangeHourLunchFinal(date)
                                  setFieldValue('horaAlmocoFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography
                            style={{ marginBottom: -8 }}
                            variant={'h4'}>
                            Período futuro de visualização de horários
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.future && errors.future)}
                            helperText={touched.future && errors.future}
                            label="Dias"
                            type='number'
                            name="future"
                            id='text-future'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.future}
                            variant="outlined"
                            inputProps={{
                              maxlength: 3,
                            }}
                          />
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography
                            style={{ marginBottom: -8 }}
                            variant={'h4'}>
                            Antecedência de visualização de horários
                          </Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.antecedency && errors.antecedency)}
                            helperText={touched.antecedency && errors.antecedency}
                            label="Minutos"
                            type='number'
                            name="antecedency"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.antecedency}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Box my={2} display="flex" justifyContent="space-around">
                    <Button
                      onClick={() => setState(0)}
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Voltar
                    </Button>

                    <Button
                      onClick={() => handleSubmit()}
                      color='primary'
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Cadastrar
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>

    </>
  )
}

