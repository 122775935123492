import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  item: {
    color: '#979797',
    fontWeight: 'bold',
    letterSpacing: 1.5
  }
}));

export default function DataCredito({ setState, credit }) {
  const classes = useStyles()
  const goBack = () => {
    setState(0)
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.Card}>
        <CardContent>
          <Grid spacing={3}>
            <Grid md={12}>
              <Typography style={{ marginBottom: 40 }}>
                <h1>
                  Crédito #{credit.id}
                </h1>
              </Typography>
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Status</Typography>
              {credit.status}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Data</Typography>
              {format(new Date(credit.data), 'dd/MM/yyyy HH:mm')}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Quantidade de Carteiras Atingidas</Typography>
              {credit.carteira}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Valor Total</Typography>
              {credit.valor}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Usuário Responsável</Typography>
              {credit.usuario}
            </Grid>
            <Grid container style={{ marginBottom: 20 }} alignItems='flex-end' justify='flex-end'>
              <Grid item md={3}>
                <Button
                  fullWidth
                  color='primary'
                  size="large"
                  variant="contained"
                  onClick={goBack}
                >
                  Voltar
                        </Button>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}