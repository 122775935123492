//página desbloquear/bloqueio usuario
import React, { useEffect, useState } from 'react';
import { TextField, Button, makeStyles, Container, Card, CardContent, Box, Grid, Typography, Divider, CircularProgress, MenuItem, Avatar, MuiThemeProvider, createMuiTheme, } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import { Alert } from '@material-ui/lab';


import ReactLoading from "react-loading";
import Backdrop from "@material-ui/core/Backdrop";

import { findToSchedule, sendMenssages, } from "../../../../providers/user";



import "date-fns";
import * as Yup from "yup";

import { add, differenceInDays } from "date-fns/esm";
import { capitalize } from 'lodash'
import { blockUserByAdm } from '../../../../providers/userBlock';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: "100%",
    padding: theme.spacing(3),
  },
  card: {
    background: color.white,
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  btns: {
    marginTop: 15,
  },
  textArea: {
    minHeight: 100,
  },
  inputFile: {
    display: "none",
  },
  Avatar: {
    cursor: "pointer",
    width: 200,
    height: 200,
    margin: "8px auto",
    boxShadow: "0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red,
    },
  },
});

export default function NewUser({ setState, userToBlock, selectedUserToBlock }) {

  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([])
  const [error, setError] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.bloqueio);
    };

    fetchPermissions();
  }, []);


  const getPunished = (punished) => {
    switch (punished) {
      case 'NONE':
        return 'Sem bloqueio';
      case 'FIRST':
        return 'Bloqueado';
      case 'SECOND':
        return 'Bloqueado';
      default:
        return 'Sem bloqueio';
    }
  }

  const restingDays = (usuario) => {
    if (usuario.datePunished) {
      const diff = differenceInDays(add(new Date(usuario.datePunished), { days: usuario.punished == 'FIRST' ? 30 : 30 }), new Date())
      return diff
    }
  }

  async function getUserToSchedule(value) {
    try {
      setError(false)
      let data = await findToSchedule(value)
      setUser(data)
    } catch (error) {
      setError('Usuário não encontrado')
    }
  }


  useEffect(() => {
    async function fetchData() {
      try {
        setError(false)
        let data = await findToSchedule(!!userToBlock ? userToBlock.oab : "")
        setUser(data)
      } catch (error) {
        setError('Usuário não encontrado')
      }
    }
    fetchData();
  }, [])

  const classes = useStyles();
  const goBack = () => {
    setState(0);
  };

  return (
    <>

      <Container maxWidth="md" disableGutters>


        {userToBlock.map(u => {
          return (
            <>
              <Grid
                container
                alignContent={'center'}
                className={/*u.punished != 'NONE' || u.situacao != 'ATIVO' ? '' :*/ classes.userCard}

              >
                <Grid item md={6} xs={12} >
                  <Grid item xs={12} style={{ margin: '30px 5px 30px 5px' }}>
                    <Avatar
                      className={classes.Avatar}
                      src={u.img ? URL.createObjectURL(u.img) : u.imgProfile}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid item md={12} xs={12} style={{ margin: '15px 5px 30px 5px' }}>
                    <TextField
                      fullWidth

                      label="Nome"
                      name="nome"
                      id='text-nome'
                      value={u.nome}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} style={{ margin: '30px 5px 30px 5px' }}>
                    <TextField
                      fullWidth

                      label="Tipo"
                      name="tipo"
                      id='text-tipo'
                      value={capitalize(u.userType)}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12} style={{ margin: '30px 5px 15px 5px' }}>
                    <TextField
                      fullWidth

                      label="Bloqueio por não comparecimento"
                      name="bloqueio"
                      id='text-bloqueio'
                      value={getPunished(u.punished)}
                      variant="outlined"
                      InputProps={{
                        readOnly: true
                      }}
                    />
                    {u.punished != 'NONE' && restingDays(u) > 0 && (
                      <>
                        <Grid item md={12} xs={12}>
                          <Alert
                            severity={'warning'}
                          >
                            {`Bloqueio Ativo: Tempo restante ${restingDays(u)} dias.`}
                          </Alert>

                        </Grid>
                      </>
                    )}
                    {u.situacao === 'INATIVO' && (
                      <>
                        <Grid item md={12} xs={12}>
                          <Alert
                            severity={'warning'}
                          >
                            {`Usuário Inativo!`}
                          </Alert>

                        </Grid>
                      </>
                    )}


                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <Divider style={{ marginTop: 40, marginBottom: 40 }} />
              </Grid>
            </>
          )
        })}
        <Formik
          //enableReinitialize={true}
          initialValues={{
            img: null,
            nome: "",
            title: 'Bloqueio Temporário de Agendamento',
            observacoes: "",
            message: "",

          }}
          validationSchema={Yup.object().shape({
            message: Yup.string()
              .required('Campo obrigatório'),
          })}
          onSubmit={async (values, actions) => {
            try {


              setOpen(true)

              await blockUserByAdm({
                idUser: selectedUserToBlock.id,
                message: values.message,
                title: values.title,
                // o id do adm que bloqueou ou desbloqueou o cidadão a api que decide baseado no token
              })
              
              setOpen(false)
              setState(0)
            } catch (error) {
              console.log(error);
            } finally {
              setOpen(false)
              setState(0)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldError,
            setFieldValue,

            handleFilter,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={3}>


                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        name="title"
                        label="Título da mensagem"
                        value={'Bloqueio Temporário de Agendamento'}
                        variant="outlined"
                      />
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Mensagem"
                        name="message"
                        multiline
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{
                          minLength: 10
                        }}
                        helperText={'Minimo de 10 caracteres'}
                      />
                    </Grid>


                    {/* <Grid item xs={12}>
                      <Grid container xs={12} direction='row' justify='flex-start' alignItems='center'>
                        <DocComponent docName={'doc1'} />
                      </Grid>
                    </Grid> */}
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                      className={classes.btns}
                    >
                      <Grid item md={4} xs={12}>
                        {newPermission.edit && (
                          <Button
                            fullWidth
                            color="primary"
                            disabled={isSubmitting}
                            size="large"
                            variant="contained"
                            onClick={goBack}
                          >
                            CANCELAR
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {newPermission.edit && (
                          <Button
                            fullWidth
                            color="primary"
                            disabled={isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? (
                              <CircularProgress
                                color="inherit"
                                circle={{ color: "#fff" }}
                                size={25}
                              />
                            ) : (
                              "ENVIAR"
                            )}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>

      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={"spinningBubbles"} color={color.red} />
      </Backdrop>
    </>
  );
}
