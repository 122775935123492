import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Collapse,
  IconButton,
  MenuItem,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import { color } from '../../../components/common/colors';
import { Alert, Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import * as Yup from 'yup'
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { getCidades, newMessageScheduled } from '../../../providers/mensagens';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../providers/permissions';




const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(1)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    // width: '100%', 
    marginBottom: 20
  },
  option: {
    backgroundColor: 'white'
  }
}));

const destiny = [
  { label: 'Todos', value: 'all' },
  { label: 'Tipo de usuário', value: 'type' },
  { label: 'Cidade', value: 'city' },
  { label: 'Gênero', value: 'gender' },
  { label: 'Idade', value: 'age' },
]
const gender = [
  { label: 'Masculino', value: 'M' },
  { label: 'Feminino', value: 'F' },
]
const userTypes = [
  { label: 'Titular', value: 'T' },
  { label: 'Dependente', value: 'D' },
]

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});
export default function ScheduleMessage({ props }) {

  const classes = useStyles()
  const [created, setCreated] = useState(false)
  const [destinySelected, setDestinySelect] = useState('all')
  const [value, setValue] = useState('')
  const [ageStart, setAgeStart] = useState('')
  const [ageEnd, setAgeEnd] = useState('')
  const [citys, setCitys] = useState([])
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.messageScheduled);
    };

    fetchPermissions();
  }, []);

  const [date, onChangeDate] = useState(new Date())
  const [hora, onChangeHour] = useState(new Date().setHours('00', '00'));

  function handleReset() {
    setValue('')
    setAgeStart('')
    setAgeEnd('')
    setDestinySelect('all')
    onChangeDate(new Date())
    onChangeHour(new Date().setHours('00', '00'))
  }
  useEffect(() => {
    const fetchData = async () => {
      let cidades = await getCidades()
      setCitys(cidades)
    }
    fetchData()
  }, [])

  useEffect(() => {
    switch (destinySelected) {
      case 'gender':
        setValue('M')
        break;
      case 'type':
        setValue('T')
        break;
      default:
        setValue('')
        break;
    }
  }, [destinySelected])

  function selectActions(destiny) {
    switch (destiny) {
      case 'gender':
        return (
          <Grid item md={8} xs={12} >
            <TextField
              select
              fullWidth
              margin='normal'
              value={value}
              defaultValue={gender[0]}
              style={{
                backgroundColor: color.white,
              }}
              onChange={(e, value) => {
                setValue(e.target.value)
              }}
              variant="outlined"
            >
              {gender.map((option) => (
                <MenuItem

                  style={{
                    backgroundColor: color.white,
                  }}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )
      case 'city':
        return (
          <Grid item md={8} xs={12} >
            <Autocomplete
              id="citys-auto-complete"
              options={citys}
              classes={{
                popper: classes.option
              }}
              onChange={(event, value) => setValue(value.cidade)}
              getOptionLabel={(option) => option.cidade}
              renderInput={
                (params) => <TextField {...params}
                  fullWidth
                  style={{
                    backgroundColor: color.white,
                  }}
                  label="Cidades"
                  variant="outlined"
                />}
            />
          </Grid>
        )
      case 'type':
        return (
          <Grid item md={8} xs={12} >
            <TextField
              select
              fullWidth
              value={value}
              defaultValue={userTypes[0]}
              style={{
                backgroundColor: color.white,
              }}
              onChange={(e, value) => {
                setValue(e.target.value)
              }}
              variant="outlined"
            >
              {userTypes.map((option) => (
                <MenuItem

                  style={{
                    backgroundColor: color.white,
                  }}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )
      case 'age':
        return (
          <>
            <Grid item md={8} xs={12} >
              <Typography variant={'h4'}>Intervalo</Typography>
            </Grid>
            <Grid item md={8} xs={12} >
              <TextField
                fullWidth
                label={'Inicio'}
                type={'number'}
                value={ageStart}
                style={{
                  backgroundColor: color.white,
                }}
                onChange={(e, value) => {
                  setAgeStart(e.target.value)
                }}
                variant="outlined"
              >

              </TextField>
            </Grid>
            <Grid item md={8} xs={12} >
              <TextField
                fullWidth
                label={'Fim'}
                type={'number'}
                error={Boolean((ageEnd && Number(ageStart) > Number(ageEnd)))}
                helperText={Boolean((ageEnd && Number(ageStart) > Number(ageEnd))) ? 'Idade inválida' : undefined}
                value={ageEnd}
                style={{
                  backgroundColor: color.white,
                }}
                onChange={(e, value) => {
                  setAgeEnd(e.target.value)
                }}
                variant="outlined"
              >

              </TextField>
            </Grid>
          </>
        )
      default:
        return (<> </>)
    }
  }
  return (
    newPermission.edit ? (
      <>
        <Collapse in={created} style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          zIndex: 99999
        }}>
          <Alert
            severity={'success'}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setCreated(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Mensagem agendada com sucesso
          </Alert>
        </Collapse>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="md" >

            <Formik
              initialValues={{
                title: '',
                description: '',
                data: '',
                hora: ''
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string()
                  .required('Campo obrigatório'),
                description: Yup.string()
                  .required('Campo obrigatório'),
                data: Yup.string()
                  .required('Campo obrigatório'),
                hora: Yup.string()
                  .required('Campo obrigatório'),
              })}
              onSubmit={async (values) => {
                try {
                  await newMessageScheduled({
                    ...values,
                    params: destinySelected,
                    value,
                    start: ageStart,
                    end: ageEnd
                  })
                  handleReset()
                  setCreated(true)
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                handleReset,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <Card style={{ backgroundColor: 'white', paddingTop: 30 }}>

                  <CardContent >


                    < Grid container spacing={3} justify={'center'} >
                      <Grid item md={8} xs={12} >
                        <Typography variant={'h4'}>Destinatário</Typography>
                      </Grid>

                      <Grid item md={8} xs={12} >
                        <TextField
                          select
                          disabled={!newPermission.edit}
                          fullWidth
                          value={destinySelected}
                          defaultValue={destiny[0]}
                          onChange={(e, value) => {
                            setDestinySelect(e.target.value)
                          }}
                          variant="outlined"
                        >
                          {destiny.map((option) => (
                            <MenuItem
                              style={{
                                backgroundColor: color.white,
                              }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {selectActions(destinySelected)}

                      <Grid item md={8} xs={12} >
                        <Typography variant={'p'} component='h4'>Nova Mensagem</Typography>
                      </Grid>
                      <Grid item md={8} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                          name="title"
                          disabled={!newPermission.edit}
                          label="Título para mensagem"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.title}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={8} xs={12}>
                        <TextField
                          fullWidth
                          disabled={!newPermission.edit}
                          error={Boolean(touched.description && errors.description)}
                          helperText={touched.description && errors.description}
                          name="description"
                          label="Mensagem"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item md={8} xs={12}>
                        <Typography variant='h4' >Dia e Hora</Typography>
                      </Grid>

                      <Grid item md={8} xs={12}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                            <DatePicker
                              fullWidth
                              margin="normal"
                              id="date-picker-dialog"
                              label="Dia"
                              format="dd/MM/yyyy"
                              disabled={!newPermission.edit}
                              autoOk
                              value={date}
                              onChange={(newValue) => {
                                onChangeDate(newValue)
                                setFieldValue('data', newValue)
                              }}
                              inputVariant={'outlined'}
                              variant='inline'
                              minDate={new Date()}
                            />

                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>

                      <Grid item md={8} xs={12}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                            <TimePicker
                              fullWidth
                              id="time-picker-init"
                              label="Hora"
                              margin={'normal'}
                              autoOk
                              minutesStep={5}
                              disabled={!newPermission.edit}
                              hideTabs
                              minDate={new Date()}
                              ampm={false}
                              value={hora}
                              onChange={(date) => {
                                const hora = new Date(date)
                                onChangeHour(date)
                                setFieldValue('hora', `${hora.getHours()}:${hora.getMinutes() <= 9 ? '0' + hora.getMinutes() : hora.getMinutes()}`)
                              }}
                              variant='inline'
                              inputVariant={'outlined'}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                      {newPermission.edit && (
                        <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>
                          <Button
                            onClick={() => {
                              handleSubmit()
                              handleReset()
                            }}
                            className={classes.submitBtn}
                            disabled={isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Enviar
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              )}
            </Formik>
          </Container>
        </Box>
      </>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}