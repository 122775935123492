import React, { useState, useEffect } from 'react';

import List from './list'
import NewUnity from './new'
import EditUnity from './edit'

export default function Unidade(props) {

  const [state, setState] = useState(0)
  const [unityToEdit, setUnityToEdit] = useState()

  return (
    <>
      {state === 0 && (
        <List setState={setState} setUnityToEdit={setUnityToEdit} />
      )}
      {state === 1 && (
        <NewUnity setState={setState} />
      )}
      {state === 2 && (
        <EditUnity setState={setState} unidade={unityToEdit} />
      )}
    </>
  )
}