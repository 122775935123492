import React, { useState, useEffect } from 'react';

import List from './list'
import NewProcedimento from './new'
import EditProcedimento from './edit'

export default function Profissional(props) {

  const [state, setState] = useState(0)
  const [procedimentoToEdit, setProcedimentoToEdit] = useState({})

  return (
    <>
      {state === 0 && (
        <List setState={setState} setProcedimentoToEdit={setProcedimentoToEdit} />
      )}
      {state === 1 && (
        <NewProcedimento setState={setState} />
      )}
      {state === 2 && (
        <EditProcedimento setState={setState} data={procedimentoToEdit} />
      )}
    </>
  )



}