import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  CircularProgress,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { editPartner } from '../../../../providers/partner'
import { findAllCategorys } from '../../../../providers/partner'
import { findAllTags, findConfig, editConfig } from '../../../../providers/partner';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  btns: {
    marginTop: 15
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  option: {
    backgroundColor: 'white'
  }
}));

export default function EditParceiro({ partner, setState }) {
  const classes = useStyles()
  const [categoryList, setCategoryList] = useState([])
  const [tagList, setTagList] = useState([]);
  const [categoriesValues, setCategoriesValues] = useState([])
  const [tagsValues, setTagsValues] = useState([]);
  const [configPartner, setConfigPartner] = useState([]);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    visible: partner.visible,
    name: partner.name || '',
    description: partner.description || '',
    note: partner.note || '',
    logo: null,
    categories: partner.categories,
    tags: partner.tags,
    priority: partner.priority || '',
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partner);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { configPartner } = await findConfig();
      let data = await findAllCategorys();
      setCategoryList(data);
      setCategoriesValues(partner.categories);
      data = await findAllTags();
      setTagList(data);
      setTagsValues(partner.tags);


    }
    setConfigPartner(configPartner);
    fetchData();
  }, [])

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          visible: partner.visible,
          name: partner.name || '',
          description: partner.description || '',
          note: partner.note || '',
          logo: null,
          categories: partner.categories,
          tags: partner.tags,
          priority: partner.priority || '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
          note: Yup.string()
            .required('Campo obrigatório'),
          categories: Yup.array()
            .test('categories', 'Selecione ao menos uma categoria', value => !!value.length),
          priority: Yup.string()
            .required('Campo obrigatório'),
        })}
        onSubmit={async (values, actions) => {
          try {

            if (values.logo) {
              const body = new FormData()
              body.append('file', values.logo)
              body.append('folder', UploadFolder.PARTNER)
              const imgData = await apiUpload(body);
              values.logo = imgData.location

            } else {
              delete values.logo
            }
            values.id = partner.id
            const data = await editPartner(values)
            const afterValues = {
              visible: values.visible,
              name: values.name || '',
              description: values.description || '',
              note: values.note || '',
              logo: null,
              categories: values.categories,
              tags: values.tags,
              priority: values.priority || '',
              id: values.id
            }
            await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: partner.id }, after: afterValues, menu: "Parceiros", subMenu: "Parceiros" });
          } catch (e) {
            console.log(e)
          } finally {
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <FormControlLabel
                      value="start"
                      name="visible"
                      control={<Checkbox
                        checked={values.visible}
                        name="visible"
                        color="primary"
                        onChange={handleChange}
                      />}
                      label="Visível"
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.priority && errors.priority)}
                      helperText={touched.priority && errors.priority}
                      label="Prioridade"
                      name="priority"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priority}
                      variant="outlined"
                      type='number'
                      inputProps={{
                        max: 9999,
                        min: 1
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Nome"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Autocomplete
                      multiple
                      fullWidth
                      options={categoryList}
                      value={categoriesValues}
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      onChange={(e, value) => {
                        setCategoriesValues(value)
                        setFieldValue('categories', value)
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(x, u) => {
                        return x.id === u.id
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params}
                          variant="outlined"
                          label="Categorias"
                          error={Boolean(touched.categories && errors.categories)}
                          helperText={touched.categories && errors.categories}
                          name="categories"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      multiple
                      fullWidth
                      options={tagList}
                      value={tagsValues}
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      onChange={(e, value) => {
                        setTagsValues(value)
                        setFieldValue('tags', value)
                      }}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(x, u) => {
                        return x.id === u.id
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.name}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField {...params}
                          variant="outlined"
                          label="Tags"
                          error={Boolean(touched.tags && errors.tags)}
                          helperText={touched.tags && errors.tags}
                          name="tags"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label="Descrição"
                      name="description"
                      multiline
                      InputProps={{ classes: { input: classes.textArea } }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                      label="Observação"
                      name="note"
                      multiline
                      InputProps={{ classes: { input: classes.textArea } }}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  {<Grid item xs={8}>
                    <img src={values.logo ? URL.createObjectURL(values.logo) : partner.logo} style={{ maxHeight: 630, maxWidth: 630, borderRadius: 4, objectFit: 'contain' }} />
                    <input
                      accept="image/*"
                      className={classes.inputFile}
                      name='logo'
                      id="logo-input"
                      type="file"
                      onChange={event => setFieldValue('logo', event.target.files[0])}
                    />
                    <label htmlFor="logo-input">
                      <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                        Upload
                      </Button>
                    </label>
                    {Boolean(touched.logo && errors.logo) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                      *{touched.logo && errors.logo}
                    </Typography>}
                  </Grid>}
                </Grid>
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      size="large"
                      color='secondary'
                      variant="contained"
                      onClick={() => {
                        setConfigPartner(configPartner)
                        setState(0)
                      }}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  {newPermission.edit && (
                    <Grid item md={3} xs={12}>
                      <Button
                        fullWidth
                        color='primary'
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}