import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Grid,
  Popover,
  TextField,
  Modal,
  Typography,
  MenuItem,
  Slider,
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon,
  ChevronRight,
  ExpandMore,
  LocalPrintshop,
} from '@material-ui/icons';
import { color } from '../../../../components/common/colors';
import { findAll, deletePartner, findAllCategorys } from '../../../../providers/partner'
import { Autocomplete, TreeItem, TreeView } from '@material-ui/lab';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  celTableHeaderActions: {
    width: 96,
    backgroundColor: color.white,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '90%'
  },
  option: {
    backgroundColor: 'white',
  },
}));

const IsVisible = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
]

function valueTextPrioridade(value) {
  return `${value}`;
}

export default function ListParceiros({ setState, setPartnerToEdit }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [partnerList, setPartnerList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [categories, setCategories] = useState([])
  const [checkPartnerCat, setPartnerCat] = useState([])
  const [isVisible, setVisible] = useState([])
  const [prioridade, setPrioridade] = useState([1, 1000]);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partner);
    };

    fetchPermissions();
  }, []);


  useEffect(async () => {
    const data = await findAll()
    const allCategories = await findAllCategorys()
    if (orderAlf === 0) {
      data.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
    }
    setCategories(allCategories)
    setPartnerList(data)
    setUsersToShow(data)
  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(partnerList.filter(p => p.name.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(partnerList)
      setPage(0)
    }
  }
  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    // if (!search) {
    //   setPartnerList(partnerList)
    //   setUsersToShow(partnerList)
    // } else {
    //   setUsersToShow(userToShow)
    // }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (partner) => {
    setPartnerToEdit(partner)
    setState(2)
  }

  const handleDelete = async id => {
    try {
      await deletePartner(id)
      setPartnerList(partnerList.filter(item => item.id != id))
      setUsersToShow(userToShow.filter(item => item.id != id))
    } catch (e) {
      console.log(e)
    }
  }

  const handlePrioridade = (event, newValue) => {
    setPrioridade(newValue);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setUsersToShow(partnerList)
    setPage(0)
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getVisible = type => {
    if (type === true) {
      return 'Sim'
    } else {
      return 'Não'
    }
  }

  const filterByItem = async () => {
    let filterAux = partnerList
    if (isVisible.length > 0) {
      const visivel = isVisible.map(a => a.value)
      const visibleFilter = filterAux.filter(p => {
        return visivel.some(t => t === p.visible)
      })
      filterAux = visibleFilter
      setUsersToShow(filterAux)
    }
    if (checkPartnerCat.length > 0) {
      const partner = checkPartnerCat.map(a => a.name)
      const partnerCat = filterAux.filter(p => p.categories.some(c => {
        return partner.some(p => p === c.name)
      }))
      filterAux = partnerCat
      setUsersToShow(filterAux)
    }
    filterAux = filterAux.filter(p => p.priority >= prioridade[0] && p.priority <= prioridade[1])
    if (checkPartnerCat.length === 0) {
      setUsersToShow(filterAux)
    }
    handleCloseModal(true)
    setVisible([])
    setPartnerCat([])
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Nome",
      "Categorias",
      "Nivel de prioridade",
      "Visivel",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.name,
          `${row.categories.map(p => p.name).join(', ')}`,
          row.priority,
          getVisible(row.visible),
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="button"
                variant="contained"
                onClick={handleOpenModal}
                style={{ padding: '15px 40px' }}
              >
                Filtrar
              </Button>
            </Grid>
            <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
              <Button
                variant='contained'
                className={classes.btnAdd}
                onClick={() => setState(1)}
                style={{ padding: '16px 35px' }}
              >
                Adicionar Parceiro
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Paper className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.headerTb}>
                <TableRow>
                  <TableCell key={'name'} className={classes.celTableHeader}>
                    {"Nome"}
                  </TableCell>
                  <TableCell key={'categories'} className={classes.celTableHeader}>
                    {"Categorias"}
                  </TableCell>
                  <TableCell key={'priority'} className={classes.celTableHeader}>
                    {"Nivel de prioridade"}
                  </TableCell>
                  <TableCell key={'visible'} className={classes.celTableHeader}>
                    {"Visivel"}
                  </TableCell>
                  <TableCell key={'actions'} align="right" className={classes.celTableHeaderActions}>
                    {"Ações"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => console.log(event)}
                        key={row.id}
                      >
                        <TableCell >{row.name}</TableCell>
                        <TableCell >
                          <TreeView
                            defaultCollapseIcon={<ExpandMore />}
                            defaultExpandIcon={<ChevronRight />}
                          >
                            <TreeItem nodeId="1" label="Ver mais..." >
                              <TreeItem nodeId="2" label={row.categories.map(p => p.name).join(', ')} />
                            </TreeItem>
                          </TreeView>
                        </TableCell>
                        <TableCell >{row.priority}</TableCell>
                        <TableCell >{getVisible(row.visible)}</TableCell>
                        <TableCell padding='none' align="right" >
                          {newPermission.det && (
                            <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                          )}
                          {/* {newPermission.edit && (
                            <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={3} >
            <Grid item md={6} xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: 'center'
              }} >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => createPDF()}
                startIcon={<LocalPrintshop />}
              >
                Imprimir
              </Button>
            </Grid>
            <Grid item md={6} xs={12} style={{}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={'Linhas por página'}
                component="div"
                count={userToShow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          keepMounted
        >
          <Container maxWidth='md' spacing={3}
            style={{
              backgroundColor: 'white',
              height: '100%',
              overflow: 'scroll',
              padding: 30,
              margin: '0% 10%',
              borderRadius: 4,
              border: '1px solid #000000',
              top: 100
            }}
          >
            <Grid container spacing={6}
              style={{
                padding: 10,
              }}
            >
              <Grid item md={12} xs={12}>
                <Typography variant="h3" display="block" style={{ marginTop: 10 }}>
                  Selecionar Filtros
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Visivel
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={isVisible}
                      options={IsVisible}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setVisible(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Categoria
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={checkPartnerCat}
                      options={categories}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        setPartnerCat(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} >
                <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                  Prioridade
                </Typography>
                <Slider
                  min={1}
                  max={10000}
                  value={prioridade}
                  onChange={handlePrioridade}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={valueTextPrioridade}
                />
              </Grid>
              <Grid container spacing={3}
                style={{ marginTop: 30, textAlign: "center", placeContent: 'center' }}
              >
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={filterByItem}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Aplicar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={handleCloseModal}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Modal>
      </Grid>
    )  : (
      <>
        Sem permissão
      </>
    )
  )
}