import api from './api'

export const create = async noticia => {
  const { data } = await api.post('noticia', { noticia })
  return data
}

export const getAll = async () => {
    const { data } = await api.get('noticia')
    return data
}

export const update = async noticia => {
  const { data } = await api.post(`noticia/${noticia.id}`, { noticia })
  return data
}

export const deleteNew = async id => {
  const { data } = await api.delete(`noticia/${id}`)
  return data
}