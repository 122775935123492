const EstadoCivil = [
  { label: 'Solteiro(a)', value: 'SOLTEIRO' },
  { label: 'Casado(a)', value: 'CASADO' },
  { label: 'Divorciado(a)', value: 'DIVORCIADO' },
  { label: 'Viúvo(a)', value: 'VIUVO' },
]

const Status = [
  { label: 'Aguardando', value: 'AGUARDANDO' },
  { label: 'Aceito', value: 'ACEITO' },
  { label: 'Recusado', value: 'RECUSADO' },
]

const Tipo = [
  { label: 'Titular', value: 'TITULAR' },
  { label: 'Filho(a)', value: 'FILHO' },
  { label: 'Enteado(a)', value: 'ENTEADO' },
  { label: 'Cônjuge', value: 'CONJUGE' },
  { label: 'Companheiro(a)', value: 'COMPANHEIRO' },
];

const Sexo = [
  { label: 'Masculino', value: 'MASCULINO' },
  { label: 'Feminino', value: 'FEMININO' },
  { label: 'Outro', value: 'OUTRO' },
  { label: 'Não informado', value: '-' },

]

const SituacaoOab = [
  { label: 'Regular', value: 'REGULAR' },
  { label: 'Irregular', value: 'IRREGULAR' },
]

const SituacaoFinanceira = [
  { label: 'Adimplente', value: 'ADIMPLENTE' },
  { label: 'Inadimplente', value: 'INADIMPLENTE' },
]

const Situacao = [
  { label: 'Ativo', value: 'ATIVO' },
  { label: 'Inativo', value: 'INATIVO' },
]

const Bloqueio = [
  { label: 'Bloqueado', value: 'BLOCKED'},
  { label: 'Desbloqueado', value: 'UNLOCKED'}
]
export {
  Tipo,
  Status,
  EstadoCivil,
  Sexo,
  SituacaoOab,
  SituacaoFinanceira,
  Situacao,
  Bloqueio
}