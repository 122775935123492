import api from './api'

export const create = async avaliation => {
  const { data } = await api.post('partner-avaliation', { avaliation })
  return data
}

export const getAll = async () => {
    const { data } = await api.get('partner-avaliation')
    return data
}

export const update = async avaliation => {
  const { data } = await api.post(`partner-avaliation/${avaliation.id}`, { avaliation })
  return data
}

export const deleteAvaliation = async id => {
  const { data } = await api.delete(`partner-avaliation/${id}`)
  return data
}