import api from './api'

export const create = async group => {
  const { data } = await api.post('adm/groups/user', { group })
  return data
}

export const getAll = async () => {
  const { data } = await api.get('adm/groups/user')
  return data
}

export const update = async group => {
  const { data } = await api.post(`adm/groups/user/${group.id}`, { group })
  return data
}

export const deleteGroup = async id => {
  const { data } = await api.delete(`adm/groups/user/${id}`)
  return data
}
