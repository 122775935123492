import React, { useState } from 'react';
import Page from '../../components/common/page'
import {
  Tab,
  Tabs,
  makeStyles,
  Box,
  Paper,
} from '@material-ui/core';
import { color } from '../../components/common/colors';
import LogsList from './list'
import ScheduleLog from './schedule/index';
import UserLog from './users/index'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
export default function Messages() {
  const classes = useStyles()
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page className={classes.root}
      title="Mensagens"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="Log Adm" id={0} />
          <Tab label="Log Usuário" id={1} />
          <Tab label="Log Agendamentos" id={2} />
        </Tabs>
      </Paper>

      <TabPanel value={tab} index={0}>
        <LogsList />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <UserLog />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <ScheduleLog />
      </TabPanel>
    </Page>
  )
}