import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  Divider,
  TextField,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  item: {
    color: '#979797',
    fontWeight: 'bold',
    letterSpacing: 1.5
  }
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      decimalSeparator={','}
      thousandSeparator={'.'}
      isNumericString
      prefix=""
    />
  );
}
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function DetachedPayment({ setState, setTypePayment, setDataPayment }) {
  const classes = useStyles()

  const handlePayment = (dataPayment) => {
    setTypePayment("AVULSO")
    setDataPayment(dataPayment)
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          valor: '',
          descrição: '',
          identificador: null
        }}
        onSubmit={async (values) => {
          if (parseFloat(values.valor) === 0 || parseFloat(values.valor) < 0 || values.valor === '') {
            alert("Valor não pode ser 0!")
          } else {
            try {
              setDataPayment(values)
              setTypePayment("AVULSO")
              setState(5)
            } catch (error) {
              console.log(error)
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldError,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Typography style={{ marginBottom: 40 }}>
                      <h1>
                        Pagamento Avulsto
                      </h1>
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid container spacing={6}>
                      <Grid item md={12} xs={12} style={{ marginBottom: -5 }}>
                        <TextField
                          fullWidth
                          label="Valor a ser creditado"
                          name="valor"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.valor}
                          variant="outlined"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Grid>
                      <Grid item md={12} xs={12} style={{ marginBottom: -5 }}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          name="descricao"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.descricao}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Grid container spacing={3} style={{ marginTop: 40 }} alignItems='flex-end' justify='flex-end'>
                      <Grid item md={3}>
                        <Button
                          fullWidth
                          color='primary'
                          size="large"
                          variant="contained"
                          onClick={() => setState(1)}
                        >
                          Voltar
                        </Button>
                      </Grid>
                      <Grid item md={3}>
                        <Button
                          fullWidth
                          color='primary'
                          size="large"
                          variant="contained"
                          type='submit'
                        >
                          Enviar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}