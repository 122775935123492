import { combineReducers } from 'redux';

import userReducer from './userReducer';
import colaboradorEditReducer from './colaboradorEditReducer'

const rootReducer = combineReducers({
	user: userReducer,
	colaboradorEdit: colaboradorEditReducer
});

export default rootReducer;