import React, { useState, useEffect } from 'react';

import ListExtra from './list'
import NewExtra from './new'
import EditIndisponible from './edit'

export default function Extra(props) {

  const [state, setState] = useState(0)
  const [idExtraToEdit, setExtraToEdit] = useState()

  return (
    <>
      {state === 0 && (
        <ListExtra setState={setState} setExtraToEdit={setExtraToEdit} />
      )}
      {state === 1 && (
        <NewExtra setState={setState} />
      )}
      {state === 2 && (
        <EditIndisponible setState={setState} extra={idExtraToEdit} />
      )}
    </>
  )
}