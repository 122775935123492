import React, { useEffect, useState, width } from 'react';
import {
  Button,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Collapse,
  TextField,
  Grid,
  Popover,
  MuiThemeProvider,
  createMuiTheme,
  Snackbar
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  ChevronRight,
  ExpandMore,
  LocalPrintshop,
} from '@material-ui/icons'
import { getAllExtraHour, deleteExtraHour, checkExtraUsed } from '../../../../providers/schedules';
import { Alert, TreeItem, TreeView } from '@material-ui/lab';
import jsPDF from "jspdf";
import "jspdf-autotable";
import format from 'date-fns/format';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { id } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  tableContainer: {
    backgroundColor: color.white,
  },
  card: {
    background: color.white,
    maxWidth: '50vw'
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  listText: {
    fontSize: 14
  },
  option: {
    backgroundColor: 'white'
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function ListExtra({ setState, setExtraToEdit }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')
  const [list, setList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
  const [blockAlert, setBlockAlert] = useState(false);


  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleExtra);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    async function fechData() {
      const data = await getAllExtraHour()
      // if (orderAlf === 0) {
      //   data.sort((a, b) => {
      //     return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
      //   });

        setList(data)
        setUsersToShow(data)
      // }
    }
    fechData()
  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(list.filter(p => p.nome.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(list)
      setPage(0)
    }
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
       userToShow.sort((a, b) => {
         return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
       });
       setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.nome > b.nome) ? -1 : ((b.nome > a.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleCloseBlockAlert = (event, reason) => {
    setBlockAlert(false);
  }
  async function handleEdit(data) {
    const edit = { dataInit: data.dataInit, id: data.id, professionals: data.professionals.map((e) => e.id) }

    const temp = await checkExtraUsed(edit)
    if (!!temp) {
      setBlockAlert(true);
    } else {
      setExtraToEdit(data)
      setState(2)
    }
  }
  const handleDelete = async id => {
    try {
      const result = await deleteExtraHour(id)
      setList(list.filter(item => item.id != id))
      setUsersToShow(userToShow.filter(item => item.id != id)) //atualiza a lista quando deleta um item
    } catch (error) {
      setMessage('Error ao deletar unidade')
      setSeverity('error')
    }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Nome",
      "Profissional/Recurso",
      "Data Inicial",
      "Horário Inicial",
      "Horário Final",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.nome,
          `${row.professionals.map(p => p.nome).join(', ')}`,
          format(new Date(row.dataInit), 'dd/MM/yyyy'),
          /*format(new Date(*/row.horaInit/*), 'HH:mm')*/,
          /*format(new Date(*/row.horaFim/*), 'HH:mm')*/,
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "center",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    <>
      {newPermission.viz ? (
        <>
          <Box my={2} display="flex" justifyContent="flex-end">
            <Grid container spacing={3}>
              <Grid item md={4} xs={12} >
                <TextField
                  fullWidth
                  label="Nome"
                  name="filter"
                  variant="outlined"
                  value={search}
                  onChange={handleChangeFilter}
                  style={{ backgroundColor: 'white' }}
                />
              </Grid>
              <Grid item md={2} xs={12}>
                <Button
                  color='primary'
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => handleFilter()}
                  style={{ padding: '15px 40px' }}
                >
                  Buscar
                </Button>
              </Grid>
              <Grid item md={2} xs={12} >
                <Button
                  color='primary'
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={OpenOrder}
                  style={{ padding: '15px 40px' }}
                >
                  Ordenar
                </Button>
              </Grid>
              <Grid item md={4} xs={12} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => setState(1)}
                  className={classes.submitBtn}
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ padding: '15px 40px' }}
                >
                  Nova Agenda Extra
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Collapse in={message} style={{
            position: 'absolute',
            bottom: 20,
            left: 20,
            zIndex: 99999
          }}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessage(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          </Collapse>
          <Paper className={classes.paper}>
            <MuiThemeProvider theme={defaultMaterialTheme}>
              <TableContainer className={classes.tableContainer}>
                <Table size={'small'} className={classes.table}>
                  <TableHead className={classes.headerTb}>
                    <TableRow>

                      <TableCell key={'nome'} className={classes.celTableHeader}>
                        {"Nome"}
                      </TableCell>
                      <TableCell key={'profissional'} className={classes.celTableHeader}>
                        {"Profissional/Recurso"}
                      </TableCell>
                      <TableCell key={'city'} className={classes.celTableHeader}>
                        {"Data Inicial"}
                      </TableCell>
                      <TableCell key={'hour'} className={classes.celTableHeader}>
                        {"Horário Inicial"}
                      </TableCell>
                      <TableCell key={'hour'} className={classes.celTableHeader}>
                        {"Horário Final"}
                      </TableCell>
                      <TableCell key={'actions'} align={'right'} className={classes.celTableHeader}  >
                        {"Ações"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            key={index}
                          >
                            <TableCell>{row.nome}</TableCell>
                            <TableCell>
                              <TreeView
                                defaultCollapseIcon={<ExpandMore />}
                                defaultExpandIcon={<ChevronRight />}
                              >
                                <TreeItem nodeId="1" label="Ver mais..." >
                                  {/* <TreeItem nodeId="2" label={row.professionals.map(p => p.nome).join(', ')} /> */}
                                  {row.professionals.map((p, i) => {
                                    return (
                                      <TreeItem nodeId={i + 2} label={p.nome} />
                                    );
                                  })}
                                </TreeItem>
                              </TreeView>
                            </TableCell>
                            <TableCell>{format(new Date(row.dataInit), 'dd/MM/yyyy')}</TableCell>
                            <TableCell>{/*format(new Date(*/row.horaInit/*), 'HH:mm')*/}</TableCell>
                            <TableCell>{/*format(new Date(*/row.horaFim/*), 'HH:mm')*/}</TableCell>
                            <TableCell padding='none' align={'right'} style={{ minWidth: 150 }}>
                              {newPermission.det && (
                                <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                              )}
                              {/* {newPermission.edit && (
                              <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                            )} */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={12} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={3} >
                <Grid item md={6} xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignSelf: 'center'
                  }} >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<LocalPrintshop />}
                    onClick={() => createPDF()}
                  >
                    Imprimir
                  </Button>
                </Grid>
                <Grid item md={6} xs={12} style={{}}>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={'Linhas por página'}
                    component="div"
                    count={userToShow.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </MuiThemeProvider >
          </Paper>
          <Box className={classes.footer} />
          <Popover
            id={'orderBy'}
            open={open}
            anchorEl={anchorEl}
            onClose={CloseOrder}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
              <Grid>
                <Button
                  color='white'
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{ width: 130, color: '#A93533' }}
                  onClick={() => OrderByName()}
                >
                  Nome
                </Button>
              </Grid>
            </Grid>
          </Popover>
        </>
      ) : (
        <>
          Você não tem permissão para acessar esta página.
        </>
      )}
      <Snackbar
        open={blockAlert}
        autoHideDuration={4000}
        onClose={handleCloseBlockAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleCloseBlockAlert} severity="error">
          Agenda extra com horarios utilizados.
        </Alert>
      </Snackbar>
    </>
  )
}