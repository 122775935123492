import { add, startOfDay } from "date-fns";
import { DateTime, Interval } from "luxon"
import { Settings } from "luxon";

// Configure the time zone
Settings.defaultZoneName = "America/Sao_Paulo";
const timeZone = "America/Sao_Paulo"
const todayHour = DateTime.local().setZone("America/Sao_Paulo").toISO() // full
const todayDate = DateTime.local().setZone("America/Sao_Paulo").toISODate() //Data only

export {
    todayHour,
    todayDate
}
export function formatDate(date) {
    const localDate = add(new Date(date), {minutes: new Date().getTimezoneOffset()});
    return startOfDay(localDate);
}
export function formatDateUser(value){  
    const data =  DateTime.fromISO(value).setZone(timeZone).toFormat('dd/MM/yyyy')
    return data
}
export function formatHourUser(value) {
    const data = DateTime.fromISO(value).toFormat('HH:mm')
    return data
}
export function formatDateApi(value) {
    const data =  DateTime.fromISO(value).toFormat('yyyy-MM-dd HH:mm:ss')
    return data
}
export function startOfTheDay(value){
    return DateTime.fromISO(value).startOf('day')
}
export function endOfTheDay(value){
    return DateTime.fromISO(value).endOf('day')
}
export function endOfTheDayFormat(value){
    return DateTime.fromISO(value).endOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
}
export function startOfTheDayFormat(value){
    return DateTime.fromISO(value).startOf('day').toFormat('yyyy-MM-dd HH:mm:ss')
}
export function dateIsWithRange(startDate, endDate, datePicked){
    const interval = Interval.fromDateTimes(DateTime.local(startDate), DateTime.local(endDate));
    return(interval.contains(datePicked));
}

