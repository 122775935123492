import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Collapse, IconButton, makeStyles, Box, Container, Card, CardContent, Grid, createMuiTheme, MuiThemeProvider, Divider, Avatar } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputCpf from '../../../../components/common/inputCpf'
import { Autocomplete, Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { getUserByCpf, findToSchedule, searchUsers } from '../../../../providers/user';
import { newSchedule } from '../../../../providers/schedules';
import { add, differenceInDays } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardReturnRounded } from '@material-ui/icons';
import { capitalize } from 'lodash'
import UserToShow from '../UsersToShow';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  option: {
    backgroundColor: 'white',
  },
  textArea: {
    minHeight: 100
  },
  Avatar: {
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
  userCard: {
    cursor: 'pointer',
    padding: '10px 0px 10px 0px',
    '&:hover': {
      boxShadow: '0px 0px 0px 1px #B22222',
      borderRadius: 4,
      padding: 15,
      backgroundColor: '#F8F8FF'
    },
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function NewUserToBlock({ setUserToBlock, setState, userToBlock, setSelectedUserToBlock }) {
  const classes = useStyles()
  // const [state, setState] = useState(0)
  const [user, setUser] = useState([])
  const [error, setError] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.bloqueio);
    };

    fetchPermissions();
  }, []);



  async function getUserToSchedule(value) {
    try {
      setError(false)
      let data = await findToSchedule(value)
      setUser(data)
    } catch (error) {
      setError('Usuário não encontrado')
    }
  }

  const restingDays = (usuario) => {
    if (usuario.datePunished) {
      const diff = differenceInDays(add(new Date(usuario.datePunished), { days: usuario.punished == 'FIRST' ? 30 : 30 }), new Date())
      return diff
    }
  }

  const requestSchedule = (u) => {
    setSelectedUserToBlock(u);
    setUserToBlock([u])
    setState(2)
  }

  const getPunished = (punished) => {
    switch (punished) {
      case 'NONE':
        return 'Sem bloqueio';
      case 'FIRST':
        return 'Bloqueado';
      default:
        return 'Sem bloqueio ';
    }
  }

 

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"

    >
      <Collapse in={error} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            search: "",
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
              <CardContent>
                <Grid container justify={'center'} spacing={2}>
                  {true && (
                    <>
                      <Grid item md={12} xs={12}>
                        <Typography variant='h4' >Confirme o usuário </Typography>
                      </Grid>
                      <Grid container spacing={3} justify={'center'} alignItems={'center'}
                        style={{ marginBottom: 20 }}>
                        <Grid item md={6} xs={10} >
                          <TextField
                            fullWidth
                            label="OAB"
                            id={'search'}
                            name={'search'}
                            defaultValue={userToBlock.oab}
                            margin={'normal'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   handleChangeCpf(unmaskCpf(e.target.value), setFieldValue)
                            // }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={1} >
                          {newPermission.det && (
                            <Button
                              onClick={() => { getUserToSchedule(values.search) }}
                              className={classes.submitBtn}
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {'Buscar'}
                            </Button>
                          )}
                        </Grid>
                        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
                      </Grid>

                      {user && user.length > 0 && user.map(u => {
                        return (
                          <>
                            <Grid
                              container
                              alignContent={'center'}
                              className={/*u.punished != 'NONE' || u.situacao != 'ATIVO' ? '' :*/ classes.userCard}
                              onClick={/*u.punished != 'NONE' || u.situacao != 'ATIVO' ? '' :*/ () => {
                                if (newPermission.det) {
                                  requestSchedule(u);
                                }
                              }}
                            >
                              <Grid item md={6} xs={12} >
                                <Grid item xs={12} style={{ margin: '30px 5px 30px 5px' }}>
                                  <Avatar
                                    className={classes.Avatar}
                                    src={u.img ? URL.createObjectURL(u.img) : u.imgProfile}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Grid item md={12} xs={12} style={{ margin: '15px 5px 30px 5px' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    label="Nome"
                                    name="nome"
                                    id='text-nome'
                                    value={u.nome}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true
                                    }}
                                  />
                                </Grid>
                                <Grid item md={12} xs={12} style={{ margin: '30px 5px 30px 5px' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    label="Tipo"
                                    name="tipo"
                                    id='text-tipo'
                                    value={capitalize(u.userType)}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true
                                    }}
                                  />
                                </Grid>
                                <Grid item md={12} xs={12} style={{ margin: '30px 5px 15px 5px' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    label="Bloqueio por não comparecimento"
                                    name="bloqueio"
                                    id='text-bloqueio'
                                    value={getPunished(u.punished)}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true
                                    }}
                                  />
                                  {u.punished != 'NONE' && restingDays(u) > 0 && (
                                    <>
                                      <Grid item md={12} xs={12}>
                                        <Alert
                                          severity={'warning'}
                                        >
                                          {`Bloqueio Ativo: Tempo restante ${restingDays(u)} dias.`}
                                        </Alert>

                                      </Grid>
                                    </>
                                  )}
                                  {u.situacao === 'INATIVO' && (
                                    <>
                                      <Grid item md={12} xs={12}>
                                        <Alert
                                          severity={'warning'}
                                        >
                                          {`Usuário Inativo!`}
                                        </Alert>

                                      </Grid>
                                    </>
                                  )}


                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider style={{ marginTop: 40, marginBottom: 40 }} />
                            </Grid>
                          </>
                        )
                      })}
                    </>
                  )}


                </Grid>
              </CardContent>
            </Card>
          )}
        </Formik>
      </Container>
    </Box >
  )
}