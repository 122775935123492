import React, { useEffect, useState } from 'react';
import Page from '../../components/common/page'
import {
  Tab,
  Tabs,
  makeStyles,
  Box,
  Paper,
} from '@material-ui/core';
import { color } from '../../components/common/colors';

import DirectMessage from './directMessage'
import ScheduleMessage from './scheduleMessage'
import AutomaticMessage from './automaticMessage'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}
export default function Messages({ props }) {
  const classes = useStyles()
  const [tab, setTab] = useState(0);
  
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page className={classes.root}
      title="Mensagens"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="Mensagens" id={0} />
          <Tab label="Mensagens Agendadas" id={0} />
          <Tab label="Mensagens automáticas" id={1} />

        </Tabs>
      </Paper>

      <TabPanel value={tab} index={0}>
        <DirectMessage />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ScheduleMessage />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <AutomaticMessage />
      </TabPanel>
    </Page>
  )
}