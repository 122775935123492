import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import {
  TextField,
  Button,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { updateCategory } from '../../../../providers/schedules';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    marginTop: 30
  },
}));

export default function EditCategory({ setState, category }) {
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    nome: category.nome || '',
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleCategories);
    };

    fetchPermissions();
  }, []);

  const classes = useStyles()
  return (
    <Container maxWidth="md" disableGutters>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" >
          <Formik
            initialValues={{
              nome: category.nome || '',
            }}
            validationSchema={
              Yup.object().shape({
                nome: Yup.string()
                  .required('Campo obrigatório'),
              })
            }
            onSubmit={async (values) => {
              try {
                values.id = category.id
                await updateCategory(values)
                await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: category.id }, after: values, menu: "Agendamentos", subMenu: "Serviço" });
                setState(0)
              } catch (e) {
                console.error("Error Upload Slide Image")
                console.error(e)
                setState(0)
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <Card style={{ backgroundColor: 'white', paddingTop: 30 }}>
                <CardContent >
                  < Grid container spacing={3} justify={'center'} >
                    <Grid item md={8} xs={12} >
                      <Typography variant={'h4'}>Editar categoria</Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Categoria"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={8} xs={12}>

                      <Box display="flex" justifyContent="space-around" style={{ width: '100%' }}>
                        <Button
                          onClick={() => setState(0)}
                          className={classes.submitBtn}
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          voltar
                        </Button>
                        {newPermission.edit && (
                          <Button
                            onClick={() => handleSubmit()}
                            className={classes.submitBtn}
                            disabled={isSubmitting}
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            Cadastrar
                          </Button>
                        )}

                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>
    </Container >
  )
}