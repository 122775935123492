import api from './api'

export const login = async (cpf, password) => {
  const { data } = await api.post('user/adm/login', { cpf, password })
  return data
}

export const getAll = async () => {
  const { data } = await api.get('user/adm')
  return data
}

export const getById = async (id) => {
  const { data } = await api.get(`user/adm/${id}`)
  return data
}

export const newUserAdm = async (user) => {
  const { data } = await api.post('user/adm', { user })
  return data
}

export const editUserAdm = async (user) => {
  const { data } = await api.post(`user/adm/${user.id}`, { user })
  return data
}

export const deleteUserAdm = async (id) => {
  const { data } = await api.delete(`user/adm/${id}`)
  return data
}

export const creditPoints = async body => {
  const { data } = await api.post('user/adm/credit', body);
  return data;
}

export const getAllCreditInfo = async () => {
  const { data } = await api.get('user/adm/creditinfo');
  return data;
}

export const getAllTransactions = async () => {
  const { data } = await api.get('user/adm/transaction');
  return data;
}

export async function searchTransaction(body) {
  const { data } = await api.post(`user/adm/search/transactions`, body)
  return data
}