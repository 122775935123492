import React from "react";
import { Route, Navigate } from "react-router-dom";

import Login from "./pages/login";
import DashboardLayout from "./components/layouts/DashboardLayout";
import MainLayout from "./components/layouts/MainLayout";
import News from "./pages/news";
import Users from "./pages/users";
import Schedule from "./pages/schedule";
import Colaboradores from "./pages/colaboradores";
import NotFoundView from "./components/common/notFound";
import Caassh from "./pages/caassh";
import Parceiros from "./pages/parceiros";
import Messages from "./pages/messages";
import Pagamento from "./pages/Pagamento";
import AlterationLog from "./pages/log";

const getRoute = (permissions) => {
  const [item] = permissions;
  switch (item) {
    case "news":
      return "news";
    case "auth":
      return "colaboradores";
    case "credit":
      return "caassh";
    case "partner":
      return "parceiros";
    case "message":
      return "mensagens";
    case "schedule":
      return "schedules";
    case "user":
      return "users";
    case "log":
      return "logs";
    default:
      return "";
  }
};

const routes = (isLoggedIn, type, permissions) => [
  {
    path: "app",
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: "news", element: <News /> },
      { path: "schedules", element: <Schedule /> },
      { path: "users", element: <Users /> },
      { path: "caassh", element: <Caassh /> },
      { path: "colaboradores", element: <Colaboradores /> },
      { path: "parceiros", element: <Parceiros /> },
      { path: "mensagens", element: <Messages /> },
      { path: "logs", element: <AlterationLog /> },
      { path: "users-partners", element: <Pagamento /> },
      { path: "404", element: <NotFoundView /> },
      { path: "*", element: <Navigate to="/app/404" /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? (
      <MainLayout />
    ) : (
      <Navigate
        to={
          type == "USER"
            ? `/app/${getRoute(permissions)}`
            : "/login"
        }
      />
    ),
    children: [
      { path: "login", element: <Login /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/login" /> },
    ],
  },
];

export default routes;
