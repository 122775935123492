import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  Button,
  Grid,
  TextField,
  Popover,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { format } from 'date-fns';
import { getAllCreditInfo } from '../../../../providers/userAdm';
import { LocalPrintshop } from '@material-ui/icons';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  celTableHeaderActions: {
    width: 96,
    backgroundColor: color.white,
  },
  ellipseComent: {
    width: 50,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ellipseName: {
    width: 50,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function Credito({ setState, SetCreditToCheck }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.caashCredit);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const temp = await getAllCreditInfo();
      if (orderAlf === 0) {
        temp.sort((a, b) => {
          return (a.usuario > b.usuario) ? 1 : ((b.usuario > a.usuario) ? -1 : 0);
        });
      }
      setList(temp);
      setUsersToShow(temp)
    }

    fetchData();
  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(list.filter(p => p.usuario.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(list)
      setPage(0)
    }
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.usuario > b.usuario) ? 1 : ((b.usuario > a.usuario) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.usuario > b.usuario) ? -1 : ((b.usuario > a.usuario) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OrderByStatus = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.status > b.status) ? -1 : ((b.status > a.status) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheck = (credit) => {
    if (!newPermission.det) {
      return;
    }
    SetCreditToCheck(credit)
    setState(1)
  }

  const handleDelete = async id => {
    try {
      // await deletePartner(id)
      // setPartnerList(partnerList.filter(item => item.id != id))
      console.log('deleted')
    } catch (e) {
      console.log('error')
    }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "ID",
      "Status",
      "Data",
      "Valor",
      "Usuário Responsável",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.id,
          row.status,
          format(new Date(row.data), 'dd/MM/yyyy HH:mm'),
          row.valor,
          row.usuario,
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={4} xs={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => setState(2)}
                className={classes.btnAdd}
                size="large"
                type="submit"
                variant="contained"
                style={{ padding: '15px 40px' }}
              >
                Creditar Pontos
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table}>
                <TableHead className={classes.headerTb}>
                  <TableRow>
                    <TableCell key={'id'} className={classes.celTableHeader}>
                      {"ID"}
                    </TableCell>
                    <TableCell key={'status'} className={classes.celTableHeader}>
                      {"Status"}
                    </TableCell>
                    <TableCell key={'data'} className={classes.celTableHeader}>
                      {"Data"}
                    </TableCell>
                    <TableCell key={'valor'} className={classes.celTableHeader}>
                      {"Valor"}
                    </TableCell>
                    {/* <TableCell key={'cedente'} className={classes.celTableHeader}>
                    {"Cedente"}
                  </TableCell>
                  <TableCell key={'cessionario'} className={classes.celTableHeader}>
                    {"Cessionário"}
                  </TableCell> */}
                    <TableCell key={'usuario'} className={classes.celTableHeader}>
                      {"Usuário Responsável"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={() => handleCheck(row)}
                          key={index}
                        >
                          <TableCell style={{ width: 50 }} >
                            <p className={classes.ellipseComent}>
                              {row.id}
                            </p>
                          </TableCell>
                          <TableCell >{row.status}</TableCell>
                          <TableCell >{format(new Date(row.data), 'dd/MM/yyyy HH:mm')}</TableCell>
                          <TableCell >{row.valor}</TableCell>
                          {/* <TableCell>{row.cedente}</TableCell>
                        <TableCell>{row.cessionario}</TableCell> */}
                          <TableCell>{row.usuario}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: 'center'
                }} >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<LocalPrintshop />}
                  onClick={() => createPDF()}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={'Linhas por página'}
                  component="div"
                  count={userToShow.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByStatus()}
              >
                Status
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </>
    ) : (
      <>
        Sem Permissão
      </>
    )
  )
}