import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { findAllUnidades } from '../../../../providers/partner'
import Backdrop from '@material-ui/core/Backdrop';
import ReactLoading from 'react-loading';
import { insertCaassh } from '../../../../providers/saldo'
import CurrencyInput from 'react-currency-input-field';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  currencyField: {
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function CreditPartner({ setState, setUsers }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [unityList, setUnityList] = useState([])
  const [selected, setSelected] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('')
  const [userToShow, setUsersToShow] = useState([])

  useEffect(async () => {
    const data = await findAllUnidades()

    setUnityList(data)
    setUsersToShow(data)
    setLoading(false);
  }, [])

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const creditPoints = (users) => {
    if (selected.length) {
      setUsers(selected)
      selected.length === 1 && selected.length !== 0 ? setState(5) : setState(6)
    }
  }

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(unityList.filter(p => p.name.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(unityList)
      setPage(0)
    }
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(unityList);
      return;
    }
    setSelected([]);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Container maxWidth="md" disableGutters>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Formik
          initialValues={{
          }}
          validationSchema={Yup.object().shape({
          })}
          onSubmit={async (values, actions) => {
            try {

            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Nome"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={handleChangeFilter}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Button
                        color='primary'
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => handleFilter()}
                        style={{ padding: '15px 40px' }}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                  <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                      <Table stickyHeader className={classes.table}>
                        <TableHead className={classes.headerTb}>
                          <TableRow>
                            <TableCell padding="checkbox" className={classes.celTableHeader}>
                              <Checkbox
                                indeterminate={selected.length > 0 && selected.length < unityList.length}
                                checked={unityList.length > 0 && selected.length === unityList.length}
                                onChange={handleSelectAllClick}
                                color="primary"
                              />

                            </TableCell>
                            <TableCell key={'name'} className={classes.celTableHeader}>
                              {"Nome"}
                            </TableCell>
                            <TableCell key={'cnpj'} className={classes.celTableHeader}
                              style={{ width: 500 }}
                            >
                              {"CNPJ"}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              const isItemSelected = isSelected(row);
                              return (
                                <TableRow
                                  hover
                                  onClick={(event) => handleClick(event, row)}
                                  key={row.id}
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      color="primary"
                                    />
                                  </TableCell>
                                  <TableCell >{row.name}</TableCell>
                                  <TableCell >{row.cnpj}</TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      labelRowsPerPage={'Linhas por página'}
                      component="div"
                      count={userToShow.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
                  <Grid container style={{ marginTop: 20 }} alignItems='flex-end' justify='flex-end'>
                    <Grid item md={3}>
                      <Button
                        color='primary'
                        fullWidth
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => creditPoints(selected)}
                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Creditar Pontos'}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  )
}