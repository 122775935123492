import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Typography,
  Grid
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Check as CheckIcon
} from '@material-ui/icons';
import { color } from '../../../../components/common/colors';
import { Rating } from '@material-ui/lab';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { format, getDate } from 'date-fns';
import { findAllPayment } from '../../../../providers/partner';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  celTableHeaderActions: {
    width: 96,
    backgroundColor: color.white,
  },
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
  ellipseComent: {
    width: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ellipseName: {
    width: 100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const labels = {
  0.5: '',
  1: '',
  1.5: '',
  2: '',
  2.5: '',
  3: '',
  3.5: '',
  4: '',
  4.5: '',
  5: '',
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{labels[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function PaymentSolicitation({ setState, SetAvaliationToCheck }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([]);

  React.useEffect(() => {
    async function fetchData() {
      const temp = await findAllPayment();
      setList(temp);
    }

    fetchData();
  }, [])

  const goBack = () => {
    setState(1)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheck = (avaliation) => {
    SetAvaliationToCheck(avaliation)
    setState(1)
  }

  const handleDelete = async id => {
    try {
      // await deletePartner(id)
      // setPartnerList(partnerList.filter(item => item.id != id))
      console.log('deleted')
    } catch (e) {
      console.log('error')
    }
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  return (
    <Container maxWidth="md" disableGutters>
      <Paper className={classes.paper}>



        <TableContainer className={classes.tableContainer}>

          <Grid md={12} style={{ marginLeft: 15 }}>
            <Typography style={{ marginTop: 20, marginBottom: 20 }}>
              <h1>
                Solicitações de Pagamento
            </h1>
            </Typography>
          </Grid>

          <Table stickyHeader className={classes.table}>
            <TableHead className={classes.headerTb}>
              <TableRow>
                <TableCell key={'id'} className={classes.celTableHeader}>
                  {"Identificador"}
                </TableCell>
                <TableCell key={'status'} className={classes.celTableHeader}>
                  {"Status"}
                </TableCell>
                <TableCell key={'data'} className={classes.celTableHeader}>
                  {"Data"}
                </TableCell>
                <TableCell key={'valor'} className={classes.celTableHeader} style={{ width: 400 }}>
                  {"Valor"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  console.log(row.nota)
                  return (
                    <TableRow
                      hover
                      // onClick={() => handleCheck(row)}
                      key={row}
                    >
                      <TableCell >{row.id}</TableCell>
                      <TableCell >{row.status}</TableCell>
                      <TableCell >{format(new Date(row.data), 'dd/MM/yyyy HH:mm')}</TableCell>
                      <TableCell >{row.valor}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={'Linhas por página'}
          component="div"
          count={list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Grid container style={{ marginTop: 20 }} alignItems='flex-end' justify='flex-end'>
        <Grid item md={3}>
          <Button
            fullWidth
            color='primary'
            size="large"
            variant="contained"
            onClick={goBack}
          >
            Voltar
            </Button>
        </Grid>
      </Grid>
      <Box className={classes.footer} />
    </Container>
  )
}