import {
  Box,
  Button,
  CircularProgress, Container,
  createMuiTheme, Grid, IconButton, makeStyles,
  Modal,
  MuiThemeProvider, Paper,
  Popover, Table,
  TableBody, TableCell,
  TableContainer,
  TableFooter, TableHead,
  TablePagination, TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import {
  Delete as DeleteIcon, SettingsOutlined, LocalPrintshop,
} from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { color } from '../../../../components/common/colors';
import { getAll, searchUsers, userCities } from '../../../../providers/user';
import {
  EstadoCivil,
  Sexo,
  SituacaoOab,
  SituacaoFinanceira,
  Situacao,
  Status,
  Tipo
} from '../../../../utils/dataUser'
import { format, add, getTime } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import jsPDF from "jspdf";
import "jspdf-autotable";
import TestIntegrityLevel from 'es-abstract/2015/TestIntegrityLevel';
import { subscribeToUserUpdates, unsubscribeToUserUpdates } from '../../../../sockets/Socket';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%',
  },
  headerPage: {
    marginBottom: 30
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '95%'
  },
  option: {
    backgroundColor: 'white',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function UserList({ setState, setUserToEdit }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [list, setList] = useState([])
  const [search, setSearch] = useState('')
  const [searchApi, setSearchApi] = useState('')
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [validadeInit, setValidadeInit] = useState('')
  const [validadeFim, setValidadeFim] = useState('')
  const [idadeInit, setIdadeInit] = useState('')
  const [idadeFim, setIdadeFim] = useState('')
  const [validadeTemp, setValidadeTemp] = useState(validadeInit.valueOf())
  const [idadeTemp, setIdadeTemp] = useState(idadeInit.valueOf())
  const [status, setStatus] = useState('')
  const [tipo, setTipo] = useState('')
  const [sexo, setSexo] = useState('')
  const [estadoCiv, setEstadoCiv] = useState('')
  const [situacao, setSituacao] = useState('')
  const [situacaoOab, setSituacaoOab] = useState('')
  const [situacaoFin, setSituacaoFin] = useState('')
  const [cidade, setCidade] = useState('')
  const [cities, setCities] = useState('')
  const [loading, setLoading] = useState(true);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.associates);
    };

    fetchPermissions();
  }, []);
  useEffect(() => {
    setLoading(true);
    async function fechData() {
      const temp = await userCities()
      const data = await searchUsers({
        'pagina': page,
        'entradasPorPagina': rowsPerPage,
        'search': searchApi,
        'filter': {
          status: status,
          sitaucao: situacao,
          tipo: tipo,
          situacaoOab: situacaoOab,
          sexo: sexo,
          situacaoFin: situacaoFin,
          estadoCivil: estadoCiv,
          cidade: cidade,
          nascidosInic: idadeInit,
          nascidosFim: idadeFim,
          validosInit: validadeInit,
          validosFim: validadeFim,
        },
        'order': orderAlf,
      });
      setCities(temp);
      setUsersToShow(data);
    };
    fechData();
    setLoading(false);
  }, [rowsPerPage, page, searchApi, orderAlf])
  function handleUserUpdated(user) {
    const temp = userToShow.map(u => {
      if (u.id == user.id) return user;
      return u;
    })
    setUsersToShow(temp)
  }
  useEffect(() => {
    subscribeToUserUpdates((user) => {
      handleUserUpdated(user);
    });

    return () => {
      unsubscribeToUserUpdates();
    }
  }, []);
  
  const chamadaApi = async () => {
    setLoading(true);
    const data = await searchUsers({
      'pagina': 0,
      'entradasPorPagina': rowsPerPage,
      'search': "",
      'filter': {
        status: status,
        sitaucao: situacao,
        tipo: tipo,
        situacaoOab: situacaoOab,
        sexo: sexo,
        situacaoFin: situacaoFin,
        estadoCivil: estadoCiv,
        cidade: cidade,
        nascidosInic: idadeInit,
        nascidosFim: idadeFim,
        validosInit: validadeInit,
        validosFim: validadeFim,
      },
      'order': orderAlf,
    });
    setUsersToShow(data);
    setLoading(false);
  }

  const handleFilter = async () => {
    setSearchApi(search)
    setPage(0)
  }
  const OrderByType = () => {
    setOrderAlf(prevValues => (prevValues === 'Titular' ? 'Dependente' : 'Titular'))
  }
  const getType = type => {
    if (type === 'TITULAR') {
      return 'Titular'
    } else {
      return 'Dependente'
    }
  }
  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const CloseOrder = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
    setPage(0)
  }
  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
  };

  const handleEditProfile = user => {
    if (!newPermission.det) {
      return;
    }
    setUserToEdit(user)
    setState(2)
  }

  const filterByItem = async () => {
    chamadaApi()
    handleCloseModal(true)
  }

  const handleOpenModal = () => {
    setValidadeInit(add(new Date(), { years: -10 }));
    setValidadeFim(add(new Date(), { years: 57 }));
    setIdadeFim(new Date());
    setIdadeInit(add(new Date(), { years: -100 }));
    setOpenModal(true);
  };

  const handleCloseModal = () => {

    setOpenModal(false);
  };

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Status",
      "Nome",
      "Tipo",
      "CPF",
      "OAB",
      "Situação",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1100) {
        body.push([
          index + 1,
          row.status,
          row.nome,
          getType(row.userType),
          row.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4'),
          row.oab,
          row.situacao,
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };
  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12} >
              <TextField
                fullWidth
                label="Nome, OAB ou CPF"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12} style={{ marginRight: "-1%" }}>
              <Button
                color='primary'
                type="submit"
                size="large"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="button"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="button"
                variant="contained"
                onClick={handleOpenModal}
                style={{ padding: '15px 40px' }}
              >
                Filtrar
              </Button>
            </Grid>
            <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
              <Button variant='contained' className={classes.btnAdd} onClick={() => setState(1)}
                style={{ padding: '16px 35px' }}
              >
                Adicionar Associado
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            {loading
              ? <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', padding: 100 }}>
                <CircularProgress color={'primary'} size={80} />
              </Grid>
              :
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table}>
                  <TableHead className={classes.headerTb}>
                    <TableRow>
                      <TableCell key={'status'} className={classes.celTableHeader}>
                        {"Status"}
                      </TableCell>
                      <TableCell key={'nome'} className={classes.celTableHeader}>
                        {"Nome"}
                      </TableCell>
                      <TableCell key={'type'} className={classes.celTableHeader}>
                        {"Tipo"}
                      </TableCell>
                      <TableCell key={'cpf'} className={classes.celTableHeader}>
                        {"CPF"}
                      </TableCell>
                      <TableCell key={'oab'} className={classes.celTableHeader}>
                        {"OAB"}
                      </TableCell>
                      <TableCell key={'situacao'} className={classes.celTableHeader}>
                        {"Situação"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userToShow.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => console.log(event)}
                          key={row.id}
                        >
                          <TableCell>
                            {row.status}
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer' }} onClick={() => handleEditProfile(row)}>{row.nome.toUpperCase()}</TableCell>
                          <TableCell>{getType(row.userType)}</TableCell>
                          <TableCell>{row.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')}</TableCell>
                          <TableCell>{row.oab}</TableCell>
                          <TableCell>{row.situacao}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>}
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: 'center'
                }} >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<LocalPrintshop />}
                  onClick={() => createPDF()}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={'Linhas por página'}
                  component="div"
                  count={-1}
                  rowsPerPage={rowsPerPage}
                  nextIconButtonProps={{ disabled: userToShow.length < rowsPerPage }}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => setOrderAlf('nome')}
              >
                Nome
              </Button>
            </Grid>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => setOrderAlf('oab')}
              >
                OAB
              </Button>
            </Grid>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => getType(OrderByType())}
              >
                Tipo
              </Button>
            </Grid>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => setOrderAlf('cidade')}
              >
                Cidade
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          keepMounted
        >
          <Container maxWidth='md' spacing={3}
            style={{
              backgroundColor: 'white',
              height: '100%',
              overflow: 'scroll',
              padding: 30,
              margin: '0% 10%',
              borderRadius: 4,
              border: '1px solid #000000',
              top: 100
            }}
          >
            <Grid container spacing={6}
              style={{
                padding: 10,
              }}
            >
              <Grid item md={12} xs={12}>
                <Typography variant="h3" display="block" style={{ marginTop: 10 }}>
                  Selecionar Filtros
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Status
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={status}
                      options={Status}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setStatus(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Tipo
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={tipo}
                      options={Tipo}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setTipo(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Sexo
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={sexo}
                      options={Sexo}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setSexo(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Estado Civil
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={estadoCiv}
                      options={EstadoCivil}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setEstadoCiv(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Nascidos entre:
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="idadeInicio"
                          format="dd/MM/yyyy"
                          autoOk
                          value={idadeInit}
                          onChange={(newValue) => {
                            setIdadeInit(newValue)
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      e:
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="IdadeFim"
                          format="dd/MM/yyyy"
                          autoOk
                          value={idadeFim}
                          onChange={(newValue) => {
                            setIdadeFim(newValue)
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                          maxDate={new Date()}
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Situação
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={situacao}
                      options={Situacao}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setSituacao(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Situação Cad OAB
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={situacaoOab}
                      options={SituacaoOab}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setSituacaoOab(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Situação Fin OAB
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={situacaoFin}
                      options={SituacaoFinanceira}
                      getOptionLabel={(option) => option.label}
                      onChange={(e, value) => {
                        setSituacaoFin(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} >
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Cidade
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demo"
                      classes={{
                        popper: classes.option
                      }}
                      value={cidade}
                      options={cities}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setCidade(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Valido de:
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="validadeInicio"
                          format="dd/MM/yyyy"
                          autoOk
                          value={validadeInit}
                          onChange={(newValue) => {
                            setValidadeInit(newValue)
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      até:
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="validadeFim"
                          format="dd/MM/yyyy"
                          autoOk
                          value={validadeFim}
                          onChange={(newValue) => {
                            setValidadeFim(newValue)
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}
                style={{ marginTop: 30, textAlign: "center", placeContent: 'center' }}
              >
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={filterByItem}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Aplicar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={handleCloseModal}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Modal>
      </Grid >
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}