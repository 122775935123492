import React, { useEffect } from 'react';
import './App.css';
import { useRoutes } from "react-router-dom";
import routes from './routes';
import { useSelector, useDispatch } from 'react-redux';
import GlobalStyles from './components/common/GlobalStyles';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme';
import { getById } from './providers/userAdm'
import { addUser, rmvUser } from './redux/actions/userAction'
import { disconnectSocket, initiateSocket } from './sockets/Socket';
import { validateSession } from './providers/user';

export default function App() {
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user);
  let permissions;
  let permissionsWithLog;
  if (!!user) {
    permissions = user?.permissions;
    permissionsWithLog = [...permissions, 'log'];
  }
  const routing = useRoutes(routes(!!user, !!user ? user.type : null, user?.permissions)); //descomentar

  const revalidateSession = async (loadUserData) => {
    try {
      const token = localStorage.getItem('token')
      const response = await validateSession(token);

      if ([200, 201].includes(response.status) && !!response.data?.id) {
        if (loadUserData) {
          if (!!user && user.type === 'USER') {
            const data = await getById(user.id)
            dispatch(addUser(data))
          }
        }
        return;
      }

      throw new Error({ message: 'Unauthorized', status: 201 });
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(rmvUser());
    }
  }

  useEffect(() => {
    initiateSocket();

    revalidateSession(true);
    setInterval(() => {
      revalidateSession();
    }, 1000 * 60 * 5);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
}
