import React, { useState } from 'react';

import SlideList from '../../../components/news/slides';
import EditSlide from '../../../components/news/edit-slide';
import AddSlide from '../../../components/news/add-slide';

export default function Slides() {

  const [state, setState] = useState(0)
  const [slideToEdit, setSlideToEdit] = useState()

  return (
    <>
      {state === 0 && <SlideList setState={setState} setSlideToEdit={setSlideToEdit} />}
      {state === 1 && <AddSlide setState={setState} />}
      {state === 2 && <EditSlide slide={slideToEdit} setState={setState} />} 
    </>
  )
}