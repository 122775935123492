export const evento = [
  'Notícias',
  'Agendamentos',
  'Associados',
  'Colaboradores',
  'Parceiros',
  'Mensagens'
];

export const subeventoNoticias = [
  'Notícias',
  'Slides',
  'Configurações',
];

export const subeventoAgendamentos = [
  'Agendamentos',
  'Unidade',
  'Serviço',
  'Profissional / Recurso',
  'Procedimento',
  'Avaliação',
  'Indisponibilidades',
  'Agenda Extra'
];

export const subeventoAssociados = [
  'Associados',
  'Bloquear',
  'Configuração',
  'OAB',
];

export const subeventoColaboradores = [
  'Colaboradores',
  'Grupos'
]

export const subeventoParceiros = [
  'Parceiros',
  'Unidades',
  'Configuração',
]

export const subeventoMensagens = [
  'Mensagens',
  'Mensagens Agendadas',
  'Mensagens Automáticas',
]
export function changeSubeventoListagem(evento) {

  switch (evento) {
    case 'Notícias':
      return subeventoNoticias;
    case 'Agendamentos':
      return subeventoAgendamentos;
    case 'Associados':
      return subeventoAssociados;
    case 'Colaboradores':
      return subeventoColaboradores
    case 'Parceiros':
      return subeventoParceiros;
    case 'Mensagens':
      return subeventoMensagens;
    default:
      return subeventoNoticias;

  }
}


export const statusOptions = [
  'Criação',
  'Cancelamento',
  'Não compareceu'
]
export const localOptions = [
  'API',
  'CMS'
]

//Filtro de usuários log
export const acaoUsersOptions = [
  'acao 1',
  'acao 2',
  'acao 3',
]
export const localUsersOptions = [
  'local 1',
  'local 2',
  'local 3',
]
