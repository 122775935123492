import axios from 'axios';
import api from './api';

export const findUserOab = async (oab) => {
  const { data } = await api.post('user/oab/search', { oab })
  return data
}


// export const findUserOab = async (oab) => {
//   console.log(oab);

//   const {data} = axios.create({
//     baseURL:
//       'https://oab-ba.implanta.net.br/siscaf/servico/api/RelatoriosPersonalizados?sistema=siscaf&modulo=WEBSERVICE%20CAAB&nomeRelatorio=STATUS%20CAAB',
//     headers: {
//       CHAVE: 'ecc4346f-ef85-48ec-ae55-25b0d663b285',
//       SENHA: 'd61629b7-2cd4-42b6-b0c6-05e65a9429f2',
//     },
//     params:{
//       OAB: oab
//     }
//   });

//   return data;
// }
