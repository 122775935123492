import React, { useEffect, useState } from 'react';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Button,
  CircularProgress,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  TextField,
  Container,
  Divider,
  Typography,
  Collapse,
  IconButton
} from '@material-ui/core';
import { Formik } from 'formik';
import { color } from '../../../components/common/colors';
import { findUserOab } from '../../../providers/apiOab';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  card: {
    background: color.white,
    marginBottom: 60
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  date: {
    opacity: 1
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});



export default function OabTest(props) {

  const classes = useStyles()
  const [loading, setLoading] = useState(false);
  const [seek, setSeek] = useState([]);
  const [error, setError] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.associatesOab);
    };

    fetchPermissions();
  }, []);

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        enableReinitialize
        initialValues={{
          oab: '',
          // nome: "",
          // cpf: "",
          // situacaoRegular: "",
          // detalhe: "",
          // inadimplente: "",
          // pagoTotalExercicioAtual: "",
          // dataInadimplencia: null
        }}
        onSubmit={async (values, actions) => {
          try {
            setLoading(true);

            if (newPermission.edit) {
              const allData = await findUserOab(values.oab);
  
              // const data = allData[0];
  
              // values.nome = data.nome;
              // values.cpf = data.cpf;
              // values.situacaoRegular = data.situacaoRegular;
              // values.detalhe = data.detalhe;
              // values.inadimplente = data.inadimplente;
              // values.pagoTotalExercicioAtual = data.pagoTotalExercicioAtual;
  
              if (allData.length > 0) {
                setSeek(allData);
              } else {
                setSeek([]);
                setError('OAB não encontrada!');
              }
            }

          } catch (e) {
            console.log(e)
          } finally {
            setLoading(false);
          }
        }}>
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            {loading ?
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', padding: 100 }}>
                <CircularProgress color={'primary'} size={80} />
              </Grid>
              :
              <Card className={classes.card}>
                <CardContent>
                  <Grid item xs={12}>
                    <Grid container spacing={3} direction='column' >
                      <Grid item md={6}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.oab && errors.oab)}
                          helperText={touched.oab && errors.oab}
                          label="OAB"
                          name="oab"
                          disabled={!newPermission.edit}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.oab}
                          variant="outlined"
                          inputProps={{
                            maxlength: 6,
                          }}
                        />
                      </Grid>

                      <Grid item md={6} >
                        <Button
                          fullWidth
                          color='primary'
                          size="large"
                          type="submit"
                          disabled={!newPermission.edit}
                          variant="contained"
                        >
                          {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Verificar Oab'}
                        </Button>
                      </Grid>

                      {seek.length > 0 && (
                        <Grid item xs={12} style={{ maxHeight: 340, overflow: 'auto' }}>
                          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                          {seek.map((a) => {
                            return (
                              <>
                                <Typography>{'nome: '}{a.nome || 'N/A'}</Typography>
                                <Typography>{'cpf: '}{a.cpf || 'N/A'}</Typography>
                                <Typography>{'situacaoRegular: '}{a.situacaoRegular || 'N/A'}</Typography>
                                <Typography>{'detalhe: '}{a.detalhe || 'N/A'}</Typography>
                                <Typography>{'inadimplente: '}{a.inadimplente || 'N/A'}</Typography>
                                <Typography>{'pagoTotalExercicioAtual: '}{a.pagoTotalExercicioAtual || 'N/A'}</Typography>
                                <Typography>{'dataInadimplencia: '}{a.dataInadimplencia || 'N/A'}</Typography>
                                <Divider style={{ marginTop: 12, marginBottom: 12 }} />
                              </>
                            );
                          })}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* {!!seek && (
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Divider style={{ marginTop: 12 }} />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.nome && errors.nome)}
                          helperText={touched.nome && errors.nome}
                          label="Nome"
                          name="nome"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.nome}
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.cpf && errors.cpf)}
                          helperText={touched.cpf && errors.cpf}
                          label="CPF"
                          name="cpf"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.cpf}
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.situacaoRegular && errors.situacaoRegular)}
                          helperText={touched.situacaoRegular && errors.situacaoRegular}
                          label="Regular"
                          name="situacaoRegular"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.situacaoRegular}
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.detalhe && errors.detalhe)}
                          helperText={touched.detalhe && errors.detalhe}
                          label="Detalhe"
                          name="detalhe"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.detalhe}
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.inadimplente && errors.inadimplente)}
                          helperText={touched.inadimplente && errors.inadimplente}
                          label="Inadimplente"
                          name="inadimplente"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.inadimplente}
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          error={Boolean(touched.pagoTotalExercicioAtual && errors.pagoTotalExercicioAtual)}
                          helperText={touched.pagoTotalExercicioAtual && errors.pagoTotalExercicioAtual}
                          label="Pago total"
                          name="pagoTotalExercicioAtual"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.pagoTotalExercicioAtual}
                          variant="outlined"
                          InputProps={{
                            readOnly: true
                          }}
                        />
                      </Grid>

                      <Grid item md={6} xs={12}>
                      <MuiThemeProvider theme={defaultMaterialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                          <DatePicker
                            fullWidth
                            autoOk
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="validadeCartao"
                            label="Validade do Cartão"
                            value={values.date}
                            onChange={(newValue) => {
                              onChangeDate(newValue)
                              setFieldValue('date', newValue)
                            }}
                            inputVariant={'outlined'}
                            variant='inline'
                            disablePast
                            minDateMessage={null}
                          />
                        </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </Grid>

                      <Grid item md={12} xs={12}>
                        <Divider style={{ marginBottom: 7 }} />
                      </Grid>
                    </Grid>
                  )} */}


                  {/* <Grid item xs={12}>
                    <Grid container spacing={3} direction='column' >
                      <Grid item md={6} >
                        <Button
                          fullWidth
                          color='primary'
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Verificar Oab'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid> */}

                </CardContent>
              </Card>
            }
          </form>
        )}
      </Formik>

      <Collapse in={error} style={{
        position: 'absolute',
        bottom: 20,
        right: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>

    </Container>
  );
}