import React, { useEffect, useState } from 'react';
import {
  Button,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Collapse,
  Grid,
  TextField,
  Popover,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  ChevronRight,
  ExpandMore,
  LocalPrintshop,
} from '@material-ui/icons';
import { deleteProfissional, getAllProfissional } from '../../../../providers/schedules';
import { Alert, TreeItem, TreeView } from '@material-ui/lab';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: color.white,
    height: '100%',
    padding: theme.spacing(3)
  },
  tableContainer: {
    backgroundColor: color.white,
  },
  card: {
    background: color.white,
    maxWidth: '50vw'
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  listText: {
    fontSize: 14
  },
  option: {
    backgroundColor: 'white'
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function ListProfissional(props) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [message, setMessage] = useState('')
  const [severity, setSeverity] = useState('error')
  const [list, setList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduProfessional);
    };

    fetchPermissions();
  }, []);


  useEffect(async () => {
    const data = await getAllProfissional()
    if (orderAlf === 0) {
      data.sort((a, b) => {
        return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
      });
    }
    setList(data)
    setUsersToShow(data)

  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(list.filter(p => p.nome.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(list)
      setPage(0)
    }
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.nome > b.nome) ? -1 : ((b.nome > a.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OrderByUnity = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.unidade.nome > b.unidade.nome) ? 1 : ((b.unidade.nome > a.unidade.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.unidade.nome > b.unidade.nome) ? -1 : ((b.unidade.nome > a.unidade.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OrderByServ = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.servico.nome > b.servico.nome) ? 1 : ((b.servico.nome > a.servico.nome) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.servico.nome > b.servico.nome) ? -1 : ((b.servico.nome > a.servico.nome) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setList(list)
      setUsersToShow(list)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  function handleEdit(data) {
    props.setProfissionalToEdit(data)
    props.setState(2)
  }

  const handleDelete = async id => {
    try {
      const result = await deleteProfissional(id)
      setList(list.filter(item => item.id != id))
      setUsersToShow(userToShow.filter(item => item.id != id))
    } catch (error) {
      setMessage('Error ao excluir')
      setSeverity('error')
    }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Status",
      "Unidade",
      "Serviço",
      "Procedimentos",
      "Horário de Funcionamento",
      "Disponível",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.nome,
          row.unidade.nome,
          row.servico.nome,
          `${row.procedimentos.map(p => p.nome).join(', ')}`,
          `${!!row.segunda ? 'Seg: ' + row.segunda.horaInit + ' / ' + row.segunda.horaFim : 'Seg: Fechado'}, ${!!row.terca ? 'Ter: ' + row.terca.horaInit + ' / ' + row.terca.horaFim : 'Ter: Fechado'}, ${!!row.quarta ? 'Qua: ' + row.quarta.horaInit + ' / ' + row.quarta.horaFim : 'Qua: Fechado'}, ${!!row.quinta ? 'Qui: ' + row.quinta.horaInit + ' / ' + row.quinta.horaInit : 'Qui: Fechado'}, ${!!row.sexta ? 'Sex: ' + row.sexta.horaInit + ' / ' + row.sexta.horaInit : 'Sex: Fechado'}, ${!!row.sabado ? 'Sáb: ' + row.sabado.horaInit + ' / ' + row.sabado.horaInit : 'Sáb: Fechado'}, ${!!row.domingo ? 'Dom: ' + row.domingo.horaInit + ' / ' + row.domingo.horaInit : 'Dom: Fechado'}`,
          `${row.disponible ? 'Sim' : 'Não'}`
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);
  return (
    newPermission.viz ? (
      <>
        <Box my={2} display="flex" justifyContent="flex-end">
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={4} xs={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => props.setState(1)}
                className={classes.submitBtn}
                size="large"
                type="submit"
                variant="contained"
                style={{ padding: '15px 30px' }}

              >
                Cadastrar Profissional/Recurso
              </Button>
            </Grid>
          </Grid>

        </Box>
        <Collapse in={message} style={{
          position: 'absolute',
          bottom: 20,
          left: 20,
          zIndex: 99999
        }}>
          <Alert
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMessage(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        </Collapse>
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            <TableContainer className={classes.tableContainer}>
              <Table size={'small'} className={classes.table}>
                <TableHead className={classes.headerTb}>
                  <TableRow>
                    <TableCell key={'nome'} style={{ minWidth: 100 }} className={classes.celTableHeader}>
                      {"Nome"}
                    </TableCell>
                    <TableCell key={'category'} className={classes.celTableHeader}>
                      {"Unidade"}
                    </TableCell>
                    <TableCell key={'servico'} className={classes.celTableHeader}>
                      {"Serviço"}
                    </TableCell>
                    <TableCell key={'disponible'} className={classes.celTableHeader}>
                      {"Procedimentos"}
                    </TableCell>
                    <TableCell key={'horario'} className={classes.celTableHeader}>
                      {"Horário de Funcionamento"}
                    </TableCell>
                    <TableCell key={'disponible'} className={classes.celTableHeader}>
                      {"Disponível"}
                    </TableCell>
                    <TableCell key={'actions'} align={'right'} className={classes.celTableHeader}  >
                      {"Ações"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <>
                          <TableRow
                            hover
                            key={index}
                          >
                            <TableCell style={{ minWidth: 100 }}>{row.nome}</TableCell>
                            <TableCell>{row.unidade ? row.unidade.nome : 'Unidade indisponível'}</TableCell>
                            <TableCell>{row.servico ? row.servico.nome : 'Serviço indisponível'}</TableCell>
                            <TableCell>
                              <TreeView
                                defaultCollapseIcon={<ExpandMore />}
                                defaultExpandIcon={<ChevronRight />}
                              >
                                <TreeItem nodeId="1" label="Ver mais..." >
                                  <TreeItem nodeId="2" label={row.procedimentos.map(p => p.nome).join(', ')} />
                                </TreeItem>
                              </TreeView>
                            </TableCell>
                            <TableCell >
                              <TreeView
                                defaultCollapseIcon={<ExpandMore />}
                                defaultExpandIcon={<ChevronRight />}
                              >
                                <TreeItem nodeId="1" label="Ver mais..." >
                                  {row.segunda && <TreeItem nodeId="2" label={`Segunda ${row.segunda?.horaInit} / ${row.segunda?.horaFim}`} />}
                                  {row.terca && <TreeItem nodeId="3" label={`Terca: ${row.terca?.horaInit} / ${row.terca?.horaFim}`} />}
                                  {row.quarta && <TreeItem nodeId="4" label={`Quarta: ${row.quarta?.horaInit} / ${row.quarta?.horaFim}`} />}
                                  {row.quinta && <TreeItem nodeId="5" label={`Quinta: ${row.quinta?.horaInit} / ${row.quinta?.horaFim}`} />}
                                  {row.sexta && <TreeItem nodeId="6" label={`Sexta: ${row.sexta?.horaInit} / ${row.sexta?.horaFim}`} />}
                                  {row.sabado && <TreeItem nodeId="7" label={`Sábado: ${row.sabado?.horaInit} / ${row.sabado?.horaFim}`} />}
                                  {row.domingo && <TreeItem nodeId="8" label={` Domingo: ${row.domingo?.horaInit} / ${row.domingo?.horaFim}`} />}
                                </TreeItem>
                              </TreeView>
                            </TableCell>
                            <TableCell >{row.disponible ? 'Sim' : 'Não'}</TableCell>
                            <TableCell padding='none' align={'right'} style={{ minWidth: 150 }}>
                              {newPermission.det && (
                                <IconButton onClick={() => handleEdit(row)} ><EditIcon /></IconButton>
                              )}
                              {/* {newPermission.edit && (
                                <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                              )} */}
                            </TableCell>
                          </TableRow>

                        </>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: 'center'
                }} >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<LocalPrintshop />}
                  onClick={() => createPDF()}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={'Linhas por página'}
                  component="div"
                  count={userToShow.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider >
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByUnity()}
              >
                Unidade
              </Button>
            </Grid>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByServ()}
              >
                Serviço
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}