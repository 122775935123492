import React, { useState } from 'react';
import {
  Tab,
  Tabs,
  makeStyles,
  Box,
  Paper,
} from '@material-ui/core';
import { color } from '../../components/common/colors';
import Page from '../../components/common/page'
import Partner from './partner'
import Unidade from './unidade'
// import Category from './category'
import Avaliation from './avaliation'
// import TagTab from './tagtab';
import Configuration from './config'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}


export default function Parceiros(props) {
  const classes = useStyles()
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page className={classes.root}
      title="Parceiros"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs value={tab} onChange={handleChange} indicatorColor="primary"
          textColor="primary">
          <Tab label="Parceiros" id={0} />
          <Tab label="Unidades" id={1} />
          {/* <Tab label="Categorias" id={2} /> */}
          <Tab label="Avaliação" id={2} />
          {/* <Tab label="Tag" id={4} /> */}
          <Tab label="Configuração" id={3} />
        </Tabs>
      </Paper>
      <TabPanel value={tab} index={0}>
        <Partner />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Unidade />
      </TabPanel>
      {/* <TabPanel value={tab} index={2}>
        <Category />
      </TabPanel> */}
      <TabPanel value={tab} index={2}>
        <Avaliation />
      </TabPanel>
      {/* <TabPanel value={tab} index={4}>
        <TagTab />
      </TabPanel> */}
      <TabPanel value={tab} index={3}>
        <Configuration />
      </TabPanel>
    </Page>
  )
}