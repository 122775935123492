import openSocket from 'socket.io-client';

const URL = 'https://caab.homolog.komunick.com';
// const URL = 'https://qa.ykt.com.br';
// const URL = 'http://localhost:4000';

let socket = openSocket(URL, {
  path: URL.includes('qa') ? `/api-caap/socket.io` : `/api/socket.io`
});

const ROOMS = {
  ASSOCIADOS: 'ASSOCIADOS',
}

export const initiateSocket = () => {
  console.log('Trying to initiate a new socket...');
  if (!socket) socket = openSocket(URL);
}

export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  if (socket) socket.disconnect();
}

export const subscribeToUserUpdates = (callback) => {
  socket.emit('room', ROOMS.ASSOCIADOS, 'join', res => console.log(res));
  socket.on('userUpdated', userUpdated => callback(userUpdated));
}

export const unsubscribeToUserUpdates = () => {
  socket.emit('room', ROOMS.ASSOCIADOS, 'leave', res => console.log(res));
  socket.off('userUpdated');
}

export const broadcastUpdateUser = async (userId) => {
  if (socket) {
    console.log('-----<BEGIN: BROADCAST UPDATE USER>-----')
    console.log('Trying to update user...');
    console.log('(If there\'s no aditional loggin below, the socket most likely didn\'t receive a response)')
    socket.emit('broadcastUpdateUser', userId, (response) => {
      console.log('Response from API @ broadcastUpdateUser:', response.status);
      console.log('------<END: BROADCAST UPDATE USER>------');
    });
  }
}
