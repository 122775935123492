import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Grid,
  TextField,
  Popover,
  Modal,
  Typography,
  MenuItem,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon,
  LocalPrintshop
} from '@material-ui/icons';
import { color } from '../../../../components/common/colors';
import { findAllUnidades, deleteUnidade } from '../../../../providers/partner'
import { format, add, startOfDay, endOfDay } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    height: '90%'
  },
  option: {
    backgroundColor: 'white',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function ListUnidades({ setState, setUnidadeToEdit }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [unidadeList, setUnidadeList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [partner, setPartner] = useState([])
  const [cidade, setCidade] = useState([])
  const [bairro, setBairro] = useState([])
  const [dataInit, setDataInit] = useState(new Date())
  const [dataFim, setDataFim] = useState(add(new Date(), { years: 2 }))
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerUnities);
    };

    fetchPermissions();
  }, []);

  const getVisible = type => {
    if (type === true) {
      return 'Sim'
    } else {
      return 'Não'
    }
  }

  const allCities = unidadeList.map(o => o.cidade)
  const cities = [...new Set(allCities)]

  const allDistrict = unidadeList.map(o => o.bairro)
  const district = [...new Set(allDistrict)]

  useEffect(async () => {
    const data = await findAllUnidades()
    if (orderAlf === 0) {
      data.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
    }
    setUnidadeList(data)
    setUsersToShow(data)
  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(unidadeList.filter(p => p.name.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(unidadeList)
      setPage(0)
    }
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    // if (!search) {
    //   setUnidadeList(unidadeList)
    //   setUsersToShow(unidadeList)
    // } else {
    //   setUsersToShow(userToShow)
    // }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (unidade) => {
    setUnidadeToEdit(unidade)
    setState(2)
  }

  const handleDelete = async id => {
    try {
      await deleteUnidade(id)
      setUnidadeList(unidadeList.filter(item => item.id != id))
      setUsersToShow(userToShow.filter(item => item.id != id))
    } catch (e) {
      console.log(e)
    }
  }

  const handleOpenModal = () => {
    setOpenModal(true);
    setUsersToShow(unidadeList)
    setPage(0)
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const partnerArray = unidadeList.map(p => p.partner)

  const filterByItem = async () => {
    let filterAux = unidadeList

    if (partner.length > 0) {
      const parceiro = partner.map(a => a.name)
      const partnerFilter = filterAux.filter(p => {
        return parceiro.some(t => t === p.partner.name)
      })
      filterAux = partnerFilter
      setUsersToShow(filterAux)
    }
    if (cidade.length > 0) {
      const city = cidade.map(a => a)
      const cidadeFilter = filterAux.filter(p => {
        return city.some(t => t === p.cidade)
      })
      filterAux = cidadeFilter
      setUsersToShow(filterAux)
    }
    if (bairro.length > 0) {
      const district = bairro.map(a => a)
      const bairroFilter = filterAux.filter(p => {
        return district.some(t => t === p.bairro)
      })
      filterAux = bairroFilter
      setUsersToShow(filterAux)
    }
    filterAux = filterAux.filter(c => format(new Date(c.createdAt), 'dd/MM/yyyy') >= format(new Date(dataInit), 'dd/MM/yyyy') && format(new Date(c.createdAt), 'dd/MM/yyyy') <= format(new Date(dataFim), 'dd/MM/yyyy'))
    if (partner.length === 0 && bairro.length === 0 && cidade.length === 0) {
      setUsersToShow(filterAux)
    }
    handleCloseModal(true)
    setPartner([])
    setCidade([])
    setBairro([])
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Nome",
      "Parceiro",
      "Cidade",
      "Bairro",
      "Data",
      "Benefico",
      "Visivel",
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.name,
          row.partner.name,
          row.cidade,
          row.bairro,
          format(new Date(row.createdAt), 'dd/MM/yyyy'),
          row.beneficio1,
          getVisible(row.visible)
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRownGroup = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="button"
                variant="contained"
                onClick={handleOpenModal}
                style={{ padding: '15px 40px' }}
              >
                Filtrar
              </Button>
            </Grid>
            <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
              <Button
                variant='contained'
                className={classes.btnAdd}
                onClick={() => setState(1)}
                style={{ padding: '15px 40px' }}
              >
                Adicionar Unidade
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Paper className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.headerTb}>
                <TableRow>
                  <TableCell key={'name'} className={classes.celTableHeader}>
                    {"Nome"}
                  </TableCell>
                  <TableCell key={'partner'} className={classes.celTableHeader}>
                    {"Parceiro"}
                  </TableCell>
                  <TableCell key={'city'} className={classes.celTableHeader}>
                    {"Cidade"}
                  </TableCell>
                  <TableCell key={'bairro'} className={classes.celTableHeader}>
                    {"Bairro"}
                  </TableCell>
                  <TableCell key={'date'} className={classes.celTableHeader}>
                    {"Data"}
                  </TableCell>
                  <TableCell key={'beneficio'} className={classes.celTableHeader}>
                    {"Benefico"}
                  </TableCell>
                  <TableCell key={'visible'} className={classes.celTableHeader}>
                    {"Visivel"}
                  </TableCell>
                  <TableCell key={'actions'} align="right" className={classes.celTableHeader}>
                    {"Ações"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => console.log(event)}
                        key={row.id}
                      >
                        <TableCell >{row.name}</TableCell>
                        <TableCell >{row.partner.name}</TableCell>
                        <TableCell >{row.cidade}</TableCell>
                        <TableCell >{row.bairro}</TableCell>
                        <TableCell >{format(new Date(row.createdAt), 'dd/MM/yyyy')}</TableCell>
                        <TableCell >{row.beneficio1}</TableCell>
                        <TableCell >{getVisible(row.visible)}</TableCell>
                        <TableCell padding='none' align="right" >
                          {newPermission.det && (
                            <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                          )}
                          {/* {newPermission.edit && (
                            <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRownGroup > 0 && (
                  <TableRow style={{ height: 53 * emptyRownGroup }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={3} >
            <Grid item md={6} xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: 'center'
              }} >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => createPDF()}
                startIcon={<LocalPrintshop />}
              >
                Imprimir
              </Button>
            </Grid>
            <Grid item md={6} xs={12} style={{}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={'Linhas por página'}
                component="div"
                count={userToShow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          keepMounted
        >
          <Container maxWidth='md' spacing={3}
            style={{
              backgroundColor: 'white',
              height: '100%',
              overflow: 'scroll',
              padding: 30,
              margin: '0% 10%',
              borderRadius: 4,
              border: '1px solid #000000',
              top: 100
            }}
          >
            <Grid container spacing={6}
              style={{
                padding: 10,
              }}
            >
              <Grid item md={12} xs={12}>
                <Typography variant="h3" display="block" style={{ marginTop: 10 }}>
                  Selecionar Filtros
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Parceiro
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={partner}
                      options={partnerArray}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) => {
                        setPartner(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Cidade
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={cidade}
                      options={cities}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setCidade(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Bairro
                    </Typography>
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="checkboxes-tags-demo"
                      disableCloseOnSelect
                      classes={{
                        popper: classes.option
                      }}
                      value={bairro}
                      options={district}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setBairro(value)
                      }}
                      style={{ backgroundColor: 'white' }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" style={{ marginTop: 10 }} />
                      )}
                    />
                  </Grid>
                  <Grid xs={3} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Data Inicio
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="dataInicio"
                          format="dd/MM/yyyy"
                          autoOk
                          value={dataInit}
                          onChange={(newValue) => {
                            setDataInit(startOfDay(newValue))
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid xs={3} style={{ padding: 5 }}>
                    <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                      Data Fim
                    </Typography>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="dataFim"
                          format="dd/MM/yyyy"
                          autoOk
                          value={dataFim}
                          onChange={(newValue) => {
                            setDataFim(endOfDay(newValue))
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                        />
                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={3}
                style={{ marginTop: 30, textAlign: "center", placeContent: 'center' }}
              >
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={filterByItem}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Aplicar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color='primary'
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={handleCloseModal}
                    style={{ padding: '15px 30px', width: 150 }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Modal>
      </Grid>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}