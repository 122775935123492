import api from './api'


export async function newMessage(body) {
  const { data } = await api.post('/mensagem', body)
  return data
}

export async function sendManyMensagens(body) {
  const { data } = await api.post('/mensagem/send-many-messages', body)
  return data
}
export async function newMessageScheduled(body) {
  const { data } = await api.post('/mensagem-agendada', body)
  return data
}

export async function getCidades() {
  const { data } = await api.get('/mensagem/cidades')
  return data
}

export async function getMensagensAutomaticas() {
  const { data } = await api.get('mensagem-automatica')
  return data
}

export async function updateMensagemAutomatica(mensagem) {
  const { data } = await api.post(`mensagem-automatica/${mensagem.id}`, mensagem)
  return data
}