import React, { useState } from 'react';

import Transacao from './transation';
import DataTransacao from './check-transation'

export default function Transation(props) {

  const [state, setState] = useState(0)
  const [transationToCheck, SetTransationToCheck] = useState()


  return (
    <>
      {state === 0 && <Transacao setState={setState} SetTransationToCheck={SetTransationToCheck} />}
      {state === 1 && <DataTransacao setState={setState} transation={transationToCheck} />}
    </>
  )
}