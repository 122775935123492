import React, { useState } from 'react';

import Credito from './credit';
import DataCredito from './check-credit'
import CreditPoint from './credit-point'
import CreditAssociate from './credits-associates'
import CreditPartner from './credits-partners'
import CreditOne from './credit-one'
import CreditMany from './credit-many'

export default function Credit(props) {

  const [state, setState] = useState(0)
  const [CreditToCheck, SetCreditToCheck] = useState()
  const [users, setUsers] = useState([])
  const [typeCredit, setTypeCredit] = useState()


  return (
    <>
      {state === 0 && <Credito setState={setState} SetCreditToCheck={SetCreditToCheck} />}
      {state === 1 && <DataCredito setState={setState} credit={CreditToCheck} />}
      {state === 2 && <CreditPoint setState={setState} credit={CreditToCheck} setTypeCredit={setTypeCredit} />}
      {state === 3 && <CreditAssociate setState={setState} credit={CreditToCheck} setUsers={setUsers} />}
      {state === 4 && <CreditPartner setState={setState} credit={CreditToCheck} setUsers={setUsers} />}
      {state === 5 && <CreditOne setState={setState} credit={CreditToCheck} users={users} typeCredit={typeCredit} />}
      {state === 6 && <CreditMany setState={setState} credit={CreditToCheck} users={users} typeCredit={typeCredit} />}

    </>
  )
}