import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Collapse, IconButton, makeStyles, Box, Container, Card, CardContent, Grid, createMuiTheme, MuiThemeProvider, Divider, Avatar } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputCpf from '../../../../components/common/inputCpf'
import { Autocomplete, Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { getUserByCpf, findToSchedule } from '../../../../providers/user';
import { getAllCategory, getServicoByCategory, getProcedimentoByServico, getProfissionalByServico, newSchedule } from '../../../../providers/schedules';
import { add, differenceInDays } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardReturnRounded } from '@material-ui/icons';
import { capitalize } from 'lodash'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';

const unmaskCpf = value => {
  return value.replace(/\./g, '').replace('-', '')
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  option: {
    backgroundColor: 'white',
  },
  textArea: {
    minHeight: 100
  },
  Avatar: {
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
  userCard: {
    cursor: 'pointer',
    padding: '10px 0px 10px 0px',
    '&:hover': {
      boxShadow: '0px 0px 0px 1px #B22222',
      borderRadius: 4,
      padding: 15,
      backgroundColor: '#F8F8FF'
    },
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function NewSchedule({ setRequestSchedule, setState }) {
  const classes = useStyles()
  // const [state, setState] = useState(0)
  const [user, setUser] = useState([])
  const [error, setError] = useState(false)
  const [date, onChangeDate] = useState(add(new Date(), { days: 1 }));
  const [hourInit, onChangeHourInit] = useState(new Date().setHours('00', '00'));
  const [categorias, setCategorias] = useState([])
  const [categoriaSelected, setCategorySelected] = useState()
  const [servicos, setServicos] = useState()
  const [serviceSelected, setServiceSelected] = useState()
  const [procedimentos, setProcedimentos] = useState()
  const [procedimentoSelected, setProcedimentoSelected] = useState()
  const [profissionais, setProfissionais] = useState()
  const [profissionalSelected, setProfissionalSelected] = useState()
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.bloqueio);
    };

    fetchPermissions();
  }, []);

  async function getUserToSchedule(value) {
    try {
      setError(false)
      let data = await findToSchedule(value.cpf)
      setUser(data)
    } catch (error) {
      setError('Usuário não encontrado')
    }
  }

  useEffect(() => {
    async function fetchData() {
      setCategorias(await getAllCategory())
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      if (categoriaSelected) {
        setServicos(await getServicoByCategory(categoriaSelected))
      }
    }
    fetchData()
  }, [categoriaSelected])
  useEffect(() => {
    async function fetchData() {
      if (serviceSelected) {
        setProcedimentos(await getProcedimentoByServico(serviceSelected))
        setProfissionais(await getProfissionalByServico(serviceSelected))
      }
    }
    fetchData()

  }, [serviceSelected])

  function disableWeekends(date) {
    let days = profissionalSelected.diasDaSemana
    let result = days.filter(item => item == date.getDay())
    return result.length > 0 ? false : true
  }

  const restingDays = (usuario) => {
    if (usuario.datePunished) {
      const diff = differenceInDays(add(new Date(usuario.datePunished), { days: usuario.punished == 'FIRST' ? 30 : 30 }), new Date())
      return diff
    }
  }

  const requestSchedule = (schedule) => {
    setRequestSchedule(schedule)
    setState(4)
  }

  const getPunished = (punished) => {
    switch (punished) {
      case 'NONE':
        return 'Sem bloqueio';
      case 'FIRST':
        return 'Bloqueado';
      case 'SECOND':
        return 'Bloqueado';
      default:
        return 'Sem bloqueio';
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"

    >
      <Collapse in={error} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            oab: '',
            cpf: '',
            idUser: '',
            idProfissional: '',
            idServico: '',
            idProcedimento: '',
            horaInicio: '',
            horaFim: '',
            data: '',
            observacao: ''

          }}
          onSubmit={async (values) => {
            try {
              const result = await newSchedule(values)
              await alterationLog({ action: 'CREATE', idUser: userData.id, before: '', after: values, menu: "Agendamentos", subMenu: "Agendamentos", });
              if (result) return setState(0)
            } catch (e) {
              setError('Horário não disponível')
            }
          }}
          validationSchema={Yup.object().shape({
            idUser: Yup.string()
              .required('Campo obrigatório'),
            idProfissional: Yup.string()
              .required('Campo obrigatório'),
            idServico: Yup.string()
              .required('Campo obrigatório'),
            idProcedimento: Yup.string()
              .required('Campo obrigatório'),
            horaInicio: Yup.string()
              .required('Campo obrigatório'),
            horaFim: Yup.string()
              .required('Campo obrigatório'),
            data: Yup.date()
              .required('Campo obrigatório'),
            observacao: Yup.string()
              .required('Campo obrigatório'),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
              <CardContent>
                <Grid container justify={'center'} spacing={2}>
                  {true && (
                    <>
                      <Grid item md={12} xs={12}>
                        <Typography variant='h4' >Confirme o usuário </Typography>
                      </Grid>
                      <Grid item md={12} xs={12} >
                        <TextField
                          fullWidth
                          label="CPF ou OAB"
                          id={'cpf-or-oab'}
                          name={'cpf'}
                          margin={'normal'}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          // onChange={(e) => {
                          //   handleChangeCpf(unmaskCpf(e.target.value), setFieldValue)
                          // }}
                          variant="outlined"
                        />
                        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
                      </Grid>
                      {user && user.length > 0 && user.map(u => {
                        return (
                          <>
                            <Grid
                              container
                              alignContent={'center'}
                              className={/*u.punished != 'NONE' || u.situacao != 'ATIVO' ? '' :*/ classes.userCard}
                              onClick={/*u.punished != 'NONE' || u.situacao != 'ATIVO' ? '' :*/ () => requestSchedule(u)}
                            >
                              <Grid item md={6} xs={12} >
                                <Grid item xs={12} style={{ margin: '30px 5px 30px 5px' }}>
                                  <Avatar
                                    className={classes.Avatar}
                                    src={u.img ? URL.createObjectURL(u.img) : u.imgProfile}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <Grid item md={12} xs={12} style={{ margin: '15px 5px 30px 5px' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    label="Nome"
                                    name="nome"
                                    id='text-nome'
                                    value={u.nome}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true
                                    }}
                                  />
                                </Grid>
                                <Grid item md={12} xs={12} style={{ margin: '30px 5px 30px 5px' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    label="Tipo"
                                    name="tipo"
                                    id='text-tipo'
                                    value={capitalize(u.userType)}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true
                                    }}
                                  />
                                </Grid>
                                <Grid item md={12} xs={12} style={{ margin: '30px 5px 15px 5px' }}>
                                  <TextField
                                    fullWidth
                                    error={Boolean(touched.nome && errors.nome)}
                                    helperText={touched.nome && errors.nome}
                                    label="Bloqueio por não comparecimento"
                                    name="bloqueio"
                                    id='text-bloqueio'
                                    value={getPunished(u.punished)}
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true
                                    }}
                                  />
                                  {u.punished != 'NONE' && restingDays(u) > 0 && (
                                    <>
                                      <Grid item md={12} xs={12}>
                                        <Alert
                                          severity={'warning'}
                                        >
                                          {`Bloqueio Ativo: Tempo restante ${restingDays(u)} dias.`}
                                        </Alert>

                                      </Grid>
                                    </>
                                  )}
                                  {u.situacao === 'INATIVO' && (
                                    <>
                                      <Grid item md={12} xs={12}>
                                        <Alert
                                          severity={'warning'}
                                        >
                                          {`Usuário Inativo!`}
                                        </Alert>

                                      </Grid>
                                    </>
                                  )}


                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item md={12} xs={12}>
                              <Divider style={{ marginTop: 40, marginBottom: 40 }} />
                            </Grid>
                          </>
                        )
                      })}
                    </>
                  )}
                  <Grid item md={8} xs={12}>

                    <Box my={2} display="flex" justifyContent={"space-between"}>
                      <Button
                        onClick={() => {
                          setState(0)
                        }}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        variant="contained"
                      >
                        Voltar
                      </Button>


                      <Button
                        onClick={() => {
                          getUserToSchedule(values)
                        }}
                        className={classes.submitBtn}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {'Avançar'}
                      </Button>
                    </Box>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          )}
        </Formik>
      </Container>
    </Box >
  )
}