import api from './api'

export const updateConfig = async config => {
  const { data } = await api.post('config', { config })
  return data
}

export const getConfig = async () => {
    const { data } = await api.get('config')
    return data
}
