import React from 'react';
import {
  TextField, Button,
  Typography, makeStyles,
  Box, Container, Card,
  CardContent, Grid, CircularProgress
} from '@material-ui/core';
import {
  CloudUpload as CloudUploadIcon
} from '@material-ui/icons'
import { color } from '../../common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { create } from '../../../providers/news'
import { alterationLog } from '../../../providers/log';
import { useSelector } from 'react-redux';
import { UploadFolder, apiUpload } from '../../../providers/upload';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
    marginBottom: 60
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  }
}));

export default function AddNew({ setState }) {
  const classes = useStyles()

  const user = useSelector(({ user }) => user);

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          title: '',
          description: '',
          img: null
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
          img: Yup.mixed().required('Escolha uma imagem')
        })}
        onSubmit={async (values, actions) => {
          try {
            const body = new FormData()
            body.append('file', values.img)
            body.append('folder', UploadFolder.NEWS)
            const data = await apiUpload(body);

            delete values.img
            values.imgUri = data.location
            
            const news = await create(values)
            await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Notícias", subMenu: "Notícias" });

            setState(0)
          } catch (e) {
            console.error("Error Upload News Image")
            console.error(e)
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3} justify='center' alignItems="center">
                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      label="Titulo"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label="Conteúdo"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      multiline
                      InputProps={{ classes: { input: classes.textArea } }}
                    />
                  </Grid>
                  {<Grid item xs={12}>
                    <input
                      accept="image/*"
                      className={classes.inputFile}
                      name='img'
                      id="img-input"
                      type="file"
                      onChange={(event) => setFieldValue('img', event.target.files[0])}
                    />
                    <label htmlFor="img-input">
                      <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                        Upload
                      </Button>
                    </label>
                    {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                      *{touched.img && errors.img}
                    </Typography>}
                  </Grid>}
                  {values.img && <> <Grid item xs={0} md={2}></Grid><Grid item md={8} xs={12}>
                    <img src={URL.createObjectURL(values.img)} style={{ width: '100%', borderRadius: 4 }} />
                    {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                      *{touched.img && errors.img}
                    </Typography>}
                  </Grid><Grid item xs={0} md={2}></Grid></>}
                  <Grid item xs={12} md={3}>
                    <Box display="flex" justifyContent="center" >
                      <Button
                        size="large"
                        variant="contained"
                        fullWidth
                        color='secondary'
                        onClick={() => setState(0)}
                      >
                        {'Cancelar'}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Box display="flex" justifyContent="center" >
                      <Button
                        color='primary'
                        size="large"
                        type="submit"
                        variant="contained"
                        fullWidth

                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}