import React, { useState, useEffect } from "react";



import UserToShow from "./UsersToShow";
import UserToBlock from "./MessageToBlock";
import New from "./FindUserToBlock"
import User from "./User"
import { getConfig } from "../../../providers/configCartao";
import { useSelector, useDispatch } from "react-redux";
import { subscribeToUserUpdates, unsubscribeToUserUpdates } from "../../../sockets/Socket";
import { findAllUserBlocked } from "../../../providers/user";

export default function Users(props) {
  const [state, setState] = useState(0);
  const [config, setConfig] = useState({});
  const [userToBlock, setUserToBlock] = useState({});
  const [selectedUserToBlock, setSelectedUserToBlock] = useState();
  const [userMsg ,setUserMsg] = useState({})

  useEffect(() => {
    async function fetchData() {
      const [data] = await getConfig();
      setConfig(data);
    }
    fetchData();
  }, []);

  return (
    <>

      {state === 0 && <UserToShow setState={setState} setUserToBlock={setUserToBlock} validade={config} setSelectedUserToBlock={setSelectedUserToBlock} setUserMsg={setUserMsg} />}
      {state === 1 && <New setState={setState} userToBlock={userToBlock} setUserToBlock={setUserToBlock} validade={config} setSelectedUserToBlock={setSelectedUserToBlock}> </New>}
      {state === 2 && <UserToBlock setState={setState} userToBlock={userToBlock} validade={config} selectedUserToBlock={selectedUserToBlock} />}
      {state === 3 && <User setState={setState} userToBlock={userToBlock} validade={config} selectedUserToBlock={selectedUserToBlock}  userMsg={userMsg}/>}

    </>
  );
}
