import React, { useState, useEffect } from 'react';
import {
  Tab,
  Tabs,
  makeStyles,
  Box,
  Paper,
} from '@material-ui/core';
import { color } from '../../components/common/colors';
import Page from '../../components/common/page'
import Users from './user'
import Groups from './group'
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}


export default function Colaboradores(props) {
  const userData = useSelector(state => state.user);

  const [hasPermission, setHasPermission] = useState({});

  useEffect(() => {
    if (userData.permissionsDet !== null && typeof userData.permissionsDet !== 'undefined') {
      if (userData.permissionsDet.length) {
        setHasPermission(userData.permissionsDet[0].permissions);
      }
    }
  }, []);
  
  const classes = useStyles()
  
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page className={classes.root}
      title="Colaboradores"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs value={tab} onChange={handleChange} indicatorColor="primary"
          textColor="primary">
          <Tab label="Colaboradores" id={0} />
          <Tab label="Grupos" id={1} />
        </Tabs>
      </Paper>
      <TabPanel value={tab} index={0}>
        <Users />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Groups />
      </TabPanel>
    </Page>
  )
}