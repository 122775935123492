import React, { useEffect, useState } from 'react';
import { TextField, Button, makeStyles, Container, Card, CardContent, Grid, Typography, CircularProgress, MenuItem, Avatar, MuiThemeProvider, createMuiTheme, } from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { color } from '../../../../components/common/colors';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import { updateIndisponible, getAllProfissional } from '../../../../providers/schedules'
import { endOfDay, startOfDay } from 'date-fns';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btns: {
    marginTop: 50,
    marginBottom: 30
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  Avatar: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  option: {
    backgroundColor: 'white'
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function EditIndisponible({ setState, indisponible }) {


  const [dataInitial, setDataInitial] = useState(new Date());
  const [dataFinal, setDataFinal] = useState(new Date());
  const [hourInitial, onChangeHourInitial] = useState(new Date().setHours(`${indisponible.horaInit.split(':')[0] || 0}`, `${indisponible.horaInit.split(':')[1] || 0}`));
  const [hourFinal, onChangeHourFinal] = useState(new Date().setHours(`${indisponible.horaFim.split(':')[0] || 0}`, `${indisponible.horaFim.split(':')[1] || 0}`));
  const [allProfi, setAllProfi] = useState([])
  const [professional, setProfessional] = useState([])
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    nome: indisponible.nome,
    descricao: indisponible.descricao,
    profissional: indisponible.professionals,
    dataInit: indisponible.dataInit,
    dataFim: indisponible.dataFim,
    horaInit: indisponible.horaInit,
    horaFim: indisponible.horaFim,
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleUnavailabilites);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const data = await getAllProfissional()
    setAllProfi(data)
  }, [])

  const handleDateInit = (date) => {
    setDataInitial(startOfDay(date));
  };

  const handleDateFim = (date) => {
    setDataFinal(endOfDay(date));
  };

  const classes = useStyles()
  const goBack = () => {
    setState(0)
  }

  return (
    <>
      <Container maxWidth="md" disableGutters>
        <Formik
          //enableReinitialize={true}
          initialValues={{
            nome: indisponible.nome,
            descricao: indisponible.descricao,
            profissional: indisponible.professionals,
            dataInit: indisponible.dataInit,
            dataFim: indisponible.dataFim,
            horaInit: indisponible.horaInit,
            horaFim: indisponible.horaFim,
          }}
          validationSchema={Yup.object().shape({
            nome: Yup.string()
              .required('Campo obrigatório'),
            descricao: Yup.string()
              .required('Campo obrigatório'),
            // profissional: Yup.array()
            //   .test('categories', 'Selecione ao menos uma categoria', values => !!values.length),
            dataInit: Yup.date()
              .required('Campo obrigatório'),
            dataFim: Yup.date()
              .required('Campo obrigatório'),
            horaInit: Yup.string()
              .required('Campo obrigatório'),
            horaFim: Yup.string()
              .required('Campo obrigatório'),

          })}
          onSubmit={async (values, actions) => {
            try {
              values.id = indisponible.id
              await updateIndisponible(values)
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: indisponible.id }, after: values, menu: "Agendamentos", subMenu: "Indisponibilidade", });
              setState(0)
            } catch (e) {
              console.error("Error To Create a New Unavailability")
              console.error(e)
              setState(0)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3}>

                    <Grid item md={12} xs={12}>
                      <Grid container spacing={3}>

                        <Grid item md={6} xs={12} style={{ marginTop: 30 }}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            label="Nome"
                            name="nome"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nome}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.descricao && errors.descricao)}
                            helperText={touched.descricao && errors.descricao}
                            label="Descrição"
                            name="descricao"
                            value={values.descricao}
                            multiline
                            InputProps={{ classes: { input: classes.textArea } }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>

                          <Autocomplete
                            multiple
                            id="profissional"
                            disableCloseOnSelect
                            classes={{
                              popper: classes.option
                            }}
                            value={professional.id}
                            options={allProfi}
                            defaultValue={indisponible.professionals}
                            onChange={(e, value) => {
                              setProfessional(value)
                              setFieldValue('profissional', value)
                            }}
                            getOptionLabel={(option) => option.nome}
                            getOptionSelected={(x, u) => {
                              return x.nome === u.nome
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                style={{ marginTop: 10 }}
                                label="Profissional/Recurso" />
                            )}
                            style={{ backgroundColor: 'white' }}
                          />
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography variant="h5" display="block" style={{ marginTop: 20, marginBottom: -10 }}>
                            Data
                          </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} >
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <DatePicker
                                fullWidth
                                margin="normal"
                                id="dataInit"
                                label="Inicial"
                                format="dd/MM/yyyy"
                                value={values.dataInit}
                                onChange={(newValue) => {
                                  if (new Date(newValue) >= new Date(dataFinal)) {
                                    setDataFinal(endOfDay(newValue))
                                  }
                                  setDataInitial(newValue)
                                  setFieldValue('dataInit', newValue.toISOString())
                                }}
                                inputVariant={'outlined'}
                                variant='inline'
                                autoOk
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <DatePicker
                                fullWidth
                                margin="normal"
                                id="dataFim"
                                label="Final"
                                format="dd/MM/yyyy"
                                value={values.dataFim}
                                onChange={(newValue) => {
                                  newValue.setHours(0, 0, 0)
                                  if (new Date(newValue).getTime() <= new Date(dataFinal).getTime()) {
                                    setDataInitial(startOfDay(newValue))
                                  }
                                  setDataFinal(newValue)
                                  setFieldValue('dataFim', newValue.toISOString())
                                }}
                                inputVariant={'outlined'}
                                variant='inline'
                                autoOk
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                            Horário
                          </Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                id="time-picker-almoco-final"
                                label="Inicio"
                                hideTabs
                                error={Boolean(touched.horaInit && errors.horaInit)}
                                helperText={touched.horaInit && errors.horaInit}
                                ampm={false}
                                value={hourInitial}
                                onChange={(date) => {
                                  const data = new Date(date)
                                  onChangeHourInitial(date)
                                  setFieldValue('horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                id="time-picker-almoco-final"
                                label="Fim"
                                hideTabs
                                error={Boolean(touched.horaFim && errors.horaFim)}
                                helperText={touched.horaFim && errors.horaFim}
                                ampm={false}
                                value={hourFinal}
                                onChange={(date) => {
                                  const data = new Date(date)
                                  onChangeHourFinal(date)
                                  setFieldValue('horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>




                        <Grid container spacing={3} alignItems='center' justify='center' className={classes.btns}>
                          <Grid item md={3} xs={12}>
                            <Button
                              fullWidth
                              color='primary'
                              disabled={isSubmitting}
                              size="large"
                              variant="contained"
                              onClick={goBack}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                          {newPermission.edit && (
                            <Grid item md={3} xs={12}>
                              <Button
                                fullWidth
                                color='primary'
                                disabled={isSubmitting}
                                size="large"
                                type="submit"
                                variant="contained"
                              >
                                {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar'}
                                {/* Salvar */}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container>
    </>

  )
}
