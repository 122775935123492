import React, { useState, useEffect } from "react";

import Activate from "./activate";
import UserList from "./user-list";
import ProfileEdit from "./profile";
import NewUser from "./new-user";
import EditUser from "./edit-user";
import { getConfig } from "../../../providers/configCartao";
import { useSelector, useDispatch } from "react-redux";
import { subscribeToUserUpdates, unsubscribeToUserUpdates } from "../../../sockets/Socket";

export default function Users(props) {
  const [state, setState] = useState(0);
  const [config, setConfig] = useState({});
  const [userToEdit, setUserToEdit] = useState({});

  useEffect(() => {
    async function fetchData() {
      const [data] = await getConfig();
      setConfig(data);
    }
    fetchData();
  }, []);

  return (
    <>
      {state === 0 && (
        <UserList setState={setState} setUserToEdit={setUserToEdit} />
      )}
      {/* {(state === 1) && <Activate userActivate={userToActivate} setState={setState} />}
      {(state === 2) && <ProfileEdit userEdit={userToEdit} setState={setState} />} */}
      {state === 1 && <NewUser setState={setState} validade={config} />}
      {state === 2 && (
        <EditUser userEdit={userToEdit} setState={setState} validade={config} setUserToEdit={setUserToEdit} />
      )}




    </>
  );
}
