import api from './api'

export const blockUserByAdm = async (model) => {
  const { data } = await api.post('/user-block', model);
  return data;
}

export const unblockUserByAdm = async (model) => {
  const { data } = await api.post('/user-block/unblock', model);
  return data;
}

export async function showOnGrid(body) {
  const {data} = await api.post('/user-block/showOnGrid', body);
  return data;
}
