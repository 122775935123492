import api from './api'

export const alterationLog = async config => {
  const { data } = await api.post('log-create', config)
  return data
}

export const getLog = async (entry) => {
  const { data } = await api.post('log-find-all', { entry })
  return data
}

export const getLogSchedule = async (entry) => {
  const {data} = await api.post('/scheduleLog/findAll', {entry})
  return data
}

export const getLogUser = async (users)=> {
  const {data} = await api.post('logUser-findAll', {users})
  return data
}

