import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  createMuiTheme,
  MuiThemeProvider,
  Checkbox,
  MenuItem,
  FormControlLabel,
  Avatar,
  Snackbar,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { ErrorMessage, Formik } from 'formik';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import * as Yup from 'yup';
import { getCepInformation, newUnidade } from '../../../../providers/schedules';
import estados from '../../../../utils/estados'
import InputCel from '../../../../components/common/inputCelular'
import InputCep from '../../../../components/common/inputCep'
import { getCepInfos } from '../../../../utils/getCepInfos'
import googleApiKey from '../../../../config/googleApiKey'
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import { LocationCity } from '@material-ui/icons';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3),
  },
  card: {
    background: color.white,
    maxWidth: '50vw'
  },
  listText: {
    fontSize: 14
  },
  option: {
    backgroundColor: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputFile: {
    display: 'none'
  },
  Avatar: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

function NewUnity(props) {
  const classes = useStyles()
  // const [hourInit, onChangeHourInit] = useState(new Date().setHours(props.unidade ? props.unidade.horaInicio.split(':')[0] : '00', props.unidade ? props.unidade.horaInicio.split(':')[1] : '00'));
  // const [hourFinal, onChangeHourFinal] = useState(new Date().setHours(props.unidade ? props.unidade.horaFim.split(':')[0] : '00', props.unidade ? props.unidade.horaFim.split(':')[1] : '00'));
  const [show, setShow] = useState(false)
  const [lat, setLat] = useState()
  const [lng, setLng] = useState()
  const [mapZoom, setMapZoom] = useState(4)
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const user = useSelector(({ user }) => user);

  const setHourFieldValue = value => {
    if (value) {
      return null
    } else {
      return { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) }
    }
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(false);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              img: null,
              cep: '',
              estado: '',
              cidade: '',
              bairro: '',
              endereco: '',
              numero: '',
              complemento: '',
              nome: '',
              point: '',
              telefone: '',
              segunda: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              terca: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              quarta: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              quinta: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              sexta: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              sabado: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              domingo: { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) },
              disponible: true,
            }}
            validationSchema={Yup.object().shape({
              img: Yup.mixed().required('Escolha uma imagem'),
              cidade: Yup.string()
                .required('Campo obrigatório'),
              bairro: Yup.string()
                .required('Campo obrigatório'),
              numero: Yup.string()
                .required('Campo obrigatório'),
              endereco: Yup.string()
                .required('Campo obrigatório'),
              estado: Yup.string()
                .required('Campo obrigatório'),
              cep: Yup.string()
                .required('Campo obrigatório'),
              nome: Yup.string()
                .required('Campo obrigatório'),
              point: Yup.object()
                .required('Obrigatório a escolha de um ponto no mapa.'),
              telefone: Yup.string()
                .required('Campo obrigatório'),
            })}
            onSubmit={async (values) => {
              try {
                const body = new FormData()
                body.append('file', values.img)
                body.append('folder', UploadFolder.MISC)
                const data = await apiUpload(body);
                // delete values.img
                values.imgProfile = data.location

                if (values.segunda) {
                  values.segunda = {
                    horaInit: `${('0' + values.segunda.horaInit.getHours()).slice(-2).replace(' ', '')}: ${('0' + values.segunda.horaInit.getMinutes()).slice(-2).replace(' ', '')}`,
                    horaFim: `${('0' + values.segunda.horaFim.getHours()).slice(-2).replace(' ', '')}:${('0' + values.segunda.horaFim.getMinutes()).slice(-2).replace(' ', '')}`
                  }
                }
                if (values.terca) {
                  values.terca = {
                    horaInit: `${('0' + values.terca.horaInit.getHours()).slice(-2)}:${('0' + values.terca.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.terca.horaFim.getHours()).slice(-2)}:${('0' + values.terca.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.quarta) {
                  values.quarta = {
                    horaInit: `${('0' + values.quarta.horaInit.getHours()).slice(-2)}:${('0' + values.quarta.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.quarta.horaFim.getHours()).slice(-2)}:${('0' + values.quarta.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.quinta) {
                  values.quinta = {
                    horaInit: `${('0' + values.quinta.horaInit.getHours()).slice(-2)}:${('0' + values.quinta.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.quinta.horaFim.getHours()).slice(-2)}:${('0' + values.quinta.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.sexta) {
                  values.sexta = {
                    horaInit: `${('0' + values.sexta.horaInit.getHours()).slice(-2)}:${('0' + values.sexta.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.sexta.horaFim.getHours()).slice(-2)}:${('0' + values.sexta.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.sabado) {
                  values.sabado = {
                    horaInit: `${('0' + values.sabado.horaInit.getHours()).slice(-2)}:${('0' + values.sabado.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.sabado.horaFim.getHours()).slice(-2)}:${('0' + values.sabado.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                if (values.domingo) {
                  values.domingo = {
                    horaInit: `${('0' + values.domingo.horaInit.getHours()).slice(-2)}:${('0' + values.domingo.horaInit.getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + values.domingo.horaFim.getHours()).slice(-2)}:${('0' + values.domingo.horaFim.getMinutes()).slice(-2)}`
                  }
                }

                const result = await newUnidade(values)
                await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Agendamentos", subMenu: "Unidade", });

                if (result) return props.setState(0)
              } catch (e) {
                console.log('Error submit', e)
                setError(true)
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldError,
              isSubmitting,
              touched,
              values
            }) => (

              <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
                <CardContent>
                  <Grid container alignContent={'center'} spacing={3}>

                    <Grid item md={12}>
                      <FormControlLabel
                        value="start"
                        name="disponible"
                        control={<Checkbox
                          checked={values.disponible}
                          name="disponible"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Disponível"
                        labelPlacement="start"
                      />
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Grid container alignContent={'center'} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Typography paragraph variant={'h4'}>Cadastrar nova unidade </Typography>
                        </Grid>
                        <Grid item xs={12} >
                          <input
                            accept="image/*"
                            className={classes.inputFile}
                            name='img'
                            id="img-input"
                            type="file"
                            onChange={(event) => setFieldValue('img', event.target.files[0])}
                          />
                          <label htmlFor="img-input">

                            <Avatar
                              className={classes.Avatar}
                              src={values.img ? URL.createObjectURL(values.img) : ''}
                            >
                              <LocationCity style={{ height: 150, width: 150 }} />
                            </Avatar>
                          </label>
                          {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                            *{touched.img && errors.img}
                          </Typography>}
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            name="nome"
                            label="Nome"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nome}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.telefone && errors.telefone)}
                            helperText={touched.telefone && errors.telefone}
                            label="Telefone"
                            name="telefone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="tel"
                            value={values.telefone}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputCel,
                            }}
                          />
                        </Grid>

                      </Grid>

                      <Grid item md={12} xs={12} >
                        <Typography paragraph variant={'h4'} style={{ marginBottom: 0, marginTop: 23 }}>Dias e Horário de Funcionamento</Typography>
                      </Grid>

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Segunda-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.segunda}
                              color="primary"
                              onChange={(event) => setFieldValue('segunda', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.segunda && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.segunda.horaInit}
                                onChange={(date) => {
                                  setFieldValue('segunda.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.segunda.horaFim}
                                onChange={(date) => {
                                  setFieldValue('segunda.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Terça-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.terca}
                              color="primary"
                              onChange={(event) => setFieldValue('terca', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.terca && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.terca.horaInit}
                                onChange={(date) => {
                                  setFieldValue('terca.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.terca.horaFim}
                                onChange={(date) => {
                                  setFieldValue('terca.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Quarta-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.quarta}
                              color="primary"
                              onChange={(event) => setFieldValue('quarta', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.quarta && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.quarta.horaInit}
                                onChange={(date) => {
                                  setFieldValue('quarta.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.quarta.horaFim}
                                onChange={(date) => {
                                  setFieldValue('quarta.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Quinta-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.quinta}
                              color="primary"
                              onChange={(event) => setFieldValue('quinta', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.quinta && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.quinta.horaInit}
                                onChange={(date) => {
                                  setFieldValue('quinta.horaInit', date)

                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.quinta.horaFim}
                                onChange={(date) => {
                                  setFieldValue('quinta.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Sexta-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.sexta}
                              color="primary"
                              onChange={(event) => setFieldValue('sexta', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.sexta && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.sexta.horaInit}
                                onChange={(date) => {
                                  setFieldValue('sexta.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.sexta.horaFim}
                                onChange={(date) => {
                                  setFieldValue('sexta.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Sábado</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.sabado}
                              color="primary"
                              onChange={(event) => setFieldValue('sabado', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.sabado && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.sabado.horaInit}
                                onChange={(date) => {
                                  setFieldValue('sabado.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.sabado.horaFim}
                                onChange={(date) => {
                                  setFieldValue('sabado.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Domingo</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.domingo}
                              color="primary"
                              onChange={(event) => setFieldValue('domingo', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.domingo && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.domingo.horaInit}
                                onChange={(date) => {
                                  setFieldValue('domingo.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.domingo.horaFim}
                                onChange={(date) => {
                                  setFieldValue('domingo.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container alignContent={'center'} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Typography paragraph variant={'h4'}>Localização</Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.cep && errors.cep)}
                            helperText={touched.cep && errors.cep}
                            id='cep'
                            label="CEP"
                            name="cep"
                            onBlur={handleBlur}
                            onChange={async (event, value) => {
                              setFieldValue('cep', event.target.value)
                              if (event.target.value.length === 9) {
                                setOpen(true)
                                const dados = await getCepInfos(event.target.value)
                                if (dados.erro) {
                                  setFieldError('cep', 'CEP não encontrado')
                                } else {
                                  setFieldValue('estado', dados.uf)
                                  setFieldValue('cidade', dados.localidade)
                                  setFieldValue('bairro', dados.bairro)
                                  setFieldValue('endereco', dados.logradouro)
                                  const cepGeolocation = await getCepInformation(event.target.value);
                                  if (cepGeolocation && cepGeolocation.latitude) {
                                    const cepLat = parseFloat(
                                      cepGeolocation.latitude
                                    );
                                    const cepLong = parseFloat(
                                      cepGeolocation.longitude
                                    );
                                    
                                    setLat(cepLat);
                                    setLng(cepLong);
                                    setFieldValue("latLng", {
                                      type: "Point",
                                      coordinates: [cepLat, cepLong],
                                    });
                                    setMapZoom(10);
                                    setShow(true);
                                  }
                                }
                                setOpen(false)
                              }
                            }}
                            value={values.cep}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputCep,
                            }}
                          />
                        </Grid>

                        <Grid item md={8} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.cidade && errors.cidade)}
                            helperText={touched.cidade && errors.cidade}
                            id='cidade'
                            label="Cidade"
                            name="cidade"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cidade}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.estado && errors.estado)}
                            helperText={touched.estado && errors.estado}
                            select
                            id='uf'
                            label="Estado"
                            name="estado"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.estado}
                            variant="outlined"
                          >
                            {estados.map((option) => (
                              <MenuItem

                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.bairro && errors.bairro)}
                            helperText={touched.bairro && errors.bairro}
                            id='bairro'
                            label="Bairro"
                            name="bairro"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.bairro}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.endereco && errors.endereco)}
                            helperText={touched.endereco && errors.endereco}
                            id='rua'
                            label="Endereço"
                            name="endereco"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.endereco}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.numero && errors.numero)}
                            helperText={touched.numero && errors.numero}
                            label="Número"
                            type='number'
                            name="numero"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.numero}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.complemento && errors.complemento)}
                            helperText={touched.complemento && errors.complemento}
                            label="Complemento"
                            name="complemento"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.complemento}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <Map
                            google={props.google}
                            zoom={mapZoom}
                            onClick={(props, map, clickEvent) => {
                              setLat(clickEvent.latLng.lat());
                              setLng(clickEvent.latLng.lng());
                              setFieldValue('point', {
                                type: "Point",
                                coordinates: [clickEvent.latLng.lat(), clickEvent.latLng.lng()]
                              })
                              setShow(true)
                            }}
                            initialCenter={{
                              lat: -14.235004,
                              lng: -51.92528
                            }}
                            center={{
                              lat: lat,
                              lng: lng
                            }}
                            centerAroundCurrentLocation={true}
                            containerStyle={{ marginTop: 20, width: '100%', height: 450, position: 'relative' }}
                          >
                            {show && (
                              <Marker
                                position={{ lat, lng }}
                              />
                            )}
                          </Map>

                          <ErrorMessage name='point' render={msg => (<Typography style={{ fontSize: 14, margin: 10 }} color={'error'}>{msg}</Typography>)} />
                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid>
                  <Box my={2} display="flex" justifyContent="space-around">
                    <Button
                      onClick={() => props.setState(0)}
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Voltar
                    </Button>
                    <Button
                      onClick={() => handleSubmit()}
                      color='primary'
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Cadastrar
                    </Button>
                  </Box>

                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>

      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={'spinningBubbles'} color={color.red} />
      </Backdrop>

      <Snackbar open={error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          Escolha um horário de atendimento válido!
        </Alert>
      </Snackbar>
    </>
  )
}

export default GoogleApiWrapper({
  apiKey: (googleApiKey)
})(NewUnity)