import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  IconButton,
  Collapse,
  FormGroup,
  ListItemIcon,
  ListItemText,
  ListItem,
  Checkbox,
} from '@material-ui/core';
import { color } from '../../../components/common/colors';
import { Alert } from '@material-ui/lab';
import { getMensagensAutomaticas, updateMensagemAutomatica } from '../../../providers/mensagens';
import * as Yup from 'yup'
import { Close } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../providers/permissions';




const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(1)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    // width: '100%', 
    marginBottom: 20
  },
  option: {
    backgroundColor: 'white'
  }
}));

const MessageConfirmationSchedule = ({ mensagem, handleSetSuccess, handleSetError, isEditable }) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.messageAutomatic);
      setEdit(permissions.permissions.messageAutomatic.edit);
    };

    fetchPermissions();
  }, []);

  function handleEdit() {
    setEdit(prev => !prev)
  }
  if (!mensagem) return (<> </>)
  // Prezado Sr. {nome}, seu agendamento para {servico}, , em Unidade {unidade} está confirmado.
  return (
    newPermission.viz ? (
      <Formik
        initialValues={{
          title: mensagem.title,
          description: mensagem.description,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
        })}
        onSubmit={async (values) => {
          try {
            await updateMensagemAutomatica({ ...mensagem, ...values })
            handleSetSuccess()
            handleEdit()
          } catch (error) {
            console.log(error);
            handleSetError()
          }
        }}

      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <Card style={{ backgroundColor: 'white', paddingTop: 30, marginBottom: 20 }}>

            <CardContent>


              < Grid container spacing={3} justify={'center'} >

                <Grid item md={8} xs={12} >
                  <Typography variant={'p'} component='h4'>Confirmação de agendamento</Typography>
                </Grid>

                <Grid item md={8} xs={12}>
                  <Alert severity="info">Variáveis disponiveis: {mensagem.variaveis}</Alert>
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    disabled={!edit}
                    fullWidth
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    name="title"
                    label="Título para mensagem"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    disabled={!edit}
                    fullWidth
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    name="description"
                    label="Mensagem"
                    multiline
                    rowsMax={5}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>

                {isEditable && (
                  <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>

                    {!edit ? (

                      <Button
                        onClick={handleEdit}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Editar
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Atualizar
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Formik>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}

const MessageRememberSchedule = ({ mensagem, handleSetSuccess, handleSetError, isEditable }) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  function handleEdit() {
    setEdit(prev => !prev)
  }
  if (!mensagem) return (<> </>)
  return (
    <Formik
      initialValues={{
        title: mensagem.title,
        description: mensagem.description,
        timeRemember: '1'
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string()
          .required('Campo obrigatório'),
        description: Yup.string()
          .required('Campo obrigatório'),
      })}
      onSubmit={async (values) => {
        try {
          await updateMensagemAutomatica({ ...mensagem, ...values })
          handleSetSuccess()
          handleEdit()
        } catch (error) {
          console.log(error);
          handleSetError()
        }
      }}

    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <Card style={{ backgroundColor: 'white', paddingTop: 30, marginBottom: 20 }}>

          <CardContent >


            < Grid container spacing={3} justify={'center'} >

              <Grid item md={8} xs={12} >
                <Typography variant={'p'} component='h4'>Lembrete de agendamento</Typography>
              </Grid>

              <Grid item md={8} xs={12}>
                <Alert severity="info">Variáveis disponiveis: {mensagem.variaveis}</Alert>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  disabled={!edit}
                  fullWidth
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  name="title"
                  label="Título para mensagem"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.title}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={8} xs={12}>
                <TextField
                  disabled={!edit}
                  fullWidth
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                  name="description"
                  label="Mensagem"
                  multiline
                  rowsMax={5}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={8} xs={12} >
                <Typography variant={'p'} component='h4'>Relembrar usuários faltando:</Typography>
              </Grid>
              <Grid item md={8} xs={12}>
                <FormGroup row={false}>

                  <ListItem disabled={!edit} role={undefined} dense button onClick={() => setFieldValue('timeRemember', '1')}>
                    <ListItemIcon>
                      <Checkbox

                        edge="start"
                        size={'small'}
                        checked={values.timeRemember == '1' ? true : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': '24horas' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classe={{
                        primary: classes.listText
                      }}
                      id={'24'}
                      primary={'24 Horas'}
                    />
                  </ListItem>
                  <ListItem disabled={!edit} role={undefined} dense button onClick={() => setFieldValue('timeRemember', '2')}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        size={'small'}
                        checked={values.timeRemember == '2' ? true : false}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': 'yes' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.listText
                      }}
                      id={'48'}
                      primary={'48 Horas'}
                    />
                  </ListItem>
                </FormGroup>
              </Grid>

              {isEditable && (
                <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>

                  {!edit ? (
                    <Button
                      onClick={handleEdit}
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Editar
                    </Button>
                  ) : (
                    <Button
                      onClick={handleSubmit}
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Atualizar
                    </Button>
                  )}
                </Box>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Formik>
  )
}

const MessageBirthday = ({ mensagem, handleSetSuccess, handleSetError, isEditable }) => {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.messageAutomatic);
    };

    fetchPermissions();
  }, []);

  if (!mensagem) return (<> </>)

  function handleEdit() {
    setEdit(prev => !prev)
  }
  return (
    newPermission.viz ? (
      <Formik
        initialValues={{
          title: 'Feliz aniversário!',
          description: 'Parabéns Sr. {nome}. Te desejamos o melhor e que esta data se repita muitos anos.',
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
        })}
        onSubmit={async (values) => {
          try {
            await updateMensagemAutomatica({ ...mensagem, ...values })
            handleSetSuccess()
            handleEdit()
          } catch (error) {
            console.log(error);
            handleSetError()
          }
        }}

      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <Card style={{ backgroundColor: 'white', paddingTop: 30, marginBottom: 20 }}>

            <CardContent >


              < Grid container spacing={3} justify={'center'} >

                <Grid item md={8} xs={12} >
                  <Typography variant={'p'} component='h4'>Felicitação de aniversário</Typography>
                </Grid>

                <Grid item md={8} xs={12}>
                  <Alert severity="info">Variáveis disponiveis: {'{nome}'}</Alert>
                </Grid>
                <Grid item md={8} xs={12}>
                  <TextField
                    disabled={!edit}
                    fullWidth
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    name="title"
                    label="Título para mensagem"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    disabled={!edit}
                    fullWidth
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    name="description"
                    label="Mensagem"
                    multiline
                    rowsMax={5}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>

                {isEditable && (
                  <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>

                    {!edit ? (
                      <Button
                        onClick={handleEdit}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Editar
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Atualizar
                      </Button>
                    )}
                  </Box>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Formik>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}

export default function AutomaticMessage({ props }) {

  const classes = useStyles()
  const [mensagens, setMensagens] = useState({})
  const [show, setShow] = useState(false)
  const [mensagemAlert, setMensagemAlert] = useState('')
  const [severity, setSeverity] = useState('warning')
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.messageAutomatic);
    };

    fetchPermissions();
  }, []);
  async function fectchMensagens() {
    let mensagens = await getMensagensAutomaticas()
    console.table(mensagens.messageConfirm);
    setMensagens(mensagens)
  }
  useEffect(() => {
    fectchMensagens()
  }, [])

  function handleSetError() {
    setMensagemAlert('Houve um error ao atualizar!')
    setSeverity('error')
    setShow(true)
  }
  function handleSetSuccess() {
    setMensagemAlert('Mensagem atualizada com sucesso!')
    setSeverity('success')
    setShow(true)
  }
  return (
    <>
      <Collapse in={show} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShow(false);
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {mensagemAlert}
        </Alert>
      </Collapse>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" >
          {newPermission.viz ? (
            <>
              <MessageConfirmationSchedule
                mensagem={mensagens.messageConfirm}
                handleSetError={handleSetError}
                handleSetSuccess={handleSetSuccess}
                isEditable={newPermission.edit}
              />
              <MessageRememberSchedule
                mensagem={mensagens.messageRemember}
                handleSetError={handleSetError}
                handleSetSuccess={handleSetSuccess}
                isEditable={newPermission.edit}
              />
              <MessageBirthday
                mensagem={mensagens.messageHappyBirthday}
                handleSetError={handleSetError}
                handleSetSuccess={handleSetSuccess}
                isEditable={newPermission.edit}
              />
            </>
          ) : (
            <>
              Sem permissão
            </>
          )}
        </Container>
      </Box>
    </>
  )
}