import React, { useEffect, useState } from 'react';
import {
  TextField, Button, makeStyles, Container, Card, CardContent, Grid,
  Typography, CircularProgress, MenuItem, Avatar,
  MuiThemeProvider, createMuiTheme, Snackbar,
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { color } from '../../../../components/common/colors';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import { alterationLog } from '../../../../providers/log';
import { useSelector } from 'react-redux';
import { newExtraHour, getAllProfissional, getAllIndisponible } from '../../../../providers/schedules'
import { endOfDay, isWithinInterval, startOfDay, format, eachDayOfInterval, isPast } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btns: {
    marginTop: 50,
    marginBottom: 30
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  Avatar: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  option: {
    backgroundColor: 'white'
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function NewExtra({ setState }) {

  const [dataInitial, setDataInitial] = useState(new Date());
  const [hourInitial, onChangeHourInitial] = useState(new Date().setHours('00', '00'));
  const [hourFinal, onChangeHourFinal] = useState(new Date().setHours('00', '00'));
  const [allProfi, setAllProfi] = useState([])
  const [professional, setProfessional] = useState([])
  const [indisponible, setIndisponible] = useState([]);
  const [blockAlert, setBlockAlert] = useState(false);
  const [blockAlert2, setBlockAlert2] = useState(false);
  const [blockAlert3, setBlockAlert3] = useState(false);
  const [blockAlert4, setBlockAlert4] = useState("");

  const user = useSelector(({ user }) => user);

  useEffect(() => {
    async function fetchData() {
      const data = await getAllProfissional()
      setAllProfi(data)

      const indis = await getAllIndisponible();
      setIndisponible(indis);

    }
    fetchData()
  }, [])

  const classes = useStyles()
  const goBack = () => {
    setState(0)
  }

  function permitedHours() {
    if (hourInitial <= hourFinal) {
      return true
    } else {
      return false
    }
  }
  useEffect(() => {
    if (!permitedHours()) {
      setBlockAlert2(true);
    }
    if (workingDays()) {
      setBlockAlert3(true)
    }
  })
  function Disponibilidade() {

    let choiceProfissional = professional.map((a) => a.id);
    const horaDiaProfissionalIndisponivel = indisponible.map((i) => {
      return {
        dataInicial: new Date(new Date(i.dataInit).getFullYear(), new Date(i.dataInit).getMonth(), new Date(i.dataInit).getDate(), 0, 0),
        dataFinal: new Date(new Date(i.dataFim).getFullYear(), new Date(i.dataFim).getMonth(), new Date(i.dataFim).getDate(), 0, 0),
        horaInicial: i.horaInit,
        horaFinal: i.horaFim,
        id: (i.professionals).map((z) => z.id)
      };
    }).filter((i) => i.id.some(id => choiceProfissional.includes(id)))
    for (const x of horaDiaProfissionalIndisponivel) {
      let horaInicialIndisponibilidade = (parseFloat((x.horaInicial.replace(/:/i, ".")))) * 3600
      let horaInicialExtra = (parseFloat(format(hourInitial, "HH.mm"))) * 3600
      let horaFinalIndisponibilidade = (parseFloat((x.horaFinal.replace(/:/i, ".")))) * 3600
      let horaFinalExtra = (parseFloat(format(hourFinal, "HH.mm"))) * 3600

      let timeInitialChoiceForComparation = dataInitial.getTime()
      let timeInitialIndispobilitForComparation = x.dataInicial.getTime()
      let timeFinalIndiponibilityComparation = endOfDay(x.dataFinal).getTime()

      const verifyIndisponibilityAndExtraHour =
        (timeInitialIndispobilitForComparation <= timeInitialChoiceForComparation && timeInitialChoiceForComparation <= timeFinalIndiponibilityComparation) && (
          (horaInicialIndisponibilidade <= horaInicialExtra && horaInicialExtra <= horaFinalIndisponibilidade)
          || (horaInicialIndisponibilidade <= horaFinalExtra && horaFinalExtra <= horaFinalIndisponibilidade)
          || (horaInicialExtra <= horaInicialIndisponibilidade && horaFinalExtra >= horaFinalIndisponibilidade)
        );
      while (verifyIndisponibilityAndExtraHour === true) {
        return true
      }
    }
  }
  Disponibilidade()
  function DayName(dataInitial) {
    switch (dataInitial) {
      case 'Sunday':
        return 'Domingo'
      case 'Monday':
        return 'Segunda'
      case 'Tuesday':
        return 'Terca'
      case 'Wednesday':
        return 'Quarta'
      case 'Thursday':
        return 'Quinta'
      case 'Friday':
        return 'Sexta'
      case 'Saturday':
        return 'Sabado'
      default:
        break;
    }
  }

  function workingDays() {
    let profChoice2 = professional.map((e) => {
      return {
        ...e,
        id: e.id,
        Segunda: e.segunda,
        Terca: e.terca,
        Quarta: e.quarta,
        Quinta: e.quinta,
        Sexta: e.sexta,
        Sabado: e.sabado,
        Domingo: e.domingo,
      }
    });
    let dayName = DayName(format(dataInitial, "EEEE"))
    const profChoiceAvailable = profChoice2.map((c) => {
      if (!c) return true;

      const periodo = c[dayName];
      if (!periodo) return false;

      // esse if trocar por comparacao com os horarios selecionados
      if (periodo) {

        //horario de almoço tratato para segundos.
        // let horaAlmocoInicial = c.horaAlmocoInicio
        let horaAlmocoInicial2 = c.horaAlmocoInicio
        let horaAlmocoInicial3 = parseFloat(horaAlmocoInicial2.replace(/:/i, ".")) * 3600
        //inicio almoço ok
        let horaAlmocoFim = c.horaAlmocoFim
        let horaAlmocoFim2 = parseFloat(horaAlmocoFim.replace(/:/i, ".")) * 3600
        //fim hora de almoço
        //começo e fim de atendimentos
        let startWorkDay = parseFloat(periodo.horaInit.replace(/:/i, ".")) * 3600
        let endWorkDay = parseFloat(periodo.horaFim.replace(/:/i, ".")) * 3600
        //
        //horas escolhidas
        let horaInicialExtra = (parseFloat(format(hourInitial, "HH.mm"))) * 3600
        let horaFinalExtra = (parseFloat(format(hourFinal, "HH.mm"))) * 3600
        //

        if (startWorkDay < horaAlmocoInicial3 && endWorkDay <= horaAlmocoInicial3) {
          if ((startWorkDay >= horaInicialExtra && horaFinalExtra > startWorkDay) ||
            (endWorkDay > horaInicialExtra && horaFinalExtra >= endWorkDay) ||
            (startWorkDay <= horaInicialExtra && horaFinalExtra <= endWorkDay) ||
            (startWorkDay >= horaInicialExtra && horaFinalExtra >= endWorkDay)) {
            return true
          }
        } else if (startWorkDay >= horaAlmocoFim2 && endWorkDay > horaAlmocoFim2) {
          if ((startWorkDay >= horaInicialExtra && horaFinalExtra > startWorkDay) ||
            (endWorkDay > horaInicialExtra && horaFinalExtra >= endWorkDay) ||
            (startWorkDay <= horaInicialExtra && horaFinalExtra <= endWorkDay) ||
            (startWorkDay >= horaInicialExtra && horaFinalExtra >= endWorkDay)) {
            return true
          }
        } else if (startWorkDay <= horaAlmocoInicial3 && horaAlmocoFim2 <= endWorkDay) {
          if (horaInicialExtra >= horaAlmocoInicial3 && horaFinalExtra <= horaAlmocoFim2) {
            return false
          } else if ((startWorkDay >= horaInicialExtra && horaFinalExtra > startWorkDay) ||
            (endWorkDay > horaInicialExtra && horaFinalExtra >= endWorkDay) ||
            (startWorkDay <= horaInicialExtra && horaFinalExtra <= endWorkDay) ||
            (startWorkDay >= horaInicialExtra && horaFinalExtra >= endWorkDay)) {
            return true
          }
          return false;
        }
      }
    })

    const unavailable = profChoiceAvailable.some(value => value === true)
    return unavailable
  }

  workingDays()


  const handleCloseBlockAlert = (event, reason) => {
    setBlockAlert(false);
  }
  const handleCloseBlockAlert2 = (event, reason) => {
    setBlockAlert2(false);
  }
  const handleCloseBlockAlert3 = (event, reason) => {
    setBlockAlert3(false);
  }
  const handleCloseBlockAlert4 = (event, reason) => {
    setBlockAlert4();
  }
  return (
    <>
      <Container maxWidth="md" disableGutters>
        <Formik
          //enableReinitialize={true}
          initialValues={{
            nome: '',
            descricao: '',
            profissional: [],
            dataInit: startOfDay(new Date()),
            horaInit: new Date(),
            horaFim: new Date(),
          }}
          validationSchema={Yup.object().shape({
            nome: Yup.string()
              .required('Campo obrigatório'),
            descricao: Yup.string()
              .required('Campo obrigatório'),
            profissional: Yup.array()
              .test('profissional', 'Selecione ao menos uma categoria', values => !!values.length),
            dataInit: Yup.date()
              .required('Campo obrigatório'),
            horaInit: Yup.string()
              .required('Campo obrigatório'),
            horaFim: Yup.string()
              .required('Campo obrigatório'),
          })}
          onSubmit={async (values, actions) => {
            if (workingDays() === false) {
              if (permitedHours()) {
                if (Disponibilidade() === undefined) {
                  try {
                    await newExtraHour(values)
                    await alterationLog({ action: 'CREATE', menu: "Agendamentos", subMenu: "Agenda Extra", idUser: user.id, before: '', after: { ...values, profissionais: values.profissional.length } });
                    setState(0)
                  } catch (e) {
                    console.error("Error To Create a New Unavailability")
                    console.error(e)
                    setBlockAlert4(e.response.data.error)
                  }
                } else {
                  setBlockAlert(true)
                }
              } else {
                setBlockAlert2(true)
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3}>

                    <Grid item md={12} xs={12}>
                      <Grid container spacing={3}>

                        <Grid item md={6} xs={12} style={{ marginTop: 30 }}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            label="Nome"
                            name="nome"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nome}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.descricao && errors.descricao)}
                            helperText={touched.descricao && errors.descricao}
                            label="Descrição"
                            name="descricao"
                            value={values.descricao}
                            multiline
                            InputProps={{ classes: { input: classes.textArea } }}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                          />
                        </Grid>



                        <Grid item md={12} xs={12} >
                          <Typography variant="h5" display="block" style={{ marginTop: 20, marginBottom: -10 }}>
                            Data
                          </Typography>
                        </Grid>

                        <Grid item md={6} xs={12} >
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <DatePicker
                                fullWidth
                                margin="normal"
                                id="dataInit"
                                label="Dia"
                                format="dd/MM/yyyy"
                                value={dataInitial}
                                onChange={(date) => {
                                  setDataInitial(startOfDay(date));
                                  setFieldValue('dataInit', startOfDay(date));
                                }}
                                inputVariant={'outlined'}
                                variant='inline'
                                autoOk
                                disablePast
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={12} xs={12} >
                          <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                            Horário
                          </Typography>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                id="time-picker-almoco-final"
                                label="Inicio"
                                hideTabs
                                error={Boolean(touched.horaInit && errors.horaInit)}
                                helperText={touched.horaInit && errors.horaInit}
                                ampm={false}
                                value={hourInitial}
                                onChange={(date) => {
                                  const data = new Date(date)
                                  onChangeHourInitial(date)
                                  setFieldValue('horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                id="time-picker-almoco-final"
                                label="Fim"
                                hideTabs
                                error={Boolean(touched.horaFim && errors.horaFim)}
                                helperText={touched.horaFim && errors.horaFim}
                                ampm={false}
                                value={hourFinal}
                                onChange={(date) => {
                                  const data = new Date(date)
                                  onChangeHourFinal(date)
                                  setFieldValue('horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item xs={12}>

                          <Autocomplete
                            multiple
                            id="profissional"
                            disableCloseOnSelect
                            classes={{
                              popper: classes.option
                            }}
                            value={professional.id}
                            options={allProfi}
                            getOptionLabel={(option) => option.nome}
                            onChange={(e, value) => {
                              setProfessional(value)
                              setFieldValue('profissional', value)
                            }}
                            style={{ backgroundColor: 'white' }}
                            renderInput={(params) => (
                              <TextField {...params}
                                variant="outlined" style={{ marginTop: 10 }}
                                name="profissional"
                                label="Profissional/Recurso"
                                error={Boolean(touched.profissional && errors.profissional)}
                                helperText={touched.profissional && errors.profissional} />
                            )}
                          />
                        </Grid>
                        <Grid container spacing={3} alignItems='center' justify='center' className={classes.btns}>
                          <Grid item md={3} xs={12}>
                            <Button
                              fullWidth
                              color='primary'
                              disabled={isSubmitting}
                              size="large"
                              variant="contained"
                              onClick={goBack}
                            >
                              Cancelar
                            </Button>
                          </Grid>
                          <Grid item md={3} xs={12}>
                            <Button
                              fullWidth
                              color='primary'
                              disabled={isSubmitting}
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar'}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
        <Snackbar
          open={blockAlert}
          autoHideDuration={4000}
          onClose={handleCloseBlockAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={handleCloseBlockAlert} severity="error">
            Existe uma indisponibilidade para essa data
          </Alert>
        </Snackbar>
        <Snackbar
          open={blockAlert2}
          autoHideDuration={4000}
          onClose={handleCloseBlockAlert2}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={handleCloseBlockAlert2} severity="error">
            Horário de inicio maior que Horário Fim.
          </Alert>
        </Snackbar>
        <Snackbar
          open={blockAlert3}
          autoHideDuration={4000}
          onClose={handleCloseBlockAlert3}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={handleCloseBlockAlert3} severity="error">
            Agenda normal ja existe para essa data e hora
          </Alert>
        </Snackbar>
        <Snackbar
          open={blockAlert4}
          autoHideDuration={4000}
          onClose={handleCloseBlockAlert4}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={handleCloseBlockAlert4} severity="error">
            {blockAlert4}
          </Alert>
        </Snackbar>
      </Container>
    </>

  )
}
