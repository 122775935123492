import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Collapse, IconButton, makeStyles, Box, Container, Card, CardContent, Grid, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Autocomplete, Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { newSchedule, getAllProfissional } from '../../../../providers/schedules';
import CloseIcon from '@material-ui/icons/Close';
import { alterationLog } from '../../../../providers/log';
import { useSelector } from 'react-redux';

const unmaskCpf = value => {
  return value.replace(/\./g, '').replace('-', '')
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  option: {
    backgroundColor: 'white',
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});
export default function NewReservedTime({ setState }) {
  const classes = useStyles()
  // const [state, setState] = useState(0)
  const [user, setUser] = useState()
  const [error, setError] = useState(false)
  const [date, onChangeDate] = useState(new Date());
  const [hourInit, onChangeHourInit] = useState(new Date().setHours('00', '00'));
  const [hourFinal, onChangeHourFinal] = useState(new Date().setHours('00', '00'));

  const [profissionais, setProfissionais] = useState()
  const [profissionalSelected, setProfissionalSelected] = useState()

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    async function fetchData() {
      setProfissionais(await getAllProfissional())
    }
    fetchData()

  }, [])

  function disableDays(date) {
    let days = profissionalSelected.diasDaSemana
    let result = days.filter(item => item == date.getDay())
    return result.length > 0 ? false : true
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"

    >
      <Collapse in={error} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={'error'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      </Collapse>
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            idUser: 'BLOCK_TIME_SCHEDULE',
            idProfissional: '',
            idServico: 'BLOCK_TIME_SCHEDULE',
            idProcedimento: 'BLOCK_TIME_SCHEDULE',
            horaInicio: '',
            horaFim: '',
            data: ''

          }}
          onSubmit={async (values) => {
            try {
              const result = await newSchedule(values)
              await alterationLog({ action: 'CREATE', idUser: userData.id, before: '', after: values, menu: "Agendamentos", subMenu: "Agendamento", });
              if (result) return setState(0)
            } catch (e) {
              setError('Horário não disponível')
            }
          }}
          validationSchema={Yup.object().shape({
            idProfissional: Yup.string()
              .required('Campo obrigatório'),
            horaInicio: Yup.string()
              .required('Campo obrigatório'),
            horaFim: Yup.string()
              .required('Campo obrigatório'),
            data: Yup.date()
              .required('Campo obrigatório'),
          })}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
              <CardContent>
                <Grid container justify={'center'} spacing={2}>

                  <Grid item md={8} xs={12} >
                    <Autocomplete
                      fullWidth
                      id="select-profissional"
                      color={'primary'}
                      noOptionsText={'Nenhum profissional ou serviço encontrado'}
                      options={profissionais ? profissionais.map((option) => option) : []}
                      getOptionLabel={option => option.nome}
                      getOptionSelected={option => option.nome}
                      onChange={(e, value) => {
                        setProfissionalSelected(value)
                        setFieldValue('idProfissional', value ? value.id : '')
                      }}
                      onBlur={handleBlur}
                      classes={{
                        popper: classes.option
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onBlur={handleBlur}
                          margin={'normal'}
                          label="Profissional/Recurso"
                          variant="outlined"
                          error={Boolean(touched.idProfissional && errors.idProfissional)}
                          helperText={touched.idProfissional && errors.idProfissional}
                          name="idProfissional"
                        />
                      )}

                    />
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <Typography variant='h4' >Dia e Hora</Typography>
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <DatePicker
                          fullWidth
                          margin="normal"
                          id="date-picker-dialog"
                          label="Dia"
                          format="dd/MM/yyyy"
                          autoOk
                          disabled={profissionalSelected ? false : true}
                          shouldDisableDate={disableDays}
                          value={date}
                          onChange={(newValue) => {
                            onChangeDate(newValue)
                            setFieldValue('data', newValue)
                          }}
                          inputVariant={'outlined'}
                          variant='inline'
                          minDate={new Date()}
                        />

                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <TimePicker
                          fullWidth
                          id="time-picker-init"
                          label="Hora"
                          margin={'normal'}
                          autoOk
                          minutesStep={5}
                          disabled={profissionalSelected ? false : true}
                          hideTabs
                          error={Boolean(touched.horaInicio && errors.horaInicio)}
                          helperText={touched.horaInicio && errors.horaInicio}
                          ampm={false}
                          value={hourInit}
                          onChange={(date) => {
                            const inicio = new Date(date)
                            onChangeHourInit(date)
                            setFieldValue('horaInicio', `${inicio.getHours()}:${inicio.getMinutes() <= 9 ? '0' + inicio.getMinutes() : inicio.getMinutes()}`)
                          }}
                          variant='inline'
                          inputVariant={'outlined'}
                        />

                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                        <TimePicker
                          fullWidth
                          id="time-picker-init"
                          label="Hora"
                          margin={'normal'}
                          autoOk
                          minutesStep={5}
                          disabled={profissionalSelected ? false : true}
                          hideTabs
                          error={Boolean(touched.horaFim && errors.horaFim)}
                          helperText={touched.horaFim && errors.horaFim}
                          ampm={false}
                          value={hourFinal}
                          onChange={(date) => {
                            const fim = new Date(date)
                            onChangeHourFinal(date)
                            setFieldValue('horaFim', `${fim.getHours()}:${fim.getMinutes() <= 9 ? '0' + fim.getMinutes() : fim.getMinutes()}`)
                          }}
                          variant='inline'
                          inputVariant={'outlined'}
                        />

                      </MuiPickersUtilsProvider>
                    </MuiThemeProvider>
                  </Grid>
                  <Grid item md={8} xs={12}>

                    <Box my={2} display="flex" justifyContent={"space-between"}>
                      <Button
                        onClick={() => setState(0)}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        variant="contained"
                      >
                        Voltar
                      </Button>


                      <Button
                        onClick={() => handleSubmit()}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Cadastrar
                      </Button>
                    </Box>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          )}
        </Formik>
      </Container>
    </Box>
  )
}