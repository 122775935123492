import React, { useState } from 'react';
import { makeStyles, Paper, Tab, Tabs, Box, Typography } from '@material-ui/core';
import { color } from '../../../components/common/colors';
import EditParceiro from './edit-parceiro';
import ListParceiro from './list-parceiro';
import AddParceiro from './new-parceiro';
import EditCategory from '../category/edit-category'
import ListCategory from '../category/list-category'
import AddCategory from '../category/new-category'
import EditTag from '../tagtab/edit-tag'
import ListTag from '../tagtab/list-tag'
import AddTag from '../tagtab/new-tag'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    // width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Partner(props) {

  const [state, setState] = useState(0)
  const [stateCategory, setStateCategory] = useState(0)
  const [tab, setTab] = useState(0);
  const [partnerToEdit, setPartnerToEdit] = useState()
  const [categoryToEdit, setCategoryToEdit] = useState()
  const [tagToEdit, setTagToEdit] = useState()
  const classes = useStyles()
  const handleChange = (event, newValue) => {
    setTab(newValue);
    setState(0)
    setStateCategory(0)
  };

  return (
    <>
      <Paper position="static" className={classes.paper}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        // variant="scrollable"
        >
          <Tab label="Parceiro" {...a11yProps(0)} />
          <Tab label="Categoria" {...a11yProps(1)} />
          <Tab label="Tag" {...a11yProps(2)} />

        </Tabs>
      </Paper>

      <TabPanel value={tab} index={0}>
        {state === 0 && (
          <ListParceiro setState={setState} setPartnerToEdit={setPartnerToEdit} />
        )}
        {state === 1 && (
          <AddParceiro setState={setState} setTab={setTab} setStateCategory={setStateCategory} />
        )}
        {state === 2 && (
          <EditParceiro partner={partnerToEdit} setState={setState} setTab={setTab} setStateCategory={setStateCategory} />
        )}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {stateCategory === 0 && (
          <ListCategory setState={setStateCategory} setCategoryToEdit={setCategoryToEdit} setTab={setTab} />
        )}
        {stateCategory === 1 && (
          <AddCategory setState={setStateCategory} setTab={setTab} />
        )}
        {stateCategory === 2 && (
          <EditCategory setState={setStateCategory} category={categoryToEdit} setTab={setTab} />
        )}
      </TabPanel>
      <TabPanel value={tab} index={2}>
        {stateCategory === 0 && (
          <ListTag setState={setStateCategory} setTagToEdit={setTagToEdit} setTab={setTab} />
        )}
        {stateCategory === 1 && (
          <AddTag setState={setStateCategory} setTab={setTab} />
        )}
        {stateCategory === 2 && (
          <EditTag setState={setStateCategory} tag={tagToEdit} setTab={setTab} />
        )}
      </TabPanel>
    </>
  )
}