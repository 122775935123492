import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { color } from '../../common/colors';
import { format } from 'date-fns';
import { getAll, deleteSlide } from '../../../providers/slides';
import { getPermissions } from '../../../providers/permissions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function ShowSlides({ setState, setSlideToEdit, hasPermission }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [slideList, setSlideList] = useState([]);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.newsSlides);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const slideData = await getAll()
    setSlideList(slideData.slides)
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = slide => {
    setSlideToEdit(slide)
    setState(2)
  }

  const handleDelete = async id => {
    await deleteSlide(id)
    setSlideList(slideList.filter(item => item.id !== id))
  }

  const emptyRownSlide = rowsPerPage - Math.min(rowsPerPage, slideList.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <>
        <Container maxWidth="md" disableGutters>
          <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
            <Button variant='contained' className={classes.btnAdd} onClick={() => setState(1)}>Adicionar Slide</Button>
          </Box>
          <Paper className={classes.paper}>
            <MuiThemeProvider theme={defaultMaterialTheme}>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader className={classes.table}>
                  <TableHead className={classes.headerTb}>
                    <TableRow>
                      <TableCell key={'title'}>
                        {"Titulo"}
                      </TableCell>
                      <TableCell key={'date'}>
                        {"Data da postagem"}
                      </TableCell>
                      <TableCell key={'actions'} align="right">
                        {"Ações"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slideList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <TableRow key={row.id}>
                            <TableCell >{row.title}</TableCell>
                            <TableCell >{format(new Date(row.createdAt), 'dd/MM/yyyy')}</TableCell>
                            <TableCell align="right" padding='none' >
                              {newPermission.det && <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>}
                              {/* {newPermission.edit && <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>} */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRownSlide > 0 && (
                      <TableRow style={{ height: 53 * emptyRownSlide }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={'Linhas por página'}
                component="div"
                count={slideList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </MuiThemeProvider >
          </Paper>
        </Container>
        <Box className={classes.footer} />
      </>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}