import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
  Divider,
  TextField,
  createMuiTheme,
  MuiThemeProvider,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Formik } from 'formik';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { creditPoints } from '../../../../providers/userAdm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  item: {
    color: '#979797',
    fontWeight: 'bold',
    letterSpacing: 1.5
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      fixedDecimalScale={true}
      decimalSeparator={','}
      thousandSeparator={'.'}
      isNumericString
      prefix=""
    />
  );
}
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function CreditMany({ setState, credit, users, typeCredit }) {
  const classes = useStyles()
  const user = useSelector(({ user }) => user);

  const [hour, onChangeHour] = useState(new Date().setHours('04', '20'));
  const [date, onChangeDate] = useState(new Date().setMonth(9, 10));

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          nome: typeCredit === 'USER' ? users[0].nome : users[0].name,
          saldo: typeCredit === 'USER' ? users[0].saldo : '6969',
          valor: '0,00',
          descrição: '',
          data: '',
          hora: ''
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldError,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>

                  <Grid item md={12} xs={12}>
                    <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                      <h1>
                        Creditar Pontos em Lote
                      </h1>
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        <Typography variant="h5" display="block" style={{ marginTop: 20 }}>
                          <p style={{ color: 'red' }}>{users.length} associados selecionados.</p>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                      Dados da Transação
                    </Typography>
                    <Divider style={{ marginBottom: 10 }} />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item md={6} xs={12} style={{ marginBottom: -5 }}>
                        <TextField
                          fullWidth
                          label="Valor a ser creditado"
                          name="valor"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.valor}
                          variant="outlined"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: -5 }}>
                        <TextField
                          fullWidth
                          label="Descrição"
                          name="descricao"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.descricao}
                          variant="outlined"
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: -5 }}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk
                              fullWidth
                              id="date-picker-dialog"
                              label="Dia"
                              format="dd/MM/yyyy"
                              value={date}
                              onChange={(newValue) => {
                                onChangeDate(newValue)
                                setFieldValue('data', newValue)
                              }}
                              inputVariant={'outlined'}
                              variant='inline'
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                      <Grid item md={6} xs={12} style={{ marginBottom: -5 }}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              autoOk
                              fullWidth
                              id="hora"
                              label="Hora"
                              hideTabs
                              ampm={false}
                              value={hour}
                              onChange={(date) => {
                                const data = new Date(date)
                                onChangeHour(date)
                                setFieldValue('hora', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                              }}
                              variant='inline'
                              inputVariant={'outlined'}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Grid container spacing={3} style={{ marginTop: 40 }} alignItems='flex-end' justify='flex-end'>
                      <Grid item md={3}>
                        <Button
                          fullWidth
                          color='primary'
                          size="large"
                          variant="contained"
                          onClick={() => setState(2)}
                        >
                          Voltar
                        </Button>
                      </Grid>
                      <Grid item md={3}>
                        <Button
                          fullWidth
                          color='primary'
                          size="large"
                          variant="contained"
                          onClick={async () => {
                            if (parseFloat(values.valor) === 0 || parseFloat(values.valor) < 0  || values.valor === '') {
                              alert("Valor não pode ser 0!")
                            } else {
                              try {
                                await creditPoints({
                                  userAdmId: user.id,
                                  userId: users.map((user) => user.id),
                                  userType: typeCredit,
                                  value: values.valor,
                                  description: values.descricao,
                                  offset: new Date().getTimezoneOffset(),
                                });
                                setState(0)
                                console.log('Pontos creditados com sucesso!');
                              } catch (e) {
                                if (e.response.data.error) {
                                  switch (e.response.data.error) {
                                    case 'NOT_SUFFICIENT':
                                      console.log('Saldo insuficiente!');
                                      break;
                                    case 'INVALID_GIVER_TYPE':
                                    case 'INVALID_RECEIVER_TYPE':
                                    case 'INVALID_VALUE':
                                    case "Cannot read property 'dataValues' of null":
                                      console.log('Erro ao buscar informações no servidor!');
                                      break;
                                    default:
                                      console.log('Ocorreu um erro inesperado no servidor.');
                                  }
                                  console.log('====<ERROR from API @ credit-one>=====');
                                  console.log(e.response.data.error);
                                  console.log('===============</ERROR>===============');
                                } else {
                                  // Se o erro não vier da API, mostra a mensagem de erro genérica abaixo.
                                  console.log('====<ERROR from ADM @ credit-one>=====');
                                  console.log(e.message);
                                  console.log('===============</ERROR>===============');
                                }
                              }
                            }
                          }}
                        >
                          Enviar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}