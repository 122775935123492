import { ADD_USERS, RMV_USERS } from '../actions/userAction';

const initState = null

const usersReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_USERS:
            return {...state, ...action.payload};
        case RMV_USERS:
            return initState
        default:
            return state;
    }
};

export default usersReducer;