import React, { useEffect, useState } from "react";
import "date-fns";
import pt from "date-fns/locale/pt-BR";
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Button,
  CircularProgress,
  MuiThemeProvider,
  createMuiTheme,
  TextField,
  Checkbox,
} from "@material-ui/core";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@material-ui/icons";
import { findAllCategorys } from "../../../providers/partner";
import { Autocomplete } from "@material-ui/lab";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from 'yup';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Formik } from "formik";
import { color } from "../../../components/common/colors";
import { editConfig, findConfig } from "../../../providers/partner";
import { useSelector } from "react-redux";
import { getPermissions } from "../../../providers/permissions";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: "100%",
  },
  headerPage: {
    marginBottom: 30,
  },
  paper: {
    backgroundColor: color.white,
    width: "100%",
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    "&:hover": {
      backgroundColor: "#a35655",
    },
  },
  card: {
    background: color.white,
    marginBottom: 60,
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  date: {
    opacity: 1,
  },  
  option: {
    backgroundColor: 'white',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red,
    },
  },
});

export default function Configuration(props) {
  const classes = useStyles();
  const [config, setConfig] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [configCategories, setConfigCategories] = useState([]);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerConfig);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const { configCount, configCategories, allCategories } = await findConfig();
      
      setConfig(configCount);
      setCategoryList(allCategories);
      setCategoriesSelected(configCategories)
      setConfigCategories(configCategories);
    }
    fetchData();
  }, []);

  if (!newPermission.viz) {
    return (
      <>
        Sem permissão de acesso
      </>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={12} xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={{
                count: config.count,
                categories: [],
              }}
              validationSchema={Yup.object().shape({
                count: Yup.number()
                  .required('Campo obrigatório!'),
              })}
              onSubmit={async (values, actions) => {
                try {
                  await editConfig({
                    config: {
                      id: config.id,
                      count: values.count,
                    },
                    categories: values.categories,
                  });
                } catch (e) {
                  console.log(e);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} direction="column">
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Parceiros"
                        disabled={!newPermission.edit}
                        name="count"
                        error={Boolean(touched.count && errors.count)}
                        helperText={errors.count || 'Quantidade a ser exibida'}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.count}
                        variant="outlined"
                        type="number"
                        inputProps={{
                          max: 100,
                          min: 1,
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Autocomplete
                        multiple
                        fullWidth
                        options={categoryList}
                        value={categoriesSelected}
                        disableCloseOnSelect
                        disabled={!newPermission.edit}
                        noOptionsText="Sem opções"
                        classes={{
                          popper: classes.option,
                        }}
                        onChange={(e, value, reason) => {
                          if (
                            String(e.target).includes("HTML") &&
                            reason === "remove-option"
                          ) {
                            // Se a remoção foi pq clicou na lista num elemento novamente,
                            // ao invés de removê-lo, adiciona-o novamente.

                            const estadoAntigo = categoriesSelected;
                            const estadoNovo = value;
                            let res = [];

                            estadoAntigo.every((ea, index) => {
                              if (ea !== estadoNovo[index]) {
                                res = estadoAntigo;
                                res.push(ea);
                                return false;
                              }
                              return true;
                            });
                            setCategoriesSelected(res);
                            setFieldValue("categories", res);
                          } else {
                            setCategoriesSelected(value);
                            setFieldValue("categories", value);
                          }
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => {
                          return <React.Fragment>{option.name}</React.Fragment>;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Selecione as categorias"
                            disabled={!newPermission.edit}
                            error={Boolean(
                              touched.categories && errors.categories
                            )}
                            helperText={errors.categories || 'Ordem de exibição na tela inicial (respeitando o nível de prioridade)'}
                            name="categories"
                          />
                        )}
                      />
                    </Grid>

                    <Grid
                      container
                      spacing={3}
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid item md={2} xs={12}>
                        {newPermission.edit && (
                          <Button
                            fullWidth
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={() => {
                              setCategoriesSelected(configCategories);
                            }}
                          >
                            Cancelar
                          </Button>
                        )}
                      </Grid>
                      {newPermission.edit && (
                        <Grid item md={2} xs={12}>
                          <Button
                            fullWidth
                            color="primary"
                            size="large"
                            type="submit"
                            variant="contained"
                          >
                            {isSubmitting ? (
                              <CircularProgress
                                color="inherit"
                                circle={{ color: "#fff" }}
                                size={25}
                              />
                            ) : (
                              "Salvar"
                            )}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
