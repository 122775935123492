import React, { useState, useEffect } from 'react';

import List from './list'
import NewIndisponible from './new'
import EditIndisponible from './edit'

export default function Indisponible(props) {

  const [state, setState] = useState(0)
  const [idisponibleToEdit, setIndisponibleToEdit] = useState()

  return (
    <>
      {state === 0 && (
        <List setState={setState} setIndisponibleToEdit={setIndisponibleToEdit} />
      )}
      {state === 1 && (
        <NewIndisponible setState={setState} />
      )}
      {state === 2 && (
        <EditIndisponible setState={setState} indisponible={idisponibleToEdit} />
      )}
    </>
  )
}