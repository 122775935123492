import api from './api'

export const findAll = async () => {
  const { data } = await api.get('partner')
  return data
}

export const create = async partner => {
  const { data } = await api.post('partner', { partner })
  return data
}

export const editPartner = async partner => {
  const { data } = await api.post(`partner/${partner.id}`, { partner })
  return data
}

export const deletePartner = async id => {
  const { data } = await api.delete(`partner/${id}`)
  return data
}

export const findAllUnidades = async () => {
  const { data } = await api.get('partner-unity')
  return data
}

export const findAllPayment = async () => {
  const { data } = await api.get('partner-unity/payment')
  return data
}

export const createUnidade = async unity => {
  const { data } = await api.post('partner-unity', { unity })
  return data
}

export const editUnidade = async unity => {
  const { data } = await api.post(`partner-unity/${unity.id}`, { unity })
  return data
}

export const deleteUnidade = async id => {
  const { data } = await api.delete(`partner-unity/${id}`)
  return data
}

export const findAllCategorys = async () => {
  const { data } = await api.get('category-partner')
  return data
}

export const createCategory = async category => {
  const { data } = await api.post(`category-partner`, { category })
  return data
}

export const editCategory = async category => {
  const { data } = await api.post(`category-partner/${category.id}`, { category })
  return data
}

export const deleteCategory = async id => {
  const { data } = await api.delete(`category-partner/${id}`)
  return data
}

export const editConfig = async (body) => {
  const { data } = await api.post(`partner-config/`, body);
  return data
}

export const findConfig = async () => {
  const { data } = await api.get(`partner-config/`)
  return data
}


export const loginCnpj = async (cnpj, password) => {
  const { data } = await api.post('partner-unity/login', { cnpj, password })
  return data
}

export const findAllTags = async () => {
  const { data } = await api.get('tag-partner')
  return data
}

export const createTag = async body => {
  const { data } = await api.post(`tag-partner`, body)
  return data
}

export const editTag = async body => {
  const { data } = await api.post(`tag-partner/edit`, body)
  return data
}

export const deleteTag = async body => {
  const { data } = await api.post(`tag-partner/delete`, body)
  return data
}