import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  createMuiTheme,
  MuiThemeProvider,
  Checkbox,
  MenuItem,
  FormControlLabel,
  CircularProgress,
  Avatar,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { ErrorMessage, Formik } from 'formik';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import * as Yup from 'yup';
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@material-ui/icons'
import { getCepInformation, updateUnidade } from '../../../../providers/schedules';
import estados from '../../../../utils/estados'
import InputCel from '../../../../components/common/inputCelular'
import InputCep from '../../../../components/common/inputCep'
import { getCepInfos } from '../../../../utils/getCepInfos'
import googleApiKey from '../../../../config/googleApiKey'
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white,
    maxWidth: '50vw'
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    marginTop: 30
  },
  listText: {
    fontSize: 14
  },
  option: {
    backgroundColor: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputFile: {
    display: 'none'
  },
  Avatar: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    margin: '8px auto',
    //border: 5,
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});



function EditUnity({ unidade, setState, google }) {
  const classes = useStyles()
  // const [hourInit, onChangeHourInit] = useState(new Date().setHours(unidade.horaInicio.split(':')[0], unidade.horaInicio.split(':')[1]));
  // const [hourFinal, onChangeHourFinal] = useState(new Date().setHours(unidade.horaFim.split(':')[0], unidade.horaFim.split(':')[1]));
  const [show, setShow] = useState(true)
  const [lat, setLat] = useState(unidade.point.coordinates[0])
  const [lng, setLng] = useState(unidade.point.coordinates[1])
  const [open, setOpen] = useState(false);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    // img: null,
    cep: unidade.cep,
    estado: unidade.estado,
    cidade: unidade.cidade,
    bairro: unidade.bairro,
    endereco: unidade.endereco,
    numero: unidade.numero,
    complemento: unidade.complemento,
    nome: unidade.nome,
    point: unidade.point,
    telefone: unidade.telefone,
    disponible: unidade.disponible,
    segunda: !unidade.segunda ? null : {
      horaInit: unidade.segunda.horaInit.replace( ' ', '' ), 
      horaFim: unidade.segunda.horaFim.replace( ' ', '' ), 
    },
    terca: !unidade.terca ? null : {
      horaInit: unidade.terca.horaInit.replace( ' ', '' ), 
      horaFim: unidade.terca.horaFim.replace( ' ', '' ),
    },
    quarta: !unidade.quarta ? null : {
      horaInit: unidade.quarta.horaInit.replace( ' ', '' ), 
      horaFim: unidade.quarta.horaFim.replace( ' ', '' ), 
    },
    quinta: !unidade.quinta ? null : {
      horaInit: unidade.quinta.horaInit.replace( ' ', '' ), 
      horaFim: unidade.quinta.horaFim.replace( ' ', '' ), 
    },
    sexta: !unidade.sexta ? null : {
      horaInit: unidade.sexta.horaInit.replace( ' ', '' ), 
      horaFim: unidade.sexta.horaFim.replace( ' ', '' ), 
    },
    sabado: !unidade.sabado ? null : {
      horaInit: unidade.sabado.horaInit.replace( ' ', '' ), 
      horaFim: unidade.sabado.horaFim.replace( ' ', '' ), 
    },
    domingo: !unidade.domingo ? null : {
      horaInit: unidade.domingo.horaInit.replace( ' ', '' ), 
      horaFim: unidade.domingo.horaFim.replace( ' ', '' ),
    },
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleUnities);
    };

    fetchPermissions();
  }, []);


  const setHourFieldValue = value => {
    if (value) {
      return null
    } else {
      return { horaInit: new Date().setHours(0, 0), horaFim: new Date().setHours(0, 0) }
    }
  }


  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              cep: unidade.cep,
              estado: unidade.estado,
              cidade: unidade.cidade,
              bairro: unidade.bairro,
              endereco: unidade.endereco,
              numero: unidade.numero,
              complemento: unidade.complemento,
              nome: unidade.nome,
              point: unidade.point,
              telefone: unidade.telefone,
              disponible: unidade.disponible,
              segunda: !unidade.segunda ? null : {
                horaInit: new Date().setHours(unidade.segunda.horaInit.split(':')[0], unidade.segunda.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.segunda.horaFim.split(':')[0], unidade.segunda.horaFim.split(':')[1])
              },
              terca: !unidade.terca ? null : {
                horaInit: new Date().setHours(unidade.terca.horaInit.split(':')[0], unidade.terca.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.terca.horaFim.split(':')[0], unidade.terca.horaFim.split(':')[1])
              },
              quarta: !unidade.quarta ? null : {
                horaInit: new Date().setHours(unidade.quarta.horaInit.split(':')[0], unidade.quarta.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.quarta.horaFim.split(':')[0], unidade.quarta.horaFim.split(':')[1])
              },
              quinta: !unidade.quinta ? null : {
                horaInit: new Date().setHours(unidade.quinta.horaInit.split(':')[0], unidade.quinta.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.quinta.horaFim.split(':')[0], unidade.quinta.horaFim.split(':')[1])
              },
              sexta: !unidade.sexta ? null : {
                horaInit: new Date().setHours(unidade.sexta.horaInit.split(':')[0], unidade.sexta.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.sexta.horaFim.split(':')[0], unidade.sexta.horaFim.split(':')[1])
              },
              sabado: !unidade.sabado ? null : {
                horaInit: new Date().setHours(unidade.sabado.horaInit.split(':')[0], unidade.sabado.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.sabado.horaFim.split(':')[0], unidade.sabado.horaFim.split(':')[1])
              },
              domingo: !unidade.domingo ? null : {
                horaInit: new Date().setHours(unidade.domingo.horaInit.split(':')[0], unidade.domingo.horaInit.split(':')[1]),
                horaFim: new Date().setHours(unidade.domingo.horaFim.split(':')[0], unidade.domingo.horaFim.split(':')[1])
              },
            }}
            validationSchema={Yup.object().shape({
              cidade: Yup.string()
                .required('Campo obrigatório'),
              bairro: Yup.string()
                .required('Campo obrigatório'),
              numero: Yup.string()
                .required('Campo obrigatório'),
              endereco: Yup.string()
                .required('Campo obrigatório'),
              estado: Yup.string()
                .required('Campo obrigatório'),
              cep: Yup.string()
                .required('Campo obrigatório'),
              nome: Yup.string()
                .required('Campo obrigatório'),
              point: Yup.object()
                .required('Obrigatório a escolha de um ponto no mapa.'),
              telefone: Yup.string()
                .required('Campo obrigatório'),
            })}
            onSubmit={async (values) => {
              try {
                if (values.img) {
                  const body = new FormData()
                  body.append('file', values.img)
                  body.append('folder', UploadFolder.MISC)
                  const data = await apiUpload(body);
                  values.imgProfile = data.location
                }
                delete values.img

                if (values.segunda) {
                  values.segunda = {
                    horaInit: `${('0' + new Date(values.segunda.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.segunda.horaInit).getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + new Date(values.segunda.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.segunda.horaFim).getMinutes()).slice(-2)}`
                  }
                }
                if (values.terca) {
                  values.terca = {
                    horaInit: `${('0' + new Date(values.terca.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.terca.horaInit).getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + new Date(values.terca.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.terca.horaFim).getMinutes()).slice(-2)}`
                  }
                }

                if (values.quarta) {
                  values.quarta = {
                    horaInit: `${('0' + new Date(values.quarta.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.quarta.horaInit).getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + new Date(values.quarta.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.quarta.horaFim).getMinutes()).slice(-2)}`
                  }
                }

                if (values.quinta) {
                  values.quinta = {
                    horaInit: `${('0' + new Date(values.quinta.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.quinta.horaInit).getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + new Date(values.quinta.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.quinta.horaFim).getMinutes()).slice(-2)}`
                  }
                }

                if (values.sexta) {
                  values.sexta = {
                    horaInit: `${('0' + new Date(values.sexta.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.sexta.horaInit).getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + new Date(values.sexta.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.sexta.horaFim).getMinutes()).slice(-2)}`
                  }
                }

                if (values.sabado) {
                  values.sabado = {
                    horaInit: `${('0' + new Date(values.sabado.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.sabado.horaInit).getMinutes()).slice(-2)}`,
                    horaFim: `${('0' + new Date(values.sabado.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.sabado.horaFim).getMinutes()).slice(-2)}`
                  }
                }

                if (values.domingo) {
                  values.domingo = {
                    horaInit: `${('0' + new Date(values.domingo.horaInit).getHours()).slice(-2)}:${('0' + new Date(values.domingo.horaInit).getMinutes()).slice(-2)}`.replace(' ', ''),
                    horaFim: `${('0' + new Date(values.domingo.horaFim).getHours()).slice(-2)}:${('0' + new Date(values.domingo.horaFim).getMinutes()).slice(-2)}`
                  }
                }
                const result = await updateUnidade({
                  id: unidade.id,
                  ...values
                })
                const temp = {
                  ...values,
                  id: unidade.id,
                  segunda: values.segunda,
                  terca: values.terca,
                  quarta: values.quarta,
                  quinta: values.quinta,
                  sexta: values.sexta,
                  sabado: values.sabado,
                  domingo: values.domingo,
                  point: values.point,
                }
                delete values.imgProfile;
                await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: unidade.id }, after: temp, menu: "Agendamentos", subMenu: "Unidade", });
                if (result) {
                  setState(0)
                }
              } catch (e) {
                console.log('Error submit', e)
                // setState(0)
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldError,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (

              <Card style={{ backgroundColor: 'white', paddingTop: 20, marginTop: 20, marginBottom: 20 }}>
                <CardContent>
                  <Grid container alignContent={'center'} spacing={3}>

                    <Grid item md={12}>
                      <FormControlLabel
                        value="start"
                        name="disponible"
                        control={<Checkbox
                          checked={values.disponible}
                          name="disponible"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Disponível"
                        labelPlacement="start"
                      />
                    </Grid>


                    <Grid item md={6} xs={12} >
                      <Grid container alignContent={'center'} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Typography paragraph variant={'h4'}>Editar unidade</Typography>
                        </Grid>
                        <Grid item xs={12} >
                          <input
                            accept="image/*"
                            className={classes.inputFile}
                            name='img'
                            id="img-input"
                            type="file"
                            onChange={(event) => setFieldValue('img', event.target.files[0])}
                          />
                          <label htmlFor="img-input">
                            <Avatar
                              className={classes.Avatar}
                              src={values.img ? URL.createObjectURL(values.img) : unidade.imgProfile}
                            />
                          </label>
                          {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ color: 'red', display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                            *{touched.img && errors.img}
                          </Typography>}
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            label="Nome"
                            name="nome"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.nome}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.telefone && errors.telefone)}
                            helperText={touched.telefone && errors.telefone}
                            label="Telefone"
                            name="telefone"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="tel"
                            value={values.telefone}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputCel,
                            }}
                          />
                        </Grid>

                      </Grid>

                      <Grid item md={12} xs={12} >
                        <Typography paragraph variant={'h4'} style={{ marginBottom: 0, marginTop: 23 }}>Dias e Horário de Funcionamento</Typography>
                      </Grid>

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Segunda-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.segunda}
                              color="primary"
                              onChange={(event) => setFieldValue('segunda', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.segunda && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.segunda.horaInit}
                                onChange={(date) => {
                                  //const data = new Date(date)
                                  // onChangeHourInit(date)
                                  //setFieldValue('segunda.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('segunda.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.segunda.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('segunda.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('segunda.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Terça-Feira</Typography>
                        </Grid>
                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.terca}
                              color="primary"
                              onChange={(event) => setFieldValue('terca', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.terca && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.terca.horaInit}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourInit(date)
                                  // setFieldValue('terca.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('terca.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.terca.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('terca.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('terca.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Quarta-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.quarta}
                              color="primary"
                              onChange={(event) => setFieldValue('quarta', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.quarta && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.quarta.horaInit}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourInit(date)
                                  // setFieldValue('quarta.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('quarta.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.quarta.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('quarta.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('quarta.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Quinta-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.quinta}
                              color="primary"
                              onChange={(event) => setFieldValue('quinta', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.quinta && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.quinta.horaInit}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourInit(date)
                                  // setFieldValue('quinta.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('quinta.horaInit', date)

                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.quinta.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('quinta.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('quinta.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Sexta-Feira</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.sexta}
                              color="primary"
                              onChange={(event) => setFieldValue('sexta', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.sexta && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.sexta.horaInit}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourInit(date)
                                  // setFieldValue('sexta.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('sexta.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.sexta.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('sexta.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('sexta.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Sábado</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.sabado}
                              color="primary"
                              onChange={(event) => setFieldValue('sabado', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.sabado && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.sabado.horaInit}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourInit(date)
                                  // setFieldValue('sabado.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('sabado.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.sabado.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('sabado.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('sabado.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                      <Grid container alignContent={'center'} spacing={0}>
                        <Grid item md={6} xs={12} >
                          <Typography paragraph variant={'h5'} style={{ marginBottom: 0, marginTop: 20 }}>Domingo</Typography>
                        </Grid>

                        <Grid item md={6}>
                          <FormControlLabel
                            style={{ marginBottom: 0, marginTop: 10 }}
                            value="start"
                            name="visible"
                            control={<Checkbox
                              checked={!values.domingo}
                              color="primary"
                              onChange={(event) => setFieldValue('domingo', setHourFieldValue(event.target.checked))}
                            />}
                            label="Fechado"
                            labelPlacement="start"
                          />
                        </Grid>
                      </Grid>

                      {values.domingo && <Grid container spacing={3} >

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                autoOk
                                fullWidth
                                margin="normal"
                                id="time-picker"
                                label="Início"
                                hideTabs
                                ampm={false}
                                value={values.domingo.horaInit}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourInit(date)
                                  // setFieldValue('domingo.horaInit', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('domingo.horaInit', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />

                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>

                        <Grid item md={6} xs={12}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <TimePicker
                                fullWidth
                                autoOk
                                margin="normal"
                                id="horaFim"
                                label="Fim"
                                hideTabs
                                ampm={false}
                                value={values.domingo.horaFim}
                                onChange={(date) => {
                                  // const data = new Date(date)
                                  // onChangeHourFinal(date)
                                  // setFieldValue('domingo.horaFim', `${data.getHours()}:${data.getMinutes() <= 9 ? '0' + data.getMinutes() : data.getMinutes()}`)
                                  setFieldValue('domingo.horaFim', date)
                                }}
                                variant='inline'
                                inputVariant={'outlined'}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      </Grid>}

                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container alignContent={'center'} spacing={3}>
                        <Grid item md={12} xs={12}>
                          <Typography paragraph variant={'h4'}>Localização</Typography>
                        </Grid>

                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.cep && errors.cep)}
                            helperText={touched.cep && errors.cep}
                            label="CEP"
                            name="cep"
                            onBlur={handleBlur}
                            onChange={async (event, value) => {
                              setFieldValue('cep', event.target.value)
                              if (event.target.value.length === 9) {
                                setOpen(true)
                                const dados = await getCepInfos(event.target.value)
                                if (dados.erro) {
                                  setFieldError('cep', 'CEP não encontrado')
                                } else {
                                  setFieldValue('estado', dados.uf)
                                  setFieldValue('cidade', dados.localidade)
                                  setFieldValue('bairro', dados.bairro)
                                  setFieldValue('endereco', dados.logradouro)
                                  setFieldValue('numero', '')
                                  setFieldValue('complemento', '')
                                  const cepGeolocation = await getCepInformation(event.target.value);
                                  if (cepGeolocation && cepGeolocation.latitude) {
                                    const cepLat = parseFloat(
                                      cepGeolocation.latitude
                                    );
                                    const cepLong = parseFloat(
                                      cepGeolocation.longitude
                                    );
                                    
                                    setLat(cepLat);
                                    setLng(cepLong);
                                    setFieldValue("latLng", {
                                      type: "Point",
                                      coordinates: [cepLat, cepLong],
                                    });
                                    setShow(true);
                                  }
                                }
                                setOpen(false)
                              }
                            }}
                            value={values.cep}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputCep,
                            }}
                          />
                        </Grid>
                        <Grid item md={8} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.cidade && errors.cidade)}
                            helperText={touched.cidade && errors.cidade}

                            label="Cidade"
                            name="cidade"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.cidade}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.estado && errors.estado)}
                            helperText={touched.estado && errors.estado}
                            select
                            label="Estado"
                            name="estado"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.estado}
                            variant="outlined"
                          >
                            {estados.map((option) => (
                              <MenuItem

                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.bairro && errors.bairro)}
                            helperText={touched.bairro && errors.bairro}
                            label="Bairro"
                            name="bairro"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.bairro}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.endereco && errors.endereco)}
                            helperText={touched.endereco && errors.endereco}
                            label="Endereço"
                            name="endereco"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.endereco}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.numero && errors.numero)}
                            helperText={touched.numero && errors.numero}
                            label="Número"
                            type='number'
                            name="numero"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.numero}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.complemento && errors.complemento)}
                            helperText={touched.complemento && errors.complemento}
                            label="Complemento"
                            name="complemento"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.complemento}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Map
                            google={google}
                            zoom={10}
                            onClick={(props, map, clickEvent) => {
                              setLat(clickEvent.latLng.lat());
                              setLng(clickEvent.latLng.lng());
                              setFieldValue('point', {
                                type: "Point",
                                coordinates: [clickEvent.latLng.lat(), clickEvent.latLng.lng()]
                              })
                              setShow(true)
                            }}
                            // centerAroundCurrentLocation={true}

                            initialCenter={{
                              lat: lat,
                              lng: lng
                            }}
                            center={{
                              lat: lat,
                              lng: lng
                            }}
                            containerStyle={{ marginTop: 20, width: '100%', height: 450, position: 'relative' }}
                          >
                            {show && (
                              <Marker
                                position={{ lat, lng }}

                              />
                            )}
                          </Map>

                          <ErrorMessage name='point' render={msg => (<Typography style={{ fontSize: 14, margin: 10 }} color={'error'}>{msg}</Typography>)} />
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Box my={2} display="flex" justifyContent="space-around">
                    <Button
                      onClick={() => setState(0)}
                      className={classes.submitBtn}
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Voltar
                    </Button>
                    {newPermission.edit && (
                      <Button
                        onClick={() => handleSubmit()}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Atualizar'}
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>

      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={'spinningBubbles'} color={color.red} />
      </Backdrop>

    </>
  )
}

export default GoogleApiWrapper({
  apiKey: (googleApiKey)
})(EditUnity)