import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Checkbox,
  Divider,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { update } from '../../../../providers/groupUserAdm'
import { CheckboxWithLabel } from 'formik-material-ui';
import { getAll } from '../../../../providers/userAdm'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btns: {
    marginTop: 15
  }
}));

export default function EditarGrupo({ group, setState }) {

  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userList, setUsertList] = useState([])
  const [selected, setSelected] = useState(group.users.map(item => item.id));
  const [qntUserError, setQntUserError] = useState(false);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    name: group.nome,
    permissions: group.permissions
  })

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.collaboratorGroup);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const data = await getAll()
    setUsertList(data)
  }, [])

  useEffect(async () => {
    if (qntUserError && !!selected.length) setQntUserError(false)
  }, [selected])

  const goBack = () => {
    setState(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userList.length - page * rowsPerPage);
  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          nome: group.nome,
          permissions: group.permissions
        }}
        validationSchema={Yup.object().shape({
          nome: Yup.string()
            .required('Campo obrigatório'),
          permissions: Yup.array()
            .test('permissions', 'Selecione ao menos uma opção', value => !!value.length)
        })}
        onSubmit={async (values, actions) => {
          if (!selected.length) {
            setQntUserError(true)
            return;
          }
          values.users = selected
          try {
            values.id = group.id
            const data = await update(values)
            const afterValues = {
                name: values.nome,
                permissions: values.permissions
            }
            await alterationLog({ action: 'UPDATE', idUser: userData.id, before: originalValues, after: afterValues, menu: "Colaboradores", subMenu: "Grupos", });
            setState(0)
          } catch (e) {
            console.log(e)
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.nome && errors.nome)}
                      helperText={touched.nome && errors.nome}
                      label="Nome"
                      name="nome"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.nome}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Paper className={classes.paper}>
                  <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader className={classes.table}>
                      <TableHead className={classes.headerTb}>
                        <TableRow>
                          <TableCell padding="checkbox" className={classes.celTableHeader}>
                            <Checkbox
                              indeterminate={selected.length > 0 && selected.length < userList.length}
                              checked={userList.length > 0 && selected.length === userList.length}
                              onChange={handleSelectAllClick}
                            />
                          </TableCell>
                          <TableCell key={'name'} className={classes.celTableHeader}>
                            {"Nome"}
                          </TableCell>
                          <TableCell key={'cpf'} className={classes.celTableHeader}>
                            {"CPF"}
                          </TableCell>
                          <TableCell key={'email'} className={classes.celTableHeader}>
                            {"Email"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            return (
                              <TableRow
                                hover
                                onClick={(event) => handleClick(event, row.id)}
                                key={row.id}
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                  />
                                </TableCell>
                                <TableCell >{row.nome}</TableCell>
                                <TableCell >{row.cpf}</TableCell>
                                <TableCell >{row.email}</TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    labelRowsPerPage={'Linhas por página'}
                    component="div"
                    count={userList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
                <Box className={classes.footer} />
                <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                  Permissões
                </Typography>
                <Divider style={{ marginBottom: 10 }} />
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12} >
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="permissions"
                      value="auth"
                      Label={{ label: 'Colaboradores' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      value="user"
                      name="permissions"
                      Label={{ label: 'Associados' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      value="news"
                      name="permissions"
                      Label={{ label: 'Notícias' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      value="schedule"
                      name="permissions"
                      Label={{ label: 'Agendamento' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      value="credit"
                      name="permissions"
                      Label={{ label: 'Caassh' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12} >
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      value="message"
                      name="permissions"
                      Label={{ label: 'Mensagens' }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Field
                      className={classes.checkbox}
                      component={CheckboxWithLabel}
                      type="checkbox"
                      value="partner"
                      name="permissions"
                      Label={{ label: 'Parceiros' }}
                    />
                  </Grid>
                </Grid>
                {qntUserError && <Typography variant="subtitle1" display="block" style={{ marginTop: 5, color: 'red' }}>
                  *Selecione ao menos um usuário
                </Typography>}
                {Boolean(touched.permissions && errors.permissions) && <Typography variant="subtitle1" display="block" style={{ marginTop: 5, color: 'red' }}>
                  *{touched.permissions && errors.permissions}
                </Typography>}
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  <Grid item md={4} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      size="large"
                      color='secondary'
                      variant="contained"
                      onClick={goBack}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  {newPermission.edit && (
                    <Grid item md={4} xs={12}>
                      <Button
                        fullWidth
                        color='primary'
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}

                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}