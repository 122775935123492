import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAction: {
    boxShadow: '0px 0px 0px 2px #979797',
    width: 200,
    height: 200,
    marginRight: 100,
  }
}));

export default function QrCode({ setState, typePayment, dataPayment }) {
  const classes = useStyles()


  const user = useSelector(({ user }) => user);

  const goBack = () => {
    setState(1)
  }

  const qrcode = {
    tipo: typePayment === 'AVULSO' ? 'Pagamento Avulso' : 'Pagamento Identificado',
    id_partner: user.id,
    identificador: typePayment === 'AVULSO' ? null : dataPayment.identificador,
    valor: dataPayment.valor,
    descricao: dataPayment.descricao
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.Card}>
        <CardContent>
          <Grid md={12}>
            <Typography style={{ marginBottom: 45 }}>
              <h1>
                {typePayment === 'AVULSO' ? 'Pagamento Avulso' : 'Pagamento Identificado'}
              </h1>
            </Typography>
          </Grid>
          <Grid container style={{ marginBottom: 45 }} alignItems='center' justify='center'>
            <Grid item md={3}>
              <QRCode value={JSON.stringify(qrcode)} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container style={{ marginTop: 20 }} alignItems='flex-end' justify='flex-end'>
        <Grid item md={3}>
          <Button
            fullWidth
            color='primary'
            size="large"
            variant="contained"
            onClick={goBack}
          >
            Cancelar
            </Button>
        </Grid>
      </Grid>
    </Container>
  )
}