import React, { useState } from 'react';
import {
  makeStyles,
  Box,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core';
import { color } from '../../components/common/colors';
import Page from '../../components/common/page'
import Config from './config';
import InsertCaassh from './insert-caassh';
import Transation from './transation'
import Credit from './credit'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  currencyField: {
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Transaction(props) {
  const classes = useStyles()

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };


  return (
    <Page className={classes.root}
      title="Caassh"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs value={tab} onChange={handleChange} indicatorColor="primary"
          textColor="primary">
          {/* <Tab label="Caassh" id={0} /> */}
          <Tab label="Crédito" id={0} />
          <Tab label="Transação" id={1} />
          <Tab label="Configuração" id={2} />
        </Tabs>
      </Paper>

      {/* <TabPanel value={tab} index={0}>
        <InsertCaassh />
      </TabPanel> */}
      <TabPanel value={tab} index={0}>
        <Credit />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Transation />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Config />
      </TabPanel>


    </Page>
  )
}