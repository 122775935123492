import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Grid,
  TextField,
  Popover
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon,
  LocalPrintshop
} from '@material-ui/icons';
import { color } from '../../../../components/common/colors';
import { findAllCategorys, deleteCategory } from '../../../../providers/partner'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  celTableHeaderActions: {
    width: 96,
    backgroundColor: color.white,
  }
}));

export default function ListCategory({ setState, setCategoryToEdit }) {
  const classes = useStyles()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [categoryList, setCategoryList] = useState([])
  const [search, setSearch] = useState()
  const [userToShow, setUsersToShow] = useState([])
  const [orderAlf, setOrderAlf] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerCategorie);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const data = await findAllCategorys()
    if (orderAlf === 0) {
      data.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
    }
    setCategoryList(data)
    setUsersToShow(data)
  }, [])

  const handleFilter = () => {
    if (search) {
      const regexp = new RegExp(search, 'gi')
      setUsersToShow(categoryList.filter(p => p.name.match(regexp)))
      setPage(0)
    } else {
      setUsersToShow(categoryList)
      setPage(0)
    }
  }

  const OrderByName = () => {
    if (orderAlf === 0) {
      userToShow.sort((a, b) => {
        return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
      });
      setOrderAlf(1)
    } else {
      userToShow.sort((a, b) => {
        return (a.name > b.name) ? -1 : ((b.name > a.name) ? 1 : 0);
      });
      setOrderAlf(0)
    }
    if (!search) {
      setCategoryList(categoryList)
      setUsersToShow(categoryList)
    } else {
      setUsersToShow(userToShow)
    }
  }

  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0)
  };

  const CloseOrder = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (category) => {
    setCategoryToEdit(category)
    setState(2)
  }

  const handleDelete = async id => {
    try {
      await deleteCategory(id)
      setCategoryList(categoryList.filter(item => item.id != id))
      setUsersToShow(userToShow.filter(item => item.id != id))
    } catch (e) {
      console.log(e)
    }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Nome",
      "Nível de prioridade"
    ];
    const body = [];

    userToShow.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.name,
          row.priority
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <Grid item md={12} xs={12}>
        <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} >
              <TextField
                fullWidth
                label="Nome"
                name="filter"
                variant="outlined"
                value={search}
                onChange={handleChangeFilter}
                style={{ backgroundColor: 'white' }}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleFilter()}
                style={{ padding: '15px 40px' }}
              >
                Buscar
              </Button>
            </Grid>
            <Grid item md={2} xs={12} >
              <Button
                color='primary'
                size="large"
                type="submit"
                variant="contained"
                onClick={OpenOrder}
                style={{ padding: '15px 40px' }}
              >
                Ordenar
              </Button>
            </Grid>
            <Grid item md={4} xs={12} style={{ textAlign: 'right' }}>
              <Button
                variant='contained'
                className={classes.btnAdd}
                onClick={() => setState(1)}
                style={{ padding: '15px 40px' }}
              >
                Adicionar Categoria
              </Button>
            </Grid>
          </Grid>

        </Box>
        <Paper className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader className={classes.table}>
              <TableHead className={classes.headerTb}>
                <TableRow>
                  <TableCell key={'name'} className={classes.celTableHeader}>
                    {"Nome"}
                  </TableCell>
                  <TableCell key={'name'} className={classes.celTableHeader}>
                    {"Nível de prioridade"}
                  </TableCell>
                  <TableCell key={'actions'} align="right" className={classes.celTableHeaderActions}>
                    {"Ações"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userToShow.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => console.log(event)}
                        key={row.id}
                      >
                        <TableCell >{row.name}</TableCell>
                        <TableCell >{row.priority}</TableCell>
                        <TableCell padding='none' align="right" >
                          {newPermission.det && (
                            <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                          )}
                          {/* {newPermission.edit && (
                            <IconButton onClick={() => handleDelete(row.id)}><DeleteIcon /></IconButton>
                          )} */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={3} >
            <Grid item md={6} xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignSelf: 'center'
              }} >
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => createPDF()}
                startIcon={<LocalPrintshop />}
              >
                Imprimir
              </Button>
            </Grid>
            <Grid item md={6} xs={12} style={{}}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                labelRowsPerPage={'Linhas por página'}
                component="div"
                count={userToShow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={'orderBy'}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: '#A93533' }}>
            <Grid>
              <Button
                color='white'
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: '#A93533' }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
          </Grid>
        </Popover>
      </Grid>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}