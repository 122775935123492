import React, { useEffect, useState } from 'react';
import {
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Button,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import Avatar from '@material-ui/core/Avatar';
import redQr from '../../../../assets/red-qr.png'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAction: {
    boxShadow: '0px 0px 0px 2px #979797',
    width: 200,
    height: 200,
    marginRight: 100,
  }
}));

export default function GetPayment({ setState, setTypePayment }) {
  const classes = useStyles()

  const goBack = () => {
    setState(0)
  }


  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.Card}>
        <CardContent>
          <Grid md={12}>
            <Typography style={{ marginBottom: 40 }}>
              <h1>
                Receber Pagamento
              </h1>
            </Typography>
          </Grid>
          <Grid spacing={6} style={{ marginBottom: 40 }}>
            <Button className={classes.btnAction}
              onClick={() => {
                setTypePayment("AVULSO")
                setState(3)
              }}
            >
              <Avatar src={redQr} variant="square"
                style={{ position: "relative", top: -10, left: 50, width: '50%', height: '50%' }}
              />
              <h3 style={{ position: "relative", top: 70, right: 45 }}>Pagamento Avulso</h3>
            </Button>
            <Button className={classes.btnAction}
              onClick={() => {
                setTypePayment("IDENTIFICADO")
                setState(4)
              }}
            >
              <Avatar src={redQr} variant="square"
                style={{ position: "relative", top: -10, left: 55, width: '50%', height: '50%' }}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h3 style={{ position: "relative", top: 70, right: 47 }}>Pagamento Identificado</h3>
              </div>
            </Button>
          </Grid>
        </CardContent>
      </Card>
      <Grid container style={{ marginTop: 20 }} alignItems='flex-end' justify='flex-end'>
        <Grid item md={3}>
          <Button
            fullWidth
            color='primary'
            size="large"
            variant="contained"
            onClick={goBack}
          >
            Voltar
            </Button>
        </Grid>
      </Grid>
    </Container>
  )
}