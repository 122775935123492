import React, { useState } from 'react';
import {
  Tab,
  Tabs,
  makeStyles,
  Box,
  Paper,
} from '@material-ui/core';
import { color } from '../../components/common/colors';
import Page from '../../components/common/page'
import Cartoes from './config'
import Users from './users'
import BlockUsers from './BlockUsers'
import OabTest from './oab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%',
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Home(props) {
  const classes = useStyles()
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (

    <Page className={classes.root}
      title="Associados"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Associados" id={0} />
          <Tab label="Bloquear" id={1} />
          <Tab label="Configuração" id={2} />
          <Tab label="Oab" id={3} />
        </Tabs>
      </Paper>
      <TabPanel value={tab} index={0}>
        <Users />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <BlockUsers />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Cartoes />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <OabTest />
      </TabPanel>
    </Page>
  )
}