import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Card,
  TextField,
  Typography,
  makeStyles,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import Page from '../../components/common/page';
import { login } from '../../providers/userAdm'
import { loginCnpj } from '../../providers/partner'
import { useDispatch } from 'react-redux';
import { addUser } from '../../redux/actions/userAction'
import { color } from '../../components/common/colors';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import InputCpf from '../../components/common/inputCpf'
import InputCnpj from '../../components/common/inputCnpj'
import { initiateSocket } from '../../sockets/Socket';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnParceiro, setBtnParceiro] = useState(false);

  const unmaskCpf = value => {
    return value.replace(/\./g, '').replace('-', '')
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" disableGutters>
          <Card style={{ background: color.white, padding: 30 }}>
            <Formik
              initialValues={{
                credential: '',
                password: '',
              }}

              validationSchema={Yup.object().shape({
                credential: Yup.string()
                  .required(`Informe seu ${btnParceiro ? 'CPF' : 'CNPJ'}`),
                // .test(btnParceiro ? ['cpf-valid', 'CPF inválido', value => cpf.isValid(value)] :
                //   ['cnpj-valid', 'CNPJ inválido', value => cnpj.isValid(value)]),
                password: Yup.string().
                  required('Informe sua senha').
                  min(6, 'Senha deve ter ao menos 6 dígitos')
              })}

              onSubmit={async ({ credential, password }, actions) => {
                if (btnParceiro) {
                  // if (credential != '00.000.000/0000-00') {
                  //   if (!cnpj.isValid(credential)) {
                  //     actions.setFieldError('credential', 'Informe um CNPJ válido')
                  //     return
                  //   }
                  // }
                } else {
                  if (!cpf.isValid(credential)) {
                    actions.setFieldError('credential', 'Informe um CPF válido')
                    return
                  }
                }
                try {
                  let data
                  if (btnParceiro) {
                    data = await loginCnpj(credential, password)
                    data.user.type = 'PARTNER'
                    localStorage.setItem('token', data.token)
                    dispatch(addUser(data.user))
                    navigate('/app/parceiros')
                  } else {
                    data = await login(unmaskCpf(credential), password)
                    data.user.type = 'USER'
                    localStorage.setItem('token', data.token)
                    dispatch(addUser(data.user))
                    navigate('/app/parceiros')
                  }
                } catch (e) {
                  switch (e.response.data.error) {
                    case 'USER_NOT_EXIST':
                      actions.setFieldError('cpf', 'CPF não cadastrado')
                      break;
                    case 'INVALID_PASSWORD':
                      actions.setFieldError('password', 'Senha inválida')
                      break;
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box mb={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                      align="center"
                    >
                      Sistema Caassh
                  </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.credential && errors.credential)}
                    fullWidth
                    helperText={touched.credential && errors.credential}
                    label={!btnParceiro ? "CPF" : "CNPJ"}
                    margin="normal"
                    name="credential"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.credential}
                    variant="outlined"
                    InputProps={{
                      inputComponent: !btnParceiro ? InputCpf : InputCnpj,
                    }}
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    variant="outlined"
                    inputProps={{
                      type: "password",
                      autoComplete: 'new-password'
                    }}
                  />
                  <FormControlLabel
                    control={<Switch color="primary"
                      onChange={(event) => {
                        if (!btnParceiro) {
                          setBtnParceiro(true)
                        } else {
                          setBtnParceiro(false)
                        }
                        setFieldValue('credential', '')
                        setFieldValue('password', '')
                      }}
                    />}
                    checked={btnParceiro}
                    value={btnParceiro}
                    label="Parceiro"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    style={{ margin: '20px 0px 20px 0px' }}
                  />
                  <Box my={2}>
                    <Button
                      style={{
                        background: color.red,
                        color: color.white
                      }}
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign in now
                  </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Card>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;