import { createStore, applyMiddleware  } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['colaboradorEdit']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
    persistedReducer,
    applyMiddleware()
)

const  persistor = persistStore(store);

export {store, persistor}