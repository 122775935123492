import React, { useState, useEffect } from 'react';
import {
  Button,
  makeStyles,
  Box,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TablePagination,
  IconButton,
  Container,
  Grid,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import {
  // Delete as DeleteIcon,
  Edit as EditIcon,
  LocalPrintshop
} from '@material-ui/icons';
import { color } from '../../../../components/common/colors';
import { deleteUserAdm, getAll as getAllUsers } from '../../../../providers/userAdm'
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

export default function ListUsers({ setState, setUserToEdit }) {
  const classes = useStyles()
  const [userList, setUsertList] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.collaborator);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const usersdata = await getAllUsers()
    setUsertList(usersdata)
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (user) => {
    setUserToEdit(user)
    setState(2)
  }

  const handleDelete = async id => {
    try {
      await deleteUserAdm(id)
      setUsertList(userList.filter(item => item.id != id))
    } catch (e) {
      console.log(e)
    }
  }

  const createPDF = () => {
    const doc = new jsPDF();

    const head = [
      "",
      "Nome",
      "CPF",
      "E-mail",
    ];
    const body = [];

    userList.forEach((row, index) => {
      if (index < 1000) {
        body.push([
          index + 1,
          row.nome,
          row.cpf,
          row.email,
        ]);
      }
    });

    doc.autoTable({
      theme: "striped",
      headStyles: {
        fillColor: "#A93533",
        textColor: "#FFFFFF",
        halign: "left",
      },
      showHeader: 'firstPage',
      head: [head],
      body,
    });
    doc.autoPrint();
    doc.output("dataurlnewwindow");
  };

  const emptyRowsUser = rowsPerPage - Math.min(rowsPerPage, userList.length - page * rowsPerPage);

  return (
    newPermission.viz ? (
      <Container maxWidth="md" disableGutters>
        <Box display="flex" justifyContent="flex-end" className={classes.headerPage}>
          <Button variant='contained' className={classes.btnAdd} onClick={() => setState(1)}>Adicionar Colaborador</Button>
        </Box>
        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader className={classes.table}>
                <TableHead className={classes.headerTb}>
                  <TableRow>
                    <TableCell key={'name'} className={classes.celTableHeader}>
                      {"Nome"}
                    </TableCell>
                    <TableCell key={'cpf'} className={classes.celTableHeader}>
                      {"CPF"}
                    </TableCell>
                    <TableCell key={'email'} className={classes.celTableHeader}>
                      {"Email"}
                    </TableCell>
                    <TableCell key={'actions'} align="right" className={classes.celTableHeader}>
                      {"Ações"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => console.log(event)}
                          key={row.id}
                        >
                          <TableCell >{row.nome}</TableCell>
                          <TableCell >{row.cpf}</TableCell>
                          <TableCell >{row.email}</TableCell>
                          <TableCell padding='none' align="right" >
                            {newPermission.det && (
                              <IconButton onClick={() => handleEdit(row)}><EditIcon /></IconButton>
                            )}
                            {/* {newPermission.edit && (
                              <IconButton onClick={() => handleDelete(row.id)}></IconButton>
                            )} */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRowsUser > 0 && (
                    <TableRow style={{ height: 53 * emptyRowsUser }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container spacing={3} >
              <Grid item md={6} xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignSelf: 'center'
                }} >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => createPDF()}
                  startIcon={<LocalPrintshop />}
                >
                  Imprimir
                </Button>
              </Grid>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={'Linhas por página'}
                  component="div"
                  count={userList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider >
        </Paper>
        <Box className={classes.footer} />
      </Container>
    ) : (
      <>
        Sem permissão
      </>
    )
  )
}