import React, { useState, useEffect } from 'react';

import List from './list'
import NewProfissional from './new'
import EditProfissional from './edit'

export default function Profissional(props) {

  const [state, setState] = useState(0)
  const [profissionalToEdit, setProfissionalToEdit] = useState()

  return (
    <>
      {state === 0 && (
        <List setState={setState} setProfissionalToEdit={setProfissionalToEdit} />
      )}
      {state === 1 && (
        <NewProfissional setState={setState} />
      )}
      {state === 2 && (
        <EditProfissional setState={setState} profissional={profissionalToEdit} />
      )}
    </>
  )
}