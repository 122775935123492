/* eslint-disable react-hooks/exhaustive-deps */
import DateFnsUtils from "@date-io/date-fns";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    createMuiTheme,
    Grid,
    makeStyles,
    Modal,
    MuiThemeProvider,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@material-ui/core";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
    add,
    differenceInMinutes,
    endOfDay,
    format,
    getTime,
    startOfDay,
} from "date-fns";
import pt from "date-fns/locale/pt-BR";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { color } from "../../../components/common/colors";
import { getLogUser } from "../../../providers/log";
import {
    ChevronRight,
    ExpandMore,
} from '@material-ui/icons';
import { acaoUsersOptions, localUsersOptions } from "../../../components/common/categories";
import { useSelector } from "react-redux";
import { getPermissions } from "../../../providers/permissions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background,
        height: "100%",
        padding: theme.spacing(3),
    },
    card: {
        background: color.white,
    },
    tableContainer: {
        backgroundColor: color.white,
    },
    submitBtn: {
        background: color.red,
        color: color.white,
        "&:hover": {
            backgroundColor: "#a35655",
        },
    },
    listText: {
        fontSize: 14,
    },
    option: {
        backgroundColor: "white",
    },
    paper: {
        marginTop: 10,
    },
    diolog: {
        background: color.white,
    },
    dialogRoot: {
        background: "rgba(0.0.0, 0.3)",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        height: "90%",
    },
}));

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {
            main: color.red,
        },
    },
});
export default function UsersLog() {

    const classes = useStyles();
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(true);
    const [logToShow, setLogToShow] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [acao, setAcao] = useState('');
    const [dataInit, setDataInit] = useState(add(new Date(), { days: -30 }));
    const [dataFim, setDataFim] = useState(new Date());
    const [local, setLocal] = useState('');
    const [localSelected, setLocalSelected] = useState('');
    const [oab, setOab] = useState('');
    const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

    const userData = useSelector(({ user }) => user);

    useEffect(() => {
        const fetchPermissions = async () => {
            const { id } = userData;
            const permissions = await getPermissions(id);
            setNewPermission(permissions.permissions.logUser);
        };

        fetchPermissions();
    }, []);

    const handleChangeFilter = (event) => {
        setSearch(event.target.value);
    };

    const handleChangeOab = (event) => {
        setOab(event.target.value)
    }

    const handleFilter = async () => {
        setLoading(true);
        const data = await getLogUser({
            busca: search,
            filtros: {
                'de': format(new Date(dataInit), "yyyy-MM-dd"),
                'ate': format(new Date(dataFim), "yyyy-MM-dd"),
            },
            'pagina': page,
            'entradasPorPagina': rowsPerPage,
        });
        setLoading(false);
        setLogToShow(data);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const filterByItem = async () => {
        setLoading(true);
        setPage(0);
        const data = await getLogUser({
            busca: search,
            filtros: {
                'de': format(new Date(dataInit), "yyyy-MM-dd"),
                'ate': format(new Date(dataFim), "yyyy-MM-dd"),
                'acao': acao,
                'local': localSelected,
                'oab': oab,
            },
            'pagina': page,
            'entradasPorPagina': rowsPerPage,
        });
        setLoading(false);
        setLogToShow(data);
        handleCloseModal(true);
    };

    const handleChangeRowsPerPage = async (event) => {
        setLoading(true);
        let tempRows = event.target.value;
        setRowsPerPage(tempRows);
        setPage(0);
        const data = await getLogUser({
            busca: search,
            filtros: {
                'de': format(new Date(dataInit), "yyyy-MM-dd"),
                'ate': format(new Date(dataFim), "yyyy-MM-dd"),
            },
            'pagina': 0,
            'entradasPorPagina': tempRows,
        });
        setLogToShow(data);
        setLoading(false);
    };

    const handleChangePage = async (event, newPage) => {
        setLoading(true);
        setPage(newPage);
        const data = await getLogUser({
            busca: search,
            filtros: {
                'de': format(new Date(dataInit), "yyyy-MM-dd"),
                'ate': format(new Date(dataFim), "yyyy-MM-dd"),
            },
            'pagina': newPage,
            'entradasPorPagina': rowsPerPage,
        });
        setLogToShow(data);
        setLoading(false);
    };

    useEffect(async () => {
        const data = await getLogUser({
            busca: search,
            filtros: {
                'de': format(new Date(dataInit), "yyyy-MM-dd"),
                'ate': format(new Date(dataFim), "yyyy-MM-dd"),
            },
            'pagina': page,
            'entradasPorPagina': rowsPerPage,
        });

        setLogToShow(data);
        setLoading(false);
    }, []);

    return (
        newPermission.viz ? (
            <>
                <Grid container justify="space-between" spacing={2}>
                    <Grid item md={12} xs={12}>
                        <Box my={2} display="flex" justifyContent="flex-end">
                            <Grid container spacing={3}>
                                <Grid item md={3} xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Nome ou OAB"
                                        name="filter"
                                        variant="outlined"
                                        value={search}
                                        onChange={handleChangeFilter}
                                        style={{ backgroundColor: "white" }}
                                    />
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => handleFilter()}
                                        style={{ padding: "15px 40px" }}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                                <Grid item md={2} xs={12}>
                                    <Button
                                        color="primary"
                                        size="large"
                                        type="button"
                                        variant="contained"
                                        onClick={handleOpenModal}
                                        style={{ padding: "15px 40px" }}
                                    >
                                        Filtrar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Paper className={classes.paper}>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                        {loading ? (
                            <Grid
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    margin: "auto",
                                    padding: 100,
                                }}
                            >
                                <CircularProgress color={"primary"} size={80} />
                            </Grid>
                        ) : (
                            <TableContainer className={classes.tableContainer}>
                                <Table className={classes.table} size={"small"}>
                                    <TableHead className={classes.headerTb}>
                                        <TableRow>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"OAB"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Nome"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Criador"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Ação"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Data"}
                                            </TableCell>
                                            <TableCell key={""} className={classes.celTableHeader}>
                                                {"Hora"}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logToShow.map((row) => {
                                            return (
                                                <TableRow hover key={row.id} style={{ verticalAlign: 'text-top' }}>
                                                    <TableCell style={{ minWidth: 150 }}>{row.newUser.oab}</TableCell>
                                                    <TableCell>{row.newUser.nome}</TableCell>
                                                    <TableCell>{!!row.userAdmCreator ? row.userAdmCreator.nome : row.userCreator.nome}</TableCell>
                                                    {/* <TableCell>d</TableCell> */}
                                                    <TableCell>{row.action}</TableCell>
                                                    <TableCell>{format(new Date(row.createdAt), 'dd/MM/yyyy')}</TableCell>
                                                    <TableCell>{format(new Date(row.createdAt), 'HH:mm')}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12} style={{}}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    labelRowsPerPage={"Linhas por página"}
                                    component="div"
                                    count={logToShow.length}
                                    nextIconButtonProps={{
                                        disabled: logToShow.length < rowsPerPage || loading,
                                        // disabled: true,
                                    }}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Grid>
                    </MuiThemeProvider>
                </Paper>
                <Modal
                    className={classes.modal}
                    open={openModal}
                    onClose={handleCloseModal}
                    keepMounted
                >
                    <Container
                        maxWidth="md"
                        spacing={3}
                        style={{
                            backgroundColor: "white",
                            height: "100%",
                            overflow: "scroll",
                            padding: 30,
                            margin: "0% 10%",
                            borderRadius: 4,
                            border: "1px solid #000000",
                            top: 100,
                        }}
                    >
                        <Grid
                            container
                            spacing={6}
                            style={{
                                padding: 10,
                            }}
                        >
                            <Grid item md={12} xs={12}>
                                <Typography
                                    variant="h3"
                                    display="block"
                                    style={{ marginTop: 10 }}
                                >
                                    Selecionar Filtros
                                </Typography>
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <Grid container spacing={3}>
                                    <Grid xs={6} style={{ padding: 5 }}>
                                        <Typography
                                            variant="h5"
                                            display="block"
                                            style={{ marginTop: 20, marginBottom: 10 }}
                                        >
                                            OAB
                                        </Typography>
                                        <MuiThemeProvider theme={defaultMaterialTheme}>
                                            <TextField
                                                fullWidth
                                                name="filter"
                                                variant="outlined"
                                                value={oab}
                                                onChange={handleChangeOab}
                                                style={{ backgroundColor: 'white' }}
                                            />
                                        </MuiThemeProvider>
                                    </Grid>
                                    <Grid item md={6} xs={12}>
                                        <Grid container spacing={3}>
                                            <Grid xs={6} style={{ padding: 5 }}>
                                                <Typography
                                                    variant="h5"
                                                    display="block"
                                                    style={{ marginTop: 20 }}
                                                >
                                                    Datas entre:
                                                </Typography>
                                                <MuiThemeProvider theme={defaultMaterialTheme}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                                        <DatePicker
                                                            error={Boolean(dataFim < dataInit)}
                                                            helperText={(dataFim < dataInit) ? 'Data inicial deve ser menor que a data final' : ''}
                                                            fullWidth
                                                            margin="normal"
                                                            id="dataInicio"
                                                            format="dd/MM/yyyy"
                                                            autoOk
                                                            value={dataInit}
                                                            onChange={(newValue) => {
                                                                setDataInit(newValue);
                                                            }}
                                                            maxDate={new Date()}
                                                            inputVariant={"outlined"}
                                                            variant="inline"
                                                            style={{ marginTop: 10 }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </MuiThemeProvider>
                                            </Grid>
                                            <Grid xs={6} style={{ padding: 5 }}>
                                                <Typography
                                                    variant="h5"
                                                    display="block"
                                                    style={{ marginTop: 20 }}
                                                >
                                                    E:
                                                </Typography>
                                                <MuiThemeProvider theme={defaultMaterialTheme}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                                                        <DatePicker
                                                            error={Boolean(dataFim < dataInit)}
                                                            helperText={(dataFim < dataInit) ? 'Data inicial deve ser menor que a data final' : ''}
                                                            fullWidth
                                                            margin="normal"
                                                            id="dataInicio"
                                                            format="dd/MM/yyyy"
                                                            autoOk
                                                            value={dataFim}
                                                            onChange={(newValue) => {
                                                                setDataFim(newValue);
                                                            }}
                                                            maxDate={new Date()}
                                                            inputVariant={"outlined"}
                                                            variant="inline"
                                                            style={{ marginTop: 10 }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </MuiThemeProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                                style={{
                                    marginTop: 30,
                                    textAlign: "center",
                                    placeContent: "center",
                                }}
                            >
                                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        onClick={filterByItem}
                                        style={{ padding: "15px 30px", width: 150 }}
                                    >
                                        Aplicar
                                    </Button>
                                </Grid>
                                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        onClick={handleCloseModal}
                                        style={{ padding: "15px 30px", width: 150 }}
                                    >
                                        Cancelar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Modal>
            </>
        ) : (
            <>
                Sem permissão
            </>
        )
    );
}