import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  FormGroup,
  ListItemIcon,
  ListItemText,
  ListItem,
  Checkbox,
  FormControlLabel,
  Divider
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Autocomplete } from '@material-ui/lab';
import { getAllServicos, updateProcedimento } from '../../../../providers/schedules';
import * as Yup from 'yup'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    marginTop: 30
  },

  option: {
    backgroundColor: 'white',
  },

  textArea: {
    minHeight: 100
  },

  checkbox: {
    padding: "0px 12px !important",
    display: "flex",
    alignItems: "center",
  },

  divider: {
    margin: "10px 0px"
  }
}));


export default function EditProcedimento(props) {

  const classes = useStyles()
  const [servicos, setServicos] = useState([])
  const procedimento = props.data
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    nome: procedimento.nome,
    duracao: procedimento.duracao,
    idServico: procedimento.idServico,
    disponivel: procedimento.disponivel,
    disponivelDependente: procedimento.disponivelDependente,
    confirmation: procedimento.confirmation,
    description: procedimento.description,
    days: procedimento.days || false,
    qntdDays: procedimento.qntdDays || 0,
    month: procedimento.month || false,
    qntdMonth: procedimento.qntdMonth || 0,
    end: procedimento.end || false,
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleProcedure);
    };

    fetchPermissions();
  }, []);


  useEffect(async () => {
    const data = await getAllServicos()
    setServicos(data)
  }, [])

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              nome: procedimento.nome,
              duracao: procedimento.duracao,
              idServico: procedimento.idServico,
              disponivel: procedimento.disponivel,
              disponivelDependente: procedimento.disponivelDependente,
              confirmation: procedimento.confirmation,
              description: procedimento.description,
              days: procedimento.days || false,
              qntdDays: procedimento.qntdDays || 0,
              month: procedimento.month || false,
              qntdMonth: procedimento.qntdMonth || 0,
              end: procedimento.end || false,
            }}
            validationSchema={Yup.object().shape({

              nome: Yup.string()
                .required('Campo obrigatório'),
              idServico: Yup.string()
                .required('Campo obrigatório'),

            })}
            onSubmit={async (values) => {
              const temp = {
                id: procedimento.id,
                ...values
              }
              const result = await updateProcedimento({
                id: procedimento.id,
                ...values
              })
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: procedimento.id }, after: temp, menu: "Agendamentos", subMenu: "Procedimento", });
              if (result) {
                props.setState(0)
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <Card style={{ backgroundColor: 'white', paddingTop: 30, marginTop: 20, marginBottom: 20 }}>
                <CardContent >
                  < Grid container spacing={3} justify={'center'} >
                    <Grid item md={8} xs={12} >
                      <Typography variant={'h4'}>Editar procedimento</Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Nome do procedimento"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Autocomplete
                        fullWidth
                        id="select-local"
                        classes={{
                          popper: classes.option
                        }}
                        color={touched.idServico && errors.idServico ? 'error' : 'primary'}
                        options={servicos.map((option) => option)}
                        onChange={(e, value) => {
                          setFieldValue('idServico', value ? value.id : '')
                        }}
                        filterSelectedOptions={true}
                        defaultValue={procedimento.servico}
                        getOptionLabel={(option) => option.nome}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} onBlur={handleBlur} label={'Serviço'} variant="outlined" />
                        )}

                      />
                    </Grid>

                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        label="Descrição"
                        name="description"
                        multiline
                        InputProps={{ classes: { input: classes.textArea } }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid md={8} >

                      <Divider className={classes.divider} />

                      <Grid item md={12} className={classes.checkbox}>
                        <FormControlLabel
                          value="start"
                          name="days"
                          control={<Checkbox
                            checked={values.days}
                            name="days"
                            color="primary"
                            onChange={(event) => {
                              if (!event.target.checked) {
                                setFieldValue('qntdDays', 0);
                              }

                              handleChange(event);
                            }}
                          />}
                          label="Dias"
                          labelPlacement="end"
                        />
                        {values.days && (
                          <TextField
                            error={Boolean(touched.qntdDays && errors.qntdDays)}
                            helperText={touched.qntdDays && errors.qntdDays}
                            name="qntdDays"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              if (!event.target.value.match(/\d/g) && event.target.value !== '') {
                                return;
                              }

                              if (event.target.value < 0) {
                                return;
                              }

                              const value = parseInt(event.target.value);
                              setFieldValue('qntdDays', value.toString());
                              return;
                            }} aa
                            value={values.qntdDays}
                            type="number"
                          />
                        )}
                      </Grid>

                      <Grid item md={12} className={classes.checkbox}>
                        <FormControlLabel
                          value="start"
                          name="month"
                          control={<Checkbox
                            checked={values.month}
                            name="month"
                            color="primary"
                            onChange={(event) => {
                              if (!event.target.checked) {
                                setFieldValue('qntdMonth', 0);
                              }

                              handleChange(event);
                            }}
                          />}
                          label="Quantidade Mês"
                          labelPlacement="end"
                        />
                        {values.month && (
                          <TextField
                            error={Boolean(touched.qntdMonth && errors.qntdMonth)}
                            helperText={touched.qntdMonth && errors.qntdMonth}
                            name="qntdMonth"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              if (!event.target.value.match(/\d/g) && event.target.value !== '') {
                                return;
                              }

                              if (event.target.value < 0) {
                                return;
                              }

                              const value = parseInt(event.target.value);
                              setFieldValue('qntdMonth', value.toString());
                              return;
                            }}
                            value={values.qntdMonth}
                            type="number"
                          />
                        )}
                      </Grid>


                      <Grid item md={12} className={classes.checkbox}>
                        <FormControlLabel
                          value={values.end}
                          name="start"
                          control={<Checkbox
                            checked={values.end}
                            name="end"
                            color="primary"
                            onChange={handleChange}
                          />}
                          label="Após Último Agendamento Finalizado"
                          labelPlacement="end"
                        />
                      </Grid>

                      <Divider className={classes.divider} />

                    </Grid>

                    <Grid item md={8} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.duracao && errors.duracao)}
                        helperText={touched.duracao && errors.duracao}
                        label="Duração"
                        name="duracao"
                        type='number'
                        id='text-duracao'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.duracao}
                        variant="outlined"
                      />
                    </Grid>


                    <Grid item md={8} className={classes.checkbox}>
                      <FormControlLabel
                        value="start"
                        name="disponivel"
                        control={<Checkbox
                          checked={values.disponivel}
                          name="disponivel"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Disponível"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={8} my={2} className={classes.checkbox}>
                      <FormControlLabel
                        value="start"
                        name="disponivelDependente"
                        control={<Checkbox
                          checked={values.disponivelDependente}
                          name="disponivelDependente"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Disponível para Dependente"
                        labelPlacement="end"
                      />
                    </Grid>

                    <Grid item md={8} className={classes.checkbox}>
                      <FormControlLabel
                        value="start"
                        name="confirmation"
                        control={<Checkbox
                          checked={values.confirmation}
                          name="confirmation"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Confirmação automática"
                        labelPlacement="end"
                      />
                    </Grid>



                    <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>
                      <Button
                        onClick={() => props.setState(0)}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Voltar
                      </Button>
                      {newPermission.edit && (
                        <Button
                          onClick={() => handleSubmit()}
                          className={classes.submitBtn}
                          color='primary'
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Atualizar
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}