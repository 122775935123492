import React, { useEffect, useState } from 'react';
import Noticia from './noticias'
import Slides from './slides'
import Config from '../../components/news/config';
import { makeStyles, Paper, Tabs, Tab, Box, CircularProgress } from '@material-ui/core';
import { color } from '../../components/common/colors';
import Page from '../../components/common/page'
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(0)
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function NewsHome({ }) {
  const classes = useStyles()
  const [tab, setTab] = useState(0);
  const [stateSlide, setStateSlide] = useState(0);
  const [slideToEdit, setSlideToEdit] = useState()

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page className={classes.root}
      title="Notícias"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab label="Notícias" id={0} />
          <Tab label="Slides" id={1} />
          <Tab label="Configurações" id={2} />
        </Tabs>
      </Paper>
      <TabPanel value={tab} index={0}>
        <Noticia />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Slides />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Config />
      </TabPanel>
    </Page>
  )
}