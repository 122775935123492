import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  createMuiTheme,
  Grid,
  makeStyles,
  Modal,
  MuiThemeProvider,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, TreeItem, TreeView } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  add,
  differenceInMinutes,
  endOfDay,
  format,
  getTime,
  startOfDay,
} from "date-fns";
import pt from "date-fns/locale/pt-BR";
import "jspdf-autotable";
import React, { useEffect, useState } from "react";
import { color } from "../../../components/common/colors";
import { deleteUserAdm, getAll as getAllUsers } from '../../../providers/userAdm'
import { getLog } from "../../../providers/log";
import {
  ChevronRight,
  ExpandMore,
} from '@material-ui/icons';
import { changeSubeventoListagem, evento } from "../../../components/common/categories";
import { useSelector } from "react-redux";
import { getPermissions } from "../../../providers/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: "100%",
    padding: theme.spacing(3),
  },
  card: {
    background: color.white,
  },
  tableContainer: {
    backgroundColor: color.white,
  },
  submitBtn: {
    background: color.red,
    color: color.white,
    "&:hover": {
      backgroundColor: "#a35655",
    },
  },
  listText: {
    fontSize: 14,
  },
  option: {
    backgroundColor: "white",
  },
  paper: {
    marginTop: 10,
  },
  diolog: {
    background: color.white,
  },
  dialogRoot: {
    background: "rgba(0.0.0, 0.3)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    height: "90%",
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red,
    },
  },
});

export default function ListLog({ props }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState();
  const [logToShow, setLogToShow] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [dataInit, setDataInit] = useState(add(new Date(), { days: -30 }));
  const [dataFim, setDataFim] = useState(add(new Date(), { days: 30 }));
  const [acao, setAcao] = useState('');
  const [user, setUser] = useState('');
  const [eventos, setEventos] = useState(evento);
  const [subeventos, setSubeventos] = useState("");
  const [eventoSelected, setEventoSelected] = useState('');
  const [subeventoSelected, setSubeventoSelected] = useState('');
  const [searchApi, setSearchApi] = useState("");
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
  const [userList, setUserList] = useState('');

  const [loading, setLoading] = useState(true);
  const filtrosAcao = ['','UPDATE', 'DELETE', 'CREATE'];

  useEffect(async () => {
    const usersdata = await getAllUsers()
    const body = usersdata.map((element) => element.nome)
    setUserList(body);
  }, []);

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.logAdm);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    setLoading(true);
    const req = async () => {
      const data = await getLog({
        busca: searchApi,
        filtros: {
          'de': format(new Date(dataInit), "yyyy-MM-dd"),
          'ate': format(new Date(dataFim), "yyyy-MM-dd"),
          'acao': acao,
          'eventos': eventoSelected,
          'subEventos': subeventoSelected
        },
        'pagina': page,
        'entradasPorPagina': rowsPerPage,
      });
      console.log(data)
      setLogToShow(data);
    }
    req();
    setLoading(false);
  }, [rowsPerPage, page, searchApi]);

  const chamadaApi = async () => {
    setLoading(true);
    const data = await getLog({
      busca: search,
      filtros: {
        'de': format(new Date(dataInit), "yyyy-MM-dd"),
        'ate': format(new Date(dataFim), "yyyy-MM-dd"),
        'acao': acao,
        'eventos': eventoSelected,
        'subEventos': subeventoSelected,
        'nome': user,
      },
      'pagina': page,
      'entradasPorPagina': rowsPerPage,
    });
    setLoading(false);
    setLogToShow(data);
    setOpenModal(false);
  }

  function listItem(entry) {
    // const array = [];
    // const keys = Object.keys(entry);
    // for (const k of keys) {
    //   array.push(k + ' : ' + entry[k]);
    // }
    // return array

    const value = entry.split(',');
    if (!!entry) {
      if (!!value && value.length > 0) {
        return value;
      }
    }
    return [];
  }


  const handleFilter = async () => {
    setSearchApi(search)
    chamadaApi()
  };

  const OrderByName = () => {

  };
  const OrderByStatus = () => {

  };
  const OrderByProf = () => {

  };
  const OpenOrder = (event) => {
    setAnchorEl(event.currentTarget);
    setPage(0);
  };
  const CloseOrder = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleChangeFilter = (event) => {
    setSearch(event.target.value);
  };

  const handleChangeRowsPerPage = async (event) => {
    let teste = event.target.value;
    setRowsPerPage(teste);
    setPage(0);
  };

  const handleChangePage = async (event, newPage) => {
    setLoading(true);
    setPage(newPage);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    setPage(0);
    // setLogToShow(list);
    // setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const filterByItem = async () => {
    setLoading(true);
    setPage(0);
    const data = await getLog({
      busca: search,
      filtros: {
        'de': format(new Date(dataInit), "yyyy-MM-dd"),
        'ate': format(new Date(dataFim), "yyyy-MM-dd"),
        'acao': acao,
        'eventos': eventoSelected,
        'subEventos': subeventoSelected
      },
      'pagina': page,
      'entradasPorPagina': rowsPerPage,
    });
    setLoading(false);
    setLogToShow(data);
    handleCloseModal(true);
  };
  console.log(logToShow)
  return (
    newPermission.viz ? (
      <>
        <Grid container justify="space-between" spacing={2}>
          <Grid item md={12} xs={12}>
            <Box my={2} display="flex" justifyContent="flex-end">
              <Grid container spacing={3}>
                <Grid item md={3} xs={12}>
                  <TextField
                    fullWidth
                    label="Nome ou OAB"
                    name="filter"
                    variant="outlined"
                    value={search}
                    onChange={handleChangeFilter}
                    style={{ backgroundColor: "white" }}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleFilter()}
                    style={{ padding: "15px 40px" }}
                  >
                    Buscar
                  </Button>
                </Grid>

                {/* <Grid item md={2} xs={12}>
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={OpenOrder}
                  style={{ padding: "15px 40px" }}
                >
                  Ordenar
                </Button>
              </Grid> */}
                <Grid item md={2} xs={12}>
                  <Button
                    color="primary"
                    size="large"
                    type="button"
                    variant="contained"
                    onClick={handleOpenModal}
                    style={{ padding: "15px 40px" }}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Paper className={classes.paper}>
          <MuiThemeProvider theme={defaultMaterialTheme}>
            {loading ? (
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "auto",
                  padding: 100,
                }}
              >
                <CircularProgress color={"primary"} size={80} />
              </Grid>
            ) : (
              <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} size={"small"}>
                  <TableHead className={classes.headerTb}>
                    <TableRow>
                      <TableCell key={"nome"} className={classes.celTableHeader}>
                        {"Usuário"}
                      </TableCell>
                      <TableCell key={"acao"} className={classes.celTableHeader}>
                        {"Ação"}
                      </TableCell>
                      <TableCell key={"eventos"} className={classes.celTableHeader}>
                        {"Eventos"}
                      </TableCell>
                      <TableCell key={"registros"} className={classes.celTableHeader}>
                        {"Registros"}
                      </TableCell>
                      <TableCell key={"antes"} className={classes.celTableHeader}>
                        {"Antes"}
                      </TableCell>
                      <TableCell
                        key={"depois"}
                        className={classes.celTableHeader}
                      >
                        {"Depois"}
                      </TableCell>
                      <TableCell key={"data"} className={classes.celTableHeader}>
                        {"Data"}
                      </TableCell>
                      <TableCell key={"hora"} className={classes.celTableHeader}>
                        {"Hora"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {logToShow.map((row) => {
                      return (
                        <TableBody>
                          <TableRow hover key={row.id} style={{ verticalAlign: 'text-top' }}>
                            <TableCell style={{ minWidth: 150 }}>{`${!!row.userAdmLog + ' ID: ' + row.idUser ? row.userAdmLog.nome + ' ID: ' + row.idUser : ''}`}</TableCell>
                            <TableCell>{row.action}</TableCell>
                            <TableCell>{row.menu}</TableCell>
                            <TableCell>{'ID: ' + row.id} </TableCell>
                            <TableCell>
                              <TreeView
                                defaultCollapseIcon={<ExpandMore />}
                                defaultExpandIcon={<ChevronRight />}
                              >
                                <TreeItem nodeId="1" label="Ver mais..." >
                                  {listItem(row.before).map((p, i) => {
                                    if (row.action == 'UPDATE' && p != '{}') {
                                      return (
                                        <TreeItem nodeId={i + 1} label={p.includes('http') ?
                                          p.replace(/['"{}]/g, "").replace(':', ': ')
                                          : p.replace(/[\[\].!'@><|//\\;&*()_+={}"]/g, "").replace(':', ': ')} />
                                      )
                                    } else {
                                      return (
                                        <TreeItem nodeId={i + 1} label={'N/A'} />
                                      )
                                    }
                                  })}
                                </TreeItem>
                              </TreeView>
                            </TableCell>
                            <TableCell>
                              <TreeView
                                defaultCollapseIcon={<ExpandMore />}
                                defaultExpandIcon={<ChevronRight />}
                              >
                                <TreeItem nodeId="1" label="Ver mais..." >
                                  {listItem(row.after).map((p, i) => {
                                    if (p != '{}') {
                                      return (
                                        <TreeItem nodeId={i + 1} label={p.includes('http') ?
                                          p.replace(/['"{}]/g, "").replace(':', ': ')
                                          : p.replace(/[\[\].!'@><|//\\;&*()_+={}"]/g, "").replace(':', ': ')} />
                                      )
                                    } else {
                                      return (
                                        <TreeItem nodeId={i + 1} label={'N/A'} />
                                      )
                                    }
                                  })}
                                </TreeItem>
                              </TreeView>
                            </TableCell>
                            <TableCell>{format(new Date(row.createdAt), 'dd/MM/yyyy')}</TableCell>
                            <TableCell>{format(new Date(row.createdAt), 'HH:mm')}</TableCell>
                          </TableRow>
                        </TableBody>
                      );
                    })}
                    

                </Table>
              </TableContainer>
            )}
            <Grid container spacing={3}>
              <Grid item md={6} xs={12} style={{}}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  labelRowsPerPage={"Linhas por página"}
                  component="div"
                  count={logToShow.length}
                  nextIconButtonProps={{
                    disabled: logToShow.length < rowsPerPage || loading,
                    // disabled: true,
                  }}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </MuiThemeProvider>
        </Paper>
        <Box className={classes.footer} />
        <Popover
          id={"orderBy"}
          open={open}
          anchorEl={anchorEl}
          onClose={CloseOrder}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Grid style={{ padding: 10, backgroundColor: "#A93533" }}>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color="white"
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: "#A93533" }}
                onClick={() => OrderByName()}
              >
                Nome
              </Button>
            </Grid>
            <Grid style={{ marginBottom: 10 }}>
              <Button
                color="white"
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: "#A93533" }}
              // onClick={() => OrderByServ()}
              >
                Serviço
              </Button>
            </Grid>
            <Grid>
              <Button
                color="white"
                size="large"
                type="submit"
                variant="contained"
                style={{ width: 130, color: "#A93533" }}
                onClick={() => OrderByProf()}
              >
                Profissional/ Recurso
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Modal
          className={classes.modal}
          open={openModal}
          onClose={handleCloseModal}
          keepMounted
        >
          <Container
            maxWidth="md"
            spacing={3}
            style={{
              backgroundColor: "white",
              height: "100%",
              overflow: "scroll",
              padding: 30,
              margin: "0% 10%",
              borderRadius: 4,
              border: "1px solid #000000",
              top: 100,
            }}
          >
            <Grid
              container
              spacing={6}
              style={{
                padding: 10,
              }}
            >
              <Grid item md={12} xs={12}>
                <Typography
                  variant="h3"
                  display="block"
                  style={{ marginTop: 10 }}
                >
                  Selecionar Filtros
                </Typography>
              </Grid>
              <Grid item md={12} xs={12}>
                <Grid container spacing={3}>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography
                      variant="h5"
                      display="block"
                      style={{ marginTop: 20 }}
                    >
                      Ação
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demos"
                      classes={{
                        popper: classes.option,
                      }}
                      value={acao}
                      options={filtrosAcao}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setAcao(value);
                      }}
                      style={{ backgroundColor: "white" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginTop: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Grid container spacing={3}>
                      <Grid xs={6} style={{ padding: 5 }}>
                        <Typography
                          variant="h5"
                          display="block"
                          style={{ marginTop: 20 }}
                        >
                          Datas entre:
                        </Typography>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                            <DatePicker
                              error={Boolean(dataFim < dataInit)}
                              helperText={(dataFim < dataInit) ? 'Data inicial deve ser menor que a data final' : ''}
                              fullWidth
                              margin="normal"
                              id="dataInicio"
                              format="dd/MM/yyyy"
                              autoOk
                              value={dataInit}
                              onChange={(newValue) => {
                                setDataInit(newValue);
                              }}
                              maxDate={new Date()}
                              inputVariant={"outlined"}
                              variant="inline"
                              style={{ marginTop: 10 }}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                      <Grid xs={6} style={{ padding: 5 }}>
                        <Typography
                          variant="h5"
                          display="block"
                          style={{ marginTop: 20 }}
                        >
                          E:
                        </Typography>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                            <DatePicker
                              error={Boolean(dataFim < dataInit)}
                              helperText={(dataFim < dataInit) ? 'Data inicial deve ser menor que a data final' : ''}
                              fullWidth
                              margin="normal"
                              id="dataInicio"
                              format="dd/MM/yyyy"
                              autoOk
                              value={dataFim}
                              onChange={(newValue) => {
                                setDataFim(newValue);
                              }}
                              maxDate={new Date()}
                              inputVariant={"outlined"}
                              variant="inline"
                              style={{ marginTop: 10 }}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography
                      variant="h5"
                      display="block"
                      style={{ marginTop: 20 }}
                    >
                      Eventos
                    </Typography>
                    <Autocomplete
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demos"
                      classes={{
                        popper: classes.option,
                      }}
                      value={eventoSelected}
                      options={evento}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setEventoSelected(value);
                        // setSubeventoSelected('');
                        setSubeventos(changeSubeventoListagem(value));
                      }}
                      style={{ backgroundColor: "white" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginTop: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography
                      variant="h5"
                      display="block"
                      style={{ marginTop: 20 }}
                    >
                      Subeventos
                    </Typography>
                    <Autocomplete
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demos"
                      classes={{
                        popper: classes.option,
                      }}
                      value={subeventoSelected}
                      options={subeventos}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setSubeventoSelected(value);
                      }}
                      disabled={!eventoSelected}
                      style={{ backgroundColor: "white" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginTop: 10 }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} style={{ padding: 5 }}>
                    <Typography
                      variant="h5"
                      display="block"
                      style={{ marginTop: 20 }}
                    >
                      Usuários
                    </Typography>
                    <Autocomplete
                      disablePortal
                      DisableClearable
                      CloseOnSelect
                      id="checkboxes-tags-demos"
                      classes={{
                        popper: classes.option,
                      }}
                      value={user}
                      options={userList}
                      getOptionLabel={(option) => option}
                      onChange={(e, value) => {
                        setUser(value);
                      }}
                      style={{ backgroundColor: "white" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{ marginTop: 10 }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                style={{
                  marginTop: 30,
                  textAlign: "center",
                  placeContent: "center",
                }}
              >
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color="primary"
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={chamadaApi}
                    style={{ padding: "15px 30px", width: 150 }}
                  >
                    Aplicar
                  </Button>
                </Grid>
                <Grid item md={3} xs={12} style={{ marginRight: "-1%" }}>
                  <Button
                    color="primary"
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={handleCloseModal}
                    style={{ padding: "15px 30px", width: 150 }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Modal>
      </>
    ) : (
      <>
        Sem permissão
      </>
    )
  );
}
