import React, { useState, useEffect } from 'react';
import { ErrorMessage, Formik } from 'formik';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  FormGroup,
  ListItemIcon,
  ListItemText,
  ListItem,
  Checkbox,
  Collapse,
  IconButton,
  FormControlLabel,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Alert, Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { getAllCategory, updateServico } from '../../../../providers/schedules';
import * as Yup from 'yup';
import {
  CloudUpload as CloudUploadIcon
} from '@material-ui/icons'
import { getPermissions } from '../../../../providers/permissions';
import { useSelector } from 'react-redux';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(1)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    // width: '100%', 
    marginBottom: 20
  },
  option: {
    backgroundColor: 'white'
  },
  inputFile: {
    display: 'none'
  }
}));


export default function EditService({ setState, category, servico, setTab, setStateCategory }) {

  const classes = useStyles()
  const [categorias, setCategorias] = useState([])
  const [created, serCreated] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    nome: servico.nome,
    idCategoria: servico.idCategoria,
    disponivelDependente: servico.disponivelDependente,
    disponivel: servico.disponivel,
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.scheduleServices);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const data = await getAllCategory()
    setCategorias(data)
  }, [])

  return (
    <>
      <Collapse in={created} style={{
        position: 'absolute',
        bottom: 20,
        left: 20,
        zIndex: 99999
      }}>
        <Alert
          severity={'success'}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                serCreated(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Serviço editado com sucesso
        </Alert>
      </Collapse>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" >
          <Box display="flex" justifyContent="flex-end">
            <Button
              onClick={() => {
                setTab(1)
                setStateCategory(1)
              }}
              className={classes.submitBtn}
              size="large"
              type="submit"
              variant="contained"
            >
              Cadastrar nova categoria
            </Button>
          </Box>
          <Formik
            initialValues={{
              nome: servico.nome,
              idCategoria: servico.idCategoria,
              disponivelDependente: servico.disponivelDependente,
              disponivel: servico.disponivel,
              img: null,
            }}
            validationSchema={Yup.object().shape({

              nome: Yup.string()
                .required('Campo obrigatório'),
              idCategoria: Yup.string()
                .required('Campo obrigatório'),

            })}
            onSubmit={async (values) => {
              if (values.img) {
                const body = new FormData()
                body.append('file', values.img)
                body.append('folder', UploadFolder.MISC)
                const data = await apiUpload(body);
                values.urlImage = data.location
              }
              delete values.img
              values.id = servico.id
              const result = await updateServico({
                id: servico.id,
                ...values
              })
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: servico.id }, after: values, menu: "Agendamentos", subMenu: "Serviço", });
              serCreated(true)
            }}

          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <Card style={{ backgroundColor: 'white', paddingTop: 30 }}>

                <CardContent >


                  < Grid container spacing={3} justify={'center'} >
                    <Grid item md={8} xs={12} >
                      <Grid item md={12}>
                        <FormControlLabel
                          value="start"
                          name="disponivel"
                          control={<Checkbox
                            checked={values.disponivel}
                            name="disponivel"
                            color="primary"
                            onChange={handleChange}
                          />}
                          label="Disponível?"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <FormControlLabel
                          value="start"
                          name="disponivelDependente"
                          control={<Checkbox
                            checked={values.disponivelDependente}
                            name="disponivelDependente"
                            color="primary"
                            onChange={handleChange}
                          />}
                          label="Disponível para dependente?"
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={8} xs={12} >
                      <Typography variant={'h4'}>Editar serviço</Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        name="nome"
                        label="Nome do serviço"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <Autocomplete
                        fullWidth
                        id="select-local"
                        classes={{
                          popper: classes.option
                        }}
                        color={errors.idCategoria ? 'error' : 'primary'}
                        options={categorias.map((option) => option)}
                        getOptionLabel={option => option.nome}
                        onChange={(e, value) => {
                          setFieldValue('idCategoria', value ? value.id : '')
                        }}
                        filterSelectedOptions={true}
                        defaultValue={servico.categoria}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} onBlur={handleBlur} label={'Categoria'} variant="outlined" />
                        )}
                      />
                      <ErrorMessage name='idCategoria' render={msg => (<Typography style={{ fontSize: 14, marginLeft: '4%' }} color={'error'}>{msg}</Typography>)} />
                    </Grid>

                    {<><Grid item md={8} xs={12}>
                      <img src={values.img ? URL.createObjectURL(values.img) : servico.urlImage}
                        style={{ width: '100%', borderRadius: 4 }} alt="newImage" />
                      <input
                        accept="image/*"
                        className={classes.inputFile}
                        name='img'
                        id="img-input"
                        type="file"
                        onChange={event => setFieldValue('img', event.target.files[0])}
                      />
                      <label htmlFor="img-input">
                        <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                          Nova Imagem
                        </Button>
                      </label>
                      {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.img && errors.img}
                      </Typography>}
                    </Grid></>}


                    <Box my={2} display="flex" justifyContent="space-around" style={{ width: '100%' }}>
                      <Button
                        onClick={() => {
                          setState(0)
                        }}
                        className={classes.submitBtn}
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Voltar
                      </Button>
                      {newPermission.edit && (
                        <Button
                          onClick={() => {
                            handleSubmit()
                          }}
                          className={classes.submitBtn}
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Atualizar
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}