import React from 'react';
import logo from '../../assets/newLogo.png'
import komunick from '../../assets/komunick.png'

const Logo = (props) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: 1200 }}>
      <div style={{ flex: 1 }}>
        <img
          alt="Logo"
          src={logo}
          {...props}
          width={80}
          style={{ borderRadius: 3 }}
        />
      </div>
      <div style={{ flex: 1, alignSelf: 'flex-end', textAlignLast: 'right' }}>
        <a href="https://komunick.com" target="_blank" style={{ backgroundColor: 'transparent', border: 'none' }}>
          <img
            alt="komunick"
            src={komunick}
            {...props}
            width={100}
            style={{ borderRadius: 3 }}
          />
        </a>
      </div>
    </div>
  );
};

export default Logo;
