import api from './api'

export const create = async slide => {
  const { data } = await api.post('slide', { slide })
  return data
}

export const getAll = async () => {
    const { data } = await api.get('slide')
    return data
}

export const update = async slide => {
  const { data } = await api.post(`slide/${slide.id}`, { slide })
  return data
}

export const deleteSlide = async id => {
  const { data } = await api.delete(`slide/${id}`)
  return data
}