import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { editCategory } from '../../../../providers/partner'
import {
  CloudUpload as CloudUploadIcon
} from '@material-ui/icons'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  btns: {
    marginTop: 15,
  },
  btns2: {
    marginTop: 15,
    marginLeft: 7
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
}));

export default function EditCategory({ setState, category }) {
  const classes = useStyles()
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    name: category.name || '',
    description: category.description || '',
    priority: category.priority || '',
    imgCard: null,
    imgCapa: null
  });

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerCategorie);
    };

    fetchPermissions();
  }, []);

  return (
    <Container maxWidth="md" disableGutters>
      <Formik
        initialValues={{
          name: category.name || '',
          description: category.description || '',
          priority: category.priority || '',
          imgCard: null,
          imgCapa: null
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required('Campo obrigatório'),
          description: Yup.string()
            .required('Campo obrigatório'),
          priority: Yup.string()
            .required('Campo obrigatório'),
        })}
        onSubmit={async (values, actions) => {
          try {
            if (values.imgCard) {
              const body = new FormData()
              body.append('folder', UploadFolder.MISC)
              body.append('file', values.imgCard)
              const dataCard = await apiUpload(body);
              values.imgCard = dataCard.location
            } if (values.imgCapa) {
              const body = new FormData()
              body.append('folder', UploadFolder.MISC)
              body.append('file', values.imgCapa)
              const dataCapa = await apiUpload(body);
              values.imgCapa = dataCapa.location
            }
            values.id = category.id
            await editCategory(values)
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: category.id }, after: values, menu: "Parceiros", subMenu: "Parceiros" });
            } catch (e) {
            console.log(e)
          } finally {
            setState(0)
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Card className={classes.card}>
              <CardContent >
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Nome"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      error={Boolean(touched.priority && errors.priority)}
                      helperText={touched.priority && errors.priority}
                      label="Prioridade"
                      name="priority"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.priority}
                      variant="outlined"
                      type='number'
                      inputProps={{
                        max: 9999,
                        min: 1
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} >
                    <TextField
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      label="Descrição"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>

                  {<><Grid item md={8} xs={12}>
                    <img src={values.imgCard ? URL.createObjectURL(values.imgCard) : category.imgCard}
                      style={{ width: '100%', borderRadius: 4 }} alt="newImage" />
                    <input
                      accept="image/*"
                      className={classes.inputFile}
                      name='imgCard'
                      id="image-card"
                      type="file"
                      onChange={event => setFieldValue('imgCard', event.target.files[0])}
                    />
                    <label htmlFor="image-card">
                      <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                        NOVO CARD
                      </Button>
                    </label>
                    {Boolean(touched.imgCard && errors.imgCard) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                      *{touched.imgCard && errors.imgCard}
                    </Typography>}
                  </Grid></>}

                  {<><Grid item md={8} xs={12}>
                    <img src={values.imgCapa ? URL.createObjectURL(values.imgCapa) : category.imgCapa}
                      style={{ width: '100%', borderRadius: 4 }} alt="newImage" />
                    <input
                      accept="image/*"
                      className={classes.inputFile}
                      name='imgCapa'
                      id="image-capa"
                      type="file"
                      onChange={event => setFieldValue('imgCapa', event.target.files[0])}
                    />
                    <label htmlFor="image-capa">
                      <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                        NOVA CAPA
                      </Button>
                    </label>
                    {Boolean(touched.imgCapa && errors.imgCapa) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                      *{touched.imgCapa && errors.imgCapa}
                    </Typography>}
                  </Grid></>}

                </Grid>
                <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                  <Grid item md={3} xs={12}>
                    <Button
                      fullWidth
                      disabled={isSubmitting}
                      size="large"
                      color='secondary'
                      variant="contained"
                      onClick={() => setState(0)}
                    >
                      Voltar
                        </Button>
                  </Grid>
                  {newPermission.edit && (
                    <Grid item md={3} xs={12}>
                      <Button
                        fullWidth
                        color='primary'
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar Dados'}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Container>
  )
}