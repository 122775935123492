import api from "./api";

export async function apiUpload(formData) {
  const { data } = await api.post('/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return data;
}

export const UploadFolder = {
  NEWS: 'news',
  SLIDES: 'slides',
  PARTNER: 'partner',
  PARTNER_LOGO: 'partner/logo',
  PARTNER_CONTRACT: 'partner/contract',
  MISC: 'misc',
}