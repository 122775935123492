import { ADD_ADM_EDIT, RMV_ADM_EDIT } from '../actions/colaboradorEditActions';

const initState = null

const colaboradorEditReducer = (state = initState, action) => {
    switch (action.type) {
        case ADD_ADM_EDIT:
            return {...state, ...action.payload};
        case RMV_ADM_EDIT:
            return initState
        default:
            return state;
    }
};

export default colaboradorEditReducer;