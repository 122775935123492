import React from 'react';
import { Formik } from 'formik';
import {
  TextField,
  Button,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Typography,

} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { newCategory } from '../../../../providers/schedules';
import { useSelector } from 'react-redux';
import { alterationLog } from '../../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },

  submitBtn: {
    background: color.red,
    color: color.white,
    '&:hover': {
      backgroundColor: '#a35655',
    },
    marginTop: 30
  },
  card: {
    background: color.white,
    marginBottom: 60
  },
  textArea: {
    minHeight: 100
  },
}));


export default function NewCategory({ setState }) {
  const classes = useStyles()

  const userData = useSelector(({ user }) => user);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md" >
          <Formik
            initialValues={{
              nome: '',
            }}
            onSubmit={async (values) => {
              try {
                await newCategory(values)
                await alterationLog({ action: 'CREATE', idUser: userData.id, before: '', after: values, menu: "Agendamentos", subMenu: "Serviço" });
                setState(0)
              } catch (e) {
                console.error(e)
                setState(0)
              }
            }}
            validationSchema={Yup.object().shape({
              nome: Yup.string()
                .required('Campo obrigatório'),
            })}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values
            }) => (
              <Card style={{ backgroundColor: 'white', paddingTop: 30 }}>
                <CardContent >
                  < Grid container spacing={3} justify={'center'} >
                    <Grid item md={8} xs={12} >
                      <Typography variant={'h4'}>Nova categoria</Typography>
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Categoria"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>



                    <Grid item md={8} xs={12}>

                      <Box my={2} display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                        <Button
                          onClick={() => setState(0)}
                          className={classes.submitBtn}
                          color='primary'
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          voltar
                        </Button>
                        <Button
                          onClick={() => handleSubmit()}
                          className={classes.submitBtn}
                          color='primary'
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Cadastrar
                        </Button>

                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  )
}