import React, { useState } from 'react';

import AvaliationSchedule from './avaliation';
import DataAvaliationSchedule from './check-avaliation'

export default function AvaliacaoAgendamento(props) {

  const [state, setState] = useState(0)
  const [avaliationToCheck, SetAvaliationToCheck] = useState()


  return (
    <>
      {state === 0 && <AvaliationSchedule setState={setState} SetAvaliationToCheck={SetAvaliationToCheck} />}
      {state === 1 && <DataAvaliationSchedule setState={setState} avaliation={avaliationToCheck} />}
    </>
  )
}