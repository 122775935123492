import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  CircularProgress,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { format, add, getTime } from 'date-fns';
import { getAll, searchUsers } from '../../../../providers/user'
import Backdrop from '@material-ui/core/Backdrop';
import ReactLoading from 'react-loading';
import { insertCaassh } from '../../../../providers/saldo'
import CurrencyInput from 'react-currency-input-field';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  currencyField: {
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function CreditAssociate({ setState, setUsers }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userList, setUsertList] = useState([])
  const [allUsers, setAllUsers] = useState([])
  const [search, setSearch] = useState('')
  const [userToShow, setUsersToShow] = useState([])
  const [selected, setSelected] = useState([]);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allSelected, setAllSelected] = useState(false)

  useEffect(async () => {
    const data = await searchUsers({
      'pagina': page,
      'entradasPorPagina': rowsPerPage
    })
    const listWithFilter = data.map((user) => {
      const filtro = `${user.nome};${user.cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4')};${user.oab}`
      return { ...user, filter: filtro }
    })
    listWithFilter.sort((a, b) => {
      return (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0);
    });


    setUsertList(listWithFilter)
    setUsersToShow(listWithFilter)
    setLoading(false);
  }, [])

  useEffect(async () => {
    const data = await getAll()

    setAllUsers(data)
    setUsertList(data)
  }, [])

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    const data = await searchUsers({
      'texto': search,
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': newPage,
      'entradasPorPagina': rowsPerPage
    })
    setUsersToShow(data)
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0)
    const data = await searchUsers({
      'texto': search,
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': 0,
      'entradasPorPagina': parseInt(event.target.value, 10)
    })
    setUsersToShow(data)
  };

  const creditPoints = (users) => {
    if (selected.length) {
      setUsers(selected)
      selected.length === 1 && selected.length !== 0 ? setState(5) : setState(6)
    }
  }

  const handleChangeFilter = (event) => {
    setSearch(event.target.value)
  }

  const handleFilter = async () => {
    setPage(0)
    const data = await searchUsers({
      'texto': search,
      'timeOffset': new Date().getTimezoneOffset(),
      'pagina': 0,
      'entradasPorPagina': rowsPerPage
    })
    setUsersToShow(data)
  }


  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelected(userList);
      setAllSelected(true)
      return;
    }
    setAllSelected(false)
    setSelected([]);
  };


  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, userToShow.length - page * rowsPerPage);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  return (
    <Container maxWidth="md" disableGutters>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Formik
          initialValues={{
          }}
          validationSchema={Yup.object().shape({
          })}
          onSubmit={async (values, actions) => {
            try {

            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3} style={{ marginBottom: 20 }}>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Nome, OAB ou CPF"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={handleChangeFilter}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Button
                        color='primary'
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => handleFilter()}
                        style={{ padding: '15px 40px' }}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                  <Paper className={classes.paper}>
                    <MuiThemeProvider theme={defaultMaterialTheme}>
                      {loading
                        ? <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', padding: 100 }}>
                          <Backdrop className={classes.backdrop} open={loading}>
                            <ReactLoading type={'spokes'} color={color.red} />
                          </Backdrop>
                        </Grid>
                        :
                        <TableContainer className={classes.tableContainer}>
                          <Table stickyHeader className={classes.table}>
                            <TableHead className={classes.headerTb}>
                              <TableRow>
                                {allUsers.length > 0 ?
                                  <TableCell padding="checkbox" className={classes.celTableHeader}>
                                    <Checkbox
                                      indeterminate={selected.length > 0 && selected.length < userList.length}
                                      checked={userList.length > 0 && selected.length === userList.length}
                                      onChange={handleSelectAllClick}
                                      color="primary"
                                    />
                                  </TableCell>
                                  :
                                  <TableCell key={'checkbox'} className={classes.celTableHeader} padding="checkbox">
                                    {""}
                                  </TableCell>
                                }
                                <TableCell key={'nome'} className={classes.celTableHeader}>
                                  {"Nome"}
                                </TableCell>
                                <TableCell key={'oab'} className={classes.celTableHeader}>
                                  {"OAB"}
                                </TableCell>
                                <TableCell key={'cpf'} className={classes.celTableHeader}>
                                  {"CPF"}
                                </TableCell>
                                <TableCell key={'situacao'} className={classes.celTableHeader}
                                  style={{ width: 300 }}
                                >
                                  {"Situação"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {userToShow.map((row, index) => {
                                const isItemSelected = isSelected(row);
                                return (
                                  <TableRow
                                    hover
                                    onClick={(event) => handleClick(event, row)}
                                    key={row.id}
                                    selected={isItemSelected}
                                    aria-checked={isItemSelected}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={allSelected ? allSelected : isItemSelected}
                                        color="primary"
                                      />
                                    </TableCell>
                                    <TableCell >{row.nome}</TableCell>
                                    <TableCell >{row.oab}</TableCell>
                                    <TableCell >{row.cpf}</TableCell>
                                    <TableCell >{row.situacao}</TableCell>
                                  </TableRow>
                                );
                              })}
                              {/* {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )} */}
                            </TableBody>
                          </Table>
                        </TableContainer>}
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        labelRowsPerPage={'Linhas por página'}
                        component="div"
                        count={-1}
                        nextIconButtonProps={{ disabled: userToShow.length < rowsPerPage }}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    </MuiThemeProvider>
                  </Paper>
                  <Grid container style={{ marginTop: 20 }} alignItems='flex-end' justify='flex-end'>
                    <Grid item md={3}>
                      <Button
                        color='primary'
                        fullWidth
                        disabled={isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                        onClick={() => creditPoints(selected)}
                      >
                        {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Creditar Pontos'}
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  )
}