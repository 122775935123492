import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Box,
  Container,
  Card,
  CardContent,
  Grid,
  Checkbox,
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { color } from '../../../components/common/colors';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getAll } from '../../../providers/user'
import { insertCaassh } from '../../../providers/saldo'
import CurrencyInput from 'react-currency-input-field';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  currencyField: {
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function InsertCaassh(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userList, setUsertList] = useState([])
  const [selected, setSelected] = useState([]);
  const [tab, setTab] = useState(0);


  useEffect(async () => {
    const data = await getAll()
    setUsertList(data)
  }, [])

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filter = (nome) => {
    // setUsertList(userList1.filter(user => {
    //   return user.nome.includes(nome.target.value);
    // }))
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = userList.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, userList.length - page * rowsPerPage);
  const isSelected = (id) => selected.indexOf(id) !== -1;


  return (
    <Container maxWidth="md" disableGutters>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Formik
          initialValues={{
            saldo: ''
          }}
          validationSchema={Yup.object().shape({
            saldo: Yup.string()
              .required('saldo', 'insira o valor a ser depositado'),
          })}
          onSubmit={async (values, actions) => {
            try {
              await insertCaassh(selected, values.saldo)
            } catch (error) {
              console.log(error)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container style={{ marginBottom: 20 }}>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Nome"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={filter}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Paper className={classes.paper}>
                    <TableContainer className={classes.tableContainer}>
                      <Table stickyHeader className={classes.table}>
                        <TableHead className={classes.headerTb}>
                          <TableRow>
                            <TableCell padding="checkbox" className={classes.celTableHeader}>
                              <Checkbox
                                indeterminate={selected.length > 0 && selected.length < userList.length}
                                checked={userList.length > 0 && selected.length === userList.length}
                                onChange={handleSelectAllClick}
                              />

                            </TableCell>
                            <TableCell key={'nome'} className={classes.celTableHeader}>
                              {"Nome"}
                            </TableCell>
                            <TableCell key={'cpf'} className={classes.celTableHeader}>
                              {"CPF"}
                            </TableCell>
                            <TableCell key={'email'} className={classes.celTableHeader}>
                              {"Email"}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                              const isItemSelected = isSelected(row.id);
                              return (
                                <TableRow
                                  hover
                                  onClick={(event) => handleClick(event, row.id)}
                                  key={row.id}
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}

                                    />
                                  </TableCell>
                                  <TableCell >{row.nome}</TableCell>
                                  <TableCell >{row.cpf}</TableCell>
                                  <TableCell >{row.email}</TableCell>
                                </TableRow>
                              );
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      labelRowsPerPage={'Linhas por página'}
                      component="div"
                      count={userList.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Paper>
                  <Box className={classes.footer} marginBottom="20px" />
                  <Grid item md={6} xs={12} className='MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl'>
                    <CurrencyInput decimalSeparator=',' decimalsLimit={2} decimalScale={2}
                      className='MuiInputBase-input MuiOutlinedInput-input'
                      id="input-example"
                      name="saldo"
                      value={values.saldo}
                      onValueChange={(x, n) => setFieldValue(n, x)}
                      placeholder="Insira o valor a ser creditado"
                    />
                  </Grid>
                  <Box my={2} display="flex" justifyContent="center">
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Depositar Caassh
            </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  )
}