import api from './api'

export const updateConfig = async config => {
  const { data } = await api.post('config-cartao', { config })
  return data
}

export const getConfig = async () => {
  const { data } = await api.get('config-cartao')
  return data
}
