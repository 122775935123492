import React, { useEffect, useState } from 'react';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Button,
  CircularProgress,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Formik } from 'formik';
import { color } from '../../../components/common/colors';
import { getConfig as getConfigCartao, updateConfig as updateConfigCartao } from '../../../providers/configCartao';
import { getConfig as getConfigSms, updateConfig as updateConfigSms } from '../../../providers/configSms';
import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../providers/permissions';
import { alterationLog } from '../../../providers/log';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  headerPage: {
    marginBottom: 30
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  card: {
    background: color.white,
    marginBottom: 60
  },
  table: {
    minWidth: 750,
  },
  tableContainer: {
    maxHeight: 750,
  },
  footer: {
    height: 20,
  },
  celTableHeader: {
    backgroundColor: color.white,
  },
  date: {
    opacity: 1
  }
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});



export default function Cartoes(props) {

  const classes = useStyles()
  const [date, onChangeDate] = useState(new Date())

  const [configCartao, setConfigCartao] = useState({});
  const [configSms, setConfigSms] = useState({});

  const [configValues, setConfigValues] = useState({
    date: new Date(),
    awsSns: false,
    maxxmobi: false,
  })

  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newPermission, setNewPermission] = useState({ edit: false });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.associatesConfig);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const [data1] = await getConfigCartao();
    setConfigCartao(data1);

    const [data2] = await getConfigSms();
    setConfigSms(data2);

    setConfigValues({
      date: data1.date,
      awsSns: data2.smsService === 'AWS_SNS',
      maxxmobi: data2.smsService === 'MAXXMOBI',
    })

    setLoading(false)
  }, [])

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12} >
        <Card className={classes.card}>
          <CardContent>
            <Formik
              enableReinitialize
              initialValues={configValues}
              onSubmit={async (values, actions) => {
                try {

                  const cartaoValues = {
                    date: values.date,
                    id: configCartao.id,
                  };

                  const smsValues = {
                    id: configSms.id,
                    smsService: values.awsSns ? 'AWS_SNS' : 'MAXXMOBI',
                  };

                  await updateConfigCartao(cartaoValues);
                  await updateConfigSms(smsValues);

                  await alterationLog({ action: 'CREATE', idUser: userData.id, before: '', after: { cartaoValues, smsValues }, menu: "Associados", subMenu: "Configuração", });

                  setIsEdit(false)
                } catch (e) {
                  console.log(e)
                }
              }}>
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue
              }) => (
                <form onSubmit={handleSubmit}>
                  {loading ?
                    <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', padding: 100 }}>
                      <CircularProgress color={'primary'} size={80} />
                    </Grid>
                    :
                    <Grid container spacing={3} direction='column'>
                      <Grid item md={6} xs={12}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                            <DatePicker
                              fullWidth
                              autoOk
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="validadeCartao"
                              label="Validade do Cartão"
                              value={values.date}
                              onChange={(newValue) => {
                                onChangeDate(newValue)
                                setFieldValue('date', newValue)
                              }}
                              inputVariant={'outlined'}
                              variant='inline'
                              disablePast
                              minDateMessage={null}
                              disabled={!isEdit}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Grid container spacing={3} direction='row'>
                          <Grid item md={6} xs={12}>
                            <MuiThemeProvider theme={defaultMaterialTheme}>
                              <FormControlLabel
                                value="end"
                                name="awsSns"
                                control={
                                  <Checkbox
                                    checked={values.awsSns}
                                    name="Amazon Web Services - SNS"
                                    color="primary"
                                    onChange={() => {
                                      setFieldValue('awsSns', !values.awsSns);
                                      setFieldValue('maxxmobi', values.awsSns);
                                    }}
                                    disabled={!isEdit}
                                  />
                                }
                                label="Amazon Web Services - SNS"
                                labelPlacement="end"
                              />
                            </MuiThemeProvider>
                          </Grid>

                          <Grid item md={6} xs={12}>
                            <MuiThemeProvider theme={defaultMaterialTheme}>
                              <FormControlLabel
                                value="end"
                                name="maxxmobi"
                                control={
                                  <Checkbox
                                    checked={values.maxxmobi}
                                    name="MaxxMobi"
                                    color="primary"
                                    onChange={() => {
                                      setFieldValue('maxxmobi', !values.maxxmobi);
                                      setFieldValue('awsSns', values.maxxmobi);
                                    }}
                                    disabled={!isEdit}
                                  />
                                }
                                label="MaxxMobi"
                                labelPlacement="end"
                              />
                            </MuiThemeProvider>
                          </Grid>
                        </Grid>
                      </Grid>


                      <Grid item xs={12} >
                        {newPermission.edit && (
                          isEdit && (
                            <Button
                              fullWidth
                              color='primary'
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}
                            </Button>
                          )
                        )}
                        {newPermission.edit && (
                          !isEdit && (
                            <Button
                              fullWidth
                              color='primary'
                              size="large"
                              variant="contained"
                              onClick={() => setIsEdit(true)}
                            >
                              Editar informações
                            </Button>
                          )
                        )}
                    </Grid>
                    </Grid>}
            </form>
              )}
          </Formik>
        </CardContent>
      </Card>
    </Grid>
    </Grid >
  );
}