import React, { useEffect, useState } from 'react';
import {
  Tab,
  Tabs,
  Typography,
  makeStyles,
  Box,
  Paper,
} from '@material-ui/core';
import Page from '../../components/common/page'
import { color } from '../../components/common/colors';
import SchedulePage from './schedule';
import Profissional from './profissional';
import Procedimento from './procedimento/';
import Service from './service';
import Unidade from './unity';
import AvaliacaoAgendamento from './avaliation';
import Indisponible from './indisponible';
import Extra from './extra';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function Schedule(props) {
  const classes = useStyles()

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page className={classes.root}
      title="Agendamentos"
    >
      <Paper position="static" className={classes.paper}>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
        >
          <Tab label="Agendamentos" id={0} />
          <Tab label="Unidade" id={1} />
          <Tab label="Serviço" id={2} />
          <Tab label="Profissional/Recurso" id={3} />
          <Tab label="Procedimento" id={4} />
          <Tab label="Avaliação" id={5} />
          <Tab label="Indisponibilidades" id={6} />
          <Tab label="Agenda Extra" id={7} />
        </Tabs>
      </Paper>

        <TabPanel value={tab} index={0}>
          <SchedulePage  />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <Unidade  />
        </TabPanel>

        <TabPanel value={tab} index={2}>
          <Service  />
        </TabPanel>

        <TabPanel value={tab} index={3}>
          <Profissional  />
        </TabPanel>

        <TabPanel value={tab} index={4}>
          <Procedimento  />
        </TabPanel>

        <TabPanel value={tab} index={5}>
          <AvaliacaoAgendamento  />
        </TabPanel>

      <TabPanel value={tab} index={6}>
        <Indisponible />
      </TabPanel>

      <TabPanel value={tab} index={7}>
        <Extra />
      </TabPanel>
    </Page>
  )
}