import { createMuiTheme } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { color } from '../components/common/colors'

const theme = createMuiTheme({
  palette: {
    blue: '#354F5C',
    red: '#A93533',
    black: '#313131',
    white: '#FFFFFF',
    gray: '#979797',
    background: '#F4F6F8',
    primary: {
      main: color.red,
    },
    secondary: {
      main: color.blue,
    },
  },
  shadows,
  typography
});

export default theme;
