import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, makeStyles, Container, Card, CardContent, Grid, Divider, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import InputCpf from '../../../../components/common/inputCpf'
import InputCel from '../../../../components/common/inputCelular'
import { editUserAdm } from '../../../../providers/userAdm'
import { CheckboxWithLabel } from 'formik-material-ui';
import { verifyPerm } from '../../../../components/common/detailedPermissions';
import { getPermissions } from '../../../../providers/permissions';
import { useSelector } from 'react-redux';
import { alterationLog } from '../../../../providers/log';

const unmaskCel = value => {
  return value?.replace(' ', '')
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace('_', '')
}

const unmaskCpf = value => {
  return value.replace(/\./g, '').replace('-', '')
}

const permCheck = (entry) => {
  switch (entry) {
    case 'VIZU':
      return true;
    case 'DET':
      return true;
    case 'VIZUDET':
      return true;
    case 'EDIT':
      return true;
    default:
      return false;
  }

}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btns: {
    marginTop: 15
  },
  checkItem: {
    paddingLeft: 15
  },
  checkItem2: {
    paddingLeft: 25
  },



}));

export default function EditarColaborador({ user, setState }) {
  const classes = useStyles()
  const [noticias, setNoticias] = useState(false);
  const [caassh, setCaassh] = useState(false);
  const [agendamento, setAgendamento] = useState(false);
  const [parceiros, setParceiros] = useState(false);
  const [associados, setAssociados] = useState(false);
  const [menssagens, setMenssagens] = useState(false);
  const [log, setLog] = useState(false);
  const [colaboradores, setColaboradores] = useState(false);
  const [loading, setLoading] = useState(true);

  const [news, setNews] = useState({ viz: false, det: false, edit: false });
  const [newsSlides, setNewsSlides] = useState({ viz: false, det: false, edit: false });
  const [newsConfig, setNewsConfig] = useState({ edit: false });
  const [schedule, setSchedule] = useState({ viz: false, det: false, edit: false });
  const [scheduleUnities, setScheduleUnities] = useState({ viz: false, det: false, edit: false });
  const [scheduleServices, setScheduleServices] = useState({ viz: false, det: false, edit: false });
  const [scheduleCategories, setScheduleCategories] = useState({ viz: false, det: false, edit: false });
  const [scheduProfessional, setScheduProfessional] = useState({ viz: false, det: false, edit: false });
  const [scheduleProcedure, setScheduleProcedure] = useState({ viz: false, det: false, edit: false });
  const [scheduleAvaliations, setScheduleAvaliations] = useState({ viz: false, det: false });
  const [scheduleUnavailabilites, setScheduleUnavailabilites] = useState({ viz: false, det: false, edit: false });
  const [scheduleExtra, setScheduleExtra] = useState({ viz: false, det: false, edit: false });
  const [scheduleExtraHour, setScheduleExtraHour] = useState({ viz: false, det: false, edit: false });
  const [associates, setAssociates] = useState({ viz: false, det: false, edit: false });
  const [bloqueio, setBloqueio] = useState({ viz: false, det: false, edit: false });
  const [associatesConfig, setAssociatesConfig] = useState({ edit: false });
  const [associatesOab, setAssociatesOab] = useState({ edit: false });
  const [collaborator, setCollaborator] = useState({ viz: false, det: false, edit: false });
  const [collaboratorGroup, setCollaboratorGroup] = useState({ viz: false, det: false, edit: false });
  const [caashCredit, setCaashCredit] = useState({ viz: false, det: false });
  const [caashTransaction, setCaashTransaction] = useState({ viz: false, det: false });
  const [caashConfig, setCaashConfig] = useState({ viz: false, edit: false });
  const [partnersCheck, setPartnersCheck] = useState(false);
  const [partner, setPartner] = useState({ viz: false, det: false, edit: false });
  const [partnerCategorie, setPartnerCategorie] = useState({ viz: false, det: false, edit: false });
  const [partnerTag, setPartnerTag] = useState({ viz: false, det: false, edit: false });
  const [partnerUnities, setPartnerUnities] = useState({ viz: false, det: false, edit: false });
  const [partnerAvaliation, setPartnerAvaliation] = useState({ viz: false, det: false, edit: false });
  const [partnerConfig, setPartnerConfig] = useState({ viz: false, edit: false });
  const [message, setMessage] = useState({ edit: false });
  const [messageScheduled, setMessageScheduled] = useState({ edit: false });
  const [messageAutomatic, setMessageAutomatic] = useState({ viz: false, edit: false });
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
  const [logAdm, setLogAdm] = useState({ viz: false });
  const [logUser, setLogUser] = useState({ viz: false });
  const [logSchedule, setLogSchedule] = useState({ viz: false, det: false });

  const [originalValues, setOriginalValues] = useState({});

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.collaborator);
    };

    fetchPermissions();
  }, []);

  useEffect(() => {
    const setPermissionStates = async () => {
      try {
        const { permissions } = await getPermissions(user.id);

        setNews(permissions.news);
        setNewsSlides(permissions.newsSlides);
        setNewsConfig(permissions.newsConfig);
        setSchedule(permissions.schedule);
        setScheduleUnities(permissions.scheduleUnities);
        setScheduleServices(permissions.scheduleServices);
        setScheduleCategories(permissions.scheduleCategories);
        setScheduProfessional(permissions.scheduProfessional);
        setScheduleProcedure(permissions.scheduleProcedure);
        setScheduleAvaliations(permissions.scheduleAvaliations);
        setScheduleUnavailabilites(permissions.scheduleUnavailabilites);
        setScheduleExtra(permissions.scheduleExtra);
        setScheduleExtraHour(permissions.scheduleExtraHour);
        setAssociates(permissions.associates);
        setBloqueio(permissions.bloqueio);
        setAssociatesConfig(permissions.associatesConfig);
        setAssociatesOab(permissions.associatesOab);
        setCollaborator(permissions.collaborator);
        setCollaboratorGroup(permissions.collaboratorGroup);
        setCaashCredit(permissions.caashCredit);
        setCaashTransaction(permissions.caashTransaction);
        setCaashConfig(permissions.caashConfig);
        setPartnersCheck(permissions.partnersCheck);
        setPartner(permissions.partner);
        setPartnerCategorie(permissions.partnerCategorie);
        setPartnerTag(permissions.partnerTag);
        setPartnerUnities(permissions.partnerUnities);
        setPartnerAvaliation(permissions.partnerAvaliation);
        setPartnerConfig(permissions.partnerConfig);
        setMessage(permissions.message);
        setMessageScheduled(permissions.messageScheduled);
        setMessageAutomatic(permissions.messageAutomatic);
        setLogAdm(!!permissions.logAdm ? permissions.logAdm : { viz: false });
        setLogUser(!!permissions.logUser ? permissions.logUser : { viz: false });
        setLogSchedule(!!permissions.logSchedule ? permissions.logSchedule  : { viz: false, det: false });

        setOriginalValues({
          id: user.id || '',
          cpf: user.cpf || '',
          name: user.nome || '',
          email: user.email || '',
          endereco: user.endereco || '',
          numero: user.numero || '',
          cidade: user.cidade || '',
          bairro: user.bairro || '',
          celular: user.celular || '',
          permissions: user.permissions,
          news: permissions.news,
          newsSlides: permissions.newsSlides,
          newsConfig: permissions.newsConfig,
          schedule: permissions.schedule,
          scheduleUnities: permissions.scheduleUnities,
          scheduleServices: permissions.scheduleServices,
          scheduleCategories: permissions.scheduleCategories,
          scheduProfessional: permissions.scheduProfessional,
          scheduleProcedure: permissions.scheduleProcedure,
          scheduleAvaliations: permissions.scheduleAvaliations,
          scheduleUnavailabilites: permissions.scheduleUnavailabilites,
          scheduleExtraHour: permissions.scheduleExtraHour,
          scheduleExtra: permissions.scheduleExtra,
          associates: permissions.associates,
          associatesOab: permissions.associatesOab,
          bloqueio: permissions.bloqueio,
          associatesConfig: permissions.associatesConfig,
          collaborator: permissions.collaborator,
          collaboratorGroup: permissions.collaboratorGroup,
          caashCredit: permissions.caashCredit,
          caashTransaction: permissions.caashTransaction,
          caashConfig: permissions.caashConfig,
          partnersCheck: permissions.partnersCheck,
          partner: permissions.partner,
          partnerCategorie: permissions.partnerCategorie,
          partnerTag: permissions.partnerTag,
          partnerUnities: permissions.partnerUnities,
          partnerAvaliation: permissions.partnerAvaliation,
          partnerConfig: permissions.partnerConfig,
          message: permissions.message,
          messageScheduled: permissions.messageScheduled,
          messageAutomatic: permissions.messageAutomatic,
          logAdm: permissions.logAdm,
          logUser: permissions.logUser,
          logSchedule: permissions.logSchedule,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    setPermissionStates();
  }, []);

  const goBack = () => {
    window.location.reload();
    setState(0)
  }

  return (
    loading ? (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    ) : (
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            id: user.id || '',
            cpf: user.cpf || '',
            nome: user.nome || '',
            email: user.email || '',
            endereco: user.endereco || '',
            numero: user.numero || '',
            cidade: user.cidade || '',
            bairro: user.bairro || '',
            celular: user.celular || '',
            permissions: user.permissions,
            password: '',
            news: news,
            newsSlides: newsSlides,
            newsConfig: newsConfig,
            schedule: schedule,
            scheduleUnities: scheduleUnities,
            scheduleServices: scheduleServices,
            scheduleCategories: scheduleCategories,
            scheduProfessional: scheduProfessional,
            scheduleProcedure: scheduleProcedure,
            scheduleAvaliations: scheduleAvaliations,
            scheduleUnavailabilites: scheduleUnavailabilites,
            scheduleExtraHour: scheduleExtraHour,
            scheduleExtra: scheduleExtra,
            associates: associates,
            associatesOab: associatesOab,
            bloqueio: bloqueio,
            associatesConfig: associatesConfig,
            collaborator: collaborator,
            collaboratorGroup: collaboratorGroup,
            caashCredit: caashCredit,
            caashTransaction: caashTransaction,
            caashConfig: caashConfig,
            partnersCheck: partnersCheck,
            partner: partner,
            partnerCategorie: partnerCategorie,
            partnerTag: partnerTag,
            partnerUnities: partnerUnities,
            partnerAvaliation: partnerAvaliation,
            partnerConfig: partnerConfig,
            message: message,
            messageScheduled: messageScheduled,
            messageAutomatic: messageAutomatic,
            logAdm: logAdm,
            logUser: logUser,
            logSchedule: logSchedule,
          }}

          validationSchema={Yup.object().shape({
            cpf: Yup.string()
              .required('Campo obrigatório')
              .test('cpf-valid', 'CPF inválido', value => cpf.isValid(value)),
            nome: Yup.string()
              .required('Campo obrigatório'),
            email: Yup.string()
              .required('Campo obrigatório')
              .email('Email inválido'),
            endereco: Yup.string()
              .required('Campo obrigatório'),
            numero: Yup.string()
              .required('Campo obrigatório'),
            cidade: Yup.string()
              .required('Campo obrigatório'),
            bairro: Yup.string()
              .required('Campo obrigatório'),
            celular: Yup.string()
              .required('Campo obrigatório')
              .test('cel-valid', 'Celular inválido', value => unmaskCel(value)?.length === 11),
            permissions: Yup.array()
              .test('permissions', 'Selecione ao menos uma opção', value => !!value.length),
            password: Yup.string()
              .min(6, 'Senha deve ter ao menos 6 dígitos'),

          })}
          onSubmit={async (values, actions) => {
            const statesToMerge = { news, newsSlides, newsConfig, schedule, scheduleUnities, scheduleServices, scheduleCategories, scheduProfessional, scheduleProcedure, scheduleAvaliations, scheduleUnavailabilites, scheduleExtraHour, scheduleExtra, associates, associatesOab, bloqueio, associatesConfig, collaborator, collaboratorGroup, caashCredit, caashTransaction, caashConfig, partnersCheck, partner, partnerCategorie, partnerTag, partnerUnities, partnerAvaliation, partnerConfig, message, messageScheduled, messageAutomatic };
            values = { ...values, ...statesToMerge };
            try {
              const { celular, cpf } = values
              values.cpf = unmaskCpf(cpf)
              values.celular = unmaskCel(celular)
              const { editUser } = await editUserAdm(values)
              delete values.password;
              const afterValues = {
                id: values.id || '',
                cpf: values.cpf || '',
                name: values.nome || '',
                email: values.email || '',
                endereco: values.endereco || '',
                numero: values.numero || '',
                cidade: values.cidade || '',
                bairro: values.bairro || '',
                celular: values.celular || '',
                permissions: values.permissions,
                news: news,
                newsSlides: newsSlides,
                newsConfig: newsConfig,
                schedule: schedule,
                scheduleUnities: scheduleUnities,
                scheduleServices: scheduleServices,
                scheduleCategories: scheduleCategories,
                scheduProfessional: scheduProfessional,
                scheduleProcedure: scheduleProcedure,
                scheduleAvaliations: scheduleAvaliations,
                scheduleUnavailabilites: scheduleUnavailabilites,
                scheduleExtraHour: scheduleExtraHour,
                scheduleExtra: scheduleExtra,
                associates: associates,
                associatesOab: associatesOab,
                bloqueio: bloqueio,
                associatesConfig: associatesConfig,
                collaborator: collaborator,
                collaboratorGroup: collaboratorGroup,
                caashCredit: caashCredit,
                caashTransaction: caashTransaction,
                caashConfig: caashConfig,
                partnersCheck: partnersCheck,
                partner: partner,
                partnerCategorie: partnerCategorie,
                partnerTag: partnerTag,
                partnerUnities: partnerUnities,
                partnerAvaliation: partnerAvaliation,
                partnerConfig: partnerConfig,
                message: message,
                messageScheduled: messageScheduled,
                messageAutomatic: messageAutomatic,
                logAdm: logAdm,
                logUser: logUser,
                logSchedule: logSchedule,
              }
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: originalValues, after: afterValues, menu: "Colaboradores", subMenu: "Colaboradores", });
              setState(0)
            } catch (e) {
              switch (e.response.data.error) {
                case 'CPF_EXIST':
                  actions.setFieldError('cpf', 'CPF já cadastrado!')
                  break;
                default:
                  setState(0)
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.cpf && errors.cpf)}
                        helperText={touched.cpf && errors.cpf}
                        label="CPF"
                        name="cpf"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cpf}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCpf,
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.nome && errors.nome)}
                        helperText={touched.nome && errors.nome}
                        label="Nome"
                        name="nome"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.nome}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="Email"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.endereco && errors.endereco)}
                        helperText={touched.endereco && errors.endereco}
                        label="Rua"
                        name="endereco"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.endereco}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.numero && errors.numero)}
                        helperText={touched.numero && errors.numero}
                        label="Nº"
                        name="numero"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.numero}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.cidade && errors.cidade)}
                        helperText={touched.cidade && errors.cidade}
                        label="Cidade"
                        name="cidade"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cidade}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.bairro && errors.bairro)}
                        helperText={touched.bairro && errors.bairro}
                        label="Bairro"
                        name="bairro"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bairro}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.celular && errors.celular)}
                        helperText={touched.celular && errors.celular}
                        label="Celular"
                        name="celular"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="tel"
                        value={values.celular}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCel,
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        label="Senha"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                    Permissões
                  </Typography>
                  <Divider style={{ marginBottom: 10 }} />
                  <Grid container spacing={1}>
                    <Grid item md={6} xs={12}>
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="news"
                        name="permissions"
                        Label={{ label: 'Notícias' }}
                      />

                      {values.permissions.some((e) => e == "news") && (

                        <Button
                          onClick={() => {
                            setNoticias(!noticias)
                          }}
                        >
                          ...
                        </Button>
                      )}


                      {values.permissions.some((e) => e == "news") && noticias && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Notícias
                            </Typography>
                            <FormControlLabel
                              // disabled={values.newsEdit ? true : false}
                              value='newsVisualizar'
                              name='newsVisualizar'
                              control={<Checkbox
                                value={news.viz}
                                name='newsVisualizar'
                                disabled={news.edit || news.det}
                                color='primary'
                                checked={news.viz}
                                onChange={() => {
                                  setNews({
                                    viz: !news.viz,
                                    det: news.det,
                                    edit: news.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.newsEdit ? true : false}
                              value='newsDetalhes'
                              name='newsDetalhes'
                              control={<Checkbox
                                value={news.det}
                                name='newsDetalhes'
                                color='primary'
                                disabled={news.edit}
                                checked={news.det}
                                onChange={() => {
                                  setNews({
                                    viz: !news.det,
                                    det: !news.det,
                                    edit: news.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />

                            <FormControlLabel
                              value='newsEdit'
                              name='newsEdit'
                              control={<Checkbox
                                value={news.edit}
                                name='newsEdit'
                                color='primary'
                                checked={news.edit}
                                onChange={() => {
                                  setNews({
                                    viz: !news.edit,
                                    det: !news.edit,
                                    edit: !news.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Slides
                            </Typography>
                            <FormControlLabel
                              // disabled={values.slideEdit ? true : false}
                              value='slideVisualizar'
                              name='slideVisualizar'
                              control={<Checkbox
                                value={newsSlides.viz}
                                name='slideVisualizar'
                                color='primary'
                                checked={newsSlides.viz}
                                disabled={newsSlides.det || newsSlides.edit}
                                onChange={() => {
                                  setNewsSlides({
                                    viz: !newsSlides.viz,
                                    det: newsSlides.det,
                                    edit: newsSlides.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />
                            <FormControlLabel
                              // disabled={values.slideEdit ? true : false}
                              value='slideDetalhes'
                              name='slideDetalhes'
                              control={<Checkbox
                                value={newsSlides.det}
                                name='slideDetalhes'
                                color='primary'
                                checked={newsSlides.det}
                                disabled={newsSlides.edit}
                                onChange={() => {
                                  setNewsSlides({
                                    viz: !newsSlides.det,
                                    det: !newsSlides.det,
                                    edit: newsSlides.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />

                            <FormControlLabel
                              value='slideEdit'
                              name='slideEdit'
                              control={<Checkbox
                                value={newsSlides.edit}
                                name='slideEdit'
                                color='primary'
                                checked={newsSlides.edit}
                                onChange={() => {
                                  setNewsSlides({
                                    viz: !newsSlides.edit,
                                    det: !newsSlides.edit,
                                    edit: !newsSlides.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Configurações
                            </Typography>
                            <FormControlLabel
                              value='configEdit'
                              name='configEdit'
                              control={<Checkbox
                                value={newsConfig.edit}
                                name='configEdit'
                                color='primary'
                                checked={newsConfig.edit}
                                onChange={() => {
                                  setNewsConfig({
                                    edit: !newsConfig.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                        </>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="credit"
                        name="permissions"
                        Label={{ label: 'Caassh' }}
                      />

                      {values.permissions.some((e) => e == "credit") && (

                        <Button
                          onClick={() => {
                            setCaassh(!caassh)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "credit") && caassh && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Crédito
                            </Typography>
                            <FormControlLabel
                              // disabled={values.credEdit ? true : false}
                              value='credVisualizar'
                              name='credVisualizar'
                              control={<Checkbox
                                value={caashCredit.viz}
                                name='credVisualizar'
                                color='primary'
                                checked={caashCredit.viz}
                                disabled={caashCredit.det || caashCredit.edit}
                                onChange={() => {
                                  setCaashCredit({
                                    viz: !caashCredit.viz,
                                    det: caashCredit.det,
                                    edit: caashCredit.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.credEdit ? true : false}
                              value='credDetalhes'
                              name='credDetalhes'
                              control={<Checkbox
                                value={caashCredit.det}
                                name='credDetalhes'
                                color='primary'
                                checked={caashCredit.det}
                                disabled={caashCredit.edit}
                                onChange={() => {
                                  setCaashCredit({
                                    viz: !caashCredit.det,
                                    det: !caashCredit.det,
                                    edit: caashCredit.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />

                            <FormControlLabel
                              value='credEdit'
                              name='credEdit'
                              control={<Checkbox
                                value={caashCredit.edit}
                                name='credEdit'
                                color='primary'
                                checked={caashCredit.edit}
                                onChange={() => {
                                  setCaashCredit({
                                    viz: !caashCredit.edit,
                                    det: !caashCredit.edit,
                                    edit: !caashCredit.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Transação
                            </Typography>

                            <FormControlLabel
                              value='transVisualizar'
                              name='transVisualizar'
                              control={<Checkbox
                                value={caashTransaction.viz}
                                name='transVisualizar'
                                color='primary'
                                checked={caashTransaction.viz}
                                disabled={caashTransaction.det}
                                onChange={() => {
                                  setCaashTransaction({
                                    viz: !caashTransaction.viz,
                                    det: caashTransaction.det,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              value='transDetalhes'
                              name='transDetalhes'
                              control={<Checkbox
                                value={caashTransaction.det}
                                name='transDetalhes'
                                color='primary'
                                checked={caashTransaction.det}
                                onChange={() => {
                                  setCaashTransaction({
                                    viz: !caashTransaction.det,
                                    det: !caashTransaction.det,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Configuração
                            </Typography>

                            <FormControlLabel
                              // disabled={values.confCaasshEdit ? true : false}
                              value='confCaasshVisualizar'
                              name='confCaasshVisualizar'
                              control={<Checkbox
                                value={caashConfig.viz}
                                name='confCaasshVisualizar'
                                color='primary'
                                checked={caashConfig.viz}
                                disabled={caashConfig.edit}
                                onChange={() => {
                                  setCaashConfig({
                                    viz: !caashConfig.viz,
                                    edit: caashConfig.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              value='confCaasshEdit'
                              name='confCaasshEdit'
                              control={<Checkbox
                                value={caashConfig.edit}
                                name='confCaasshEdit'
                                color='primary'
                                checked={caashConfig.edit}
                                onChange={() => {
                                  setCaashConfig({
                                    viz: !caashConfig.edit,
                                    edit: !caashConfig.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>
                        </>
                      )}

                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="schedule"
                        name="permissions"
                        Label={{ label: 'Agendamento' }}
                      />

                      {values.permissions.some((e) => e == "schedule") && (

                        <Button
                          onClick={() => {
                            setAgendamento(!agendamento)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "schedule") && agendamento && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Agendamentos
                            </Typography>

                            <FormControlLabel
                              // disabled={values.agendEdit ? true : false}
                              value='agendVisualizar'
                              name='agendVisualizar'
                              control={<Checkbox
                                value={schedule.viz}
                                name='agendVisualizar'
                                color='primary'
                                checked={schedule.viz}
                                disabled={schedule.edit || schedule.det}
                                onChange={() => {
                                  setSchedule({
                                    viz: !schedule.viz,
                                    det: schedule.det,
                                    edit: schedule.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.agendEdit ? true : false}
                              value='agendDetalhes'
                              name='agendDetalhes'
                              control={<Checkbox
                                value={schedule.det}
                                name='agendDetalhes'
                                color='primary'
                                checked={schedule.det}
                                disabled={schedule.edit}
                                onChange={() => {
                                  setSchedule({
                                    viz: !schedule.det,
                                    det: !schedule.det,
                                    edit: schedule.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='agendEdit'
                              name='agendEdit'
                              control={<Checkbox
                                value={schedule.edit}
                                name='agendEdit'
                                color='primary'
                                checked={schedule.edit}
                                onChange={() => {
                                  setSchedule({
                                    viz: !schedule.edit,
                                    det: !schedule.edit,
                                    edit: !schedule.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Unidades
                            </Typography>

                            <FormControlLabel
                              // disabled={values.unidEdit ? true : false}
                              value='unidVisualizar'
                              name='unidVisualizar'
                              control={<Checkbox
                                value={scheduleUnities.viz}
                                name='unidVisualizar'
                                color='primary'
                                checked={scheduleUnities.viz}
                                disabled={scheduleUnities.det || scheduleUnities.edit}
                                onChange={() => {
                                  setScheduleUnities({
                                    viz: !scheduleUnities.viz,
                                    det: scheduleUnities.det,
                                    edit: scheduleUnities.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.unidEdit ? true : false}
                              value='unidDetalhes'
                              name='unidDetalhes'
                              control={<Checkbox
                                value={scheduleUnities.det}
                                name='unidDetalhes'
                                color='primary'
                                checked={scheduleUnities.det}
                                disabled={scheduleUnities.edit}
                                onChange={() => {
                                  setScheduleUnities({
                                    viz: !scheduleUnities.det,
                                    det: !scheduleUnities.det,
                                    edit: scheduleUnities.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='unidEdit'
                              name='unidEdit'
                              control={<Checkbox
                                value={scheduleUnities.edit}
                                name='unidEdit'
                                color='primary'
                                checked={scheduleUnities.edit}
                                onChange={() => {
                                  setScheduleUnities({
                                    viz: !scheduleUnities.edit,
                                    det: !scheduleUnities.edit,
                                    edit: !scheduleUnities.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Serviços
                            </Typography>

                            <FormControlLabel
                              // disabled={values.servEdit ? true : false}
                              value='servVisualizar'
                              name='servVisualizar'
                              control={<Checkbox
                                value={scheduleServices.viz}
                                name='servVisualizar'
                                color='primary'
                                checked={scheduleServices.viz}
                                disabled={scheduleServices.det || scheduleServices.edit}
                                onChange={() => {
                                  setScheduleServices({
                                    viz: !scheduleServices.viz,
                                    det: scheduleServices.det,
                                    edit: scheduleServices.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.servEdit ? true : false}
                              value='servDetalhes'
                              name='servDetalhes'
                              control={<Checkbox
                                value={scheduleServices.det}
                                name='servDetalhes'
                                color='primary'
                                checked={scheduleServices.det}
                                disabled={scheduleServices.edit}
                                onChange={() => {
                                  setScheduleServices({
                                    viz: !scheduleServices.det,
                                    det: !scheduleServices.det,
                                    edit: scheduleServices.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='servEdit'
                              name='servEdit'
                              control={<Checkbox
                                value={scheduleServices.edit}
                                name='servEdit'
                                color='primary'
                                checked={scheduleServices.edit}
                                onChange={() => {
                                  setScheduleServices({
                                    viz: !scheduleServices.edit,
                                    det: !scheduleServices.edit,
                                    edit: !scheduleServices.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Categorias
                            </Typography>

                            <FormControlLabel
                              // disabled={values.categEdit ? true : false}
                              value='categVisualizar'
                              name='categVisualizar'
                              control={<Checkbox
                                value={scheduleCategories.viz}
                                name='categVisualizar'
                                color='primary'
                                checked={scheduleCategories.viz}
                                disabled={scheduleCategories.det || scheduleCategories.edit}
                                onChange={() => {
                                  setScheduleCategories({
                                    viz: !scheduleCategories.viz,
                                    det: scheduleCategories.det,
                                    edit: scheduleCategories.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.categEdit ? true : false}
                              value='categDetalhes'
                              name='categDetalhes'
                              control={<Checkbox
                                value={scheduleCategories.det}
                                name='categDetalhes'
                                color='primary'
                                checked={scheduleCategories.det}
                                disabled={scheduleCategories.edit}
                                onChange={() => {
                                  setScheduleCategories({
                                    viz: !scheduleCategories.det,
                                    det: !scheduleCategories.det,
                                    edit: scheduleCategories.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='categEdit'
                              name='categEdit'
                              control={<Checkbox
                                value={scheduleCategories.edit}
                                name='categEdit'
                                color='primary'
                                checked={scheduleCategories.edit}
                                onChange={() => {
                                  setScheduleCategories({
                                    viz: !scheduleCategories.edit,
                                    det: !scheduleCategories.edit,
                                    edit: !scheduleCategories.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />
                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Profissional/Recurso
                            </Typography>

                            <FormControlLabel
                              // disabled={values.profEdit ? true : false}
                              value='profVisualizar'
                              name='profVisualizar'
                              control={<Checkbox
                                value={scheduProfessional.viz}
                                name='profVisualizar'
                                color='primary'
                                checked={scheduProfessional.viz}
                                disabled={scheduProfessional.del || scheduProfessional.edit}
                                onChange={() => {
                                  setScheduProfessional({
                                    viz: !scheduProfessional.viz,
                                    det: scheduProfessional.det,
                                    edit: scheduProfessional.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.profEdit ? true : false}
                              value='profDetalhes'
                              name='profDetalhes'
                              control={<Checkbox
                                value={scheduProfessional.det}
                                name='profDetalhes'
                                color='primary'
                                checked={scheduProfessional.det}
                                disabled={scheduProfessional.edit}
                                onChange={() => {
                                  setScheduProfessional({
                                    viz: !scheduProfessional.det,
                                    det: !scheduProfessional.det,
                                    edit: scheduProfessional.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='profEdit'
                              name='profEdit'
                              control={<Checkbox
                                value={scheduProfessional.edit}
                                name='profEdit'
                                color='primary'
                                checked={scheduProfessional.edit}
                                onChange={() => {
                                  setScheduProfessional({
                                    viz: !scheduProfessional.edit,
                                    det: !scheduProfessional.edit,
                                    edit: !scheduProfessional.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Procedimento
                            </Typography>

                            <FormControlLabel
                              // disabled={values.procEdit ? true : false}
                              value='procVisualizar'
                              name='procVisualizar'
                              control={<Checkbox
                                value={scheduleProcedure.viz}
                                name='procVisualizar'
                                color='primary'
                                checked={scheduleProcedure.viz}
                                disabled={scheduleProcedure.det || scheduleProcedure.edit}
                                onChange={() => {
                                  setScheduleProcedure({
                                    viz: !scheduleProcedure.viz,
                                    det: scheduleProcedure.det,
                                    edit: scheduleProcedure.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.procEdit ? true : false}
                              value='procDetalhes'
                              name='procDetalhes'
                              control={<Checkbox
                                value={scheduleProcedure.det}
                                name='procDetalhes'
                                color='primary'
                                checked={scheduleProcedure.det}
                                disabled={scheduleProcedure.edit}
                                onChange={() => {
                                  setScheduleProcedure({
                                    viz: !scheduleProcedure.det,
                                    det: !scheduleProcedure.det,
                                    edit: scheduleProcedure.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='procEdit'
                              name='procEdit'
                              control={<Checkbox
                                value={scheduleProcedure.edit}
                                name='procEdit'
                                color='primary'
                                checked={scheduleProcedure.edit}
                                onChange={() => {
                                  setScheduleProcedure({
                                    viz: !scheduleProcedure.edit,
                                    det: !scheduleProcedure.edit,
                                    edit: !scheduleProcedure.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Avaliação
                            </Typography>

                            <FormControlLabel
                              value='avalVisualizar'
                              name='avalVisualizar'
                              control={<Checkbox
                                value={scheduleAvaliations.viz}
                                name='avalVisualizar'
                                color='primary'
                                checked={scheduleAvaliations.viz}
                                disabled={scheduleAvaliations.det}
                                onChange={() => {
                                  setScheduleAvaliations({
                                    viz: !scheduleAvaliations.viz,
                                    det: scheduleAvaliations.det,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              value='avalDetalhes'
                              name='avalDetalhes'
                              control={<Checkbox
                                value={scheduleAvaliations.det}
                                name='avalDetalhes'
                                color='primary'
                                checked={scheduleAvaliations.det}
                                onChange={() => {
                                  setScheduleAvaliations({
                                    viz: !scheduleAvaliations.det,
                                    det: !scheduleAvaliations.det,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Indisponibilidades
                            </Typography>

                            <FormControlLabel
                              // disabled={values.indEdit ? true : false}
                              value='indVisualizar'
                              name='indVisualizar'
                              control={<Checkbox
                                value={scheduleUnavailabilites.viz}
                                name='indVisualizar'
                                color='primary'
                                checked={scheduleUnavailabilites.viz}
                                disabled={scheduleUnavailabilites.det || scheduleUnavailabilites.edit}
                                onChange={() => {
                                  setScheduleUnavailabilites({
                                    viz: !scheduleUnavailabilites.viz,
                                    det: scheduleUnavailabilites.det,
                                    edit: scheduleUnavailabilites.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.indEdit ? true : false}
                              value='indDetalhes'
                              name='indDetalhes'
                              control={<Checkbox
                                value={scheduleUnavailabilites.det}
                                name='indDetalhes'
                                color='primary'
                                checked={scheduleUnavailabilites.det}
                                disabled={scheduleUnavailabilites.edit}
                                onChange={() => {
                                  setScheduleUnavailabilites({
                                    viz: !scheduleUnavailabilites.det,
                                    det: !scheduleUnavailabilites.det,
                                    edit: scheduleUnavailabilites.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='indEdit'
                              name='indEdit'
                              control={<Checkbox
                                value={scheduleUnavailabilites.edit}
                                name='indEdit'
                                color='primary'
                                checked={scheduleUnavailabilites.edit}
                                onChange={() => {
                                  setScheduleUnavailabilites({
                                    viz: !scheduleUnavailabilites.edit,
                                    det: !scheduleUnavailabilites.edit,
                                    edit: !scheduleUnavailabilites.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          {/*<Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Agenda Extra
                            </Typography>

                            <FormControlLabel
                              // disabled={values.indEdit ? true : false}
                              value='indVisualizar'
                              name='indVisualizar'
                              control={<Checkbox
                                value={scheduleUnavailabilites.viz}
                                name='indVisualizar'
                                color='primary'
                                checked={scheduleUnavailabilites.viz}
                                disabled={scheduleUnavailabilites.det || scheduleUnavailabilites.edit}
                                onChange={() => {
                                  setScheduleUnavailabilites({
                                    viz: !scheduleUnavailabilites.viz,
                                    det: scheduleUnavailabilites.det,
                                    edit: scheduleUnavailabilites.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.indEdit ? true : false}
                              value='indDetalhes'
                              name='indDetalhes'
                              control={<Checkbox
                                value={scheduleUnavailabilites.det}
                                name='indDetalhes'
                                color='primary'
                                checked={scheduleUnavailabilites.det}
                                disabled={scheduleUnavailabilites.edit}
                                onChange={() => {
                                  setScheduleUnavailabilites({
                                    viz: !scheduleUnavailabilites.det,
                                    det: !scheduleUnavailabilites.det,
                                    edit: scheduleUnavailabilites.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='indEdit'
                              name='indEdit'
                              control={<Checkbox
                                value={scheduleUnavailabilites.edit}
                                name='indEdit'
                                color='primary'
                                checked={scheduleUnavailabilites.edit}
                                onChange={() => {
                                  setScheduleUnavailabilites({
                                    viz: !scheduleUnavailabilites.edit,
                                    det: !scheduleUnavailabilites.edit,
                                    edit: !scheduleUnavailabilites.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>*/}

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Agenda Extra
                            </Typography>

                            <FormControlLabel
                              // disabled={values.indEdit ? true : false}
                              value='agenExtVisualizar'
                              name='agenExtVisualizar'
                              control={<Checkbox
                                value={scheduleExtra.viz}
                                name='agenExtVisualizar'
                                color='primary'
                                checked={scheduleExtra.viz}
                                disabled={scheduleExtra.det || scheduleExtra.edit}
                                onChange={() => {
                                  setScheduleExtra({
                                    viz: !scheduleExtra.viz,
                                    det: scheduleExtra.det,
                                    edit: scheduleExtra.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.indEdit ? true : false}
                              value='agenExtDetalhes'
                              name='agenExtDetalhes'
                              control={<Checkbox
                                value={scheduleExtra.det}
                                name='agenExtDetalhes'
                                color='primary'
                                checked={scheduleExtra.det}
                                disabled={scheduleExtra.edit}
                                onChange={() => {
                                  setScheduleExtra({
                                    viz: !scheduleExtra.det,
                                    det: !scheduleExtra.det,
                                    edit: scheduleExtra.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='agenExtEdit'
                              name='agenExtEdit'
                              control={<Checkbox
                                value={scheduleExtra.edit}
                                name='agenExtEdit'
                                color='primary'
                                checked={scheduleExtra.edit}
                                onChange={() => {
                                  setScheduleExtra({
                                    viz: !scheduleExtra.edit,
                                    det: !scheduleExtra.edit,
                                    edit: !scheduleExtra.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />
                          </Grid>

                        </>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="partner"
                        name="permissions"
                        Label={{ label: 'Parceiros' }}
                      />

                      {values.permissions.some((e) => e == "partner") && (

                        <Button
                          onClick={() => {
                            setParceiros(!parceiros)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "partner") && parceiros && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>
                            <FormControlLabel
                              value='parceirosFilhos'
                              name='parceirosFilhos'
                              control={<Checkbox
                                value={values.partnersCheck}
                                name='parceirosFilhos'
                                color='primary'
                                checked={values.parceirosFilhos}
                                onChange={() => {
                                  setPartnersCheck(!partnersCheck);
                                  setFieldValue('partnersCheck', !values.partnersCheck)
                                }}
                              />}
                              label='Parceiros'
                            />

                            {values.permissions.some((e) => e == "partner") && values.partnersCheck && (
                              <>
                                <Grid item md={12} className={classes.checkItem2}>
                                  <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                                    Parceiros
                                  </Typography>

                                  <FormControlLabel
                                    // disabled={values.parcEdit ? true : false}
                                    value='parcVisualizar'
                                    name='parcVisualizar'
                                    control={<Checkbox
                                      value={partner.viz}
                                      name='parcVisualizar'
                                      color='primary'
                                      checked={partner.viz}
                                      disabled={partner.edit || partner.det}
                                      onChange={() => {
                                        setPartner({
                                          viz: !partner.viz,
                                          det: partner.det,
                                          edit: partner.edit,
                                        })
                                      }}
                                    />}
                                    label='Visualizar'
                                  />

                                  <FormControlLabel
                                    // disabled={values.parcEdit ? true : false}
                                    value='parcDetalhes'
                                    name='parcDetalhes'
                                    control={<Checkbox
                                      value={partner.det}
                                      name='parcDetalhes'
                                      color='primary'
                                      checked={partner.det}
                                      disabled={partner.edit}
                                      onChange={() => {
                                        setPartner({
                                          viz: !partner.det,
                                          det: !partner.det,
                                          edit: partner.edit,
                                        })
                                      }}
                                    />}
                                    label='Detalhes'
                                  />
                                  <FormControlLabel
                                    value='parcEdit'
                                    name='parcEdit'
                                    control={<Checkbox
                                      value={partner.edit}
                                      name='parcEdit'
                                      color='primary'
                                      checked={partner.edit}
                                      onChange={() => {
                                        setPartner({
                                          viz: !partner.edit,
                                          det: !partner.edit,
                                          edit: !partner.edit,
                                        })
                                      }}
                                    />}
                                    label='Editar'
                                  />
                                </Grid>

                                <Grid item md={12} className={classes.checkItem2}>
                                  <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                                    Categoria
                                  </Typography>

                                  <FormControlLabel
                                    // disabled={values.catEdit ? true : false}
                                    value='catVisualizar'
                                    name='catVisualizar'
                                    control={<Checkbox
                                      value={partnerCategorie.viz}
                                      name='catVisualizar'
                                      color='primary'
                                      checked={partnerCategorie.viz}
                                      disabled={partnerCategorie.edit || partnerCategorie.det}
                                      onChange={() => {
                                        setPartnerCategorie({
                                          viz: !partnerCategorie.viz,
                                          det: partnerCategorie.det,
                                          edit: partnerCategorie.edit,
                                        })
                                      }}
                                    />}
                                    label='Visualizar'
                                  />

                                  <FormControlLabel
                                    // disabled={values.catEdit ? true : false}
                                    value='catDetalhes'
                                    name='catDetalhes'
                                    control={<Checkbox
                                      value={partnerCategorie.det}
                                      name='catDetalhes'
                                      color='primary'
                                      checked={partnerCategorie.det}
                                      disabled={partnerCategorie.edit}
                                      onChange={() => {
                                        setPartnerCategorie({
                                          viz: !partnerCategorie.det,
                                          det: !partnerCategorie.det,
                                          edit: partnerCategorie.edit,
                                        })
                                      }}
                                    />}
                                    label='Detalhes'
                                  />
                                  <FormControlLabel
                                    value='catEdit'
                                    name='catEdit'
                                    control={<Checkbox
                                      value={partnerCategorie.edit}
                                      name='catEdit'
                                      color='primary'
                                      checked={partnerCategorie.edit}
                                      onChange={() => {
                                        setPartnerCategorie({
                                          viz: !partnerCategorie.edit,
                                          det: !partnerCategorie.edit,
                                          edit: !partnerCategorie.edit,
                                        })
                                      }}
                                    />}
                                    label='Editar'
                                  />
                                </Grid>

                                <Grid item md={12} className={classes.checkItem2}>
                                  <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                                    Tag
                                  </Typography>

                                  <FormControlLabel
                                    // disabled={values.tagEdit ? true : false}
                                    value='tagVisualizar'
                                    name='tagVisualizar'
                                    control={<Checkbox
                                      value={partnerTag.viz}
                                      name='tagVisualizar'
                                      color='primary'
                                      checked={partnerTag.viz}
                                      disabled={partnerTag.det || partnerTag.edit}
                                      onChange={() => {
                                        setPartnerTag({
                                          viz: !partnerTag.viz,
                                          det: partnerTag.det,
                                          edit: partnerTag.edit,
                                        })
                                      }}
                                    />}
                                    label='Visualizar'
                                  />

                                  <FormControlLabel
                                    // disabled={values.tagEdit ? true : false}
                                    value='tagDetalhes'
                                    name='tagDetalhes'
                                    control={<Checkbox
                                      value={partnerTag.det}
                                      name='tagDetalhes'
                                      color='primary'
                                      checked={partnerTag.det}
                                      disabled={partnerTag.edit}
                                      onChange={() => {
                                        setPartnerTag({
                                          viz: !partnerTag.det,
                                          det: !partnerTag.det,
                                          edit: partnerTag.edit,
                                        })
                                      }}
                                    />}
                                    label='Detalhes'
                                  />
                                  <FormControlLabel
                                    value='tagEdit'
                                    name='tagEdit'
                                    control={<Checkbox
                                      value={partnerTag.edit}
                                      name='tagEdit'
                                      color='primary'
                                      checked={partnerTag.edit}
                                      onChange={() => {
                                        setPartnerTag({
                                          viz: !partnerTag.edit,
                                          det: !partnerTag.edit,
                                          edit: !partnerTag.edit,
                                        })
                                      }}
                                    />}
                                    label='Editar'
                                  />
                                </Grid>
                              </>
                            )}

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Unidades
                            </Typography>

                            <FormControlLabel
                              // disabled={values.uniEdit ? true : false}
                              value='uniVisualizar'
                              name='uniVisualizar'
                              control={<Checkbox
                                value={partnerUnities.viz}
                                name='uniVisualizar'
                                color='primary'
                                checked={partnerUnities.viz}
                                disabled={partnerUnities.edit || partnerUnities.det}
                                onChange={() => {
                                  setPartnerUnities({
                                    viz: !partnerUnities.viz,
                                    det: partnerUnities.det,
                                    edit: partnerUnities.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.uniEdit ? true : false}
                              value='uniDetalhes'
                              name='uniDetalhes'
                              control={<Checkbox
                                value={partnerUnities.det}
                                name='uniDetalhes'
                                color='primary'
                                checked={partnerUnities.det}
                                disabled={partnerUnities.edit}
                                onChange={() => {
                                  setPartnerUnities({
                                    viz: !partnerUnities.det,
                                    det: !partnerUnities.det,
                                    edit: partnerUnities.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='uniEdit'
                              name='uniEdit'
                              control={<Checkbox
                                value={partnerUnities.edit}
                                name='uniEdit'
                                color='primary'
                                checked={partnerUnities.edit}
                                onChange={() => {
                                  setPartnerUnities({
                                    viz: !partnerUnities.det,
                                    det: !partnerUnities.det,
                                    edit: !partnerUnities.det,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Avaliação
                            </Typography>

                            <FormControlLabel
                              // disabled={values.avaEdit ? true : false}
                              value='avaVisualizar'
                              name='avaVisualizar'
                              control={<Checkbox
                                value={partnerAvaliation.viz}
                                name='avaVisualizar'
                                color='primary'
                                checked={partnerAvaliation.viz}
                                disabled={partnerAvaliation.edit || partnerAvaliation.det}
                                onChange={() => {
                                  setPartnerAvaliation({
                                    viz: !partnerAvaliation.viz,
                                    det: partnerAvaliation.det,
                                    edit: partnerAvaliation.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.avaEdit ? true : false}
                              value='avaDetalhes'
                              name='avaDetalhes'
                              control={<Checkbox
                                value={partnerAvaliation.det}
                                name='avaDetalhes'
                                color='primary'
                                checked={partnerAvaliation.det}
                                disabled={partnerAvaliation.edit}
                                onChange={() => {
                                  setPartnerAvaliation({
                                    viz: !partnerAvaliation.det,
                                    det: !partnerAvaliation.det,
                                    edit: partnerAvaliation.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='avaEdit'
                              name='avaEdit'
                              control={<Checkbox
                                value={partnerAvaliation.edit}
                                name='avaEdit'
                                color='primary'
                                checked={partnerAvaliation.edit}
                                onChange={() => {
                                  setPartnerAvaliation({
                                    viz: !partnerAvaliation.det,
                                    det: !partnerAvaliation.det,
                                    edit: !partnerAvaliation.det,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Configuração
                            </Typography>

                            <FormControlLabel
                              // disabled={values.confEdit ? true : false}
                              value='confVisualizar'
                              name='confVisualizar'
                              control={<Checkbox
                                value={partnerConfig.viz}
                                name='confVisualizar'
                                color='primary'
                                checked={partnerConfig.viz}
                                disabled={partnerConfig.edit}
                                onChange={() => {
                                  setPartnerConfig({
                                    viz: !partnerConfig.viz,
                                    edit: partnerConfig.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              value='confEdit'
                              name='confEdit'
                              control={<Checkbox
                                value={partnerConfig.edit}
                                name='confEdit'
                                color='primary'
                                checked={partnerConfig.edit}
                                onChange={() => {
                                  setPartnerConfig({
                                    viz: !partnerConfig.edit,
                                    edit: !partnerConfig.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="user"
                        name="permissions"
                        Label={{ label: 'Associados' }}
                      />

                      {values.permissions.some((e) => e == "user") && (

                        <Button
                          onClick={() => {
                            setAssociados(!associados)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "user") && associados && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Associados
                            </Typography>

                            <FormControlLabel
                              // disabled={values.assEdit ? true : false}
                              value='assVisualizar'
                              name='assVisualizar'
                              control={<Checkbox
                                value={associates.viz}
                                name='assVisualizar'
                                color='primary'
                                checked={associates.viz}
                                disabled={associates.det || associates.edit}
                                onChange={() => {
                                  setAssociates({
                                    viz: !associates.viz,
                                    det: associates.det,
                                    edit: associates.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.assEdit ? true : false}
                              value='assDetalhes'
                              name='assDetalhes'
                              control={<Checkbox
                                value={associates.det}
                                name='assDetalhes'
                                color='primary'
                                checked={associates.det}
                                disabled={associates.edit}
                                onChange={() => {
                                  setAssociates({
                                    viz: !associates.det,
                                    det: !associates.det,
                                    edit: associates.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='assEdit'
                              name='assEdit'
                              control={<Checkbox
                                value={associates.edit}
                                name='assEdit'
                                color='primary'
                                checked={associates.edit}
                                onChange={() => {
                                  setAssociates({
                                    viz: !associates.edit,
                                    det: !associates.edit,
                                    edit: !associates.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Bloquear Associados
                            </Typography>

                            <FormControlLabel
                              // disabled={values.assEdit ? true : false}
                              value='assVisualizar'
                              name='assVisualizar'
                              control={<Checkbox
                                value={bloqueio.viz}
                                name='assVisualizar'
                                color='primary'
                                checked={bloqueio.viz}
                                disabled={bloqueio.det || bloqueio.edit}
                                onChange={() => {
                                  setBloqueio({
                                    viz: !bloqueio.viz,
                                    det: bloqueio.det,
                                    edit: bloqueio.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.assEdit ? true : false}
                              value='assDetalhes'
                              name='assDetalhes'
                              control={<Checkbox
                                value={bloqueio.det}
                                name='assDetalhes'
                                color='primary'
                                checked={bloqueio.det}
                                disabled={bloqueio.edit}
                                onChange={() => {
                                  setBloqueio({
                                    viz: !bloqueio.det,
                                    det: !bloqueio.det,
                                    edit: bloqueio.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='assEdit'
                              name='assEdit'
                              control={<Checkbox
                                value={bloqueio.edit}
                                name='assEdit'
                                color='primary'
                                checked={bloqueio.edit}
                                onChange={() => {
                                  setBloqueio({
                                    viz: !bloqueio.edit,
                                    det: !bloqueio.edit,
                                    edit: !bloqueio.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Configuração
                            </Typography>

                            <FormControlLabel
                              value='confAssEdit'
                              name='confAssEdit'
                              control={<Checkbox
                                value={associatesConfig.edit}
                                name='confAssEdit'
                                color='primary'
                                checked={associatesConfig.edit}
                                onChange={() => {
                                  setAssociatesConfig({
                                    edit: !associatesConfig.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          {/*<Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              OAB
                            </Typography>

                            <FormControlLabel
                              value='confAssEdit'
                              name='confAssEdit'
                              control={<Checkbox
                                value={associatesConfig.edit}
                                name='confAssEdit'
                                color='primary'
                                checked={associatesConfig.edit}
                                onChange={() => {
                                  setAssociatesConfig({
                                    edit: !associatesConfig.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>*/}

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              OAB
                            </Typography>

                            <FormControlLabel
                              value='oabAssEdit'
                              name='oabAssEdit'
                              control={<Checkbox
                                value={associatesOab.edit}
                                name='oabAssEdit'
                                color='primary'
                                checked={associatesOab.edit}
                                onChange={() => {
                                  setAssociatesOab({
                                    edit: !associatesOab.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="message"
                        name="permissions"
                        Label={{ label: 'Mensagens' }}
                      />

                      {values.permissions.some((e) => e == "message") && (

                        <Button
                          onClick={() => {
                            setMenssagens(!menssagens)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "message") && menssagens && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>

                            <FormControlLabel
                              value='msgVisualizar'
                              name='msgVisualizar'
                              control={<Checkbox
                                value={message.edit}
                                name='msgVisualizar'
                                color='primary'
                                checked={message.edit}
                                onChange={() => {
                                  setMessage({
                                    edit: !message.edit,
                                  })
                                }}
                              />}
                              label='Menssagens'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>

                            <FormControlLabel
                              value='msgAgenVisualizar'
                              name='msgAgenVisualizar'
                              control={<Checkbox
                                value={messageScheduled.edit}
                                name='msgAgenVisualizar'
                                color='primary'
                                checked={messageScheduled.edit}
                                onChange={() => {
                                  setMessageScheduled({
                                    edit: !messageScheduled.edit,
                                  })
                                }}
                              />}
                              label='Mensagens Agendadas'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Mensagens Automáticas
                            </Typography>

                            <FormControlLabel
                              // disabled={values.msgAutEdit ? true : false}
                              value='msgAutVisualizar'
                              name='msgAutVisualizar'
                              control={<Checkbox
                                value={messageAutomatic.viz}
                                name='msgAutVisualizar'
                                color='primary'
                                checked={messageAutomatic.viz}
                                disabled={messageAutomatic.edit}
                                onChange={() => {
                                  setMessageAutomatic({
                                    viz: !messageAutomatic.viz,
                                    edit: messageAutomatic.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              value='msgAutEdit'
                              name='msgAutEdit'
                              control={<Checkbox
                                value={messageAutomatic.edit}
                                name='msgAutEdit'
                                color='primary'
                                checked={messageAutomatic.edit}
                                onChange={() => {
                                  setMessageAutomatic({
                                    viz: !messageAutomatic.edit,
                                    edit: !messageAutomatic.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        value="log"
                        name="permissions"
                        Label={{ label: 'Log de alterações' }}
                      />

                      {values.permissions.some((e) => e == "log") && (

                        <Button
                          onClick={() => {
                            setLog(!log)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "log") && log && (
                        <>
                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Log ADM
                            </Typography>

                            <FormControlLabel
                              value='logAdmVisualizar'
                              name='logAdmVisualizar'
                              control={<Checkbox
                                value={logAdm.viz}
                                name='logAdmVisualizar'
                                color='primary'
                                checked={logAdm.viz}
                                // disabled={logAdm.viz}
                                onChange={() => {
                                  setFieldValue('logAdm', {
                                    viz: !logAdm.viz,
                                  })
                                  setLogAdm({
                                    viz: !logAdm.viz,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />
                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Log Usuário
                            </Typography>

                            <FormControlLabel
                              value='logUsuarioVisualizar'
                              name='logUsuarioVisualizar'
                              control={<Checkbox
                                value={logUser.viz}
                                name='logUsuarioVisualizar'
                                color='primary'
                                checked={logUser.viz}
                                // disabled={logUsuario.}
                                onChange={() => {
                                  setFieldValue('logUser', {
                                    viz: !logUser.viz,
                                  })
                                  setLogUser({
                                    viz: !logUser.viz,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />
                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Log Agendamentos
                            </Typography>

                            <FormControlLabel
                              value='logAgendamentosVisualizar'
                              name='logAgendamentosVisualizar'
                              control={<Checkbox
                                value={logSchedule.viz}
                                name='logAgendamentosVisualizar'
                                color='primary'
                                checked={logSchedule.viz}
                                disabled={logSchedule.det}
                                onChange={() => {
                                  setFieldValue('logSchedule', {
                                    viz: !logSchedule.viz,
                                    det: logSchedule.det,
                                  })
                                  setLogSchedule({
                                    viz: !logSchedule.viz,
                                    det: logSchedule.det,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              value='logAgendamentosDetalhes'
                              name='logAgendamentosDetalhes'
                              control={<Checkbox
                                value={logSchedule.det}
                                name='logAgendamentosDetalhes'
                                color='primary'
                                checked={logSchedule.det}
                                // disabled={logAgendamentos.viz}
                                onChange={() => {
                                  setFieldValue('logSchedule', {
                                    viz: !logSchedule.det,
                                    det: !logSchedule.det,
                                  })
                                  setLogSchedule({
                                    viz: !logSchedule.det,
                                    det: !logSchedule.det,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <Field
                        className={classes.checkbox}
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="permissions"
                        value="auth"
                        Label={{ label: 'Colaboradores' }}
                      />

                      {values.permissions.some((e) => e == "auth") && (

                        <Button
                          onClick={() => {
                            setColaboradores(!colaboradores)
                          }}
                        >
                          ...
                        </Button>
                      )}

                      {values.permissions.some((e) => e == "auth") && colaboradores && (

                        <>
                          <Grid item md={12} className={classes.checkItem}>

                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Colaboradores
                            </Typography>

                            <FormControlLabel
                              // disabled={values.colEdit ? true : false}
                              value='colVisualizar'
                              name='colVisualizar'
                              control={<Checkbox
                                value={collaborator.viz}
                                name='colVisualizar'
                                color='primary'
                                checked={collaborator.viz}
                                disabled={collaborator.det || collaborator.edit}
                                onChange={() => {
                                  setCollaborator({
                                    viz: !collaborator.viz,
                                    det: collaborator.det,
                                    edit: collaborator.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.colEdit ? true : false}
                              value='colDetalhes'
                              name='colDetalhes'
                              control={<Checkbox
                                value={collaborator.det}
                                name='colDetalhes'
                                color='primary'
                                checked={collaborator.det}
                                disabled={collaborator.edit}
                                onChange={() => {
                                  setCollaborator({
                                    viz: !collaborator.det,
                                    det: !collaborator.det,
                                    edit: collaborator.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='colEdit'
                              name='colEdit'
                              control={<Checkbox
                                value={collaborator.edit}
                                name='colEdit'
                                color='primary'
                                checked={collaborator.edit}
                                onChange={() => {
                                  setCollaborator({
                                    viz: !collaborator.edit,
                                    det: !collaborator.edit,
                                    edit: !collaborator.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>

                          <Grid item md={12} className={classes.checkItem}>
                            <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                              Grupos
                            </Typography>

                            <FormControlLabel
                              // disabled={values.groupEdit ? true : false}
                              value='groupVisualizar'
                              name='groupVisualizar'
                              control={<Checkbox
                                value={collaboratorGroup.viz}
                                name='groupVisualizar'
                                color='primary'
                                checked={collaboratorGroup.viz}
                                disabled={collaboratorGroup.edit || collaboratorGroup.det}
                                onChange={() => {
                                  setCollaboratorGroup({
                                    viz: !collaboratorGroup.viz,
                                    det: collaboratorGroup.det,
                                    edit: collaboratorGroup.edit,
                                  })
                                }}
                              />}
                              label='Visualizar'
                            />

                            <FormControlLabel
                              // disabled={values.groupEdit ? true : false}
                              value='groupDetalhes'
                              name='groupDetalhes'
                              control={<Checkbox
                                value={collaboratorGroup.det}
                                name='groupDetalhes'
                                color='primary'
                                checked={collaboratorGroup.det}
                                disabled={collaboratorGroup.edit}
                                onChange={() => {
                                  setCollaboratorGroup({
                                    viz: !collaboratorGroup.det,
                                    det: !collaboratorGroup.det,
                                    edit: collaboratorGroup.edit,
                                  })
                                }}
                              />}
                              label='Detalhes'
                            />
                            <FormControlLabel
                              value='groupEdit'
                              name='groupEdit'
                              control={<Checkbox
                                value={collaboratorGroup.edit}
                                name='groupEdit'
                                color='primary'
                                checked={collaboratorGroup.edit}
                                onChange={() => {
                                  setCollaboratorGroup({
                                    viz: !collaboratorGroup.edit,
                                    det: !collaboratorGroup.edit,
                                    edit: !collaboratorGroup.edit,
                                  })
                                }}
                              />}
                              label='Editar'
                            />

                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  {Boolean(touched.permissions && errors.permissions) && <Typography variant="subtitle1" display="block" style={{ marginTop: 5, color: 'red' }}>
                    *{touched.permissions && errors.permissions}
                  </Typography>}
                  <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                    <Grid item md={4} xs={12}>
                      <Button
                        fullWidth
                        disabled={isSubmitting}
                        size="large"
                        color='secondary'
                        variant="contained"
                        onClick={goBack}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    {newPermission.edit && (
                      <Grid item md={4} xs={12}>
                        <Button
                          fullWidth
                          color='primary'
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container >
    )
  )
}