import React, { useState } from 'react';

import EditUnidade from './edit-unidade';
import ListUnidade from './list-unidade';
import AddUnidade from './new-unidade';

export default function Partner(props) {

  const [state, setState] = useState(0)
  const [unidadeToEdit, setUnidadeToEdit] = useState()

  return (
    <>
      {state === 0 && <ListUnidade setState={setState} setUnidadeToEdit={setUnidadeToEdit} />}
      {state === 1 && <AddUnidade setState={setState} />}
      {state === 2 && <EditUnidade unidade={unidadeToEdit} setState={setState} />} 
    </>
  )
}