import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
  Container,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Divider,
  MuiThemeProvider,
  createMuiTheme,
  FormControlLabel,
  Checkbox,
  IconButton
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import {
  CloudUpload as CloudUploadIcon,
  AddBox as AddBoxIcon,
  IndeterminateCheckBox as RemoveBoxIncon,
  PictureAsPdf as PictureAsPdfIcon
} from '@material-ui/icons'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import pt from 'date-fns/locale/pt-BR';
import { color } from '../../../../components/common/colors';
import * as Yup from 'yup';
import { Formik, ErrorMessage, FieldArray } from 'formik';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { editUnidade, findAll } from '../../../../providers/partner'
import googleApiKey from '../../../../config/googleApiKey'
import InputCep from '../../../../components/common/inputCep'
import cidadeMesorregiao from '../../../../utils/cidadeMesorregiao.json'
import { getCepInfos } from '../../../../utils/getCepInfos'
import estados from '../../../../utils/estados'
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import InputCnpj from '../../../../components/common/inputCnpj'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';
import { getCepInformation } from '../../../../providers/schedules';

const contactTypes = [
  {
    label: 'Telefone',
    value: 'fone'
  },
  {
    label: 'E-mail',
    value: 'email'
  },
  {
    label: 'Site',
    value: 'site'
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    // padding: theme.spacing(3)
    paddingTop: '24px',
    paddingBottom: '14px'
  },
  card: {
    background: color.white
  },
  mapa: {
    height: 500,
    position: 'relative',
    marginBottom: 20
  },
  btns: {
    marginTop: 40
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});

function EditUnidade({ setState, google, unidade }) {
  const classes = useStyles()
  const [show, setShow] = useState(true)
  const [lat, setLat] = useState(unidade.latLng.coordinates[0])
  const [lng, setLng] = useState(unidade.latLng.coordinates[1])
  const [partnerList, setPartnerList] = useState([])
  const [open, setOpen] = useState(false);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });

  const [originalValues, setOriginalValues] = useState({
    visible: unidade.visible,
    name: unidade.name,
    description: unidade.description,
    latLng: unidade.latLng,
    cep: unidade.cep,
    mesorregiao: unidade.mesorregiao,
    cidade: unidade.cidade,
    estado: unidade.estado,
    bairro: unidade.bairro,
    rua: unidade.rua,
    numero: unidade.numero,
    complemento: unidade.complemento,
    latitude: lat,
    longitude: lng,
    idPartner: unidade.idPartner,
    // dataInicio: unidade.contract.dataInicio,
    // duracao: unidade.contract.duracao,
    // responsavel: unidade.contract.responsavel,
    beneficio1: unidade.beneficio1,
    beneficio2: unidade.beneficio2,
    promo1: unidade.promo1,
    promo2: unidade.promo2,
    // contatos: unidade.contact,
    razaoSocial: unidade.razaoSocial,
    cnpj: unidade.cnpj,
    password: unidade.password,
  });

  const userData = useSelector(({ user }) => user);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerUnities);
    };

    fetchPermissions();
  }, []);

  useEffect(async () => {
    const data = await findAll()
    setPartnerList(data)
  }, [])

  return (
    <>
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            visible: unidade.visible,
            name: unidade.name,
            description: unidade.description,
            latLng: unidade.latLng,
            cep: unidade.cep,
            mesorregiao: unidade.mesorregiao,
            cidade: unidade.cidade,
            estado: unidade.estado,
            bairro: unidade.bairro,
            rua: unidade.rua,
            numero: unidade.numero,
            complemento: unidade.complemento,
            latitude: lat,
            longitude: lng,
            idPartner: unidade.idPartner,
            file: '',
            dataInicio: unidade.contract.dataInicio,
            duracao: unidade.contract.duracao,
            responsavel: unidade.contract.responsavel,
            beneficio1: unidade.beneficio1,
            beneficio2: unidade.beneficio2,
            promo1: unidade.promo1,
            promo2: unidade.promo2,
            contatos: unidade.contact,
            image: null,
            razaoSocial: unidade.razaoSocial,
            cnpj: unidade.cnpj,
            password: unidade.password,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string()
              .required('Campo obrigatório'),
            description: Yup.string()
              .required('Campo obrigatório'),
            latLng: Yup.object()
              .required('Obrigatório a escolha de um ponto no mapa.'),
            cep: Yup.string()
              .required('Campo obrigatório'),
            mesorregiao: Yup.string()
              .required('Campo obrigatório'),
            cidade: Yup.string()
              .required('Campo obrigatório'),
            bairro: Yup.string()
              .required('Campo obrigatório'),
            rua: Yup.string()
              .required('Campo obrigatório'),
            numero: Yup.string()
              .required('Campo obrigatório'),
            idPartner: Yup.string()
              .required('Campo obrigatório'),
            duracao: Yup.string()
              .required('Campo obrigatório'),
            responsavel: Yup.string()
              .required('Campo obrigatório'),
            beneficio1: Yup.string()
              .required('Campo obrigatório'),
            beneficio2: Yup.string()
              .required('Campo obrigatório'),
            dataInicio: Yup.date()
              .required('Campo obrigatório'),
            razaoSocial: Yup.string()
              .required('Campo obrigatório'),
            cnpj: Yup.string()
              .required('Campo obrigatório'),
          })}
          onSubmit={async (values, actions) => {
            try {
              if (values.file) {
                const body = new FormData()
                body.append('file', values.file)
                body.append('folder', UploadFolder.PARTNER_CONTRACT)
                const data = await apiUpload(body);
                values.fileUrl = data.location
              }
              if (values.image) {
                const body = new FormData()
                body.append('file', values.image)
                body.append('folder', UploadFolder.PARTNER_CONTRACT)
                const imgData = await apiUpload(body);
                values.image = imgData.location
              } else {
                delete values.image
              }
              const { duracao, responsavel, fileUrl, dataInicio } = values
              values.id = unidade.id
              values.contract = { duracao, responsavel, fileUrl, dataInicio, id: unidade.contract.id }
              delete values.file
              delete values.duracao
              delete values.responsavel
              delete values.dataInicio
              delete values.fileUrl
              values.latLng = {
                type: "Point",
                coordinates: [lat, lng]
              }
              const unity = await editUnidade(values)
              const afterValues = {
                visible: values.visible,
                name: values.name,
                description: values.description,
                latLng: values.latLng,
                cep: values.cep,
                mesorregiao: values.mesorregiao,
                cidade: values.cidade,
                estado: values.estado,
                bairro: values.bairro,
                rua: values.rua,
                numero: values.numero,
                complemento: values.complemento,
                latitude: lat,
                longitude: lng,
                idPartner: values.idPartner,
                // dataInicio: values.contract.dataInicio,
                // duracao: values.contract.duracao,
                // responsavel: values.contract.responsavel,
                beneficio1: values.beneficio1,
                beneficio2: values.beneficio2,
                promo1: values.promo1,
                promo2: values.promo2,
                // contatos: values.contact,
                razaoSocial: values.razaoSocial,
                cnpj: values.cnpj,
                password: values.password,
                id: values.id
              }
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: unidade.id }, after: afterValues, menu: "Parceiros", subMenu: "Unidades" });
            } catch (e) {
              console.log(e)
            } finally {
              setState(0)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3} alignContent="center">
                    <Grid item md={12}>
                      <FormControlLabel
                        value="start"
                        name="visible"
                        control={<Checkbox
                          checked={values.visible}
                          name="visible"
                          color="primary"
                          onChange={handleChange}
                        />}
                        label="Visível"
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.idPartner && errors.idPartner)}
                        helperText={touched.idPartner && errors.idPartner}
                        select
                        label="Parceiro"
                        name="idPartner"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.idPartner}
                        variant="outlined"
                      >
                        {partnerList.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option.id}
                            value={option.id}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignContent="center">
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        label="Nome"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        label="Senha"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                        variant="outlined"
                        inputProps={{
                          type: "password",
                          autoComplete: 'new-password'
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        label="Descrição"
                        name="description"
                        value={values.description}
                        multiline
                        InputProps={{ classes: { input: classes.textArea } }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.razaoSocial && errors.razaoSocial)}
                        helperText={touched.razaoSocial && errors.razaoSocial}
                        label="Razão Social"
                        name="razaoSocial"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.razaoSocial}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.cnpj && errors.cnpj)}
                        helperText={touched.cnpj && errors.cnpj}
                        label="CNPJ"
                        name="cnpj"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cnpj}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCnpj,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignContent="center">
                    <Grid item md={12}>
                      <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                        Benefício
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.beneficio1 && errors.beneficio1)}
                        helperText={touched.beneficio1 && errors.beneficio1}
                        label="Linha 1"
                        name="beneficio1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.beneficio1}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.beneficio2 && errors.beneficio2)}
                        helperText={touched.beneficio2 && errors.beneficio2}
                        label="Linha 2"
                        name="beneficio2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.beneficio2}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignContent="center">
                    <Grid item md={12}>
                      <Typography variant="h5" display="block" style={{ marginTop: 10 }}>
                        Promoção
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.promo1 && errors.promo1)}
                        helperText={touched.promo1 && errors.promo1}
                        label="Linha 1"
                        name="promo1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.promo1}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.promo2 && errors.promo2)}
                        helperText={touched.promo2 && errors.promo2}
                        label="Linha 2"
                        name="promo2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.promo2}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignContent="center">
                    <Grid item md={12}>
                      <Typography variant="h5" display="block" style={{ marginTop: 10 }} >
                        Endereço
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.cep && errors.cep)}
                        helperText={touched.cep && errors.cep}
                        label="CEP"
                        name="cep"
                        onBlur={handleBlur}
                        onChange={async (event, value) => {
                          setFieldValue('cep', event.target.value)
                          if (event.target.value.length === 9) {
                            setOpen(true)
                            const dados = await getCepInfos(event.target.value)
                            if (dados.erro) {
                              setFieldError('cep', 'CEP não encontrado')
                            } else {
                              setFieldValue('estado', dados.uf)
                              setFieldValue('cidade', dados.localidade)
                              setFieldValue('bairro', dados.bairro)
                              setFieldValue('rua', dados.logradouro)
                              setFieldValue('numero', '')
                              setFieldValue('complemento', '')
                              setFieldValue('mesorregiao', cidadeMesorregiao[dados.localidade])
                              const cepGeolocation = await getCepInformation(event.target.value);
                              
                              if (cepGeolocation && cepGeolocation.latitude) {
                                const cepLat = parseFloat(cepGeolocation.latitude)
                                const cepLong = parseFloat(cepGeolocation.longitude)

                                setLat(cepLat);
                                setLng(cepLong);
                                setFieldValue("latLng", {
                                  type: "Point",
                                  coordinates: [
                                    cepLat,
                                    cepLong,
                                  ],
                                });
                                setShow(true);
                              }
                            }
                            setOpen(false)
                          }
                        }}
                        value={values.cep}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCep,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.mesorregiao && errors.mesorregiao)}
                        helperText={touched.mesorregiao && errors.mesorregiao}
                        // select
                        label="Mesorregião"
                        name="mesorregiao"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mesorregiao}
                        variant="outlined"
                      >
                      </TextField>
                    </Grid>
                    <Grid item md={2} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.estado && errors.estado)}
                        helperText={touched.estado && errors.estado}
                        select
                        label="Estado"
                        name="estado"
                        id="uf"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.estado}
                        variant="outlined"
                      >
                        {estados.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.cidade && errors.cidade)}
                        helperText={touched.cidade && errors.cidade}
                        label="Cidade"
                        name="cidade"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.cidade}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.bairro && errors.bairro)}
                        helperText={touched.bairro && errors.bairro}
                        label="Bairro"
                        name="bairro"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.bairro}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.rua && errors.rua)}
                        helperText={touched.rua && errors.rua}
                        label="Rua"
                        name="rua"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.rua}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.numero && errors.numero)}
                        helperText={touched.numero && errors.numero}
                        label="Nº"
                        name="numero"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="number"
                        value={values.numero}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.complemento && errors.complemento)}
                        helperText={touched.complemento && errors.complemento}
                        label="Complemento"
                        name="complemento"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.complemento}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant="h5"
                        display="block"
                        style={{ marginTop: 10 }}
                      >
                        Localização
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.latitude && errors.latitude)}
                        helperText={touched.latitude && errors.latitude}
                        label="Latitude"
                        name="latitude"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setLat(event.target.value);
                        }}
                        value={lat}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.longitude && errors.longitude)}
                        helperText={touched.longitude && errors.longitude}
                        label="Longitude"
                        name="longitude"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setLng(event.target.value);
                        }}
                        value={lng}
                        variant="outlined"
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                    <Grid item xs={12} alignContent="center" fullWidth className={classes.mapa}>
                      <Map
                        google={google}
                        zoom={10}
                        onClick={(props, map, clickEvent) => {
                          setLat(clickEvent.latLng.lat());
                          setLng(clickEvent.latLng.lng());
                          setShow(true)
                        }}
                        initialCenter={{
                          lat: lat,
                          lng: lng
                        }}
                        center={{
                          lat: lat,
                          lng: lng
                        }}
                        centerAroundCurrentLocation={true}
                        containerStyle={{ marginTop: 20, width: '100%', height: 450, position: 'relative' }}
                      >
                        {show && (
                          <Marker
                            position={{ lat, lng }}
                          />
                        )}
                      </Map>
                      <ErrorMessage name='latLng' render={msg => (<Typography style={{ fontSize: 14, margin: 10 }} color={'error'}>{msg}</Typography>)} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignContent="center">
                    <Grid item xs={12}>
                      <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                        Contrato
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.responsavel && errors.responsavel)}
                        helperText={touched.responsavel && errors.responsavel}
                        label="Responsável"
                        name="responsavel"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.responsavel}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <MuiThemeProvider theme={defaultMaterialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                          <DatePicker
                            fullWidth
                            id="date-picker-dialog"
                            label="Dia"
                            format="dd/MM/yyyy"
                            value={values.dataInicio}
                            onChange={(newValue) => {
                              setFieldValue('dataInicio', newValue)
                            }}
                            inputVariant={'outlined'}
                            variant='inline'
                          />
                        </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.duracao && errors.duracao)}
                        helperText={touched.duracao && errors.duracao || 'Em meses'}
                        label="Duração"
                        name="duracao"
                        type='number'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.duracao}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <input
                        accept="application/pdf"
                        className={classes.inputFile}
                        name='file'
                        id="file-input"
                        type="file"
                        onChange={event => setFieldValue('file', event.target.files[0])}
                      />
                      <label htmlFor="file-input">
                        <Button fullWidth component="span" variant="outlined" color="primary" size='large' startIcon={<PictureAsPdfIcon />} style={{ height: 56 }}>
                          {values.file ? values.file.name : 'Contrato'}
                        </Button>
                      </label>
                      <Button fullWidth variant="contained" color="primary" onClick={() => window.open(unidade.contract.fileUrl, "_blank")} style={{ marginTop: 20 }}>
                        Ver contrato
                      </Button>
                      {Boolean(touched.file && errors.file) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.file && errors.file}
                      </Typography>}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} alignItems="center" justify="center">
                    <FieldArray
                      name="contatos"
                      render={arrayHelpers => (<>
                        <Grid item xs={12}>
                          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                              Contato
                            </Typography>
                            <IconButton onClick={() => {
                              arrayHelpers.push({ contact: '', type: 'fone', visible: true })
                            }}><AddBoxIcon /></IconButton>
                          </div>
                          <Divider style={{ marginBottom: 10 }} />
                        </Grid>
                        {values.contatos.map((c, i) => (
                          <Grid item container spacing={3} key={i}>
                            <Grid item md={6} xs={12}>
                              <TextField
                                fullWidth
                                label="Contato"
                                onChange={(event) => arrayHelpers.replace(i, { id: c.id, contact: event.target.value, type: c.type, visible: c.visible })}
                                value={c.contact}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={3} xs={6}>
                              <TextField
                                fullWidth
                                select
                                label="Tipo"
                                onChange={(event) => arrayHelpers.replace(i, { id: c.id, contact: c.contact, type: event.target.value, visible: c.visible })}
                                value={c.type}
                                variant="outlined"
                              >
                                {contactTypes.map((option) => (
                                  <MenuItem
                                    style={{
                                      backgroundColor: color.white,
                                    }}
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>

                            <Grid item md={2} xs={4} className={classes.checkboxContainer}>
                              <FormControlLabel
                                value="start"
                                control={<Checkbox
                                  color="primary"
                                  checked={c.visible}
                                  onChange={() => arrayHelpers.replace(i, { id: c.id, contact: c.contact, type: c.type, visible: !c.visible })}
                                />}
                                label="Visível"
                                labelPlacement="start"
                              />
                            </Grid>

                            <Grid item md={1} xs={2}>
                              <IconButton
                                style={{ marginTop: -3, }}
                                onClick={() => {
                                  arrayHelpers.remove(i)
                                }}
                              >
                                <RemoveBoxIncon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                        {Boolean(touched.contatos && errors.contatos) && <Grid item xs={12}> <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                          *{touched.contatos && errors.contatos}
                        </Typography> </Grid>}
                      </>)}
                    />
                  </Grid>
                  <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                    {<Grid item xs={8}>
                      <img src={values.image ? URL.createObjectURL(values.image) : unidade.image} style={{ maxHeight: 630, maxWidth: 630, borderRadius: 4, objectFit: 'contain' }} />
                      <input
                        accept="image/*"
                        className={classes.inputFile}
                        name='image'
                        id="image-input"
                        type="file"
                        onChange={event => setFieldValue('image', event.target.files[0])}
                      />
                      <label htmlFor="image-input">
                        <Button component="span" variant="contained" color="primary" startIcon={<CloudUploadIcon />}>
                          Upload
                        </Button>
                      </label>
                      {Boolean(touched.image && errors.image) && <Typography variant="h6" display="block" style={{ marginTop: 5, color: 'red' }}>
                        *{touched.image && errors.image}
                      </Typography>}
                    </Grid>}
                  </Grid>
                  <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
                    <Grid item md={4} xs={12}>
                      <Button
                        fullWidth
                        disabled={isSubmitting}
                        size="large"
                        color='secondary'
                        variant="contained"
                        onClick={() => setState(0)}
                      >
                        Voltar
                      </Button>
                    </Grid>
                    {newPermission.edit && (
                      <Grid item md={4} xs={12}>
                        <Button
                          color='primary'
                          fullWidth
                          disabled={isSubmitting}
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar dados'}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </Container >

      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={'spinningBubbles'} color={color.red} />
      </Backdrop>

    </>

  )
}

export default GoogleApiWrapper({
  apiKey: (googleApiKey)
})(EditUnidade)