import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  MenuItem,
  Avatar,
  MuiThemeProvider,
  createMuiTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Modal
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Formik, FieldArray } from 'formik';
import InputCpf from '../../../../components/common/inputCpf';
import InputCel from '../../../../components/common/inputCelular';
import {
  Tipo,
  Status,
  EstadoCivil,
  Sexo,
  SituacaoOab,
  SituacaoFinanceira,
} from '../../../../utils/dataUser'
import ReactLoading from 'react-loading';
import Backdrop from '@material-ui/core/Backdrop';
import InputCep from '../../../../components/common/inputCep';
import InputOab from '../../../../components/common/inputOab'
import { getCepInfos } from '../../../../utils/getCepInfos';
import { editUser, setActivateStatus, setRefusedStatus, getImages } from '../../../../providers/user'
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import * as Yup from 'yup';
import { values } from 'lodash';
import {
  AddBox as AddBoxIcon,
} from '@material-ui/icons';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { truncate } from 'lodash';
import { broadcastUpdateUser } from '../../../../sockets/Socket';
import { startOfDay } from 'date-fns/esm';
import { formatDate } from '../../../../utils/formatDate';
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';
import { alterationLog } from '../../../../providers/log';
import { UploadFolder, apiUpload } from '../../../../providers/upload';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    padding: theme.spacing(3)
  },
  card: {
    background: color.white
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btns: {
    margin: 15,
  },
  textArea: {
    minHeight: 100
  },
  inputFile: {
    display: 'none'
  },
  Avatar: {
    cursor: 'pointer',
    width: 200,
    height: 200,
    margin: '8px auto',
    boxShadow: '0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red
    },
  },
});


export default function EditUser({ setState, validade, userEdit, setUserToEdit }) {

  const [open, setOpen] = useState(false);
  const [Bairro, setBairro] = useState(true)
  const [logradouro, setLogradouro] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingImages, setLoadingImages] = useState(true);
  const [modal, setModal] = useState(false)
  const [motivo, setMotivo] = useState('')
  const [resendDoc, setResendDoc] = useState(false)
  const [resendSelfie, setResendSelfie] = useState(false)
  const [date, onChangeDate] = useState(new Date())
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [docArrayRemoved, setDocArrayRemoved] = useState([]);
  const [docArray, setDocArray] = useState([]);
  const [punishment, setPunishment] = useState(!!userEdit.datePunished)
  const [openModal, setOpenModal] = useState(false);
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
  const [error, setError] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [saveBlocked, setSaveBlocked] = useState(false);

  const userData = useSelector(({ user }) => user);
  const [originalValues, setOriginalValues] = useState({
    name: userEdit.nome.toUpperCase() || '',
    nascimento: formatDate(userEdit.nascimento) || '',
    estadoCivil: userEdit.estadoCivil || '',
    nomeMae: userEdit.nomeMae || '',
    status: userEdit.status || '',
    userType: userEdit.userType || '',
    oab: userEdit.oab || '',
    cpf: userEdit.cpf || '',
    sexo: userEdit.sexo || '',
    naturalidade: userEdit.naturalidade || '',
    celular: userEdit.celular || '',
    email: userEdit.email || '',
    cep: userEdit.cep || '',
    estado: userEdit.estado || '',
    cidade: userEdit.cidade || '',
    bairro: userEdit.bairro || '',
    endereco: userEdit.endereco || '',
    numero: userEdit.numero || '',
    complemento: userEdit.complemento || '',
    situacao: userEdit.situacao || '',
    situacaoOab: userEdit.situacaoOab || '',
    validade: userEdit.validade,
    situacaoFinanceira: userEdit.situacaoFinanceira || '',
    observacoes: userEdit.observacoes || '',
    // documentos: docArray,
    datePunished: userEdit.datePunished || null,
    punished: userEdit.punished || "NONE",
    idUserAdmUnblocked: userData.id || ""
  });


  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.associates);
    };

    fetchPermissions();
  }, []);

  const classes = useStyles()
  const goBack = () => {
    setUserToEdit({});
    setState(0)
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getImages(userEdit.id)
      setDocArray(data);
      setLoadingImages(false);
    }
    fetchData();
  }, [])

  const activateUser = async (values) => {
    try {
      setLoading(true)
      if (userEdit.situacaoOab === 'REGULAR' && userEdit.situacaoFinanceira === 'ADIMPLENTE') {
        userEdit.situacao = 'ATIVO'
      }
      userEdit.status = 'ACEITO'

      await setActivateStatus()
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      handleCloseModal()
    }
  }

  const sendRefuse = async () => {
    try {
      setLoading(true)
      userEdit.status = 'RECUSADO'
      userEdit.resendDoc = resendDoc
      userEdit.resendSelfie = resendSelfie
      await setRefusedStatus(userEdit, motivo)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
      setModal(false)
      setState(0)
    }
  }

  const showRefuseModal = () => {
    setModal(true)
  }

  const handleClose = () => {
    setModal(false)
  }

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false)
  }

  const unmaskCpf = value => {
    return value.replace(/\./g, '').replace('-', '')
  }

  const unMaskCelular = value => {
    return value.replace(/\./g, '').replace('-', '').replace(/[(|)]/g, '').replace(' ', '')
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  function changeMsgError(a) {
    switch (a.data.message) {
      case 'Access Denied':
        setError('Acesso Negado')
        break;
      case 'CPF já existente':
        setError('CPF já existente')
        break;
      default:
        setError('Tente novamente mais tarde')
        break;
    }
  }

  return (
    <>
      <Container maxWidth="md" disableGutters>
        {!loadingImages && <Formik
          initialValues={{
            img: null,
            nome: userEdit.nome.toUpperCase() || '',
            nascimento: formatDate(userEdit.nascimento) || '',
            estadoCivil: userEdit.estadoCivil || '',
            nomeMae: userEdit.nomeMae || '',
            status: userEdit.status || '',
            userType: userEdit.userType || '',
            oab: userEdit.oab || '',
            cpf: userEdit.cpf || '',
            sexo: userEdit.sexo || '',
            naturalidade: userEdit.naturalidade || '',
            celular: userEdit.celular || '',
            email: userEdit.email || '',
            cep: userEdit.cep || '',
            estado: userEdit.estado || '',
            cidade: userEdit.cidade || '',
            bairro: userEdit.bairro || '',
            endereco: userEdit.endereco || '',
            numero: userEdit.numero || '',
            complemento: userEdit.complemento || '',
            situacao: userEdit.situacao || '',
            situacaoOab: userEdit.situacaoOab || '',
            validade: userEdit.validade,
            situacaoFinanceira: userEdit.situacaoFinanceira || '',
            observacoes: userEdit.observacoes || '',
            documentos: docArray,
            datePunished: userEdit.datePunished || null,
            punished: userEdit.punished || 'NONE',
            idUserAdmUnblocked: userData.id || ""
          }}
          validationSchema={Yup.object().shape({
            nome: Yup.string()
              .required('Campo obrigatório'),
            nascimento: Yup.date()
              .required('Campo obrigatório'),
            estadoCivil: Yup.string()
              .required('Campo obrigatório'),
            nomeMae: Yup.string()
              .required('Campo obrigatório'),
            status: Yup.string()
              .required('Campo obrigatório'),
            userType: Yup.string()
              .required('Campo obrigatório'),
            oab: Yup.string()
              .required('Campo obrigatório'),
            cpf: Yup.string()
              .required('Campo obrigatório'),
            sexo: Yup.string()
              .required('Campo obrigatório'),
            naturalidade: Yup.string()
              .required('Campo obrigatório'),
            celular: Yup.string()
              .required('Campo obrigatório'),
            email: Yup.string()
              .required('Campo obrigatório'),
            cep: Yup.string()
              .required('Campo obrigatório'),
            estado: Yup.string()
              .required('Campo obrigatório'),
            cidade: Yup.string()
              .required('Campo obrigatório'),
            endereco: Yup.string()
              .required('Campo obrigatório'),
            numero: Yup.string()
              .required('Campo obrigatório'),
            situacao: Yup.string()
              .required('Campo obrigatório'),
            situacaoOab: Yup.string()
              .required('Campo obrigatório'),
            validade: Yup.date()
              .required('Campo obrigatório'),
            situacaoFinanceira: Yup.string()
              .required('Campo obrigatório'),
          })}
          onSubmit={async (values, actions) => {
            try {
              let newValidade = new Date(values.validade)
              newValidade.setUTCHours(23, 59, 59, 0)
              values.validade = newValidade.toISOString()
              values.cpf = unmaskCpf(values.cpf)
              values.celular = unMaskCelular(values.celular)
              values.id = userEdit.id
              let temp = null
              if (values.img) {
                const body = new FormData()
                body.append('file', values.img)
                body.append('folder', UploadFolder.MISC)
                const data = await apiUpload(body);
                values.imgProfile = data.location
                temp = data.location
              }
              delete values.img
              const arrayUris = [];
              if (values.documentos) {

                for (const doc of values.documentos) {
                  // SE O DOCUMENTO (IMAGEM) FOI ADICIONADO AGORA NO SITE...
                  if (doc.name) {
                    console.log("aqui o bo")
                    const body = new FormData()
                    body.append('file', doc)
                    body.append('folder', UploadFolder.MISC)
                    const data = await apiUpload(body);
                    arrayUris.push(data.location);
                  }
                  // SE O DOCUMENTO (IMAGEM) JÁ VEIO DO BANCO DE DADOS...
                  else {
                    arrayUris.push(doc.img)
                  }
                }
                values.documentos = arrayUris;
                values.documentosRemovidos = docArrayRemoved;
                if (values.status == "ACEITO") {
                  await setActivateStatus(values);
                } else if (values.status == "RECUSADO") {
                  sendRefuse();
                } else {
                  await editUser(values);
                }
              }
              const afterValues = {
                name: values.nome.toUpperCase() || '',
                nascimento: formatDate(values.nascimento) || '',
                estadoCivil: values.estadoCivil || '',
                nomeMae: values.nomeMae || '',
                status: values.status || '',
                userType: values.userType || '',
                oab: values.oab || '',
                cpf: values.cpf || '',
                sexo: values.sexo || '',
                naturalidade: values.naturalidade || '',
                celular: values.celular || '',
                email: values.email || '',
                cep: values.cep || '',
                estado: values.estado || '',
                cidade: values.cidade || '',
                bairro: values.bairro || '',
                endereco: values.endereco || '',
                numero: values.numero || '',
                complemento: values.complemento || '',
                situacao: values.situacao || '',
                situacaoOab: values.situacaoOab || '',
                validade: values.validade,
                situacaoFinanceira: values.situacaoFinanceira || '',
                observacoes: values.observacoes || '',
                documentos: docArray,
                datePunished: values.datePunished || null,
                punished: values.punished || '',
                idUserAdmUnblocked: userData.id || "",
                id: values.id,
              }
              await alterationLog({ action: 'UPDATE', idUser: userData.id, before: { ...originalValues, id: userEdit.id }, after: afterValues, menu: "Associados", subMenu: "Associados", idUserChanged: values.id });
              await broadcastUpdateUser(values.id);
              setState(0);
            } catch (e) {
              console.error("Error Upload News Image")
              console.error(e)
              console.log(e.response.data)
              changeMsgError(e.response)
              // setError(e.response)
              setOpenErrorModal(true)
              // setState(0)
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldError,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Card className={classes.card}>
                <CardContent >
                  <Grid container spacing={3}>
                    {/* {punishment === true ?
                      <Grid item md={12} xs={12}>
                        {newPermission.edit && (
                          <Button
                            style={{ marginBottom: -40 }}
                            color='primary'
                            size="large"
                            variant="contained"
                            onClick={() => {
                              try {
                                setFieldValue('punished', 'NONE')
                                setFieldValue('datePunished', null)
                                setPunishment(false)
                                handleSubmit();
                              } catch (e) {
                                console.log(e)
                              }
                            }}
                          >
                            Remover Punição
                          </Button>
                        )}
                      </Grid> : null} */}

                    <Grid item md={12} xs={12}>
                      <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                        Dados Pessoais
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} >
                          <input
                            accept="image/*"
                            className={classes.inputFile}
                            name='img'
                            id="img-input"
                            type="file"
                            onChange={(event) => {
                              setFieldValue('img', event.target.files[0]);
                              setSaveBlocked(true)
                            }}
                          />
                          <label htmlFor="img-input">
                            <Avatar
                              className={classes.Avatar}
                              src={values.img ? URL.createObjectURL(values.img) : userEdit.imgProfile}
                            />
                          </label>
                          {Boolean(touched.img && errors.img) && <Typography variant="h6" display="block" style={{ color: 'red', display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                            *{touched.img && errors.img}
                          </Typography>}
                        </Grid>
                        <Grid item md={12} xs={12} style={{ marginBottom: -5 }}>
                          <TextField
                            fullWidth
                            error={Boolean(touched.nome && errors.nome)}
                            helperText={touched.nome && errors.nome}
                            label="Nome"
                            name="nome"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event)
                              setSaveBlocked(true)
                            }}
                            value={values.nome.toUpperCase()}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={12} xs={12} style={{ padding: '0 12px 5px' }}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                              <DatePicker
                                fullWidth
                                margin="normal"
                                id="nascimento"
                                label="Data de Nascimento"
                                format="dd/MM/yyyy"
                                value={values.nascimento}
                                onChange={(newValue) => {
                                  setFieldValue('nascimento', startOfDay(newValue));
                                  setSelectedDate(startOfDay(newValue));
                                  setSaveBlocked(true)
                                }}
                                inputVariant={'outlined'}
                                variant='inline'
                                disableFuture
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.estadoCivil && errors.estadoCivil)}
                            helperText={touched.estadoCivil && errors.estadoCivil}
                            label="Estado Civil"
                            name="estadoCivil"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSaveBlocked(true)
                              handleChange(event)
                            }
                            }
                            value={values.estadoCivil}
                            variant="outlined"
                            select
                          >
                            {EstadoCivil.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.nomeMae && errors.nomeMae)}
                            helperText={touched.nomeMae && errors.nomeMae}
                            label="Nome da Mãe"
                            name="nomeMae"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSaveBlocked(true)
                              handleChange(event)
                            }}
                            value={values.nomeMae}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <Grid container alignContent={'center'} spacing={3}>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.status && errors.status)}
                            helperText={touched.status && errors.status}
                            label="Status"
                            name="status"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSaveBlocked(true)
                              handleChange(event)
                            }}
                            value={values.status}
                            variant="outlined"
                            select
                            inputProps={{
                              readOnly: true
                            }}
                          >
                            {Status.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.userType && errors.userType)}
                            helperText={touched.userType && errors.userType}
                            label="Tipo de Usuário"
                            name="userType"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSaveBlocked(true)
                              handleChange(event)
                            }}
                            value={values.userType}
                            variant="outlined"
                            select
                          >
                            {Tipo.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.oab && errors.oab)}
                            helperText={touched.oab && errors.oab}
                            label="OAB"
                            name="oab"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSaveBlocked(true)
                              handleChange(event)
                            }}
                            value={values.oab}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputOab,
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.cpf && errors.cpf)}
                            helperText={touched.cpf && errors.cpf}
                            label="CPF"
                            name="cpf"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event);
                              setSaveBlocked(true);
                            }}
                            value={values.cpf}
                            variant="outlined"
                            InputProps={{
                              inputComponent: InputCpf,
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.sexo && errors.sexo)}
                            helperText={touched.sexo && errors.sexo}
                            label="Sexo"
                            name="sexo"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              setSaveBlocked(true)
                              handleChange(event)
                            }}
                            value={values.sexo}
                            variant="outlined"
                            select
                          >
                            {Sexo.map((option) => (
                              <MenuItem
                                style={{
                                  backgroundColor: color.white,
                                }}
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={12} xs={12} >
                          <TextField
                            fullWidth
                            error={Boolean(touched.naturalidade && errors.naturalidade)}
                            helperText={touched.naturalidade && errors.naturalidade}
                            label="Naturalidade"
                            name="naturalidade"
                            onBlur={handleBlur}
                            onChange={(event) => {
                              handleChange(event)
                              setSaveBlocked(true)
                            }}
                            value={values.naturalidade}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>


                    <Grid item md={12} xs={12} >
                      <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                        Dados de Contato
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>


                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.celular && errors.celular)}
                        helperText={touched.celular && errors.celular}
                        label="Celular"
                        name="celular"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.celular}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCel,
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        label="E-mail"
                        name="email"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12} >
                      <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                        Dados de Endereço
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.cep && errors.cep)}
                        helperText={touched.cep && errors.cep}
                        label="CEP"
                        name="cep"
                        onBlur={handleBlur}
                        onChange={async (event, value) => {
                          setFieldValue('cep', event.target.value)
                          setSaveBlocked(true)
                          if (event.target.value.length === 9) {
                            setOpen(true)
                            const dados = await getCepInfos(event.target.value)
                            if (dados.erro) {
                              setFieldError('cep', 'CEP não encontrado')
                            } else {
                              setFieldValue('estado', dados.uf)
                              setFieldValue('cidade', dados.localidade)
                              if (dados.bairro) {
                                setFieldValue('bairro', dados.bairro)
                                setBairro(true)
                              } else {
                                setBairro(false)
                              }
                              if (dados.logradouro) {
                                setFieldValue('endereco', dados.logradouro)
                                setLogradouro(true)
                              } else {
                                setLogradouro(false)
                              }

                            }
                            setOpen(false)
                          }
                        }
                        }
                        value={values.cep}
                        variant="outlined"
                        InputProps={{
                          inputComponent: InputCep,
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.estado && errors.estado)}
                        helperText={touched.estado && errors.estado}
                        label="Estado"
                        name="estado"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.estado}
                        variant="outlined"
                      // InputProps={{
                      //   readOnly: true,
                      // }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.cidade && errors.cidade)}
                        helperText={touched.cidade && errors.cidade}
                        label="Cidade"
                        name="cidade"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.cidade}
                        variant="outlined"
                      // InputProps={{
                      //   readOnly: true,
                      // }}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.bairro && errors.bairro)}
                        helperText={touched.bairro && errors.bairro}
                        label="Bairro"
                        name="bairro"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.bairro}
                        variant="outlined"
                      // inputProps={{
                      //   readOnly: Bairro
                      // }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.endereco && errors.endereco)}
                        helperText={touched.endereco && errors.endereco}
                        label="Logradouro"
                        name="endereco"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.endereco}
                        variant="outlined"
                      // inputProps={{
                      //   readOnly: logradouro
                      // }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.numero && errors.numero)}
                        helperText={touched.numero && errors.numero}
                        label="Número"
                        type='number'
                        name="numero"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          handleChange(event)
                          setSaveBlocked(true)
                        }}
                        value={values.numero}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.complemento && errors.complemento)}
                        helperText={touched.complemento && errors.complemento}
                        label="Complemento"
                        name="complemento"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.complemento}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={12} xs={12} >
                      <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                        Dados Admnistrativos
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.situacaoOab && errors.situacaoOab)}
                        helperText={touched.situacaoOab && errors.situacaoOab}
                        label="Situação Cadastral OAB"
                        name="situacaoOab"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          setFieldValue('situacaoOab', event.target.value)
                          if (event.target.value === 'REGULAR' && values.situacaoFinanceira === 'ADIMPLENTE') {
                            setFieldValue('situacao', 'Ativo')
                          } else {
                            setFieldValue('situacao', 'Inativo')
                          }
                        }}
                        value={values.situacaoOab}
                        variant="outlined"
                        select
                        disabled={values.userType !== 'TITULAR' ? true : false}
                      >
                        {SituacaoOab.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.situacaoFinanceira && errors.situacaoFinanceira)}
                        helperText={touched.situacaoFinanceira && errors.situacaoFinanceira}
                        label="Situação Financeira OAB"
                        name="situacaoFinanceira"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)

                          setFieldValue('situacaoFinanceira', event.target.value)
                          if (event.target.value === 'ADIMPLENTE' && values.situacaoOab === 'REGULAR') {
                            setFieldValue('situacao', 'Ativo')
                          } else {
                            setFieldValue('situacao', 'Inativo')
                          }
                        }}
                        value={values.situacaoFinanceira}
                        variant="outlined"
                        select
                        disabled={values.userType !== 'TITULAR' ? true : false}

                      >
                        {SituacaoFinanceira.map((option) => (
                          <MenuItem
                            style={{
                              backgroundColor: color.white,
                            }}
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}
                      style={{
                        marginTop: -15
                      }}
                    >
                      <MuiThemeProvider theme={defaultMaterialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                          <DatePicker
                            fullWidth
                            autoOk
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="validade"
                            label="Validade do Cartão"
                            onChange={(newValue) => {
                              setSaveBlocked(true)
                              onChangeDate(newValue)
                              setFieldValue('validade', newValue)
                            }}
                            value={values.validade}
                            inputVariant={'outlined'}
                            variant='inline'
                            disablePast
                            minDateMessage={null}
                          />
                        </MuiPickersUtilsProvider>
                      </MuiThemeProvider>
                    </Grid>
                    <Grid item md={6} xs={12} >
                      <TextField
                        fullWidth
                        error={Boolean(touched.situacao && errors.situacao)}
                        helperText={touched.situacao && errors.situacao}
                        label="Situação"
                        name="situacao"
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        value={values.situacao}
                        variant="outlined"
                        inputProps={{
                          readOnly: true
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12} >
                      <Typography variant="h5" display="block" style={{ marginTop: 30 }}>
                        Informações Adicionais
                      </Typography>
                      <Divider style={{ marginBottom: 10 }} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        error={Boolean(touched.observacoes && errors.observacoes)}
                        helperText={touched.observacoes && errors.observacoes}
                        label="Observações"
                        name="observacoes"
                        value={values.observacoes}
                        multiline
                        InputProps={{ classes: { input: classes.textArea } }}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setSaveBlocked(true)
                          handleChange(event)
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="documentos"
                        render={arrayHelpers => (
                          <>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 30 }}>
                              <input
                                accept="image/*"
                                className={classes.inputFile}
                                name='docimg'
                                id="doc-img-input"
                                type="file"
                                onChange={(event) => {
                                  const temp = docArray;
                                  temp.push(event.target.files[0]);
                                  setDocArray(temp);
                                  arrayHelpers.push(event.target.files[0]);
                                  setFieldValue('documentos', docArray);
                                  setSaveBlocked(true)
                                }}
                              />
                              <Typography variant="h5" display="block">
                                Documentos
                              </Typography>
                              <label htmlFor="doc-img-input">
                                <AddBoxIcon />
                              </label>
                            </div>
                            <Divider style={{ marginBottom: 10 }} />

                            <Grid container xs={12} spacing={1}>
                              {values.documentos.map((doc, index) => (
                                <Grid item md={4} xs={12}>
                                  <OutlinedInput
                                    fullWidth
                                    name="doc"
                                    value={doc.name ? doc.name : doc.img}
                                    disabled
                                    inputProps={{
                                      readOnly: true
                                    }}
                                    style={{ backgroundColor: 'white', fontWeight: 'bold' }}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => {
                                            window.open(doc.img ? doc.img : URL.createObjectURL(doc), "_blank")
                                          }}
                                          edge="end"
                                        >
                                          <VisibilityOutlinedIcon />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            const temp = docArray;
                                            const removed = temp.splice(index, 1)
                                            setDocArray(temp);
                                            if (doc.img) {
                                              const tempRemoved = docArrayRemoved;
                                              tempRemoved.push(removed[0].img);
                                              setDocArrayRemoved(tempRemoved);
                                            }
                                            arrayHelpers.remove(index);
                                            setFieldValue('documentos', docArray);
                                          }}
                                          edge="end"
                                        >
                                          <RemoveCircleOutlineOutlinedIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>

                    {!loading && <Grid container spacing={1} alignItems='center' justify='space-between' className={classes.btns}>
                      <Grid item container md={6} xs={12} spacing={3}>
                        {userEdit.status === 'AGUARDANDO' && <><Grid item md={4} xs={6}>
                          <Button
                            onClick={newPermission.edit && handleOpenModal} //userAdm.permissions = edit ? null : handleOpenModal
                            fullWidth
                            color={newPermission.edit ? "primary" : "secundary"}  //userAdm.permissions = edit ? grey : primary
                            size="large"
                            variant="contained"
                            disabled={isSubmitting}
                          >
                            Aceitar
                          </Button>

                        </Grid>
                          <Grid item md={4} xs={6}>
                            <Button
                              fullWidth
                              color={newPermission.edit ? "primary" : "secundary"}
                              disabled={isSubmitting}
                              size="large"
                              variant="contained"
                              onClick={newPermission.edit && showRefuseModal}
                            >
                              Recusar
                            </Button>
                          </Grid></>}
                      </Grid>
                      <Grid item container md={6} xs={12} spacing={3} justify='flex-end'>
                        <Grid item md={4} xs={6}>
                          <Button
                            fullWidth
                            color='primary'
                            disabled={isSubmitting}
                            size="large"
                            variant="contained"
                            onClick={goBack}
                          >
                            Cancelar
                          </Button>
                        </Grid>
                        {newPermission.edit && (
                          <>
                            {saveBlocked ?
                              <Grid item md={4} xs={6}>
                                <Button
                                  fullWidth
                                  color='primary'
                                  disabled={isSubmitting}
                                  onClick={() => {
                                    setFieldValue('situacao', 'INATIVO')
                                    setFieldValue('status', 'AGUARDANDO')

                                  }}
                                  size="large"
                                  type="submit"
                                  variant="contained"
                                >
                                  {isSubmitting ? <CircularProgress color='inherit' circle={{ color: '#fff' }} size={25} /> : 'Salvar'
                                  }
                                </Button>
                              </Grid> :
                              <Grid item md={4} xs={6}>
                                <Button
                                  fullWidth
                                  color='primary'
                                  disabled={isSubmitting}
                                  size="large"
                                  variant="contained"
                                  onClick={goBack}
                                >
                                  Voltar
                                </Button>
                              </Grid>
                            }
                          </>
                        )}
                      </Grid>
                    </Grid>}
                    {loading && <Grid container justify='center' ><Grid item md={2}> <CircularProgress color={'red'} size={36} /> </Grid></Grid>}
                  </Grid>
                </CardContent>
              </Card>

              <Modal
                className={classes.modal}
                open={openModal}
                onClose={handleCloseModal}
                keepMounted
              >
                <Container maxWidth='md' spacing={1}
                  style={{
                    backgroundColor: 'white',
                    width: '320px',
                    height: '132px',
                    overflow: 'hidden',
                    padding: '0 30',
                    marginTop: '14%',
                    justifyContent: "center",
                    alignContent: 'center',
                    borderRadius: 4,
                    border: '1px solid #000000',
                  }}
                >
                  <Grid container spacing={6}>
                    <Grid item md={12} xs={12} style={{ textAlign: "center", placeContent: 'center', paddingTop: '36px' }}>
                      <Typography variant="h4">
                        Deseja continuar a operação
                      </Typography>
                    </Grid>

                    <Grid container spacing={1} justifyContent="center" justify
                      style={{
                        marginTop: 10,
                        textAlign: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Grid item md={4} xs={6}
                        style={{ marginRight: "-1%" }}
                      >
                        <Button
                          fullWidth
                          type="submit"
                          disabled={isSubmitting}
                          size="large"
                          variant="contained"
                          onClick={() => {
                            setFieldValue('status', 'ACEITO')
                            if (values.situacaoFinanceira === 'ADIMPLENTE' && values.situacaoOab === 'REGULAR') {
                              setFieldValue('situacao', 'ATIVO')
                            }
                            handleSubmit();
                            handleCloseModal();
                          }}
                          style={{ padding: '15px 30px', backgroundColor: '#228B22', color: "white", height: "70%" }}
                        >
                          Sim
                        </Button>
                      </Grid>
                      <Grid item md={4} xs={6} style={{
                        marginRight: "-1%",
                        idth: '100%',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      >
                        <Button
                          color="primary"
                          fullWidth
                          type="submit"
                          disabled={isSubmitting}
                          size="large"
                          variant="contained"
                          onClick={handleSubmit}
                          style={{ padding: '15px 30px', height: "70%" }}
                        >
                          Não
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>
              </Modal>


              <Modal
                className={classes.modal}
                open={openErrorModal}
                onClose={handleCloseErrorModal}
                keepMounted
              >
                <Container maxWidth='md' spacing={1}
                  style={{
                    backgroundColor: 'white',
                    width: '320px',
                    height: '132px',
                    overflow: 'hidden',
                    padding: '0 30',
                    marginTop: '14%',
                    justifyContent: "center",
                    alignContent: 'center',
                    borderRadius: 4,
                    border: '1px solid #000000',
                  }}
                >
                  <Grid container spacing={6}>
                    <Grid item md={12} xs={12} style={{ textAlign: "center", placeContent: 'center', paddingTop: '36px', alignContent: 'center', color: color.redError }}>
                      <Typography variant="h5">
                        {error}
                      </Typography>
                    </Grid>
                    <Grid container spacing={1} justifyContent="center" justify
                      style={{
                        marginTop: 10,
                        textAlign: "center",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Grid item md={4} xs={6}
                        style={{ marginRight: "-1%" }}
                      >
                        <Button
                          fullWidth
                          type="submit"
                          color={newPermission.edit ? "primary" : "secundary"}
                          size="large"
                          variant="contained"
                          onClick={() => {
                            handleCloseErrorModal();
                          }}
                          style={{ padding: '15px 30px', color: "white", height: "70%" }}
                        >
                          Fechar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Container>


              </Modal>

              <Backdrop className={classes.backdrop} open={open}>
                <ReactLoading type={'spinningBubbles'} color={color.red} />
              </Backdrop>

              <Dialog open={modal} onClose={handleClose} aria-labelledby="form-refuse">
                <DialogTitle id="form-refuse" style={{ backgroundColor: color.white }}>Recusar Solicitação</DialogTitle>
                <DialogContent style={{ backgroundColor: color.white }}>
                  <TextField
                    fullWidth
                    label="Motivo"
                    name="motivo"
                    multiline
                    InputProps={{ classes: { input: classes.textArea } }}
                    onChange={(event) => {
                      setMotivo(event.target.value); setSaveBlocked(true)
                    }}
                    value={motivo}
                    variant="outlined"
                  />
                  <FormControlLabel
                    style={{ width: '100%' }}
                    value="start"
                    name="resendDoc"
                    control={<Checkbox
                      // indeterminate={selected.length > 0 && selected.length < userList.length}
                      checked={resendDoc}
                      onChange={() => {
                        setResendDoc(!resendDoc); setSaveBlocked(true)
                      }}
                      color="primary"
                    />}
                    label="Reenviar documentos"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    style={{ width: '100%' }}
                    value="start"
                    name="resendSelfie"
                    control={<Checkbox
                      // indeterminate={selected.length > 0 && selected.length < userList.length}
                      checked={resendSelfie}
                      onChange={() => {
                        setSaveBlocked(true)
                        setResendSelfie(!resendSelfie)
                      }}
                      color="primary"
                    />}
                    label="Reenviar selfie"
                    labelPlacement="end"
                  />
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Button
                      style={{ marginTop: 60 }}
                      color='primary'
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        values.status = "RECUSADO";
                        handleSubmit();
                      }}
                    >
                      Enviar
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </form>
          )}
        </Formik>}
      </Container>
    </>
  )
}
