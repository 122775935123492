/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Divider,
  Avatar,
  TextField,
  MenuItem,
  MuiThemeProvider,
  createMuiTheme,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Modal,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Table,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { color } from "../../../../components/common/colors";
import { format, add } from "date-fns";
import pt from "date-fns/locale/pt-BR";
import { capitalize, set, values } from "lodash";
import { Formik, setIn } from "formik";
import {
  getAllServicos,
  getAllUnidadesToSchedule,
  newSchedule,
  getAllIndisponible,
  findSchedulesBasedOnProperties,
  getAllExtraHour,
  getHistoricUser,
  getHistoricFilterUser,
} from "../../../../providers/schedules";
import { Autocomplete, Alert, TreeItem, TreeView } from "@material-ui/lab";
import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "@material-ui/pickers";
import ReactLoading from "react-loading";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as _ from "lodash";
import ReactTooltip from "react-tooltip";
import { alterationLog } from "../../../../providers/log";
import { useSelector } from "react-redux";
import { PhotoSizeSelectActualRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: "100%",
  },
  Card: {
    background: color.white,
  },
  paper: {
    backgroundColor: color.white,
    width: "100%",
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    "&:hover": {
      backgroundColor: "#a35655",
    },
  },
  item: {
    color: "#979797",
    fontWeight: "bold",
    letterSpacing: 1.5,
  },
  Avatar: {
    width: 200,
    height: 200,
    margin: "8px auto",
    boxShadow: "0px 0px 0px 6px #FFFFFF, 0px 0px 0px 10px #B22222",
    backgroundColor: "white",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  textArea: {
    minHeight: 80,
  },
  option: {
    backgroundColor: "white",
  },
  cardHours: {
    display: "flex",
    width: "100%",
    textAlignLast: "center",
    marginBottom: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const defaultMaterialTheme = createMuiTheme({
  palette: {
    primary: {
      main: color.red,
    },
  },
});

function AlertBar(props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

export default function RequestSchedule({ setState, schedule }) {
  const status = [
    {
      value: "confirmed",
      label: "Confirmado",
    },
    {
      value: "recused",
      label: "Recusado",
    },
    {
      value: "await",
      label: "Aguardando",
    },
  ];

  const [servicoValue, setServicoValue] = useState({});
  const [unidadeValue, setUnidadeValue] = useState({});
  const [profissionalValue, setProfissionalValue] = useState({});
  const [procedimentoValue, setProcedimentoValue] = useState({});

  const [profissionais, setProfissionais] = useState([]);
  const [procedimentos, setProcedimentos] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [servicoForDependent, setServicoForDependent] = useState();
  const [procedimentoForDependent, setProcedimentoForDependent] = useState();
  const [locais, setLocais] = useState([]);
  const [locaisFiltered, setLocaisFiltered] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [date, setDate] = useState(new Date());
  const [hour, setHour] = useState(new Date("2020-10-10 00:00"));
  const [almocoInit, setAlmocoInit] = useState(new Date("2020-10-10 00:00"));
  const [almocoFim, setAlmocoFim] = useState(new Date("2020-10-10 00:00"));
  const [horarioOcupado, setHorarioOcupado] = useState(false);
  const [indisponible, setIndisponible] = useState([]);
  const [
    indisponibleOfCurrentProfessional,
    setIndisponibleOfCurrentProfessional,
  ] = useState([]);
  const [blockAlert, setBlockAlert] = useState(false);
  const [professionalSchedules, setProfessionalSchedules] = useState([]);
  const [professionalSchedulesException, setProfessionalSchedulesException] =
    useState([]);

  const [professionalHours, setProfessionalHours] = useState([])
  const [invalidHour, setInvalidHour] = useState(false)
  const [open, setOpen] = useState(false);
  const [profList, setProfList] = useState([]);
  const [verifyProf, setVerifyProf] = useState(true);
  const [procTime, setProcTime] = useState('');
  const [extraHour, setExtraHour] = useState([]);
  const [isExtraDay, setIsExtraDay] = useState(false);

  const user = useSelector(({ user }) => user);
  const [card, setCard] = useState('none');

  const [modal, setModal] = useState(false);
  const [modalFilter, setModalFilter] = useState({
    status: ["{status: 'a'}, {status: 'b'}"],
    servico: [{ nome: 'a', id: '1' }, { nome: 'b', id: '2' }],
    procedimento: [{ nome: 'a', id: '1' }, { nome: 'b', id: '2' }],
    profissional: [{ nome: 'a', id: '1' }, { nome: 'b', id: '2' }],
    unidade: [{ nome: 'a', id: '1' }, { nome: 'b', id: '2' }],
    data: [{ data: format(new Date(), 'dd/MM/yyyy') }],
  });
  const [modalSearchFilter, setModalSearchFilter] = useState({
    status: '',
    servico: '',
    procedimento: '',
    profissional: '',
    unidade: '',
    dataInicial: add(new Date(), { days: -30 }),
    dataFinal: new Date(),
  });

  const [modalSearchFilterValue, setModalSearchFilterValue] = useState({
    status: '',
    servico: '',
    procedimento: '',
    profissional: '',
    unidade: '',
    dataInicial: add(new Date(), { days: -30 }),
    dataFinal: new Date(),
  });

  const [historicData, setHistoricData] = useState([]);

  const [stateShow, setStateShow] = useState(false)

  const [exception, setException] = useState(false)

  const [hourPicked, setHourPicked] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        const locais = await getAllUnidadesToSchedule();
        setLocais(locais);

        const servicos = await getAllServicos();
        setServicos(servicos);

        const indis = await getAllIndisponible();
        setIndisponible(indis);

        const extra = await getAllExtraHour();
        setExtraHour(extra);

        const idUser = schedule.id;
        const filters = await getHistoricFilterUser(idUser);
        setModalFilter(filters);
      } catch (error) {
        console.log(error);
      }
    }
    add(new Date(), { hours: 3 })
    fetchData();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(procedimentoValue)) {
      fetchSchedulesBasedOnProperties().then((scheduleTemp) => {
        setProfessionalHours(hoursToSchedule(date, scheduleTemp) || []);
      });
    }
  }, [procedimentoValue, date]);

  async function fetchSchedulesBasedOnProperties() {
    const sched = await findSchedulesBasedOnProperties({
      data: date,
      idProfissional: profissionalValue.id,
      idServico: servicoValue.id,
      idUnidade: unidadeValue.id,
    });

    const scheduleTemp = sched.filter((a) => a.exception === false);
    setProfessionalSchedules(scheduleTemp);
    setProfessionalSchedulesException(
      sched.filter((a) => a.exception === true)
    );

    return scheduleTemp;
  }

  const classes = useStyles();
  const goBack = () => {
    setState(1);
  };

  const handleClose = (event, reason) => {
    setBlockAlert(false);
    setInvalidHour(false);
    if (reason === "clickaway") {
      return;
    }
    setHorarioOcupado(false);
  };

  const handleCloseBlockAlert = (event, reason) => {
    setBlockAlert(false);
    setInvalidHour(false);
  };

  function disableWeekDays(day) {
    const week = [
      !profissionalValue.domingo,
      !profissionalValue.segunda,
      !profissionalValue.terca,
      !profissionalValue.quarta,
      !profissionalValue.quinta,
      !profissionalValue.sexta,
      !profissionalValue.sabado,
    ];

    const extraFiltered = extraHour.filter((a) =>
      a.professionals.some((b) => b.id === profissionalValue.id)
    );
    const verifyExtra = extraFiltered.some(
      (a) => new Date(a.dataInit).valueOf() === new Date(day).valueOf()
    );
    if (!!verifyExtra) return false;

    return week[day.getDay()];
  }

  function pickHour(newValue, data, setFieldValue, exception, isCard) {
    if (isCard) {
      newValue = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), newValue.split(':')[0], newValue.split(':')[1]);
    }

    let blockHour = false;

    const days = [
      profissionalValue.domingo,
      profissionalValue.segunda,
      profissionalValue.terca,
      profissionalValue.quarta,
      profissionalValue.quinta,
      profissionalValue.sexta,
      profissionalValue.sabado,
    ];

    const hourFormated = format(newValue, "HH:mm");

    const hourInterval = indisponibleOfCurrentProfessional.map((d) => {
      return {
        dataInitial: new Date(new Date(d.dataInit).getFullYear(), new Date(d.dataInit).getMonth(), new Date(d.dataInit).getDate(), 0, 0),
        dataFinal: new Date(new Date(d.dataFim).getFullYear(), new Date(d.dataFim).getMonth(), new Date(d.dataFim).getDate(), 0, 0),
        hourInitial: d.horaInit,
        hourFinal: d.horaFim,
      };
    });

    for (const x of hourInterval) {
      const teste =
        format(new Date(data), "dd/MM/yyyy") === format(new Date(x.dataInitial), "dd/MM/yyyy") &&
        format(new Date(data), "dd/MM/yyyy") === format(new Date(x.dataFinal), "dd/MM/yyyy") &&
        hourFormated >= x.hourInitial.padStart(5, 0) &&
        hourFormated <= x.hourFinal.padStart(5, 0);

      if (teste === true) {
        blockHour = true;
      }
    }

    const extraFiltered = extraHour.filter((a) =>
      a.professionals.some((b) => b.id === profissionalValue.id)
    );
    const verifyExtra = extraFiltered.find(
      (a) => new Date(a.dataInit).valueOf() === new Date(data).valueOf()
    );

    // if (blockHour) {
    //  setBlockAlert(true);
    // } else if (!!verifyExtra) {
    if (!!verifyExtra) {

      if (
        (hourFormated >= verifyExtra.horaInit.padStart(5, "0") &&
          hourFormated <= verifyExtra.horaFim.padStart(5, "0") &&
          (hourFormated <= almocoInit.padStart(5, "0") ||
            hourFormated >= almocoFim.padStart(5, "0"))) ||
        !!exception === true
      ) {
        setHour(newValue);
        const horaIni = `${newValue.getHours()}:${newValue.getMinutes() <= 9
          ? "0" + newValue.getMinutes()
          : newValue.getMinutes()
          }`;
        setFieldValue("horaInicio", horaIni);

        const horaFim = add(
          new Date().setHours(horaIni.split(":")[0], horaIni.split(":")[1], 0),
          { minutes: procedures }
        );
        setFieldValue(
          "horaFim",
          `${horaFim.getHours()}:${horaFim.getMinutes() <= 9
            ? "0" + horaFim.getMinutes()
            : horaFim.getMinutes()
          }`
        );
      }
    }
    else {
      if ((((days[data.getDay()].horaInit <= hourFormated) && (days[data.getDay()].horaFim >= hourFormated)) && ((hourFormated <= almocoInit) || (hourFormated >= almocoFim))) || !!exception === true) {

        setHour(newValue);
        const horaIni = `${newValue.getHours()}:${newValue.getMinutes() <= 9 ? '0' + newValue.getMinutes() : newValue.getMinutes()}`
        setFieldValue("horaInicio", horaIni)

        const horaFim = add(new Date().setHours(horaIni.split(':')[0], horaIni.split(':')[1], 0), { minutes: procedures })
        setFieldValue("horaFim", `${horaFim.getHours()}:${horaFim.getMinutes() <= 9 ? '0' + horaFim.getMinutes() : horaFim.getMinutes()}`)
      }
    }

    return days[newValue];
  }

  const onDateFocus = useCallback(() => {
    const temp1 = indisponible
      .map((p) => p.professionals.map((u) => u.nome === profissionalValue.nome))
      .flat(1);
    if (profList !== temp1) {
      setProfList(temp1);
    }
    const temp2 = profList.some((i) => i === true);
    if (verifyProf !== temp2) {
      setVerifyProf(temp2);
    }
  }, [indisponible, profList, profissionalValue.nome, verifyProf]);

  const onDateChange = 
    (newValue, exception, setFieldValue) => {
      let blockDate = false;
      const rightData = new Date(
        newValue.getFullYear(),
        newValue.getMonth(),
        newValue.getDate()
      );
      const extraFiltered = extraHour.filter((a) =>
        a.professionals.some((b) => b.id === profissionalValue.id)
      );
      const verifyExtra = extraFiltered.find(
        (a) => new Date(a.dataInit).valueOf() === new Date(rightData).valueOf()
      );
      setIsExtraDay(verifyExtra);
      for (const x of indisponibleOfCurrentProfessional) {
        const teste =
          rightData < add(new Date(x.dataInit), { hours: -15, minutes: -2 }) ||
          rightData > add(new Date(x.dataFim), { hours: -15, minutes: -2 });
        if (!teste) {
          blockDate = true;
        }
      }
      if (date !== rightData) {
        setDate(rightData);
        setFieldValue("data", rightData);
      }
    }

  const hoursToSchedule = (rightData, scheduleTemp) => {
    const filteredProc = procedimentos.filter(l => l.id === procedimentoValue.id)[0];
    setProcTime(filteredProc)
    const weekDays = [profissionalValue.domingo, profissionalValue.segunda, profissionalValue.terca, profissionalValue.quarta, profissionalValue.quinta, profissionalValue.sexta, profissionalValue.sabado]
    let day;
    let hours
    let dayExtra = isExtraDay;


    let dayWeek = weekDays[new Date(rightData).getDay(rightData)];

    if (!!(weekDays[new Date(rightData).getDay(rightData)]) && (!!isExtraDay)) {
      let scheduleHoursDayExtra
      let scheduleHoursDayWeek
      if (!!dayWeek) {
        //separar hora de almoço e sem hora de almoço. nos dias normais
        if (dayWeek.horaInit < almocoInit && almocoFim < dayWeek.horaFim) {
          //hora de almoço no meio do horario normal
          //antes do almoço
          let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
          let initLunch = new Date(2000, 10, 10, almocoInit.split(':')[0], almocoInit.split(':')[1])
          const beforeLunch = [init1];
          for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
            beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
            init1 = add(init1, { minutes: filteredProc.duracao })
          }
          //depois do almoço
          let init2 = new Date(2000, 10, 10, almocoFim.split(':')[0], almocoFim.split(':')[1]);
          const fim = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1]);
          let laterLunch = [init2];
          for (let i = 0; init2 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
            laterLunch.push(add(init2, { minutes: filteredProc.duracao }))
            init2 = add(init2, { minutes: filteredProc.duracao })
          }
          if ((filteredProc.duracao > 40 && filteredProc.duracao < 60) ||
            (filteredProc.duracao > 60)) {
            beforeLunch.pop()
          }
          laterLunch.pop()
          scheduleHoursDayWeek = beforeLunch.concat(laterLunch);
        } else {
          //hora de almoço fora do horario normal de trabalho independente de hora ou dentro.
          let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
          let initLunch = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1])
          const beforeLunch = [init1];
          for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
            beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
            init1 = add(init1, { minutes: filteredProc.duracao })
          }
          scheduleHoursDayWeek = beforeLunch
        }
      }
      if (!!dayExtra) {
        let init1 = new Date(2000, 10, 10, dayExtra.horaInit.split(':')[0], dayExtra.horaInit.split(':')[1]);
        let fim = new Date(2000, 10, 10, dayExtra.horaFim.split(':')[0], dayExtra.horaFim.split(':')[1])
        const total = [init1];
        for (let i = 0; init1 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
          total.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }

        scheduleHoursDayExtra = total;
      }

      if (scheduleHoursDayExtra > scheduleHoursDayWeek) {
        hours = scheduleHoursDayWeek.concat(scheduleHoursDayExtra)
      } else {
        scheduleHoursDayExtra.pop()
        hours = scheduleHoursDayExtra.concat(scheduleHoursDayWeek)
      }
      //dia da semana completo com hora de almoço no meio de expediente resolvido.
      //agora sem horario de almoço no expediente.
    } else if (!!(weekDays[new Date(rightData).getDay(rightData)]) && (!!!isExtraDay)) {
      if (dayWeek.horaInit < almocoInit && almocoFim < dayWeek.horaFim) {
        //hora de almoço no meio do horario normal
        //antes do almoço
        let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, almocoInit.split(':')[0], almocoInit.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }
        //depois do almoço
        let init2 = new Date(2000, 10, 10, almocoFim.split(':')[0], almocoFim.split(':')[1]);
        const fim = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1]);
        let laterLunch = [init2];
        for (let i = 0; init2 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
          laterLunch.push(add(init2, { minutes: filteredProc.duracao }))
          init2 = add(init2, { minutes: filteredProc.duracao })
        }
        if ((filteredProc.duracao > 40 && filteredProc.duracao < 60) ||
          (filteredProc.duracao > 60)) {
          beforeLunch.pop()
        }
        hours = beforeLunch.concat(laterLunch);
      } else {
        //hora de almoço fora do horario normal de trabalho independente de hora ou dentro.
        let init1 = new Date(2000, 10, 10, dayWeek.horaInit.split(':')[0], dayWeek.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, dayWeek.horaFim.split(':')[0], dayWeek.horaFim.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }
        hours = beforeLunch

      }

    } else if (!!!(weekDays[new Date(rightData).getDay(rightData)]) && (!!isExtraDay)) {
      //APENAS HORARIO EXTRA ABAIXO COM HORARIO DE ALMOÇO NO MEIO.

      //ABAIXO HORA EXTRA COM HORARIO DE ALMOÇO NO MEIO
      day = isExtraDay
      if (day.horaInit < almocoInit && almocoFim < day.horaFim) {
        //antes do almoço
        let init1 = new Date(2000, 10, 10, day.horaInit.split(':')[0], day.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, almocoInit.split(':')[0], almocoInit.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }

        let init2 = new Date(2000, 10, 10, almocoFim.split(':')[0], almocoFim.split(':')[1]);
        const fim = new Date(2000, 10, 10, day.horaFim.split(':')[0], day.horaFim.split(':')[1])
        let laterLunch = [init2];
        for (let i = 0; init2 <= add(fim, { minutes: -filteredProc.duracao }); i++) {
          laterLunch.push(add(init2, { minutes: filteredProc.duracao }))
          init2 = add(init2, { minutes: filteredProc.duracao })
        }
        if ((filteredProc.duracao > 40 && filteredProc.duracao < 60) ||
          (filteredProc.duracao > 60)) {
          beforeLunch.pop()
        }
        hours = beforeLunch.concat(laterLunch);
      } else {
        let init1 = new Date(2000, 10, 10, day.horaInit.split(':')[0], day.horaInit.split(':')[1]);
        let initLunch = new Date(2000, 10, 10, day.horaFim.split(':')[0], day.horaFim.split(':')[1])
        const beforeLunch = [init1];
        for (let i = 0; init1 <= add(initLunch, { minutes: -filteredProc.duracao }); i++) {
          beforeLunch.push(add(init1, { minutes: filteredProc.duracao }))
          init1 = add(init1, { minutes: filteredProc.duracao })
        }
        hours = beforeLunch
      }
    }

    if (!!hours) {
      const filteredHours = [];
      for (const x of hours) {
        const xHour = x.getHours() <= 9 ? '0' + JSON.stringify(x.getHours()) : JSON.stringify(x.getHours())
        const xMin = x.getMinutes() == 0 ? JSON.stringify(x.getMinutes()) + '0' : JSON.stringify(x.getMinutes())
        const formated = `${xHour + ":" + xMin}`
        const hasSchedule = scheduleTemp.find((a) => a.horaInicio <= formated && formated < a.horaFim)
        filteredHours.push(!!hasSchedule ? { keyId: JSON.stringify(new Date().getTime() + Math.floor(Math.random() * 65536)), picked: true, hour: formated, ...hasSchedule } : { keyId: JSON.stringify(new Date().getTime() + Math.floor(Math.random() * 65536)), picked: false, selected: "", hour: formated, ...hasSchedule });
      }
      return filteredHours;
    }
  }

  function statusName(status) {
    switch (status) {
      case 'confirmed':
        return 'Confirmado'
      case 'await':
        return 'Aguardando'
      case 'not_appear':
        return 'Não Comp.'
      case 'finished':
        return 'Finalizado'
      case 'reject':
        return 'Cancelado'
      default:
        break;
    }
  }

  const openModal = () => {
    setModal(true);
  }

  const closeModal = () => {
    setModal(false);
  }

  return (
    <>
      <Container maxWidth="md" disableGutters>
        <Formik
          initialValues={{
            idUser: schedule.id,
            idServico: "",
            idUnidade: "",
            idProfissional: "",
            idProcedimento: "",
            situacao: schedule.situacao,
            oab: schedule.oab,
            cpf: schedule.cpf,
            status: "confirmed",
            data: format(new Date(date), "dd/MM/yyyy"),
            horaInicio: format(new Date(date), "dd/MM/yyyy"),
            horaFim: "",
            observacao: "",
            automaticConfirmation: "",
            exception: false,
          }}
          validationSchema={Yup.object().shape({
            idUser: Yup.string().required("Campo obrigatório"),
            idProfissional: Yup.string().required("Campo obrigatório"),
            idServico: Yup.string().required("Campo obrigatório"),
            idUnidade: Yup.string().required("Campo obrigatório"),
            idProcedimento: Yup.string().required("Campo obrigatório"),
            horaInicio: Yup.string().required("Campo obrigatório"),
            data: Yup.date().required("Campo obrigatório"),
          })} 
          onSubmit={async (values) => {
            const { data, horaFim, horaInicio } = values;

            for (const unavailable of indisponibleOfCurrentProfessional) {
              const startHour = unavailable.horaInit.length === 4 ? '0' + unavailable.horaInit : unavailable.horaInit;
              const endHour = unavailable.horaFim.length === 4 ? '0' + unavailable.horaFim : unavailable.horaFim;
              const startDate = new Date(unavailable.dataInit);
              const endDate = new Date(unavailable.dataFim);
              const horaInit = horaInicio.length === 4 ? '0' + horaInicio : horaInicio;
              const horaF = horaFim.length === 4 ? '0' + horaFim : horaFim;

              if (data >= startDate && data <= endDate) {
                if (startHour <= horaInit && horaInit <= endHour) {
                  setHorarioOcupado(true);
                  return;
                }
              }
            }

            // if (professionalHours.some((a) => a.hour == values.horaInicio.padStart(5, '0'))) {
            if (professionalHours.some((a) => a.hour == hourPicked.hour)) {
              setInvalidHour(false);
              try {
                setOpen(true)
                const temp = {
                  ...values,
                  horaInicio: values.horaInicio.length < 5 ? '0' + values.horaInicio : values.horaInicio,
                  horaFim: values.horaFim.length < 5 ? '0' + values.horaFim : values.horaFim,
                }
                const result = await newSchedule({
                  ...values,
                  horaInicio: values.horaInicio.length < 5 ? '0' + values.horaInicio : values.horaInicio,
                  horaFim: values.horaFim.length < 5 ? '0' + values.horaFim : values.horaFim, user })
                  await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: temp, menu: "Agendamentos", subMenu: "Agendamentos", idUserChanged: schedule.id, });
                setState(0)
              } catch (error) {
                if (error.response.data.error === 'HOUR_UNAVALIABLE') {
                  setHorarioOcupado(true)
                }
                console.log('ERROR_SCHEDULE_NEW');
                console.log(error);
              }
            } else if (exception === true) {
              setOpen(true)
              const result = await newSchedule({
                ...values,
                horaInicio: values.horaInicio.length < 5 ? '0' + values.horaInicio : values.horaInicio,
                horaFim: values.horaFim.length < 5 ? '0' + values.horaFim : values.horaFim,
              })
              await alterationLog({ action: 'CREATE', idUser: user.id, before: '', after: values, menu: "Agendamentos", subMenu: "Agendamentos", });
              setState(0)
            } else {
              setInvalidHour(true);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleReset,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
          }) => {
            return (
              <Card className={classes.Card}>
                <CardContent>
                  <Grid container>
                    <Grid item md={10}>
                      <Typography variant={"h1"} style={{ marginBottom: 30 }}>
                        Novo agendamento
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        onClick={async () => {
                          openModal()
                          try {
                            const idUser = schedule.id;
                            const historic = await getHistoricUser(idUser, modalSearchFilter);
                            setHistoricData(historic);
                          } catch (error) {
                            console.log(error);
                          }

                        }}
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                      >
                        Histórico
                      </Button>
                    </Grid>
                    <Grid md={12}>
                      <Typography variant={"h4"}>Dados do Associado</Typography>
                      <Divider style={{ marginBottom: 50 }} />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{ marginBottom: 30, marginLeft: 0 }}
                  >
                    <Grid item md={6}>
                      <Grid item xs={12} style={{}}>
                        <Avatar
                          className={classes.Avatar}
                          src={
                            schedule.img
                              ? URL.createObjectURL(schedule.img)
                              : schedule.imgProfile
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container md={6} spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Tipo"
                          name="tipo"
                          id="tipo"
                          value={capitalize(schedule.userType)}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Situação"
                          name="situacao"
                          id="situacao"
                          value={capitalize(schedule.situacao)}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {schedule.situacao != "ATIVO" && (
                          // <Grid item md={8} xs={12}>
                          <Alert severity={"warning"} style={{ marginTop: 5 }}>
                            Usuário Inativo
                          </Alert>
                          // </Grid>
                        )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="OAB"
                          name="oab"
                          id="oab"
                          value={schedule.oab}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="CPF"
                          name="cpf"
                          id="cpf"
                          value={schedule.cpf}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <TextField
                          fullWidth
                          label="Nome"
                          name="nome"
                          id="nome"
                          value={schedule.nome}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid md={12}>
                    <Typography variant={"h4"}>Dados do Agendamento</Typography>
                    <Divider style={{ marginBottom: 50 }} />
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                    style={{ marginBottom: 37, marginLeft: 0 }}
                  >
                    <Grid container md={12} spacing={3}>
                      <Grid item md={6} xs={12}>
                        <TextField
                          fullWidth
                          label="Status"
                          name="status"
                          id="status"
                          onBlur={handleBlur}
                          value={values.status}
                          onChange={(e, value) => {
                            setFieldValue("status", e.target.value);
                          }}
                          variant="outlined"
                          select
                          InputProps={{
                            readOnly: true,
                          }}
                        >
                          {status.map((option) => (
                            <MenuItem
                              style={{
                                backgroundColor: color.white,
                              }}
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          fullWidth
                          id="select-servico"
                          color={
                            touched.idServico && errors.idServico
                              ? "error"
                              : "primary"
                          }
                          options={servicos.map((option) => option)}
                          value={servicoValue}
                          getOptionLabel={(option) => option.nome}
                          onChange={(e, value) => {
                            setIndisponibleOfCurrentProfessional([]);
                            setFieldValue("idServico", value ? value.id : "");
                            setServicoValue(value ? value : "");
                            setServicoForDependent(value.disponivelDependente);
                            setUnidadeValue({});
                            setProfissionalValue({});
                            setStateShow(false)
                            setProcedimentoValue({});
                            setLocaisFiltered(
                              locais.filter((unidade) =>
                                unidade.professionals.some(
                                  (p) => p.idServico === value.id
                                )
                              )
                            );
                          }}
                          onBlur={handleBlur}
                          classes={{
                            popper: classes.option,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={handleBlur}
                              label="Serviço"
                              variant="outlined"
                              error={Boolean(
                                touched.idServico && errors.idServico
                              )}
                              helperText={touched.idServico && errors.idServico}
                            />
                          )}
                        />
                        {!servicoForDependent &&
                          values.idServico != "" &&
                          schedule.userType != "TITULAR" && (
                            <Alert severity={"warning"} style={{ marginTop: 5 }}>
                              Serviço disponível somente para titular
                            </Alert>
                          )}
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          fullWidth
                          id="select-unidade"
                          color={
                            touched.idUnidade && errors.idUnidade
                              ? "error"
                              : "primary"
                          }
                          options={locaisFiltered.map((option) => option)}
                          value={unidadeValue}
                          getOptionLabel={(option) => option.nome}
                          onChange={(e, value) => {
                            setIndisponibleOfCurrentProfessional([]);
                            if (value && value.id) {
                              setFieldValue("idUnidade", value.id);
                              setUnidadeValue(value);
                              setProfissionalValue({});
                              setProcedimentoValue({});
                              const profissionais = value.professionals.filter(
                                (prof) => prof.idServico === servicoValue.id
                              );
                              setProfissionais(profissionais);
                            } else {
                              setFieldValue("idUnidade", "");
                              setUnidadeValue({});
                              setProfissionalValue({});
                              setProcedimentoValue({});
                            }
                          }}
                          onBlur={handleBlur}
                          classes={{
                            popper: classes.option,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={handleBlur}
                              label="Unidade"
                              error={Boolean(
                                touched.idUnidade && errors.idUnidade
                              )}
                              helperText={touched.idUnidade && errors.idUnidade}
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          fullWidth
                          id="select-profissional"
                          color={
                            touched.idProfissional && errors.idProfissional
                              ? "error"
                              : "primary"
                          }
                          options={profissionais.map((option) => option)}
                          value={profissionalValue}
                          getOptionLabel={(option) => option.nome}
                          onChange={(e, value) => {
                            if (value && value.id) {
                              setFieldValue("idProfissional", value.id);
                              setProfissionalValue(value);
                              setProcedimentoValue({});
                              const procedimento = profissionais.filter(
                                (l) => l.id === value.id
                              )[0].procedimentos;
                              const initAlmoco = profissionais.filter(
                                (l) => l.id === value.id
                              )[0].horaAlmocoInicio;
                              const fimAlmoco = profissionais.filter(
                                (l) => l.id === value.id
                              )[0].horaAlmocoFim;
                              const localIndis = indisponible.filter(
                                (i) =>
                                  i.professionals.filter((p) => p.id === value.id)
                                    .length > 0
                              );
                              setAlmocoInit(initAlmoco);
                              setAlmocoFim(fimAlmoco);
                              setProcedimentos(procedimento);
                              setIndisponibleOfCurrentProfessional(localIndis);
                              setFieldValue("data", "");
                            } else {
                              setFieldValue("idProfissional", "");
                              setProfissionalValue({});
                              setProcedimentoValue({});
                              setIndisponibleOfCurrentProfessional([]);
                            }
                          }}
                          onBlur={handleBlur}
                          classes={{
                            popper: classes.option,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={handleBlur}
                              label="Profissional/Recurso"
                              error={Boolean(
                                touched.idProfissional && errors.idProfissional
                              )}
                              helperText={
                                touched.idProfissional && errors.idProfissional
                              }
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Autocomplete
                          fullWidth
                          id="select-procedimento"
                          color={
                            touched.idProcedimento && errors.idProcedimento
                              ? "error"
                              : "primary"
                          }
                          options={procedimentos.map((option) => option)}
                          value={procedimentoValue}
                          getOptionLabel={(option) => option.nome}
                          onChange={(e, value) => {
                            if (value && value.id) {
                              setFieldValue("idProcedimento", value.id);
                              setProcedimentoValue(value);
                              setProcedimentoForDependent(
                                value.disponivelDependente
                              );
                              const procedure = procedimentos.filter(
                                (l) => l.id === value.id
                              )[0].duracao;
                              const autoConfirmation = procedimentos.filter(
                                (l) => l.id === value.id
                              )[0].confirmation;
                              setProcedures(procedure);
                              setFieldValue(
                                "automaticConfirmation",
                                autoConfirmation
                              );
                            } else {
                              setFieldValue("idProcedimento", "");
                              setProcedimentoValue({});
                            }
                          }}
                          onBlur={handleBlur}
                          classes={{
                            popper: classes.option,
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onBlur={handleBlur}
                              label="Procedimento"
                              error={Boolean(
                                touched.idProcedimento && errors.idProcedimento
                              )}
                              helperText={
                                touched.idProcedimento && errors.idProcedimento
                              }
                              variant="outlined"
                            />
                          )}
                        />
                        {!procedimentoForDependent &&
                          values.idProcedimento != "" &&
                          schedule.userType != "TITULAR" && (
                            <Alert severity={"warning"} style={{ marginTop: 5 }}>
                              Procedimento disponível somente para titular
                            </Alert>
                          )}
                      </Grid>
                      {(stateShow) ?
                        <Grid item md={6} xs={12}>
                          <FormControlLabel
                            value="end"
                            name="exception"
                            control={
                              <Checkbox
                                checked={values.exception}
                                name="exception"
                                color="primary"
                                onChange={(event) => {
                                  handleChange(event)
                                  setException(event.target.checked)
                                }}
                              />
                            }
                            label="Horário extra"
                            labelPlacement="end"
                          />
                        </Grid> : ""}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    style={{ marginBottom: 50, marginLeft: 0 }}
                  >
                    <Grid container md={12} spacing={3}>
                      <Grid item md={6} xs={12} style={{ marginTop: -16 }}>
                        <MuiThemeProvider theme={defaultMaterialTheme}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={pt}
                          >
                            <DatePicker
                              fullWidth
                              margin="normal"
                              id="data"
                              label="Data"
                              format="dd/MM/yyyy"
                              autoOk
                              shouldDisableDate={disableWeekDays}
                              error={!stateShow}
                              helperText={stateShow ? "" : "Selecione uma data para continuar"}
                              disabled={
                                values.idProcedimento != "" ? false : true
                              }
                              value={date}
                              onFocus={() => onDateFocus()}
                              onChange={(newValue) => {
                                setStateShow(true)
                                onDateChange(newValue, values.exception, setFieldValue);
                              }}
                              inputVariant={"outlined"}
                              variant="inline"
                              minDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        </MuiThemeProvider>
                      </Grid>
                      {values.exception && (

                        <Grid item md={6} xs={12} style={{ marginTop: -16 }}>
                          <MuiThemeProvider theme={defaultMaterialTheme}>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={pt}
                            >
                              <TimePicker
                                fullWidth
                                id="time-picker-init"
                                label="Hora"
                                margin={"normal"}
                                autoOk
                                minDateMessage
                                minutesStep={5}
                                hideTabs
                                ampm={false}
                                disabled={values.data !== "" ? false : true}
                                error={Boolean(
                                  touched.horaInicio && errors.horaInicio
                                )}
                                helperText={touched.horaInicio && errors.horaInicio}
                                value={hour}
                                onChange={(newValue) => {
                                  pickHour(
                                    newValue,
                                    values.data,
                                    setFieldValue,
                                    values.exception,
                                    false
                                  );
                                }}
                                variant="inline"
                                inputVariant={"outlined"}
                              />
                            </MuiPickersUtilsProvider>
                          </MuiThemeProvider>
                        </Grid>
                      )}
                      {/* {!_.isEmpty(procedimentoValue) ?
                        <Grid item md={12} xs={12}>
                          <TreeView
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}                            
                          >
                            <TreeItem nodeId="1" label="Agendamentos">
                              {
                                professionalSchedules.length > 0 ? (professionalSchedules.map((p, i) => (
                                    <TreeItem nodeId={`${i + 2}`} label={`${p.user.nome} - ${p.horaInicio} / ${p.horaFim} ${!!p.exception ? '(Exceção)' : ''}`} />
                                  ))) : (
                                    <TreeItem nodeId='2' label='Nenhum atendimento' />
                                  )
                              }
                            </TreeItem>
                          </TreeView>
                        </Grid>
                        : null} */}
                    </Grid>
                  </Grid>

                  {!_.isEmpty(procedimentoValue) ? (
                    (stateShow == true) ? (
                      <Grid
                        container
                        md={12}
                        spacing={3}
                        style={{ marginBottom: 40 }}
                      >
                        <Grid item md={6}>
                          <Grid item md={12}>
                            <Typography variant={"h4"}>
                              Quadro de horários
                            </Typography>
                            <Divider style={{ marginBottom: 30 }} />
                          </Grid>
                          <Grid container md={12}>
                            {/* aqui mostra o quadro de horas */}
                            {
                              professionalHours.length > 0 ? (professionalHours.map((p, i) => {
                                const { data } = values;
                                const { hour } = p;

                                if (professionalHours.at(-1).keyId === p.keyId) {
                                  return null;
                                }

                                if (hour >= almocoInit && hour < almocoFim) {
                                  return null;
                                }
                                // aqui termina o que mostra os quadros de horas, retirando o horario de almoço. não faz divisão com o horario procedimento
                                for (const unavailable of indisponibleOfCurrentProfessional) {
                                  const startHour = unavailable.horaInit.length === 4 ? '0' + unavailable.horaInit : unavailable.horaInit;
                                  const endHour = unavailable.horaFim === 4 ? '0' + unavailable.horaFim : unavailable.horaFim;
                                  const startDate = new Date(unavailable.dataInit);
                                  const endDate = new Date(unavailable.dataFim);
                                  const procTimesegundos = parseFloat(procTime.duracao)* 60
                                  const horaagendamento = parseFloat(hour.replace(/:/i, ".")) * 3600
                                  const horaInicialAgendamentoFim = procTimesegundos + horaagendamento;
                                  const indisponibilidadeemsegundosinicial = parseFloat(startHour.replace(/:/i, ".")) * 3600
                                  const indisponiblidadeemsegundosfim = parseFloat(endHour.replace(/:/i, ".")) * 3600
                                  if (data >= startDate && data <= endDate) {
                                    if (
                                      (indisponibilidadeemsegundosinicial <= horaagendamento && horaagendamento <= indisponiblidadeemsegundosfim) || (horaagendamento <= indisponibilidadeemsegundosinicial && indisponibilidadeemsegundosinicial < horaInicialAgendamentoFim)
                                      ) {
                                      p.picked = true;
                                    }
                                  }
                                }

                                return (
                                  <Grid item md={3} key={p.keyId}
                                    className={classes.cardHours}
                                    data-tip
                                    data-for={p.keyId}
                                    onClick={(a) => {
                                      if (!p.picked) {
                                        setHourPicked(p)
                                        pickHour(
                                          p.hour,
                                          values.data,
                                          setFieldValue,
                                          values.exception,
                                          true
                                        );
                                        p.selected = p.keyId;
                                        setCard(p.keyId);
                                      }
                                    }}
                                  >
                                    <a
                                      data-tip
                                      data-for={p.keyId}
                                      style={{
                                        backgroundColor: card == p.selected ? '#A93533' : p.picked ? '#313131' : '#E5E5E5',
                                        width: '90%',
                                        padding: '12px 0px',
                                        borderRadius: 12,
                                        color: p.picked || card == p.selected ? 'white' : 'black',
                                      }}
                                    >{p.hour}</a>
                                    <ReactTooltip
                                      id={p.keyId}
                                      effect='solid'
                                      backgroundColor='#313131'
                                    >
                                      <Grid>
                                        {!!p.user && (
                                          <Avatar
                                            className={classes.Avatar}
                                            src={!!p.user ? p.user.img ? URL.createObjectURL(p.user.img) : p.user.imgProfile : ''}
                                            style={{
                                              width: 50,
                                              height: 50,
                                            }}
                                          />
                                        )}
                                        <Typography
                                          variant={'p'}
                                          style={{
                                            color: 'white'
                                          }}
                                        >
                                          {`${!!p.user ? p.user.nome : p.picked ? 'Horário Indisponível' : 'Horário Disponivel'}`}
                                        </Typography>
                                      </Grid>
                                    </ReactTooltip>
                                  </Grid>
                                );
                              })) : null
                            }
                          </Grid>
                        </Grid>

                        <Grid item md={6}>
                          <Grid item md={12}>
                            <Typography variant={"h4"}>
                              {'Horários extras (personalizados)'}
                            </Typography>
                            <Divider style={{ marginBottom: 30 }} />
                          </Grid>
                          <Grid container md={12}>
                            {professionalSchedulesException.length > 0
                              ? professionalSchedulesException.map((p, i) => (
                                <Grid
                                  item
                                  md={3}
                                  key={`${p.user.id}_${p.horaInicio}`}
                                  className={classes.cardHours}
                                  data-tip
                                  data-for={`${p.user.id}_${p.horaInicio}`}
                                >
                                  <a
                                    data-tip
                                    data-for={`${p.user.id}_${p.horaInicio}`}
                                    style={{
                                      backgroundColor: "#313131",
                                      width: "90%",
                                      padding: "12px 0px",
                                      borderRadius: 12,
                                      color: 'white'
                                    }}
                                  >
                                    {p.horaInicio}
                                  </a>
                                  <ReactTooltip
                                    id={`${p.user.id}_${p.horaInicio}`}
                                    effect="solid"
                                    backgroundColor="#313131"
                                  >
                                    <Grid>
                                      <Avatar
                                        className={classes.Avatar}
                                        src={
                                          p.user.img
                                            ? URL.createObjectURL(p.user.img)
                                            : p.user.imgProfile
                                        }
                                        style={{
                                          width: 50,
                                          height: 50,
                                        }}
                                      />
                                      <Typography
                                        variant={"p"}
                                        style={{
                                          color: "white",
                                        }}
                                      >
                                        {p.user.nome}
                                      </Typography>
                                    </Grid>
                                  </ReactTooltip>
                                </Grid>
                              ))
                              : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null
                  ) : null}

                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <Typography variant={"h4"}>
                        Informações adicionais
                      </Typography>
                      <Divider />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <TextField
                        multiline
                        fullWidth
                        error={Boolean(touched.observacao && errors.observacao)}
                        helperText={touched.observacao && errors.observacao}
                        label="Observações"
                        name="observacao"
                        id="observacao"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.observacao}
                        variant="outlined"
                        InputProps={{
                          classes: { input: classes.textArea },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginBottom: 20,
                      marginTop: 20,
                      paddingLeft: 20,
                    }}
                    alignItems="flex-end"
                    justify="flex-end"
                  >
                    <Grid item md={3}>
                      <Button
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={goBack}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item md={3}>
                      <Button
                        onClick={() => handleSubmit()}
                        // disabled={isSubmitting}
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            );
          }}
        </Formik>
        <Snackbar
          open={blockAlert}
          autoHideDuration={4000}
          onClose={handleCloseBlockAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert onClose={handleCloseBlockAlert} severity="error">
            Horário bloqueado!
          </Alert>
        </Snackbar>
        <Snackbar
          open={horarioOcupado}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <AlertBar onClose={handleClose} severity="error">
            Horário não disponível
          </AlertBar>
        </Snackbar>
        <Snackbar open={invalidHour} autoHideDuration={3000} onClose={handleCloseBlockAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <AlertBar onClose={handleCloseBlockAlert} severity="error">
            Horário não compatível com a agenda do Profissional! Selecione um horario compativel.
          </AlertBar>
        </Snackbar>
        <Modal
          className={classes.modal}
          open={modal}
          onClose={() => closeModal()}
          keepMounted
        >
          <Container maxWidth='lg' spacing={3}
            style={{
              backgroundColor: 'white',
              height: '100%',
              overflow: 'auto',
              padding: 30,
              margin: '0 auto',
              borderRadius: 4,
              border: '1px solid #000000',
              top: 100
            }}
          >
            <Grid container spacing={3} style={{ marginBottom: 15 }}>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  id="status"
                  options={modalFilter.status.map((option) => option)}
                  value={modalSearchFilterValue.status}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option ? statusName(option.status) : ''}
                  onChange={(e, value) => {
                    setModalSearchFilter({ ...modalSearchFilter, status: value ? value.status : '' });
                    setModalSearchFilterValue({ ...modalSearchFilterValue, status: value ? statusName(value.status) : '' });
                  }}
                  classes={{
                    popper: classes.option,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={modalSearchFilter.status}
                      label="Status"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  id="service"
                  options={modalFilter.servico.map((option) => option)}
                  value={modalSearchFilterValue.servico}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option ? option.nome : ''}
                  onChange={(e, value) => {
                    setModalSearchFilter({ ...modalSearchFilter, servico: value ? value.id : '' });
                    setModalSearchFilterValue({ ...modalSearchFilterValue, servico: value ? value.nome : '' });
                  }}
                  classes={{
                    popper: classes.option,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Serviço"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  id="procedure"
                  options={modalFilter.procedimento.map((option) => option)}
                  value={modalSearchFilterValue.procedimento}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option ? option.nome : ''}
                  onChange={(e, value) => {
                    setModalSearchFilter({ ...modalSearchFilter, procedimento: value ? value.id : '' });
                    setModalSearchFilterValue({ ...modalSearchFilterValue, procedimento: value ? value.nome : '' });
                  }}
                  classes={{
                    popper: classes.option,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Procedimento"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  id="professional"
                  options={modalFilter.profissional.map((option) => option)}
                  value={modalSearchFilterValue.profissional}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option ? option.nome : ''}
                  onChange={(e, value) => {
                    setModalSearchFilter({ ...modalSearchFilter, profissional: value ? value.id : '' });
                    setModalSearchFilterValue({ ...modalSearchFilterValue, profissional: value ? value.nome : '' });
                  }}
                  classes={{
                    popper: classes.option,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Profissional/Recurso"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  fullWidth
                  id="unity"
                  options={modalFilter.unidade.map((option) => option)}
                  value={modalSearchFilterValue.unidade}
                  getOptionLabel={(option) => typeof option === 'string' ? option : option ? option.nome : ''}
                  onChange={(e, value) => {
                    setModalSearchFilter({ ...modalSearchFilter, unidade: value ? value.id : '' });
                    setModalSearchFilterValue({ ...modalSearchFilterValue, unidade: value ? value.nome : '' });
                  }}
                  classes={{
                    popper: classes.option,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Unidade"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              {/* jeito com modalzinho de horas abaixo porem com problemas no get e push */}
              <Grid item xs={3}>
                <MuiThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                    <DatePicker
                      error={Boolean(modalSearchFilterValue.dataInicial > modalSearchFilterValue.dataFinal)}
                      helperText={(modalSearchFilterValue.dataInicial > modalSearchFilterValue.dataFinal) ? "Data inicial deve ser menor que a Data final " : ""}
                      label="Data Inicial"
                      fullWidth
                      margin="normal"
                      id="dateInit"
                      format="dd/MM/yyyy"
                      autoOk
                      value={(modalSearchFilterValue.dataInicial)}
                      onChange={(value) => {
                        setModalSearchFilter({ ...modalSearchFilter, dataInicial: value });
                        setModalSearchFilterValue({ ...modalSearchFilterValue, dataInicial: value });
                      }}
                      inputVariant={'outlined'}
                      variant='inline'
                      style={{ marginTop: 0 }}
                    />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
              </Grid>
              <Grid item xs={3}>
                <MuiThemeProvider theme={defaultMaterialTheme}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
                    <DatePicker
                      error={Boolean(modalSearchFilterValue.dataInicial > modalSearchFilterValue.dataFinal)}
                      helperText={(modalSearchFilterValue.dataInicial > modalSearchFilterValue.dataFinal) ? "Data Final deve ser maior que a Data inicial" : ""}
                      autoOk
                      fullWidth
                      id="dataFinal"
                      label="Data Final"
                      margin="normal"
                      format="dd/MM/yyyy"
                      value={modalSearchFilterValue.dataFinal}
                      onChange={(value) => {
                        setModalSearchFilter({ ...modalSearchFilter, dataFinal: value });
                        setModalSearchFilterValue({ ...modalSearchFilterValue, dataFinal: value });
                      }}
                      inputVariant={'outlined'}
                      variant='inline'
                      style={{ marginTop: 0 }}
                    />
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
              </Grid>
              {/* jeito antigo abaixo */}
              {/* <Grid item xs={3}>
              <Autocomplete
                fullWidth
                id="dateInit"
                options={modalFilter.data.map((option) => option)}
                value={modalSearchFilterValue.dataInicial}
                getOptionLabel={(option) => typeof option === 'string' ? option : option ? option.data : ''}
                onChange={(e, value) => {
                  setModalSearchFilter({ ...modalSearchFilter, dataInicial: value ? value.data : '' });
                  setModalSearchFilterValue({ ...modalSearchFilterValue, dataInicial: value ? value.data : '' });
                }}
                classes={{
                  popper: classes.option,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Data Inicial"
                    variant="outlined"
                  />
                )}
              />
            </Grid> */}
              {/* <Grid item xs={3}>
              <Autocomplete
                fullWidth
                id="dateFinal"
                options={modalFilter.data.map((option) => option)}
                value={modalSearchFilterValue.dataFinal}
                getOptionLabel={(option) => typeof option === 'string' ? option : option ? option.data : ''}
                onChange={(e, value) => {
                  setModalSearchFilter({ ...modalSearchFilter, dataFinal: value ? value.data : '' });
                  setModalSearchFilterValue({ ...modalSearchFilterValue, dataFinal: value ? value.data : '' });
                }}
                classes={{
                  popper: classes.option,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="DataFinal"
                    variant="outlined"
                  />
                )}
              />
            </Grid> */}
              <Grid item md={3}>
                <Button
                  onClick={async () => {
                    openModal()
                    try {
                      if (modalSearchFilterValue.dataInicial < modalSearchFilterValue.dataFinal) {
                        const idUser = schedule.id;
                        const historic = await getHistoricUser(idUser, modalSearchFilter);
                        setHistoricData(historic);
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  style={{ height: "3.7em " }}
                >
                  aplicar
                </Button>
              </Grid>
            </Grid>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table" style={{ overflow: "max-content" }}>
                <TableHead className={classes.headerTb}>
                  <TableRow>
                    {/* <TableCell key={'id'} align="left">
                    {"ID"}
                  </TableCell> */}
                    <TableCell key={'status'} align="left">
                      {"Status"}
                    </TableCell>
                    <TableCell key={'name'} align="left">
                      {"Nome"}
                    </TableCell>
                    <TableCell key={'service'} align="left">
                      {"Serviço"}
                    </TableCell>
                    <TableCell key={'procedure'} align="left">
                      {"Procedimento"}
                    </TableCell>
                    <TableCell key={'profissional'} align="left">
                      {"Profissional/Recurso"}
                    </TableCell>
                    <TableCell key={'unidade'} align="left">
                      {"Unidade"}
                    </TableCell>
                    <TableCell key={'date'} align="left" >
                      {"Data"}
                    </TableCell>
                    <TableCell key={'hour'} align="left" >
                      {"Horário"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historicData.map((row, index) => {
                    const data = format(new Date(row.data + 'T04:00:00'), "dd/MM/yyyy");
                    return (
                      <TableRow hover key={row.id}>
                        {/* <TableCell>{row.id}</TableCell> */}
                        <TableCell>{statusName(row.status)}</TableCell>
                        <TableCell>{row.user.nome}</TableCell>
                        <TableCell>{row.servico.nome}</TableCell>
                        <TableCell>{row.procedimento ? row.procedimento.nome : ''}</TableCell>
                        <TableCell>{row.profissional.nome}</TableCell>
                        <TableCell>{row.unidade.nome}</TableCell>
                        <TableCell>{data}</TableCell>
                        <TableCell>{row.horaInicio} até {row.horaFim}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Modal>
      </Container>
      <Backdrop className={classes.backdrop} open={open}>
        <ReactLoading type={"spinningBubbles"} color={color.red} />
      </Backdrop>
    </>
  );
}
