import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  Typography,
  Grid,
  CardContent,
  Card,
  Divider,
  CircularProgress
} from '@material-ui/core';
import { color } from '../../../../components/common/colors';
import { Rating } from '@material-ui/lab';
import PropTypes from 'prop-types';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { format } from 'date-fns';
import { update } from '../../../../providers/partnerAvaliation'
import { useSelector } from 'react-redux';
import { getPermissions } from '../../../../providers/permissions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%'
  },
  Card: {
    background: color.white
  },
  paper: {
    backgroundColor: color.white,
    width: '100%'
  },
  btnAdd: {
    color: color.white,
    background: color.red,
    '&:hover': {
      backgroundColor: '#a35655',
    },
  },
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
  item: {
    color: '#979797',
    letterSpacing: 1.5
  }
}));

export default function DataAvaliacao({ setState, avaliation }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [newPermission, setNewPermission] = useState({ viz: false, det: false, edit: false });
  const goBack = () => {
    setState(0)
  }

  const userData = useSelector(({ user }) => user);
  
  useEffect(() => {
    const fetchPermissions = async () => {
      const { id } = userData;
      const permissions = await getPermissions(id);
      setNewPermission(permissions.permissions.partnerAvaliation);
    };

    fetchPermissions();
  }, []);

  const toApprove = async () => {
    avaliation.accepted = true
    try{
      setLoading(true)
      const data = await update(avaliation)
    }catch(e){
      console.log(e)
    }finally{
      setLoading(false)
      goBack()
    }
  }

  return (
    <Container maxWidth="md" disableGutters>
      <Card className={classes.Card}>
        <CardContent>
          <Grid spacing={3}>
            <Grid md={12}>
              <Typography style={{ marginBottom: 30 }}>
                <h1>
                  Avaliação #{avaliation.id}
                </h1>
              </Typography>
            </Grid>
            <Grid md={12}>
              <Typography>
                <h3>
                  Dados do Parceiro
                </h3>
              </Typography>
              <Divider style={{ marginBottom: 10 }} />
            </Grid>
            <Grid md={12} style={{ marginBottom: 30 }}>
              <Typography className={classes.item}>Parceiro</Typography>
              {avaliation.unity.name}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Nota</Typography>
              <div>
                <Rating
                  defaultValue={avaliation.note}
                  precision={0.5}
                  readOnly
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </div>
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Comentário</Typography>
              {avaliation.comment}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Data</Typography>
              {format(new Date(avaliation.createdAt), 'dd/MM/yyyy')}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Hora</Typography>
              {format(new Date(avaliation.createdAt), 'HH:mm')}
            </Grid>
            <Grid md={12} style={{ marginBottom: 20 }}>
              <Typography className={classes.item}>Usuário</Typography>
              {avaliation.user.nome}
            </Grid>
            <Grid container spacing={1} alignItems='center' justify='center' className={classes.btns}>
              <Grid item md={3} xs={12}>
                <Button
                  fullWidth
                  color='primary'
                  size="large"
                  variant="contained"
                  onClick={goBack}
                >
                  Voltar
                        </Button>
              </Grid>
              <Grid item md={3} xs={12}>
                {newPermission.edit && (
                  <Button
                    fullWidth
                    color='primary'
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => toApprove()}
                  >
                    {loading ? <CircularProgress color={'white'} size={26}/>  : 'Aprovar'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}